import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setRoom }          from "Actions/Store/ProductActions";
import API                  from "Utils/Core/API";
import Utils                from "Utils/Common/Utils";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Product/Item/ProductRoom.css";



/**
 * The Product Room
 */
class ProductRoom extends React.Component {
    /**
     * Closes the Room
     * @returns {Void}
     */
    handleClose = () => {
        this.props.setRoom(null);
    }

    /**
     * Handles the Dialog Backdrop Close
     * @param {Event} e
     * @returns {Void}
     */
    handleBackdrop = (e) => {
        if (!Utils.hasClass(e.target, "product-room-image")) {
            this.handleClose();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { room } = this.props;

        if (!room) {
            return <React.Fragment />;
        }
        return <div className="product-room">
            <div className="product-room-content" onClick={this.handleBackdrop}>
                <img
                    className="product-room-image"
                    src={API.Product.getRoomImage({ product : room.productID })}
                    alt={room.content}
                />
            </div>
            <HyperLink
                className="product-room-close"
                variant="icon"
                icon="close"
                onClick={this.handleClose}
            />
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setRoom : PropTypes.func.isRequired,
        room    : PropTypes.object,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            room : state.product.room,
        };
    }
}

export default connect(ProductRoom.mapStateToProps, {
    setRoom,
})(ProductRoom);
