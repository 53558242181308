import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchEntry }       from "Actions/Content/ContentActions";
import NLS                  from "Utils/Core/NLS";
import Pages                from "Utils/App/Pages";
import DateTime             from "Utils/Common/DateTime";
import Utils                from "Utils/Common/Utils";

// Components
import ErrorPage            from "Components/Content/ErrorPage";
import PageContent          from "Components/Content/Page/PageContent";
import Title                from "Components/Utils/Title/Title";
import Html                 from "Components/Utils/Common/Html";

// Styles
import "Styles/Components/Content/Entry/EntryPage.css";



/**
 * The Entry Page
 */
class EntryPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = Pages.getParams(this.props.match.params);
        this.props.fetchEntry(params.entry, params.page);
    }

    /**
     * Load the New Data
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = Pages.getParams(prevProps.match.params);
        const newParams = Pages.getParams(this.props.match.params);

        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchEntry(newParams.entry, newParams.page);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, error, elem, match } = this.props;

        if (!loading && error) {
            return <ErrorPage />;
        }
        const showInfo      = Boolean(!loading && elem.showInfo);
        const titleInInfo   = Boolean(elem.showInfo && elem.titleInInfo);
        const titleInHeader = Boolean(!titleInInfo && elem.showTitle);
        const date          = DateTime.formatString(elem.createdTime);
        const style         = elem.infoMaxWidth ? { "maxWidth" : `${elem.infoMaxWidth}px` } : null;

        return <>
            <Title
                message={elem.name}
                background={elem.bannerImageUrl}
                height={elem.headerHeight}
                showTitle={titleInHeader}
                noBotomSpace={!loading && !elem.showBorder}
            />
            <PageContent
                loading={loading}
                elem={elem}
                match={match}
            >
                {showInfo && <header className="entry-info" style={style}>
                    {!!elem.category && <h4 className="entry-info-category">
                        {elem.category}
                    </h4>}
                    {titleInInfo && <h2 className="entry-info-title">
                        {elem.name}
                    </h2>}
                    <Html variant="p" className="entry-info-summary">
                        {elem.descriptionHtml}
                    </Html>
                    <p className="entry-info-author">
                        {NLS.format("ENTRIES_AUTHOR_DATE", elem.credentialName, date)}
                    </p>
                </header>}
            </PageContent>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchEntry : PropTypes.func.isRequired,
        loading    : PropTypes.bool.isRequired,
        error      : PropTypes.bool.isRequired,
        elem       : PropTypes.object.isRequired,
        match      : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading : state.content.loading,
            error   : state.content.error,
            elem    : state.content.entry,
        };
    }
}

export default connect(EntryPage.mapStateToProps, {
    fetchEntry,
})(EntryPage);
