import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/Core/NLS";

// Components
import Dialog               from "Components/Utils/Dialog/Dialog";
import DialogContent        from "Components/Utils/Dialog/DialogContent";
import DialogFooter         from "Components/Utils/Dialog/DialogFooter";
import Button               from "Components/Utils/Form/Button";



/**
 * The Delete Dialog
 */
class DeleteDialog extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, message, content, onSubmit, onClose } = this.props;

        return <Dialog
            open={open}
            message={message}
            icon="delete"
            onClose={onClose}
            hasFooter
        >
            <DialogContent withSpacing>
                <h3 className="dialog-delete">{NLS.get(content)}</h3>
            </DialogContent>
            <DialogFooter>
                <Button
                    variant="primary"
                    message="GENERAL_DELETE"
                    onClick={onSubmit}
                />
                <Button
                    variant="cancel"
                    message="GENERAL_CANCEL"
                    onClick={onClose}
                />
            </DialogFooter>
        </Dialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open     : PropTypes.bool.isRequired,
        message  : PropTypes.string.isRequired,
        content  : PropTypes.string.isRequired,
        onSubmit : PropTypes.func.isRequired,
        onClose  : PropTypes.func.isRequired,
    }
}

export default DeleteDialog;
