import React                from "react";
import PropTypes            from "prop-types";
import Href                 from "Utils/Core/Href";

// Share
import {
    FacebookShareButton,  FacebookIcon,
    TwitterShareButton,   TwitterIcon,
    PinterestShareButton, PinterestIcon,
    WhatsappShareButton,  WhatsappIcon,
} from "react-share";



/**
 * The Product Share
 */
class ProductShare extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { message, url, image } = this.props;

        const fullUrl   = Href.fullUrl("PRODUCTS", url);
        const iconProps = { size : 32, round : true };

        return <div className="product-share">
            <FacebookShareButton
                url={fullUrl}
                quote={message}
            >
                <FacebookIcon { ...iconProps } />
            </FacebookShareButton>
            <TwitterShareButton
                url={fullUrl}
                title={message}
            >
                <TwitterIcon { ...iconProps } />
            </TwitterShareButton>
            <PinterestShareButton
                url={fullUrl}
                description={message}
                media={image}
            >
                <PinterestIcon { ...iconProps } />
            </PinterestShareButton>
            <WhatsappShareButton
                url={fullUrl}
                title={message}
                separator=" :: "
            >
                <WhatsappIcon { ...iconProps } />
            </WhatsappShareButton>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        message   : PropTypes.string.isRequired,
        url       : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
        image     : PropTypes.string.isRequired,
    }
}

export default ProductShare;
