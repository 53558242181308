import React                from "react";
import PropTypes            from "prop-types";
import { Switch, Redirect } from "react-router-dom";
import Href                 from "Utils/Core/Href";



/**
 * The Switch Route Component
 */
class SwitchRoute extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { initialUrl, children } = this.props;

        const childs   = Array.isArray(children) ? children : [ children ];
        const items    = [];
        let   firstUrl = initialUrl;

        for (const [ key, child ] of childs.entries()) {
            if (!child.props || child.props.isHidden) {
                continue;
            }
            const path = Href.url(child.props.url);
            if (!firstUrl) {
                firstUrl = child.props.url;
            }
            items.push(React.cloneElement(child, { key, path }));
        }

        return <Switch>
            {items}
            {!!firstUrl && <Redirect from="*" to={Href.url(firstUrl)} />}
        </Switch>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        initialUrl : PropTypes.string,
        children   : PropTypes.any,
    }
}

export default SwitchRoute;
