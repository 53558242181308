import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Title/SubTitle";
import TextField            from "Components/Utils/Form/TextField";
import Html                 from "Components/Utils/Common/Html";



/**
 * The Cart Message
 */
class CartMessage extends React.Component {
    /**
     * Returns true if the Message should be hidden
     * @returns {Boolean}
     */
    isHidden() {
        const { settings, elem, step } = this.props;
        if (!settings.cart_messageRequested) {
            return true;
        }
        if (step === "products" && (!elem.hideShipment || !elem.hidePayment)) {
            return true;
        }
        if (step === "shipment" && !elem.hidePayment) {
            return true;
        }
        return false;
    }

    /**
     * Renders the Content
     * @returns {Object}
     */
    render() {
        const { settings, value, error, onChange } = this.props;

        if (this.isHidden()) {
            return <React.Fragment />;
        }
        return <Card className="cart-message-card" withBorder>
            <SubTitle message="CART_MESSAGE" icon="query" />
            {!!settings.cart_messageData && <Html className="spacing" content={settings.cart_messageData} />}
            <div className="spacing">
                <TextField
                    type="textarea"
                    name="message"
                    label="CART_WRITE_MESSAGE"
                    value={value}
                    error={error}
                    onChange={onChange}
                    isLarge
                    autoGrow
                />
            </div>
        </Card>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
        elem     : PropTypes.object.isRequired,
        step     : PropTypes.string.isRequired,
        value    : PropTypes.string,
        error    : PropTypes.string,
        onChange : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            elem     : state.cart.elem,
        };
    }
}

export default connect(CartMessage.mapStateToProps)(CartMessage);
