import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setSort }          from "Actions/Store/StoreActions";
import Href                 from "Utils/Core/Href";
import Pages                from "Utils/App/Pages";
import Sort                 from "Utils/App/Sort";
import ClassList            from "Utils/Common/ClassList";

// Components
import ImageList            from "Components/Content/Sections/ImageList";
import BlockList            from "Components/Content/Sections/BlockList";
import ArticleList          from "Components/Content/Sections/ArticleList";
import TestimonialList      from "Components/Content/Sections/TestimonialList";
import BrandList            from "Components/Content/Sections/BrandList";
import LatestCatalogue      from "Components/Content/Sections/LatestCatalogue";
import ArtistList           from "Components/Content/Artist/ArtistList";
import ArtistProducts       from "Components/Content/Artist/ArtistProducts";
import EntryList            from "Components/Content/Entry/EntryList";
import ContactForm          from "Components/Content/Contact/ContactForm";
import ContactData          from "Components/Content/Contact/ContactData";
import ProductsView         from "Components/Product/List/ProductsView";
import ProductBanner        from "Components/Product/Item/ProductBanner";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Html                 from "Components/Utils/Common/Html";
import ImageLink            from "Components/Utils/Media/ImageLink";
import Video                from "Components/Utils/Media/Video";
import Embed                from "Components/Utils/Media/Embed";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Content/Sections/Sections.css";



/**
 * The Sections
 */
class Sections extends React.Component {
    /**
     * Updates the Sort and goes to the Url
     * @param {Number} value
     * @returns {Void}
     */
    sortAndGoto = async (url, value) => {
        const { isAuthenticated, preferences, setSort } = this.props;
        if (value !== preferences.sort) {
            await setSort(value, isAuthenticated);
        }
        Href.goto(url);
    }



    /**
     * Does the Content Render
     * @param {Object} section
     * @returns {Object}
     */
    renderContent(section) {
        const { match, forHome } = this.props;
        const page = Pages.getParams(match.params).page;

        let url = String(match.url);
        if (url.endsWith(`/${page}`)) {
            url = url.substr(0, url.length - `/${page}`.length);
        }

        switch (section.type) {
        case "products":
            return <ProductsView
                {...section}
                message={section.name || "PRODUCTS_TITLE"}
                url="PRODUCTS"
                list={section.products}
                forHome={forHome}
            />;
        case "category":
            return <ProductsView
                {...section}
                message={section.name || section.categoryName}
                href={Href.url("PRODUCTS", section.categorySlug)}
                list={section.products}
                forHome={forHome}
            />;
        case "brand":
            return <ProductsView
                {...section}
                message={section.name || section.brandName}
                href={Href.url("BRAND", section.brandSlug)}
                list={section.products}
                forHome={forHome}
            />;
        case "artist":
            return <ProductsView
                {...section}
                message={section.name || section.artistName}
                href={Href.url("ARTIST", section.artistSlug)}
                list={section.products}
                forHome={forHome}
            />;

        case "bestsold":
            return <ProductsView
                {...section}
                message={section.name || "BESTSOLD_TITLE"}
                onClick={() => this.sortAndGoto("PRODUCTS", Sort.BEST_SOLD)}
                list={section.products}
                forHome={forHome}
            />;
        case "importants":
            return <ProductsView
                {...section}
                message={section.name || "IMPORTANTS_TITLE"}
                url="IMPORTANTS"
                list={section.products}
                forHome={forHome}
            />;
        case "newests":
            return <ProductsView
                {...section}
                message={section.name || "NEWESTS_TITLE"}
                url="NEWESTS"
                list={section.products}
                forHome={forHome}
            />;

        case "latests":
            return <ProductsView
                {...section}
                message={section.name || "LATESTS_TITLE"}
                onClick={() => this.sortAndGoto("PRODUCTS", Sort.NEWER_PRODUCT)}
                list={section.products}
                forHome={forHome}
            />
        case "trending":
            return <ProductsView
                {...section}
                message={section.name || "TRENDING_TITLE"}
                onClick={() => this.sortAndGoto("PRODUCTS", Sort.TRENDING)}
                list={section.products}
                forHome={forHome}
            />;
        case "purchases":
            return <ProductsView
                {...section}
                message={section.name || "PURCHASES_TITLE"}
                url="PURCHASES"
                list={section.products}
                forHome={forHome}
            />;
        case "favorites":
            return <ProductsView
                {...section}
                message={section.name || "FAVORITES_TITLE"}
                url="FAVORITES"
                list={section.products}
                forHome={forHome}
            />;
        case "history":
            return <ProductsView
                {...section}
                message={section.name || "HISTORY_TITLE"}
                url="HISTORY"
                list={section.products}
                forHome={forHome}
            />;


        case "lightnings":
            const result = [];
            for (const elem of section.list) {
                result.push(<ProductBanner
                    key={`${section.id}-${elem.id}`}
                    data={elem}
                />);
            }
            return result;
        case "promotions":
            return <ImageList
                {...section}
                message={section.name}
                url="OFFERS"
            />;
        case "categories":
            return <ImageList
                {...section}
                message={section.name}
                url="PRODUCTS"
                useSlug
            />;
        case "brands":
            return <BrandList
                {...section}
                message={section.name}
            />;
        case "artists":
            return <ArtistList
                {...section}
                message={section.name}
            />;
        case "artistsprods":
            return <ArtistProducts
                {...section}
                message={section.name}
                url={url}
                page={page}
            />;
        case "entries":
            return <EntryList
                {...section}
                message={section.name}
                url={url}
                page={page}
            />;


        case "blocks":
            return <BlockList
                {...section}
                message={section.name}
            />;
        case "articles":
            return <ArticleList
                {...section}
                message={section.name}
                url={url}
                page={page}
            />;
        case "testimonials":
            return <TestimonialList
                {...section}
                message={section.name}
                url={url}
                page={page}
            />;
        case "catalogue":
            return <LatestCatalogue
                {...section}
                message={section.name}
            />;
        case "form":
            return <ContactForm
                message={section.name}
            />;
        case "contact":
            return <ContactData
                {...section}
                message={section.name}
            />;


        case "button":
            return <Button
                variant="primary"
                href={section.href}
                target={section.targetBlank ? "_blank" : "_self"}
                message={section.name}
            />;
        case "image":
            return <ImageLink
                {...section}
                variant="opacity"
                target={section.targetBlank ? "_blank" : "_self"}
                source={section.imageUrl}
                mobileSource={section.imageMobileUrl}
            />;
        case "video":
            return <Video
                {...section}
                source={section.videoUrl}
                mobileSource={section.videoMobileUrl}
                title={section.name}
                maxWidth={section.width}
                autoPlay={Boolean(section.autoPlay)}
                controls={Boolean(section.controls)}
            />;
        case "map":
            return <Embed
                title={section.name}
                source={section.source}
            />;
        case "text":
            return <Html
                content={section.content}
            />;
        default:
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, sections } = this.props;
        const result = [];

        for (const section of sections) {
            const mainStyle = {};
            const wrapStyle = {};
            const classes   = new ClassList("section", `section-${section.id}`, `section-${section.type}`);
            classes.addIf("section-borderd", !section.fullWidth && section.showBorder);
            classes.addIf("section-rounded", !section.fullWidth && !section.fullWidthBg && settings.main_roundedBorders);

            if (!section.fullWidth && section.fullWidthBg) {
                mainStyle.backgroundColor = section.bgColor;
            }
            if (section.topSpace) {
                wrapStyle.paddingTop = `${section.topSpace}px`;
            }
            if (section.bottomSpace) {
                wrapStyle.paddingBottom = `${section.bottomSpace}px`;
            }
            if (section.bgColor) {
                wrapStyle.backgroundColor = section.bgColor;
            }
            if (section.titleColor) {
                wrapStyle["--tertitle-font"] = section.titleColor;
            }
            if (section.titleSize) {
                wrapStyle["--tertitle-size"] = `${section.titleSize}px`;
            }

            result.push(<div key={section.id} style={mainStyle}>
                <Wrapper
                    className={classes.get()}
                    maxWidth={section.maxWidth}
                    fullWidth={Boolean(section.fullWidth)}
                    style={wrapStyle}
                >
                    {this.renderContent(section)}
                </Wrapper>
            </div>);
        }
        return result;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setSort         : PropTypes.func.isRequired,
        settings        : PropTypes.object.isRequired,
        preferences     : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        sections        : PropTypes.array.isRequired,
        match           : PropTypes.object.isRequired,
        forHome         : PropTypes.bool,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            preferences     : state.store.preferences,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(Sections.mapStateToProps, {
    setSort,
})(Sections);
