import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleFilter }     from "Actions/Store/StoreActions";
import NLS                  from "Utils/Core/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Products Price Filter
 */
class FilterPrice extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, data, isSelected, getUrl, toggleFilter } = this.props;

        const shouldShow = Boolean(settings.products_showPriceFilter && settings.products_showPrice && data && data[1]);
        if (!shouldShow) {
            return <React.Fragment />;
        }

        const priceOptions = [
            { id : 0, name : NLS.get("PRODUCTS_PRICE_ALL"),                                 total : data[0].total },
            { id : 1, name : NLS.format("PRODUCTS_PRICE_TO",    data[1].to),               total : data[1].total },
            { id : 2, name : NLS.format("PRODUCTS_PRICE_RANGE", data[2].from, data[2].to), total : data[2].total },
            { id : 3, name : NLS.format("PRODUCTS_PRICE_FROM",  data[3].from),             total : data[3].total },
        ];

        return <div className="aside-options">
            <h4>{NLS.get("PRODUCTS_PRICE")}</h4>
            <ul className="no-list">
                {priceOptions.map((elem) => <li key={elem.id}>
                    <HyperLink
                        variant="opacity"
                        href={getUrl("price", elem)}
                        onClick={() => toggleFilter(false)}
                        icon={isSelected("price", elem.id) ? "check" : "blank"}
                    >
                        {elem.name}
                        <span className="aside-amount">{elem.total}</span>
                    </HyperLink>
                </li>)}
            </ul>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleFilter : PropTypes.func.isRequired,
        settings     : PropTypes.object.isRequired,
        data         : PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
        isSelected   : PropTypes.func.isRequired,
        getUrl       : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(FilterPrice.mapStateToProps, {
    toggleFilter,
})(FilterPrice);
