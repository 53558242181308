import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import HeaderWrapper        from "Components/Header/HeaderWrapper";
import HeaderLogo           from "Components/Header/HeaderLogo";
import SearchBar            from "Components/Header/Bar/SearchBar";
import SuperiorBar          from "Components/Header/Bar/SuperiorBar";
import MenuBar              from "Components/Header/Bar/MenuBar";
import UserBar              from "Components/Header/Bar/UserBar";
import SubMenuBar           from "Components/Header/Bar/SubMenuBar";



/**
 * The Center Logo Header
 * Two lines with the Logo Centered
 */
class CenterLogoHeader extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings } = this.props;

        return <HeaderWrapper design="center-logo">
            <section className="header-content">
                <div className="header-primary">
                    {!settings.header_searchRight && <SearchBar />}
                    {settings.header_searchRight && <SuperiorBar />}
                </div>
                <HeaderLogo />
                <div className="header-secondary">
                    {settings.header_searchRight && <SearchBar />}
                    {!settings.header_searchRight && <SuperiorBar />}
                </div>
            </section>
            <div className="header-menu">
                <MenuBar />
                <UserBar />
            </div>
            <SubMenuBar />
        </HeaderWrapper>;
    }


    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(CenterLogoHeader.mapStateToProps)(CenterLogoHeader);
