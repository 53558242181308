import { setProduct }       from "Actions/Store/ProductActions";
import esUrls               from "NLS/Urls/es.js";
import enUrls               from "NLS/Urls/en.js";

// Module variables
let store      = null;
let history    = null;
let urls       = null;



/**
 * Initialize the API
 * @param {Object} theStore
 * @param {Object} theHistory
 * @returns {Void}
 */
function init(theStore, theHistory, language) {
    store   = theStore;
    history = theHistory;

    const lang = language.split("-")[1];
    const data = { es : esUrls, en : enUrls };
    urls = data[lang];
}

/**
 * Creates an Internal Href
 * @param {...any} args
 * @returns {String}
 */
function url(...args) {
    const result = [];
    for (const arg of args) {
        const url = urls[arg] !== undefined ? urls[arg] : arg;
        if (url) {
            result.push(url);
        }
    }
    return result.join("/");
}

/**
 * Creates an External Href
 * @param {...any} args
 * @returns {String}
 */
function fullUrl(...args) {
    const baseUrl = url(...args);
    return process.env.REACT_APP_URL + baseUrl.substring(1);
}

/**
 * Creates an Voucher Href
 * @param {...any} args
 * @returns {String}
 */
function voucherUrl(...args) {
    return process.env.REACT_APP_VOUCHER + args.join("/");
}

/**
 * Returns an array of AuthRoutes
 * @returns {String[]}
 */
function authRoutes() {
    const keys = [ "LOGIN", "REGISTER", "RECOVER", "RESET", "CODE" ];
    const urls = [];
    for (const key of keys) {
        urls.push(url(key));
    }
    return urls;
}

/**
 * Returns true if the given href is from the App
 * @param {String} href
 * @returns {Boolean}
 */
function isInternal(href) {
    return href && href.startsWith(process.env.REACT_APP_URL) && !href.startsWith(process.env.REACT_APP_VOUCHER);
}

/**
 * Returns true if the given href is from the App
 * @param {String} href
 * @returns {Boolean}
 */
function forVoucher(href) {
    return href && href.startsWith(process.env.REACT_APP_VOUCHER);
}

/**
 * Returns the url without the complete path
 * @param {String} href
 * @returns {Boolean}
 */
function getUrl(href) {
    return href.replace(process.env.REACT_APP_URL, "");
}

/**
 * Creates a new Url
 * @param {String}  route
 * @param {Object=} params
 * @returns {URL}
 */
function createUrl(route, params = {}) {
    const url = new URL(process.env.REACT_APP_URL + route.substr(1));
    for (const [ key, value ] of Object.entries(params)) {
        if (value !== undefined) {
            url.searchParams.append(key, value);
        }
    }
    return "/" + getUrl(url.toString());
}



/**
 * Returns the current path
 * @returns {String}
 */
function getPath() {
    return history.location.pathname;
}

/**
 * Returns true if the current url starts with the given one
 * @returns {Boolean}
 */
function isHome() {
    return history.location.pathname === "/";
}

/**
 * Returns true if the current url starts with the given one
 * @param {...(String|Number)} args
 * @returns {Boolean}
 */
function isEqual(...args) {
    const href = url(...args);
    return history.location.pathname === href;
}

/**
 * Returns true if the current url starts with the given one
 * @param {...(String|Number)} args
 * @returns {Boolean}
 */
function startsWith(...args) {
    const href = url(...args);
    return history.location.pathname.startsWith(href);
}



/**
 * Goes to the given internal URL
 * @param {...(String|Number)} args
 * @returns {Void}
 */
function goto(...args) {
    const href = url(...args);
    gotoUrl(href);
}

/**
 * Goes to the given internal URL
 * @param {String} url
 * @returns {Void}
 */
function gotoUrl(url) {
    if (!url || url === "#") {
        return false;
    }
    if (isInternal(url)) {
        history.push(getUrl(url));
    } else if (!url.startsWith("http")) {
        history.push(url);
    } else if (url) {
        window.location.href = url;
    }
    return true;
}

/**
 * Goes to a Product
 * @param {Object}  product
 * @param {Object}  settings
 * @param {String=} url
 * @returns {Void}
 */
function gotoProduct(product, settings, url) {
    if (settings.products_useDialog) {
        store.dispatch(setProduct(product));
    } else {
        goto(url || "PRODUCT", product.productUrl);
    }
}

/**
 * Removes the hash
 * @returns {Void}
 */
function removeHash() {
    history.push(window.location.pathname + window.location.search);
}




// The Public API
export default {
    init,
    url,
    fullUrl,
    voucherUrl,
    authRoutes,
    isInternal,
    forVoucher,
    getUrl,
    createUrl,

    getPath,
    isHome,
    isEqual,
    startsWith,

    goto,
    gotoUrl,
    gotoProduct,
    removeHash,
}
