import Href                 from "Utils/Core/Href";
import Pages                from "Utils/App/Pages";
import Utils                from "Utils/Common/Utils";



/**
 * The Production Class
 */
export default class Production {
    /**
     * Creates a new Production Instance
     * @param {Function} fetchFunc
     * @param {String}   paramKey
     * @param {Number}   initialSort
     */
    constructor(fetchFunc, paramKey = null, initialSort = 0) {
        this.fetchFunc   = fetchFunc;
        this.paramKey    = paramKey;
        this.initialSort = initialSort;

        this.settings    = {};
        this.preferences = {};
        this.params      = {};
    }

    /**
     * Sets the Settings
     * @param {Object} settings
     * @param {Object} preferences
     * @param {Object} params
     * @returns {Void}
     */
    setSettings(settings, preferences, params, search) {
        this.settings    = settings;
        this.preferences = preferences;
        this.params      = params;
        this.search      = search;
    }

    /**
     * Starts the Render
     * @param {Object}    data
     * @param {...String} url
     * @returns {Void}
     */
    startRender(data, ...url) {
        this.data          = data;
        this.crumbs        = [];
        this.categories    = data.categories    || [];
        this.subcategories = data.subcategories || [];
        this.tercategories = data.tercategories || [];
        this.brands        = data.brands        || [];
        this.artists       = data.artists       || [];
        this.prices        = data.prices        || [];
        this.amounts       = data.amounts       || [];
        this.fields        = data.fields;

        this.category      = "";
        this.subcategory   = "";
        this.tercategory   = "";
        this.page          = 1;

        this.url           = Href.url(...url);
        this.baseUrl       = this.url;
        this.prodUrl       = Href.url("PRODUCT");
        this.urlParams     = this.getParams();
        this.options       = {};

        // Parse the data for the crumbs
        if (this.data.category) {
            this.category = this.data.category.name;
        }
        if (this.data.subcategory) {
            this.subcategory = this.data.subcategory.name;
        }
        if (this.data.tercategory) {
            this.tercategory = this.data.tercategory.name;
        }

        // Parse the url params
        if (this.urlParams.category && this.data.categoryID) {
            this.options.category = this.data.categoryID;
            this.baseUrl         += `/${this.data.category.slug}`;
            this.prodUrl         += `/${this.data.category.slug}`;
        }
        if (this.urlParams.subcategory && this.data.subcategoryID) {
            this.options.subcategory = this.data.subcategoryID;
            this.baseUrl            += `/${this.data.subcategory.slug}`;
            this.prodUrl            += `/${this.data.subcategory.slug}`;
        }
        if (this.urlParams.tercategory && this.data.tercategoryID) {
            this.options.tercategory = this.data.tercategoryID;
            this.baseUrl            += `/${this.data.tercategory.slug}`;
            this.prodUrl            += `/${this.data.tercategory.slug}`;
        }
        if (this.urlParams.brand && this.data.brandID) {
            this.options.brand = this.data.brandID;
        }
        if (this.urlParams.artist && this.data.artistID) {
            this.options.artist = this.data.artistID;
        }
        if (this.urlParams.price && this.data.price) {
            this.options.price = this.data.price;
        }
        if (this.urlParams.field && this.data.field) {
            this.options.field = this.data.field;
        }
        if (this.urlParams.amount && this.data.amounts && this.data.amounts.length) {
            this.options.amount = [ Number(this.urlParams.amount) ];
        }
        if (this.urlParams.page) {
            this.page = Number(this.urlParams.page);
            if (this.page > 1) {
                this.options.page = [ this.urlParams.page ];
            }
        }
    }

    /**
     * Fetches the Data
     * @returns {Void}
     */
    fetch = () => {
        this.fetchFunc(this.getParams());
    }

    /**
     * Fetches the Data, if Required
     * @param {Object} prevProps
     * @param {Object} props
     * @returns {Void}
     */
    fetchIfRequired(prevProps, props) {
        const oldParams = this.getParams(prevProps.match.params, prevProps.location.search);
        const newParams = this.getParams(props.match.params, props.location.search);
        if (!prevProps.loaded || !Utils.areObjectsEqual(oldParams, newParams)) {
            this.fetch();
        }
    }

    /**
     * Sets an option
     * @param {Object} options
     * @param {String} type
     * @param {Number} id
     * @returns {Boolean}
     */
    setOption(options, type, id) {
        if (id && !options[type]) {
            options[type] = [ id ];
        } else if (id && ((!this.settings.products_allowMultiFilter || type === "amount") && type !== "field")) {
            options[type] = [ id ];
        } else if (id && (this.settings.products_allowMultiFilter || type === "field")) {
            const index = options[type].indexOf(id);
            if (index > -1) {
                options[type].splice(index, 1);
                return true;
            }
            options[type].push(id);
        } else if (!id && options[type]) {
            options[type] = [];
            if (type === "category") {
                options.subcategory = [];
                options.tercategory = [];
            } else if (type === "subcategory") {
                options.tercategory = [];
            }
        }
        return false;
    }

    /**
     * Returns the Url
     * @param {String=} type
     * @param {Object=} data
     * @returns {String}
     */
    getUrl = (type, data) => {
        const options = Utils.clone(this.options);
        const id      = data && data.id ? data.id : undefined;
        const slug    = data ? `/${data.slug}` : "";
        const removed = this.setOption(options, type, id);
        let   url     = this.baseUrl;

        switch (type) {
        case "category":
            url = `${this.url}${slug}`;
            break;
        case "subcategory":
            url = `${this.url}/${this.data.category.slug}${slug}`;
            break;
        case "tercategory":
            url = `${this.url}/${this.data.category.slug}/${this.data.subcategory.slug}${slug}`;
            break;
        default:
        }
        options.page = [];
        return this.createUrl(url, options, removed);
    }

    /**
     * Returns the Pagination Url
     * @param {Number} page
     * @returns {String}
     */
    getPaginationUrl = (page) => {
        const options = Utils.clone(this.options);
        const url     = `${this.baseUrl}/${page}`;
        options.page  = [ page ];
        return this.createUrl(url, options);
    }

    /**
     * Creates and returns the Url
     * @param {String}   url
     * @param {Object}   options
     * @param {Boolean=} removed
     * @returns {String}
     */
    createUrl(url, options, removed = false) {
        const urlParams = {};
        let   useSearch = removed;
        for (const [ key, value ] of Object.entries(options)) {
            if (key === this.paramKey) {
                continue;
            }
            if (value.length > 1 || ([ "brand", "artist", "price", "amount", "field" ].includes(key) && value.length > 0)) {
                useSearch = true;
            }
            if (value.length > 0) {
                urlParams[key] = value.join("_");
            }
        }
        if (useSearch) {
            return Href.createUrl(this.url, urlParams);
        }
        return url;
    }

    /**
     * Returns true if the value is selected
     * @param {String} type
     * @param {Number} value
     * @returns {Boolean}
     */
    isSelected = (type, value) => {
        if (!this.options[type]) {
            return !value;
        }
        if (!value) {
            return this.options[type].length === 0;
        }
        if (type === "field") {
            return this.options[type].includes(value);
        }
        return this.options[type].includes(Number(value));
    }

    /**
     * Returns true if the type has a parent
     * @param {String} type
     * @returns {Boolean}
     */
    hasParent = (type) => {
        if (type === "subcategory") {
            return this.options.category && this.options.category.length > 0;
        }
        if (type === "tercategory") {
            return this.options.subcategory && this.options.subcategory.length > 0;
        }
        return false;
    }



    /**
     * Adds a Crumb
     * @param {String} url
     * @param {String} name
     * @returns {Void}
     */
    addCrumb(url, name) {
        this.crumbs.push({ to : Href.url(url), name });
    }

    /**
     * Adds a Start Crumb
     * @param {String} name
     * @returns {Void}
     */
    addStartCrumb(name) {
        this.crumbs.push({ to : this.url, name });
    }

    /**
     * Adds a Start Crumb
     * @param {String} name
     * @returns {Void}
     */
    addPageCrumb(pages, type) {
        const page = Pages.get(pages, type);
        if (page) {
            this.crumbs.push({ to : page.url, name : page.name });
        }
    }

    /**
     * Adds a Category
     * @returns {Void}
     */
    addCategoryCrumbs() {
        if (this.category) {
            this.crumbs.push({
                to   : this.baseUrl,
                name : this.category,
            });
        }
        if (this.subcategory) {
            this.crumbs.push({
                to   : this.baseUrl,
                name : this.subcategory,
            });
        }
        if (this.tercategory) {
            this.crumbs.push({
                to   : this.baseUrl,
                name : this.tercategory,
            });
        }
    }

    /**
     * Returns true if there are Filters
     * @returns {Boolean}
     */
    hasFilters() {
        const showAmounts = Boolean(this.settings.products_showOfferFilter    && this.amounts       && this.amounts.length);
        const showCats    = Boolean(this.settings.products_showCategoryFilter && this.categories    && this.categories.length);
        const showSubs    = Boolean(this.settings.products_showCategoryFilter && this.subcategories && this.subcategories.length && this.category);
        const showTers    = Boolean(this.settings.products_showCategoryFilter && this.tercategories && this.tercategories.length && this.subcategory);
        const showBrands  = Boolean(this.settings.products_showBrandFilter    && this.brands        && this.brands.length);
        const showArtists = Boolean(this.settings.products_showArtistFilter   && this.artists       && this.artists.length);
        const showPrice   = Boolean(this.settings.products_showPriceFilter    && this.prices        && this.prices[0]);
        return showAmounts || showCats || showSubs || showTers || showBrands || showArtists || showPrice;
    }

    /**
     * Returns the Params for the Fetch
     * @param {Object=} params
     * @param {String=} search
     * @returns {Object}
     */
    getParams(params = this.params, search = this.search) {
        const initials = this.getInitialParams();
        if (params.page && typeof params.page === "string") {
            params.page = Number(params.page);
        }
        const result      = Utils.extend(initials, params);
        const searchParams = new URLSearchParams(search);
        for (const key of Object.keys(result)) {
            if (searchParams.has(key)) {
                result[key] = searchParams.get(key);
            }
        }
        return result;
    }

    /**
     * Returns the Initial Params for the Fetch
     * @returns {Object}
     */
    getInitialParams() {
        const result = {
            category    : 0,
            subcategory : 0,
            tercategory : 0,
            brand       : 0,
            artist      : 0,
            field       : 0,
            price       : 0,
            amount      : 1,
            page        : 1,
            sort        : this.initialSort || this.preferences.sort,
        };
        if (this.paramKey) {
            result[this.paramKey] = "";
        }
        return result;
    }
}
