import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleSearch }     from "Actions/Store/StoreActions";
import SubMenu              from "Utils/App/SubMenu";
import ClassList            from "Utils/Common/ClassList";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The User Bar
 */
class UserBar extends React.Component {
    /**
     * Opens the Search
     * @returns {Void}
     */
    openSearch = () => {
        this.props.toggleSearch(true);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            settings, isAuthenticated, cart, chats, notifications,
            showSearch, leftAlign,
        } = this.props;

        const showLogin     = !isAuthenticated && settings.clients_isActive;
        const showRegister  = !isAuthenticated && settings.clients_isActive && settings.clients_allowRegister && settings.header_showRegister;
        const showFavorites = isAuthenticated && settings.products_hasFavorites;
        const showChat      = isAuthenticated && settings.chats_isActive;

        const classes       = new ClassList("header-userbar", "menu-content");
        classes.addIf("header-userbar-left", leftAlign);
        classes.addIf("menu-upper",          settings.header_inUppercase);

        return <section className={classes.get()}>
            <ul className="menu-nav menu-tertiary no-list">
                {isAuthenticated && <li>
                    <HyperLink
                        className="userbar-client"
                        variant={settings.header_showAccount ? "menu" : "icon"}
                        icon="client"
                        url="ACCOUNT_DATA"
                        message={settings.header_showAccount ? "CLIENT_NAME" : ""}
                        onMouseEnter={() => SubMenu.show("client", 0, "userbar-client", "header-userbar")}
                        onMouseLeave={SubMenu.hide}
                        onClick={SubMenu.close}
                        afterIcon={settings.header_showAccount && settings.header_showArrows ? "down" : ""}
                    />
                </li>}
                {showRegister && <li>
                    <HyperLink
                        variant="menu"
                        url="REGISTER"
                        message="AUTH_REGISTER_LINK"
                    />
                </li>}
                {showLogin && <li>
                    <HyperLink
                        variant="menu"
                        url="LOGIN"
                        message="AUTH_LOGIN_ACTION"
                    />
                </li>}
                {showFavorites && <li>
                    <HyperLink
                        className="userbar-favorites"
                        variant="icon"
                        url="FAVORITES"
                        onMouseEnter={() => SubMenu.show("favorite", 0, "userbar-favorites", "header-userbar")}
                        onMouseLeave={SubMenu.hide}
                        onClick={SubMenu.close}
                        icon="favorite"
                    />
                </li>}
                {showChat && <li>
                    <HyperLink
                        variant="icon"
                        url="CHAT"
                        icon="chat"
                        badge={chats}
                    />
                </li>}
                {isAuthenticated && <li>
                    <HyperLink
                        className="userbar-notification"
                        variant="icon"
                        url="NOTIFICATIONS"
                        onMouseEnter={() => SubMenu.show("notification", 0, "userbar-notification", "header-userbar")}
                        onMouseLeave={SubMenu.hide}
                        onClick={SubMenu.close}
                        icon="notification"
                        badge={notifications}
                    />
                </li>}
                {settings.orders_isActive && <li>
                    <HyperLink
                        variant="icon"
                        url="CART"
                        icon="cart"
                        badge={cart}
                    />
                </li>}
                {showSearch && <li>
                    <HyperLink
                        variant="icon"
                        onClick={this.openSearch}
                        icon="search"
                    />
                </li>}
            </ul>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleSearch    : PropTypes.func.isRequired,
        settings        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        menus           : PropTypes.object.isRequired,
        cart            : PropTypes.number.isRequired,
        chats           : PropTypes.number.isRequired,
        notifications   : PropTypes.number.isRequired,
        showSearch      : PropTypes.bool,
        leftAlign       : PropTypes.bool,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            isAuthenticated : state.auth.isAuthenticated,
            menus           : state.store.menus,
            cart            : state.store.cart,
            chats           : state.chat.elem.unreadClient,
            notifications   : state.notification.list.length,
        };
    }
}

export default connect(UserBar.mapStateToProps, {
    toggleSearch,
})(UserBar);
