import Utils                from "Utils/Common/Utils";
import DateTime             from "Utils/Common/DateTime";

// Types
import {
    ORDER_LOADING, ORDER_LIST, ORDER_ELEM,
} from "Utils/Core/Types";



// The initial State
const initialState = {
    loading      : true,
    error        : false,
    list         : [],
    total        : 0,
    currencies   : [],
    showShipment : true,
    elem         : {
        orderID  : 0,
        products : [],
        totals   : [],
    },
};

/**
 * Parses the List
 * @param {Array.<Object>} list
 * @returns {Array.<Object>}
 */
function parseList(list) {
    for (const elem of list) {
        parseElem(elem);
    }
    return list;
}

/**
 * Parses the Elem
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.orderDate    = DateTime.formatDate(elem.orderTime,    "dashesTime");
    elem.completeDate = DateTime.formatDate(elem.completeTime, "dashesTime");
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, ORDER_LIST, ORDER_ELEM)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case ORDER_LOADING:
        return {
            ...state,
            loading      : true,
            error        : false,
        };

    case ORDER_LIST:
        return {
            ...state,
            loading      : false,
            error        : false,
            list         : parseList(action.data.list),
            total        : action.data.total,
            currencies   : action.data.currencies,
            showShipment : action.data.showShipment,
        };
    case ORDER_ELEM:
        return {
            ...state,
            loading      : false,
            error        : false,
            elem         : parseElem(action.data),
        };
    default:
        return state;
    }
};
