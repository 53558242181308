import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchOrder }       from "Actions/Client/OrderActions";
import { setContact }       from "Actions/Client/QueryActions";
import API                  from "Utils/Core/API";
import NLS                  from "Utils/Core/NLS";
import QueryOrigin          from "Utils/App/QueryOrigin";

// Components
import OrderVoucher         from "./OrderVoucher";
import ProductImage         from "Components/Product/Item/ProductImage";
import ProductPrice         from "Components/Product/Item/ProductPrice";
import Table                from "Components/Utils/Table/Table";
import TableHead            from "Components/Utils/Table/TableHead";
import TableBody            from "Components/Utils/Table/TableBody";
import TableFoot            from "Components/Utils/Table/TableFoot";
import TableRow             from "Components/Utils/Table/TableRow";
import TableCell            from "Components/Utils/Table/TableCell";
import SubTitle             from "Components/Utils/Title/SubTitle";
import Content              from "Components/Utils/Common/Content";
import Price                from "Components/Utils/Common/Price";
import HyperLink            from "Components/Utils/Common/HyperLink";
import MultiLine            from "Components/Utils/Common/MultiLine";
import Html                 from "Components/Utils/Common/Html";
import Alert                from "Components/Utils/Form/Alert";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Client/Order.css";



/**
 * The Order Elem
 */
class OrderElem extends React.Component {
    // The Current State
    state = {
        showDialog : false,
        success    : "",
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchOrder(this.props.match.params.order);
    }

    /**
     * Handles the Voucher Click
     * @returns {Void}
     */
    handleVoucher = () => {
        this.setState({ showDialog : true });
    }

    /**
     * Handles the Subscription Click
     * @returns {Void}
     */
    handleSubscription = () => {
        this.props.setContact({
            origin  : QueryOrigin.SUBSCRIPTION,
            orderID : this.props.elem.orderID,
        });
    }

    /**
     * Handles a Submit
     * @returns {Void}
     */
    handleSubmit = (alert) => {
        this.setState({ showDialog : false, alert });
    }

    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.setState({ showDialog : false });
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ alert : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, loading, elem } = this.props;
        const { showDialog, alert       } = this.state;

        const message      = loading ? "ORDERS_SINGULAR" : NLS.format("ORDERS_TITLE", elem.orderID);
        const totals       = elem.totals.filter((elem) => elem.amount);
        const multiCoins   = elem.totals.length > 1;
        const showShipment = Boolean(elem.shipmentID);
        const statusStyle  = elem.statusColor ? { color : elem.statusColor } : {};
        const boxes        = elem.hasBoxes ? elem.boxes.filter((elem) => !!elem.trackingID) : [];

        return <>
            <SubTitle message={message} icon="cart" />
            <Content loading={loading} hasContent withCard>
                <header className="order-header">
                    <div>
                        <b>{NLS.get("ORDERS_DATE")}</b>
                        {elem.orderDate}
                    </div>
                    <div>
                        <b>{NLS.get("GENERAL_STATUS")}</b>
                        <span style={statusStyle}>{elem.statusName}</span>
                    </div>
                </header>

                <Alert
                    open={!!alert}
                    className="order-alert"
                    variant="success"
                    message={alert}
                    onClose={this.closeAlert}
                />

                <main className={"order-summary" + (showShipment ? " order-summary-shipment" : "")}>
                    <section className="order-summary-order">
                        <SubTitle message="ORDERS_SUMMARY" icon="order" smallPadding />
                        {totals.map((total) => <ul key={total.currencySymbol} className="no-list">
                            <li className="order-summary-price">
                                {multiCoins ?
                                    <h4>{NLS.format("CART_TOTAL", total.name)}</h4> :
                                    <h4>{NLS.pluralize("CART_UNITS", total.amount)}</h4>}
                                <ProductPrice data={total} />
                            </li>
                            {total.hasClientDiscount && <li className="order-summary-discount">
                                <h4>{NLS.format("CART_DISCOUNT_CLIENT", total.clientDiscountName)}</h4>
                                <Price symbol={total.currencySymbol} price={total.clientDiscountFormat} showNegative />
                            </li>}
                            {total.hasFirstDiscount && <li className="order-summary-discount">
                                <h4>{NLS.format("CART_DISCOUNT_FIRST", total.firstDiscount)}</h4>
                                <Price symbol={total.currencySymbol} price={total.firstDiscountFormat} showNegative />
                            </li>}
                            {total.hasPaymentDiscount && <li className="order-summary-discount">
                                <h4>{NLS.format("CART_DISCOUNT_PAYMENT", elem.paymentDiscount)}</h4>
                                <Price symbol={total.currencySymbol} price={total.paymentDiscountFormat} showNegative />
                            </li>}
                            {total.hasShipment && <li className="order-summary-discount">
                                <h4>{NLS.get("CART_SHIPMENT_PRICE")}</h4>
                                <Price symbol={total.currencySymbol} price={total.shipmentFormat} />
                            </li>}
                            {total.hasFinal && <li className="order-summary-price">
                                <h4>{NLS.get("CART_FINAL_PRICE")}</h4>
                                <Price symbol={total.currencySymbol} price={total.finalFormat} />
                            </li>}
                        </ul>)}
                    </section>

                    {showShipment && <section className="order-summary-shipping">
                        <SubTitle message="ORDERS_SHIPPING" icon="shipping" smallPadding />
                        <ul className="no-list">
                            <li>
                                <h4>{NLS.get("ORDERS_SHIPPING_TYPE")}</h4>
                                <p>{elem.shipmentName}</p>
                            </li>
                            {boxes.map((elem, index) => <li key={index}>
                                <h4>{NLS.format("ORDERS_SHIPPING_BOX", elem.boxName)}</h4>
                                <p>
                                    <HyperLink
                                        variant="black"
                                        href={`https://seguimiento.andreani.com/envio/${elem.trackingID}`}
                                        message={elem.trackingID}
                                        target="_blank"
                                    />
                                </p>
                            </li>)}
                            {elem.hasSubsidiary && <li className="order-summary-col">
                                <h4>{NLS.get("ORDERS_SUBSIDIARY")}</h4>
                                <p>
                                    <b>{elem.subsidiaryName}:</b>
                                    {`
                                        ${elem.subsidiaryAddress} -
                                        ${elem.subsidiaryLocality} -
                                        ${elem.subsidiaryProvince} -
                                        ${elem.subsidiaryZipCode}
                                    `}
                                </p>
                            </li>}
                            {elem.hasAddress && <li className="order-summary-col">
                                <h4>{NLS.get("ORDERS_ADDRESS")}</h4>
                                <p>
                                    <b>{elem.addressName}:</b>
                                    {`
                                        ${elem.street} ${elem.streetNumber} -
                                        ${elem.floor ? NLS.format("ADDRESSES_FLOOR_FORMAT", elem.floor) + " -" : ""}
                                        ${elem.appartment ? NLS.format("ADDRESSES_APPARTMENT_FORMAT", elem.appartment) + " -" : ""}
                                        ${elem.locality} - ${elem.province} - ${elem.zipCode}
                                    `}
                                </p>
                            </li>}
                        </ul>
                    </section>}

                    <section className="order-summary-payment">
                        <SubTitle message="ORDERS_PAYMENT" icon="payment" smallPadding />
                        <ul className="no-list">
                            <li>
                                <h4>{NLS.get("ORDERS_PAYMENT_METHOD")}</h4>
                                <p>{elem.paymentName}</p>
                            </li>
                            {!!elem.paymentMethod && <li>
                                <h4>{NLS.get("ORDERS_PAYMENT_METHOD")}</h4>
                                <p>{elem.paymentMethod}</p>
                            </li>}
                            {!!elem.paymentStatus && <li>
                                <h4>{NLS.get("ORDERS_PAYMENT_STATUS")}</h4>
                                <Html content={elem.paymentStatusName} />
                            </li>}
                            {!!elem.paymentHours && <li>
                                <h4>{NLS.get("ORDERS_PAYMENT_TIME")}</h4>
                                <p>{NLS.pluralize("ORDERS_PAYMENT_HOURS", elem.paymentHours)}</p>
                            </li>}
                            {!!elem.paymentReference && <li className="order-summary-col">
                                <h4>{NLS.get("ORDERS_PAYMENT_DATA")}</h4>
                                <Html content={elem.paymentReference} />
                            </li>}

                            {elem.paymentValidate && <li className="order-summary-col">
                                {elem.voucherRejected && <p className="order-summary-error">
                                    <b>{NLS.get("ORDERS_VOUCHER_REJECTED")}: </b>
                                    {elem.voucherReason}
                                </p>}
                                <Button
                                    variant="primary"
                                    message="ORDERS_VOUCHER_TITLE"
                                    onClick={this.handleVoucher}
                                    fullWidth
                                    isSmall
                                />
                            </li>}
                            {!!elem.paymentTicket && <li>
                                <h4>{NLS.get("ORDERS_PAYMENT_TICKET")}</h4>
                                <p>
                                    <HyperLink
                                        variant="black"
                                        href={API.Order.getTicket({ orderID : elem.orderID })}
                                        message="ORDERS_PAYMENT_TICKET_VIEW"
                                        target="_blank"
                                    />
                                </p>
                            </li>}
                            {elem.paymentSubscribed && <li>
                                <h4>{NLS.get("ORDERS_PAYMENT_SUBSCRIBED_FOR")}</h4>
                                <p>{NLS.pluralize("ORDERS_PAYMENT_MONTHS", elem.paymentMonths)}</p>
                            </li>}
                            {elem.canCancelSubscription && <li>
                                <h4>{NLS.get("ORDERS_PAYMENT_SUBSCRIPTION")}</h4>
                                <p>
                                    {elem.hasCancel ? NLS.get("ORDERS_CANCEL_RECEIVED") : <Button
                                        variant="primary"
                                        message="ORDERS_CANCEL_SUBSCRIPTION"
                                        onClick={this.handleSubscription}
                                        fullWidth
                                        isSmall
                                    />}
                                </p>
                            </li>}
                        </ul>
                    </section>
                </main>

                {elem.products.map(({ list, total }, index) => <div
                    key={index}
                    className="order-products"
                >
                    <SubTitle
                        message={multiCoins ? NLS.format("ORDERS_PRODUCTS", total.currencySymbol) : "PRODUCTS_NAME"}
                        icon="cart"
                        withTop
                    />
                    <Table
                        hasContent={!!list.length}
                        className="order-table"
                        none="ORDERS_NONE_AVAILABLE"
                        noHover
                    >
                        <TableHead>
                            <TableCell message="ORDERS_PRODUCT" />
                            <TableCell message="" />
                            <TableCell message="ORDERS_PRICE"    align="right"  />
                            <TableCell message="ORDERS_SUBTOTAL" align="right"  />
                        </TableHead>
                        <TableBody>
                            {list.map((elem, index) => <TableRow key={index}>
                                <TableCell className="order-table-image">
                                    <ProductImage
                                        className="order-image"
                                        variant="small"
                                        data={elem}
                                        clickPreview
                                    />
                                </TableCell>
                                <TableCell className="order-table-info">
                                    <header>
                                        <div className="order-info-amount">{elem.amount}×</div>
                                        <div className="order-info-header">
                                            {settings.products_showCode && !!elem.productCode && <h4>
                                                {elem.productCode}
                                            </h4>}
                                            {settings.products_showBrand && !!elem.brandName && <h4>
                                                {elem.brandName}
                                            </h4>}
                                            {settings.products_showArtist && !!elem.artistName && <h4>
                                                {elem.artistName}
                                            </h4>}
                                            <h3>{elem.name}</h3>
                                            {!!elem.variantName && <p>{elem.variantName}</p>}
                                        </div>
                                    </header>
                                    {!!elem.campaignID && <p className="order-campaign">
                                        {elem.campaignName}
                                    </p>}
                                </TableCell>
                                <TableCell className="order-subtotal order-big" align="right" nowrap>
                                    <div className="order-price">
                                        <Price symbol={elem.currencySymbol} price={elem.realPrice} />
                                        {elem.forRent && <div className="order-month">{NLS.get("PRODUCTS_RENT_MONTH_SHORT")}</div>}
                                    </div>
                                </TableCell>
                                <TableCell className="order-total order-big" align="right" nowrap>
                                    <div className="order-price">
                                        <Price symbol={elem.currencySymbol} price={elem.subtotal} />
                                        {elem.forRent && <div className="order-month">{NLS.get("PRODUCTS_RENT_MONTH_SHORT")}</div>}
                                    </div>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                        <TableFoot>
                            <TableCell message="ORDERS_TOTAL" align="right" />
                            <TableCell message={total.amount} className="order-big" align="center" />
                            <TableCell />
                            <TableCell className="order-big" align="right" nowrap>
                                <Price symbol={total.currencySymbol} price={total.priceFormat} />
                            </TableCell>
                        </TableFoot>
                    </Table>
                </div>)}
                {settings.products_withoutTax && <div className="order-legals">
                    {NLS.get("PRODUCTS_WITHOUT_TAX")}
                </div>}

                {!!elem.message && <div className="order-message">
                    <SubTitle message="GENERAL_MESSAGE" icon="query" withTop />
                    <MultiLine>{elem.message}</MultiLine>
                </div>}
            </Content>

            <OrderVoucher
                open={showDialog}
                orderID={elem.orderID}
                onSubmit={this.handleSubmit}
                onClose={this.closeDialog}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchOrder : PropTypes.func.isRequired,
        setContact : PropTypes.func.isRequired,
        match      : PropTypes.object.isRequired,
        settings   : PropTypes.object.isRequired,
        loading    : PropTypes.bool.isRequired,
        elem       : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            loading  : state.order.loading,
            elem     : state.order.elem,
        };
    }
}

export default connect(OrderElem.mapStateToProps, {
    fetchOrder, setContact,
})(OrderElem);
