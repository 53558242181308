import API                  from "Utils/Core/API";

// Types
import {
    VOUCHER_LOADING,
    VOUCHER_INVOICES, VOUCHER_RECEIPTS,
    VOUCHER_CREDIT_NOTES, VOUCHER_DEBIT_NOTES,
} from "Utils/Core/Types";



/**
 * Fetches the Invoices
 * @param {Object} params
 * @returns {Function}
 */
export function fetchInvoices(params) {
    return async (dispatch) => {
        dispatch({ type : VOUCHER_LOADING });
        const data = await API.Voucher.getInvoices(params);
        dispatch({ type : VOUCHER_INVOICES, data });
    };
}

/**
 * Fetches the Receipts
 * @param {Object} params
 * @returns {Function}
 */
export function fetchReceipts(params) {
    return async (dispatch) => {
        dispatch({ type : VOUCHER_LOADING });
        const data = await API.Voucher.getReceipts(params);
        dispatch({ type : VOUCHER_RECEIPTS, data });
    };
}

/**
 * Fetches the Credit Notes
 * @param {Object} params
 * @returns {Function}
 */
export function fetchCreditNotes(params) {
    return async (dispatch) => {
        dispatch({ type : VOUCHER_LOADING });
        const data = await API.Voucher.getCreditNotes(params);
        dispatch({ type : VOUCHER_CREDIT_NOTES, data });
    };
}

/**
 * Fetches the Debit Notes
 * @param {Object} params
 * @returns {Function}
 */
export function fetchDebitNotes(params) {
    return async (dispatch) => {
        dispatch({ type : VOUCHER_LOADING });
        const data = await API.Voucher.getDebitNotes(params);
        dispatch({ type : VOUCHER_DEBIT_NOTES, data });
    };
}
