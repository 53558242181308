import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchUpdates }     from "Actions/Store/StoreActions";
import Href                 from "Utils/Core/Href";
import Notifications        from "Utils/Common/Notifications";



/**
 * The Updater
 */
class Updater extends React.Component {
    /**
     * Starts the Timeout
     * @returns {Void}
     */
    componentDidMount() {
        this.startFetch();
    }

    /**
     * Starts the Timeout
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.lastUpdate !== prevProps.lastUpdate) {
            this.startFetch();
        }
    }

    /**
     * End the Timeouts
     * @returns {Void}
     */
    componentWillUnmount() {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
        this.timeout = null;
    }

    /**
     * Starts the Auto Fetch
     * @returns {Void}
     */
    startFetch() {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(this.doFetch, 10000);
    }

    /**
     * Does the Auto Fetch
     * @returns {Void}
     */
    doFetch = async () => {
        const { lastUpdate, fetchUpdates, onChat } = this.props;

        const request       = await fetchUpdates(lastUpdate);
        const notifications = request.notifications || [];
        const messages      = request.messages      || [];

        if (Notifications.isGranted()) {
            for (const elem of notifications) {
                const data = Notifications.getData(elem);
                Notifications.show(data.title, data.body, () => {
                    Href.goto(elem.url);
                });
            }
            if (!onChat && messages.length > 0) {
                const data = Notifications.getData({
                    type : "message",
                    body : [ request.chat.unreadClient ],
                });
                Notifications.show(data.title, data.body, () => {
                    Href.goto("CHAT");
                });
            }
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <React.Fragment />;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchUpdates : PropTypes.func.isRequired,
        lastUpdate   : PropTypes.number.isRequired,
        onChat       : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            lastUpdate : state.store.lastUpdate,
            onChat     : state.chat.isViewing,
        };
    }
}

export default connect(Updater.mapStateToProps, {
    fetchUpdates,
})(Updater);
