import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Responsive           from "Utils/App/Responsive";
import ClassList            from "Utils/Common/ClassList";
import Utils                from "Utils/Common/Utils";

// Components
import ProductCard          from "Components/Product/Card/ProductCard";
import TerTitle             from "Components/Utils/Title/TerTitle";
import Slider               from "Components/Utils/Common/Slider";

// Styles
import "Styles/Components/Product/List/ProductsView.css";



/**
 * The Products View
 */
class ProductsView extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            settings, responsive,
            isHidden, className, message, href, url, onClick,
            list, asSlider, showButton, forHome,
        } = this.props;

        if (isHidden || !list.length) {
            return <React.Fragment />;
        }
        const count   = Responsive.calcProductsCols(responsive, settings.sections_lessProductsDesktop);
        const classes = new ClassList("products-view", className);
        classes.addIf("products-less-desktop", settings.sections_lessProductsDesktop);
        classes.addIf("products-view-less",    settings.sections_lessProductsMobile);

        return <section className={classes.get()}>
            <TerTitle
                message={Utils.getProductTitle(message, settings)}
                button="GENERAL_VIEW_MORE"
                href={href}
                url={url}
                onClick={onClick}
                showButton={showButton}
            />
            {asSlider && <Slider
                className="products-slider"
                total={list.length}
                count={count}
                outsideNav
            >
                {list.map((elem) => <ProductCard
                    key={elem.productID}
                    data={elem}
                    forHome={forHome}
                />)}
            </Slider>}
            {!asSlider && <div className="products-view-list">
                {list.map((elem) => <ProductCard
                    key={elem.productID}
                    data={elem}
                    forHome={forHome}
                />)}
            </div>}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings   : PropTypes.object.isRequired,
        responsive : PropTypes.object.isRequired,
        isHidden   : PropTypes.bool,
        className  : PropTypes.string,
        message    : PropTypes.string.isRequired,
        href       : PropTypes.string,
        url        : PropTypes.string,
        onClick    : PropTypes.func,
        list       : PropTypes.array.isRequired,
        asSlider   : PropTypes.bool,
        showButton : PropTypes.bool,
        forHome    : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        isHidden  : false,
        className : "",
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings   : state.core.settings,
            responsive : state.core.responsive,
        };
    }
}

export default connect(ProductsView.mapStateToProps)(ProductsView);
