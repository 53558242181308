import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/Core/NLS";
import ClassList            from "Utils/Common/ClassList";

// Components
import Title                from "Components/Utils/Title/Title";
import TitleWrapper         from "Components/Utils/Title/TitleWrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Html                 from "Components/Utils/Common/Html";

// Styles
import "Styles/Components/Product/List/ProductsHeader.css";



/**
 * The Products Header
 */
class ProductsHeader extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            settings, className, message, submessage, description, banner, logo, url,
            height, showBanner, showLogo, showTitle, showDescription,
        } = this.props;

        const hasSubmessage  = Boolean(settings.title_showAmount && submessage);
        const hasDescription = Boolean(showDescription && description);

        if (!showBanner || !banner) {
            return <Title
                message={message}
                submessage={hasSubmessage ? submessage : ""}
                description={hasDescription ? description : ""}
            />;
        }

        const classes = new ClassList("products-header", className);
        classes.addIf("products-header-logo", showLogo && !settings.products_showAside);

        return <TitleWrapper
            className={classes.get()}
            background={banner}
            height={height}
        >
            {showLogo && <HyperLink variant="none" href={url} className="products-logo">
                {logo && <img src={logo} alt={message} />}
                {!logo && <h2>{NLS.get(message)}</h2>}
            </HyperLink>}
            {showTitle && <div className="title-content">
                <header className="title-header">
                    <h2>{NLS.get(message)}</h2>
                    {hasSubmessage && <h3>{submessage}</h3>}
                </header>
                {hasDescription && <Html
                    className="title-description"
                    content={description}
                />}
            </div>}
        </TitleWrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings   : PropTypes.object.isRequired,
        className  : PropTypes.string,
        message    : PropTypes.string,
        submessage : PropTypes.string,
        banner     : PropTypes.string,
        logo       : PropTypes.string,
        url        : PropTypes.string,
        height     : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        showBanner : PropTypes.bool,
        showLogo   : PropTypes.bool,
        showTitle  : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        height      : 0,
        showBanner  : false,
        showLogo    : false,
        showTitle   : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(ProductsHeader.mapStateToProps)(ProductsHeader);
