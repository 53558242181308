import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/Core/NLS";
import DateTime             from "Utils/Common/DateTime";
import Utils                from "Utils/Common/Utils";

// Components
import ProductPrice         from "Components/Product/Item/ProductPrice";
import SubTitle             from "Components/Utils/Title/SubTitle";
import Card                 from "Components/Utils/Common/Card";
import Price                from "Components/Utils/Common/Price";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    unconfirmToProducts,
} from "Actions/Store/CartActions";



/**
 * The Cart Summary
 */
class CartSummary extends React.Component {
    // The Current State
    state = {
        reservedDate : 0,
        reservedMins : 0,
        reservedSecs : 0,
        reservedOver : false,
    }

    /**
     * Set the Last Save and Interval
     * @returns {Void}
     */
    componentDidMount() {
        if (this.props.elem.reservedTime) {
            const reservedDate = new DateTime(this.props.elem.reservedTime);
            const reservedMins = reservedDate.getMinutesDiff();
            const reservedSecs = reservedDate.getSecondsDiff();
            const reservedOver = !reservedDate.isGreaterThan();
            this.setState({ reservedDate, reservedMins, reservedSecs, reservedOver });

            if (!reservedOver) {
                this.interval = window.setInterval(this.setReservedTime, 1000);
            } else if (!this.props.elem.isProducts) {
                this.unconfirmCart();
            }
        }
    }

    /**
     * Remove the Interval
     * @returns {Void}
     */
    componentWillUnmount() {
        if (this.interval) {
            window.clearInterval(this.interval);
        }
    }

    /**
     * Set the Last Save
     * @returns {Void}
     */
    setReservedTime = () => {
        const reservedMins = this.state.reservedDate.getMinutesDiff();
        const reservedSecs = this.state.reservedDate.getSecondsDiff();
        const reservedOver = !this.state.reservedDate.isGreaterThan();
        this.setState({ reservedMins, reservedSecs, reservedOver });

        if (reservedOver && !this.props.elem.isProducts) {
            this.unconfirmCart();
        }
    }

    /**
     * Unconfirms the Cart
     * @returns {Void}
     */
    async unconfirmCart() {
        const { elem, openAlert, unconfirmToProducts } = this.props;
        try {
            openAlert("", "CART_RESERVED_NONE");
            await unconfirmToProducts(elem.orderHash);
        } catch (errors) {
            openAlert("", errors.form);
        }
    }



    /**
     * Returns the Submit Message depending on the step and state
     * @return {String}
     */
    getSubmitMessage() {
        const { step, elem } = this.props;
        switch (step) {
        case "products":
            if (elem.hideShipment && elem.hidePayment) {
                return "CART_CONFIRM_ORDER";
            }
            return "CART_CONFIRM_PRODUCTS";
        case "shipment":
            if (elem.hidePayment) {
                return "CART_CONFIRM_ORDER";
            }
            return "CART_CONFIRM_SHIPMENT";
        case "payment":
            return "CART_CONFIRM_ORDER";
        default:
        }
    }

    /**
     * Returns the Cancel Message depending on the step and state
     * @return {String}
     */
    getCancelMessage() {
        const { step, elem } = this.props;
        switch (step) {
        case "products":
            return "CART_ADD_PRODUCTS";
        case "account":
            return "CART_EDIT_PRODUCTS";
        case "shipment":
            return "CART_EDIT_PRODUCTS";
        case "payment":
            if (elem.hideShipment) {
                return "CART_EDIT_PRODUCTS";
            }
            return "CART_EDIT_SHIPMENT";
        default:
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, elem, step, isDisabled, onSubmit, onCancel, cancelUrl } = this.props;
        const { reservedMins, reservedSecs, reservedOver                        } = this.state;
        const {
            totals, bellowMinimum, minimumTotal,
            shipments, shipmentID,
            subsidiaries, subsidiaryID,
            addresses, addressID,
            payments, paymentType,
            reservedTime,
        } = elem;

        const multiCoins    = totals.length > 1;
        const shipment      = shipmentID   ? Utils.getData(shipments,    "shipmentID",   shipmentID)   : {};
        const subsidiary    = subsidiaryID ? Utils.getData(subsidiaries, "subsidiaryID", subsidiaryID) : {};
        const address       = addressID    ? Utils.getData(addresses,    "addressID",    addressID)    : {};
        const payment       = paymentType  ? Utils.getData(payments,     "type",         paymentType)  : {};

        const hasShipment   = !Utils.isEmpty(shipment);
        const hasSubsidiary = !Utils.isEmpty(subsidiary) && shipment.reqSubsidiary;
        const hasAddress    = !Utils.isEmpty(address)    && shipment.reqAddress;
        const hasPayment    = !Utils.isEmpty(payment);
        const hasTimeMins   = Boolean(reservedTime && !reservedOver && reservedMins > 1);
        const hasTimeSecs   = Boolean(reservedTime && !reservedOver && reservedMins <= 1);
        const showInfo      = Boolean(hasTimeMins || hasTimeSecs || settings.products_withoutTax);
        const hasSubmit     = step !== "account";

        return <section className="cart-summary">
            <Card withBorder>
                <SubTitle message="CART_SUMMARY" icon="cart" smallPadding />
                {totals.map((total) => <div key={total.id} className="cart-summary-items">
                    <section className="cart-summary-item">
                        {multiCoins ?
                            <h4>{NLS.format("CART_TOTAL", total.name)}</h4> :
                            <h4>{NLS.pluralize("CART_UNITS", total.amount)}</h4>}
                        <ProductPrice data={total.totalPrice} />
                    </section>
                    {total.hasClientDiscount && <section className="cart-summary-item cart-summary-small">
                        <h4>{NLS.format("CART_DISCOUNT_CLIENT", total.clientDiscountName)}</h4>
                        <Price symbol={total.currencySymbol} price={total.clientDiscountFormat} showNegative />
                    </section>}
                    {total.hasFirstDiscount && <section className="cart-summary-item cart-summary-small">
                        <h4>{NLS.format("CART_DISCOUNT_FIRST", total.firstDiscount)}</h4>
                        <Price symbol={total.currencySymbol} price={total.firstDiscountFormat} showNegative />
                    </section>}
                    {total.hasPaymentDiscount && <section className="cart-summary-item cart-summary-small">
                        <h4>{NLS.format("CART_DISCOUNT_PAYMENT", total.paymentDiscount)}</h4>
                        <Price symbol={total.currencySymbol} price={total.paymentDiscountFormat} showNegative />
                    </section>}
                    {shipment.reqPricing && total.hasShipment && <section className="cart-summary-item cart-summary-small">
                        <h4>{NLS.get("CART_SHIPMENT_PRICE")}</h4>
                        <Price symbol={total.currencySymbol} price={total.shipmentFormat} />
                    </section>}
                    {total.hasFinal && <section className="cart-summary-item">
                        <h4>{NLS.get("CART_FINAL_PRICE")}</h4>
                        <Price symbol={total.currencySymbol} price={total.finalFormat} />
                    </section>}
                </div>)}

                {hasShipment && <section className="cart-summary-item cart-summary-type">
                    <h4>{NLS.get("CART_SHIPMENT_SELECTED")}</h4>
                    <p>{shipment.name}</p>
                </section>}

                {hasSubsidiary && <section className="cart-summary-item cart-summary-type">
                    <h4>{NLS.get("CART_SUBSIDIARY_SELECTED")}</h4>
                    <p>{subsidiary.name}</p>
                </section>}

                {hasAddress && <section className="cart-summary-item cart-summary-type">
                    <h4>{NLS.get("CART_ADDRESS_SELECTED")}</h4>
                    <p>{address.name}</p>
                </section>}

                {hasPayment && <section className="cart-summary-item cart-summary-type">
                    <h4>{NLS.get("CART_PAYMENT_SELECTED")}</h4>
                    <p>{payment.name}</p>
                </section>}

                <footer className="cart-summary-footer">
                    {hasSubmit && <Button
                        variant="primary"
                        message={this.getSubmitMessage()}
                        isDisabled={isDisabled}
                        onClick={onSubmit}
                        fullWidth
                    />}
                    <Button
                        variant="cancel"
                        message={this.getCancelMessage()}
                        onClick={onCancel}
                        url={cancelUrl}
                        fullWidth
                    />
                </footer>
            </Card>

            {bellowMinimum && <Card className="cart-summary-warning">
                {NLS.format("CART_BELLOW_MINIMUM", `$${minimumTotal}`)}
            </Card>}

            {showInfo && <Card className="cart-summary-info" withBorder>
                {hasTimeMins && <p className="cart-summary-time">
                    {NLS.pluralize("CART_RESERVED_MINS", reservedMins)}
                </p>}
                {hasTimeSecs && <p className="cart-summary-time">
                    {NLS.pluralize("CART_RESERVED_SECS", reservedSecs)}
                </p>}
                {settings.products_withoutTax && <p>{NLS.get("PRODUCTS_WITHOUT_TAX")}</p>}
            </Card>}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        unconfirmToProducts : PropTypes.func.isRequired,
        openAlert           : PropTypes.func.isRequired,
        settings            : PropTypes.object.isRequired,
        elem                : PropTypes.object.isRequired,
        onSubmit            : PropTypes.func,
        onCancel            : PropTypes.func,
        cancelUrl           : PropTypes.string,
        isDisabled          : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            elem     : state.cart.elem,
        };
    }
}

export default connect(CartSummary.mapStateToProps, {
    unconfirmToProducts,
})(CartSummary);
