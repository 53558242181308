import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { ackAddress }       from "Actions/Client/AddressActions";
import NLS                  from "Utils/Core/NLS";
import Analitics            from "Utils/Core/Analitics";
import Responsive           from "Utils/App/Responsive";
import Utils                from "Utils/Common/Utils";

// Components
import CartOption           from "Components/Cart/Utils/CartOption";
import CartMessage          from "Components/Cart/Utils/CartMessage";
import CartSummary          from "Components/Cart/Utils/CartSummary";
import AddressEdit          from "Components/Cart/Shipment/AddressEdit";
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Title/SubTitle";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Price                from "Components/Utils/Common/Price";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    unconfirmToProducts, confirmShipment,
    setShipment, priceShipment, tryShipment,
} from "Actions/Store/CartActions";



/**
 * The Shipment Content
 */
class ShipmentContent extends React.Component {
    // The Current State
    state = {
        showDialog : false,
        elemID     : 0,
        loading    : false,
        isValid    : false,
        hasPrices  : false,
        errors     : {},
        data       : {
            shipmentMode : "",
            shipmentID   : 0,
            subsidiaryID : 0,
            addressID    : 0,
            message      : "",
        },
    }



    /**
     * Set the Data When Loading
     * @returns {Void}
     */
    componentDidMount() {
        const { settings, elem                                             } = this.props;
        const { shipmentMode, shipmentID, subsidiaryID, addressID, message } = elem;

        if (shipmentMode) {
            this.updateState(shipmentMode, shipmentID, subsidiaryID, addressID);
            if (addressID) {
                this.priceShipment(shipmentMode, addressID);
            }
            if (shipmentID) {
                this.setState({ isValid : true });
            }
        } else if (settings.cart_hideShipment) {
            this.initShipment();
        }
        if (message) {
            this.handleChange("message", message);
        }
    }

    /**
     * Close the Address Alert
     * @returns {Void}
     */
    componentWillUnmount() {
        this.props.ackAddress();
    }

    /**
     * Initializes the Shipment
     * @returns {Object}
     */
    async initShipment() {
        const { shipments, subsidiaries, addresses                } = this.props.elem;
        let   { shipmentMode, shipmentID, subsidiaryID, addressID } = this.props.elem;

        let   shipment   = Utils.getData(shipments,    "shipmentID",   shipmentID);
        const subsidiary = Utils.getData(subsidiaries, "subsidiaryID", subsidiaryID);
        const address    = Utils.getData(addresses,    "addressID",    addressID);

        if (Utils.isEmpty(shipment)) {
            shipmentMode = shipments[0].mode;
            shipmentID   = shipments[0].shipmentID;
            shipment     = shipments[0];
        }
        if (Utils.isEmpty(subsidiary)) {
            subsidiaryID = 0;
        }
        if (Utils.isEmpty(address)) {
            addressID = 0;
        }

        if (shipment.reqSubsidiary && !subsidiaryID && subsidiaries.length > 0) {
            const mainSubsidiary = Utils.getData(subsidiaries, "isDefault", 1);
            subsidiaryID = mainSubsidiary.subsidiaryID;
        }
        if (shipment.reqAddress && !addressID) {
            const mainAddress = Utils.getData(addresses, "isDefault", 1);
            addressID = mainAddress.addressID || 0;
        }
        await this.updateState(shipmentMode, shipmentID, subsidiaryID, addressID);
        await this.tryShipment(shipmentID, addressID, false);

        if (addressID) {
            this.priceShipment(shipmentMode, addressID);
        }
        if (shipmentID) {
            this.setState({ isValid : true });
        }
    }



    /**
     * Updates the Shipment Type
     * @param {Number}   newShipmentMode
     * @param {Number}   newShipmentID
     * @param {Number}   newSubsidiaryID
     * @param {Number}   newAddressID
     * @param {Boolean=} isValid
     * @returns {Promise}
     */
    async updateState(newShipmentMode, newShipmentID, newSubsidiaryID, newAddressID, isValid = false) {
        const oldData      = this.state.data;
        const shipmentMode = newShipmentMode === undefined ? oldData.shipmentMode : newShipmentMode;
        const shipmentID   = newShipmentID   === undefined ? oldData.shipmentID   : newShipmentID;
        const subsidiaryID = newSubsidiaryID === undefined ? oldData.subsidiaryID : newSubsidiaryID;
        const addressID    = newAddressID    === undefined ? oldData.addressID    : newAddressID;

        await this.setState({ data : { shipmentMode, shipmentID, subsidiaryID, addressID }, isValid });
        await this.props.setShipment(shipmentMode, shipmentID, subsidiaryID, addressID);
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data, [name] : value },
            errors : { ...this.state.data, [name] : ""    },
        });
    }

    /**
     * Selects the Shipment Type
     * @param {Number} shipmentMode
     * @returns {Promise}
     */
    selectShipment = async (shipmentMode) => {
        if (shipmentMode !== this.state.data.shipmentMode) {
            const shipment = Utils.getData(this.props.elem.modes, "type", shipmentMode);
            let   isValid  = false;
            if (!shipment.reqSubsidiary && !shipment.reqAddress) {
                isValid = true;
            } else if (shipment.reqSubsidiary && !this.props.elem.subsidiaries.length) {
                isValid = true;
            }
            await this.tryShipment(shipment.defaultType, 0, false);
            await this.updateState(shipmentMode, shipment.defaultType, 0, 0, isValid);
        }
    }

    /**
     * Selects the Subsidiary
     * @param {Number} subsidiaryID
     * @returns {Promise}
     */
    selectSubsidiary = (subsidiaryID) => {
        if (subsidiaryID !== this.state.data.subsidiaryID) {
            this.updateState(undefined, undefined, subsidiaryID, 0, true);
        }
    }

    /**
     * Selects the Address
     * @param {Number} addressID
     * @returns {Void}
     */
    selectAddress = (addressID) => {
        const data = this.state.data;
        if (addressID !== data.addressID) {
            this.updateState(undefined, 0, 0, addressID);
            this.priceShipment(data.shipmentMode, addressID);
        }
    }

    /**
     * Selects the Shipment
     * @param {Number} shipmentID
     * @returns {Void}
     */
    selectType = (shipmentID) => {
        const data = this.state.data;
        if (shipmentID !== data.shipmentID) {
            this.updateState(undefined, shipmentID);
            this.tryShipment(shipmentID, data.addressID);
        }
    }

    /**
     * Selects the cheapest Shipment
     * @param {Object[]} prices
     * @returns {Void}
     */
    selectCheapest = (prices) => {
        if (this.props.settings.cart_useCheapest) {
            let best = prices[0];
            for (const price of prices) {
                if (price.cents < best.cents) {
                    best = price;
                }
            }
            this.selectType(best.type);
        }
    }



    /**
     * Edits the Address
     * @param {String} success
     * @param {Number} addressID
     * @returns {Void}
     */
    editAddress = (success, addressID) => {
        const data = this.state.data;
        if (addressID === data.addressID) {
            this.priceShipment(data.shipmentMode, data.addressID);
        } else {
            this.selectAddress(addressID);
        }
        this.closeDialog();
        this.props.openAlert(success);
    }

    /**
     * Fetches the Shipment Prices
     * @param {Number} shipmentMode
     * @param {Number} addressID
     * @returns {Promise}
     */
    async priceShipment(shipmentMode, addressID) {
        const { priceShipment, closeAlert, openAlert } = this.props;
        if (this.state.loading) {
            return;
        }

        closeAlert();
        this.setState({ loading : true });
        try {
            const response = await priceShipment(shipmentMode, addressID);
            await this.setState({ loading : false, hasPrices : true });
            this.selectCheapest(response.prices);
        } catch (response) {
            openAlert("", response.form);
            this.setState({ loading : false, hasPrices : false });
        }
    }

    /**
     * Tryes a Shipment
     * @param {Number}   shipmentID
     * @param {Number}   addressID
     * @param {Boolean=} validate
     * @returns {Promise}
     */
    async tryShipment(shipmentID, addressID, validate = true) {
        const { tryShipment, closeAlert, openAlert } = this.props;
        const { loading, isValid                   } = this.state;
        if (loading) {
            return;
        }

        closeAlert();
        this.setState({ loading : true });
        try {
            await tryShipment(shipmentID, addressID);
            this.setState({ loading : false, isValid : validate ? true : isValid });
        } catch (response) {
            openAlert("", response.form);
            this.setState({ loading : false, isValid : validate ? false : isValid });
        }
    }

    /**
     * Handles a Cart Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { elem, confirmShipment, openAlert, closeAlert, onSubmit } = this.props;
        const { data, loading, success                                 } = this.state;
        if (loading && success) {
            return;
        }

        closeAlert();
        this.setState({ loading : true });
        try {
            const response = await confirmShipment(data);
            Analitics.checkout(Analitics.stepShipment, elem.products);
            onSubmit(response.checkout, response.orderID, response.order);
        } catch (errors) {
            openAlert("", errors.form);
            this.setState({ loading : false, errors });
        }
    }

    /**
     * Handles a Cart Cancel
     * @returns {Promise}
     */
    handleCancel = async () => {
        const { elem, unconfirmToProducts, openAlert, closeAlert, onSubmit } = this.props;
        if (this.state.loading) {
            return;
        }

        this.setState({ loading : true });
        closeAlert();
        try {
            await this.updateState(0, 0, 0, 0);
            await unconfirmToProducts(elem.orderHash);
            Analitics.checkout(Analitics.stepProducts, elem.products);
            onSubmit();
        } catch (errors) {
            openAlert("", errors.form);
            this.setState({ loading : false, errors });
        }
    }



    /**
     * Opens a Dialog
     * @param {Number} elemID
     * @returns {Void}
     */
    openDialog = (elemID) => {
        this.setState({ showDialog : true, elemID });
    }

    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.setState({ showDialog : false, elemID : 0 });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isApp, responsive, settings, elem, prices, openAlert          } = this.props;
        const { modes, addresses, subsidiaries                                } = elem;
        const { showDialog, elemID, loading, data, errors, isValid, hasPrices } = this.state;

        const isMobile        = Responsive.isMobile(responsive);
        const showContent     = !(isApp && isMobile && showDialog);
        const hideMode        = Boolean(settings.cart_hideShipment && modes.length === 1);
        const shipment        = Utils.getData(modes, "type", data.shipmentMode);
        const canEdit         = settings.addresses_canEdit && !settings.addresses_validateAddress;
        const hasSubsidiaries = Boolean(shipment.reqSubsidiary && subsidiaries.length);
        const hasAddresses    = Boolean(addresses.length);
        const hasTypes        = Boolean(hasPrices && shipment.reqType && prices.length && data.addressID && !settings.cart_useCheapest);
        const isDisabled      = loading || !isValid;

        return <>
            {showContent && <section className="cart-content">
                <CartOption
                    isHidden={hideMode}
                    data={modes}
                    icon="shipping"
                    message="CART_SHIPMENT_TITLE"
                    help="CART_SHIPMENT_HELP"
                    selected={data.shipmentMode}
                    onClick={this.selectShipment}
                />

                {hasSubsidiaries && <Card className="cart-select-card" withBorder>
                    <SubTitle message="CART_SUBSIDIARY_TITLE" icon="subsidiary" />
                    <ul className="cart-select-list no-list">
                        {subsidiaries.map((subsidiary) => <li key={subsidiary.subsidiaryID}>
                            <TextField
                                className="cart-select-field"
                                type="radio"
                                name="subsidiaryID"
                                value={subsidiary.subsidiaryID}
                                isChecked={Number(subsidiary.subsidiaryID) === Number(data.subsidiaryID)}
                                onChange={() => this.selectSubsidiary(subsidiary.subsidiaryID)}
                            />
                            <div className="cart-select-elem" onClick={() => this.selectSubsidiary(subsidiary.subsidiaryID)}>
                                <h3>{subsidiary.name}</h3>
                                <ul className="no-list">
                                    <li>{subsidiary.address}</li>
                                    <li>{subsidiary.locality}</li>
                                    <li>{subsidiary.provinceName}</li>
                                    <li>{subsidiary.zipCode}</li>
                                    {!!subsidiary.schedule && <li>{NLS.format("CART_SUBSIDIARY_SCHEDULE", subsidiary.schedule)}</li>}
                                </ul>
                                {!!subsidiary.message && <p>{subsidiary.message}</p>}
                            </div>
                        </li>)}
                    </ul>
                </Card>}

                {shipment.reqAddress && <Card className="cart-select-card" withBorder>
                    <SubTitle message="CART_ADDRESS_TITLE" icon="address" />
                    {hasAddresses && <ul className="cart-select-list no-list">
                        {addresses.map((address) => <li key={address.addressID}>
                            <TextField
                                className="cart-select-field"
                                type="radio"
                                name="addressID"
                                value={address.addressID}
                                isChecked={Number(address.addressID) === Number(data.addressID)}
                                onChange={() => this.selectAddress(address.addressID)}
                            />
                            <div className="cart-select-content">
                                <div className="cart-select-elem" onClick={() => this.selectAddress(address.addressID)}>
                                    <h3>{address.name}</h3>
                                    <ul className="no-list">
                                        <li>{address.street} {address.streetNumber}</li>
                                        {!!address.floor && <li>{NLS.format("ADDRESSES_FLOOR_FORMAT", address.floor)}</li>}
                                        {!!address.appartment && <li>{NLS.get("ADDRESSES_APPARTMENT_FORMAT", address.appartment)}</li>}
                                        <li>{address.locality}</li>
                                        <li>{address.provinceName}</li>
                                        <li>{address.zipCode}</li>
                                    </ul>
                                </div>
                                {canEdit && <HyperLink
                                    className="cart-select-link"
                                    message="CART_ADDRESS_EDIT"
                                    onClick={() => this.openDialog(address.addressID)}
                                />}
                            </div>
                        </li>)}
                        <div className="cart-select-other">
                            <Button
                                variant="primary"
                                message="CART_ADDRESS_ADD_NEW"
                                onClick={() => this.openDialog()}
                            />
                        </div>
                    </ul>}
                    {!hasAddresses && <div className="cart-select-empty">
                        <p className="cart-help">{NLS.get("CART_ADDRESS_NONE_HELP")}</p>
                        <Button
                            variant="primary"
                            message="CART_ADDRESS_ADD_FIRST"
                            onClick={() => this.openDialog()}
                        />
                    </div>}
                </Card>}

                {hasTypes && <Card className="cart-select-card" withBorder>
                    <SubTitle message="CART_TYPE_TITLE" icon="transport" />
                    <ul className="cart-select-list no-list">
                        {prices.map((price) => <li key={price.shipmentID}>
                            <TextField
                                className="cart-select-field"
                                type="radio"
                                name="type"
                                value={price.shipmentID}
                                isChecked={Number(price.shipmentID) === Number(data.shipmentID)}
                                onChange={() => this.selectType(price.shipmentID)}
                            />
                            <h3 className="cart-select-elem" onClick={() => this.selectType(price.shipmentID)}>
                                {price.name}
                            </h3>
                            {price.freeShipping && <h3>{NLS.get("CART_SHIPMENT_FREE")}</h3>}
                            {price.cents > 0 && <Price
                                className="cart-select-price"
                                symbol={price.currencySymbol}
                                price={price.price}
                            />}
                        </li>)}
                    </ul>
                </Card>}

                <CartMessage
                    step="shipment"
                    value={data.message}
                    error={errors.message}
                    onChange={this.handleChange}
                />
            </section>}

            <CartSummary
                step="shipment"
                openAlert={openAlert}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                isDisabled={isDisabled}
            />
            <AddressEdit
                open={showDialog}
                elemID={elemID}
                onSubmit={this.editAddress}
                onClose={this.closeDialog}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        unconfirmToProducts : PropTypes.func.isRequired,
        confirmShipment     : PropTypes.func.isRequired,
        setShipment         : PropTypes.func.isRequired,
        priceShipment       : PropTypes.func.isRequired,
        tryShipment         : PropTypes.func.isRequired,
        ackAddress          : PropTypes.func.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        isApp               : PropTypes.bool.isRequired,
        responsive          : PropTypes.object.isRequired,
        settings            : PropTypes.object.isRequired,
        elem                : PropTypes.object.isRequired,
        prices              : PropTypes.array.isRequired,
        openAlert           : PropTypes.func.isRequired,
        closeAlert          : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp      : state.core.isApp,
            responsive : state.core.responsive,
            settings   : state.core.settings,
            elem       : state.cart.elem,
            prices     : state.cart.prices,
        };
    }
}

export default connect(ShipmentContent.mapStateToProps, {
    unconfirmToProducts, confirmShipment,
    setShipment, priceShipment, tryShipment, ackAddress,
})(ShipmentContent);
