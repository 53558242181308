import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setNotiRedirect }  from "Actions/Content/NotificationActions";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";

// Components
import Form                 from "Components/Utils/Form/Form";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Actions
import {
    login, setFocused,
} from "Actions/Core/AuthActions";
import {
    assignCart, setCartRedirect,
} from "Actions/Store/CartActions";



/**
 * The Login Page
 */
class LoginPage extends React.Component {
    // The Current State
    state = {
        data : {
            identifier : "",
            password   : "",
            isApp      : this.props.isApp ? 1 : 0,
        },
        loading : false,
        errors  : {},
        error   : "",
    }


    /**
     * Handles the Focus Change
     * @param {Boolean} isFocused
     * @returns {Void}
     */
    handleFocus = (isFocused) => {
        if (this.props.isApp) {
            this.props.setFocused(isFocused);
        }
    }

    /**
     * Handles the Focus Change
     * @param {Boolean} isFocused
     * @returns {Void}
     */
    handleBlur = (isFocused) => {
        if (this.props.isApp) {
            window.setTimeout(() => {
                this.props.setFocused(isFocused);
            }, 200);
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Does a Submit on Touch
     * @param {Event} e
     * @returns {Void}
     */
    handleTouch = (e) => {
        if (this.props.isFocused && !this.state.loading) {
            document.activeElement.blur();
            this.handleSubmit(e);
        }
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Promise}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const {
            location, orderHash, cartRedirect, notiRedirect,
            login, assignCart, setCartRedirect, setNotiRedirect,
        } = this.props;
        const { data, loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, errors : {}, error : "" });
        try {
            data.orderHash = orderHash;
            const response = await login(data);
            if (response.redirectAdmin) {
                window.location.href = response.redirectAdmin;
            } else {
                if (orderHash) {
                    await assignCart(response.clientID, orderHash);
                }
                if (cartRedirect) {
                    setCartRedirect("");
                    Href.goto(cartRedirect);
                } else if (notiRedirect) {
                    setNotiRedirect("");
                    Href.goto(notiRedirect);
                } else {
                    const redirect = location.state ? location.state.from.pathname : "/";
                    Href.goto(redirect);
                }
            }
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ error : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, loading, errors, error     } = this.state;
        const { settings, wasReseted, wasCreated } = this.props;

        const hasValidation   = Boolean(settings.clients_validateRegister);
        const identifier      = settings.clients_hasCodes ? "AUTH_LOGIN_CODE" : "AUTH_LOGIN_EMAIL";
        const identifierError = errors.identifier ? (settings.clients_hasCodes ? "AUTH_ERROR_CODE" : "AUTH_ERROR_EMAIL") : "";

        return <>
            {wasReseted && <p className="result-big result-success align-center">
                {NLS.get("AUTH_LOGIN_RESETED")}
            </p>}
            {wasCreated && <p className="result-big result-success align-center">
                {NLS.get(hasValidation ? "AUTH_LOGIN_VALIDATE" : "AUTH_LOGIN_CREATED")}
            </p>}

            <Form
                className="auth-form"
                error={error}
                onSubmit={this.handleSubmit}
                onClose={this.closeAlert}
            >
                <TextField
                    name="identifier"
                    type="email"
                    autoComplete="new-password"
                    label={identifier}
                    value={data.identifier}
                    error={identifierError}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    noCapitalize
                    isRequired
                    withMargin
                    shrink
                />
                <TextField
                    name="password"
                    type="password"
                    label="GENERAL_PASSWORD"
                    autoComplete="new-password"
                    value={data.password}
                    error={errors.password}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    isRequired
                    withMargin
                    shrink
                />
                <Button
                    variant="primary"
                    message="AUTH_LOGIN_ACTION"
                    onTouchEnd={this.handleTouch}
                    isDisabled={loading}
                    fullWidth
                />
            </Form>

            <p className="auth-link-route">
                <HyperLink
                    className="auth-link-anchor"
                    variant="black"
                    url="RECOVER"
                    message="AUTH_RECOVER_LINK"
                />
            </p>
            {settings.clients_allowRegister && <div className="auth-link-block">
                <h3>{NLS.get("AUTH_REGISTER_TITLE")}</h3>
                <HyperLink
                    className="auth-link-anchor auth-link-register"
                    variant="black"
                    url="REGISTER"
                    message="AUTH_REGISTER_LINK"
                />
            </div>}
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        login           : PropTypes.func.isRequired,
        setFocused      : PropTypes.func.isRequired,
        assignCart      : PropTypes.func.isRequired,
        setCartRedirect : PropTypes.func.isRequired,
        setNotiRedirect : PropTypes.func.isRequired,
        location        : PropTypes.object.isRequired,
        isApp           : PropTypes.bool.isRequired,
        isFocused       : PropTypes.bool.isRequired,
        wasReseted      : PropTypes.bool.isRequired,
        wasCreated      : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        orderHash       : PropTypes.string.isRequired,
        cartRedirect    : PropTypes.string.isRequired,
        notiRedirect    : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp        : state.core.isApp,
            settings     : state.core.settings,
            isFocused    : state.auth.isFocused,
            wasReseted   : state.auth.wasReseted,
            wasCreated   : state.auth.wasCreated,
            orderHash    : state.cart.elem.orderHash,
            cartRedirect : state.cart.redirect,
            notiRedirect : state.notification.redirect,
        };
    }
}

export default connect(LoginPage.mapStateToProps, {
    login, setFocused, assignCart, setCartRedirect, setNotiRedirect,
})(LoginPage);
