import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { sendContact }      from "Actions/Client/QueryActions";
import QueryOrigin          from "Utils/App/QueryOrigin";

// Components
import TerTitle             from "Components/Utils/Title/TerTitle";
import Form                 from "Components/Utils/Form/Form";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";



/**
 * The Contact Form
 */
class ContactForm extends React.Component {
    // The Initial Data
    static initialData = {
        name    : "",
        email   : "",
        phone   : "",
        subject : "",
        image   : "",
        message : "",
    }

    // The Current State
    state = {
        data    : { ...ContactForm.initialData },
        loading : false,
        success : "",
        errors  : {},
        error   : "",
    }



    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { sendContact, onSubmit, origin, orderID, productID } = this.props;
        const { data, loading                                     } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, errors : {}, error : "" });
        try {
            const success = await sendContact({ origin, orderID, productID, ...data });
            this.setState({
                success,
                loading : false,
                data    : { ...ContactForm.initialData },
            });
            if (onSubmit) {
                onSubmit();
            }
        } catch (errors) {
            this.setState({
                loading : false,
                success : "",
                error   : errors.form,
                errors,
            });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isAuthenticated, settings, origin, className, message } = this.props;
        const { data, loading, success, errors, error                 } = this.state;

        const showPhone   = !isAuthenticated && settings.contact_requestPhone;
        const showSubject = QueryOrigin.reqSubject(origin, settings);
        const showImage   = QueryOrigin.reqImage(origin);
        const reqMessage  = QueryOrigin.reqMessage(origin);

        return <div className={className}>
            <TerTitle message={message} />
            <Form
                columns="1"
                success={success}
                error={error}
                onSubmit={this.handleSubmit}
                onClose={this.closeAlert}
            >

                {!isAuthenticated && <TextField
                    name="name"
                    label="CONTACT_YOUR_NAME"
                    value={data.name}
                    error={errors.name}
                    onChange={this.handleChange}
                    isRequired
                />}
                {!isAuthenticated && <TextField
                    name="email"
                    label="CONTACT_YOUR_EMAIL"
                    value={data.email}
                    error={errors.email}
                    onChange={this.handleChange}
                    isRequired
                />}
                {showPhone && <TextField
                    name="phone"
                    label="CONTACT_YOUR_PHONE"
                    value={data.phone}
                    error={errors.phone}
                    onChange={this.handleChange}
                    isRequired
                />}
                {showSubject && <TextField
                    name="subject"
                    label="CONTACT_YOUR_SUBJECT"
                    value={data.subject}
                    error={errors.subject}
                    onChange={this.handleChange}
                    isRequired
                />}
                {showImage && <TextField
                    type="file"
                    name="image"
                    label="GENERAL_IMAGE"
                    value={data.image}
                    onChange={this.handleChange}
                    error={errors.image}
                    isRequired
                />}
                <TextField
                    type="textarea"
                    name="message"
                    label="GENERAL_MESSAGE"
                    value={data.message}
                    error={errors.message}
                    onChange={this.handleChange}
                    isRequired={reqMessage}
                    autoGrow
                />
                <Button
                    variant="primary"
                    message="CONTACT_SEND"
                    isDisabled={loading}
                    fullWidth
                />
            </Form>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        sendContact     : PropTypes.func.isRequired,
        settings        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        origin          : PropTypes.number,
        className       : PropTypes.string,
        message         : PropTypes.string,
        orderID         : PropTypes.number,
        productID       : PropTypes.number,
        onSubmit        : PropTypes.func,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        origin    : QueryOrigin.CONTACT,
        className : "",
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(ContactForm.mapStateToProps, {
    sendContact,
})(ContactForm);
