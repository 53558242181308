import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import ClassList            from "Utils/Common/ClassList";
import Utils                from "Utils/Common/Utils";

// Components
import Icon                 from "Components/Utils/Media/Icon";

// Styles
import "Styles/Components/Utils/Common/Slider.css";



/**
 * The Slider
 */
class Slider extends React.Component {
    // The Current State
    state = {
        isMounted  : false,
        curIdx     : 0,
        touchStart : 0,
        touchDiff  : 0,
        timeout    : null,
        zooming    : false,
        zoomBounds : null,
    }



    /**
     * Starts the Timeout
     * @returns {Void}
     */
    componentDidMount() {
        this.autoSlide();
        this.setState({
            isMounted : true,
            curIdx    : this.props.index || 0,
        });

        const child = document.querySelector(".slider-content > *:first-child");
        if (child) {
            child.classList.add("slider-selected");
        }
    }

    /**
     * End the Timeouts
     * @returns {Void}
     */
    componentWillUnmount() {
        this.clearTimeout();
        this.setState({ isMounted : false, timeout : null, });
    }

    /**
     * Updates the Index
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.index !== prevProps.index && this.state.curIdx !== this.props.index) {
            this.setState({ curIdx : this.props.index });
        }
    }

    /**
     * Clears the Timeout
     * @returns {Void}
     */
    clearTimeout() {
        if (this.state.timeout) {
            window.clearTimeout(this.state.timeout);
            this.setState({ timeout : null });
        }
    }

    /**
     * Returns the Amount of Slides
     * @return {Number}
     */
    getAmount() {
        const { total, count } = this.props;
        return Math.ceil(total / count);
    }

    /**
     * Returns the Auto Slide
     * @returns {Object}
     */
    autoSlide() {
        const { autoSlide, total, settings } = this.props;
        if (!autoSlide || total < 2) {
            return;
        }
        const time = Number(settings.home_sliderTime) * 1000;
        if (time > 0) {
            this.clearTimeout();
            const timeout = window.setTimeout(() => this.gotoDir(1), time);
            this.setState({ timeout });
        }
    }



    /**
     * Moves the Carousel to the given Index
     * @param {Number} curIdx
     * @returns {Void}
     */
    gotoIndex = (curIdx) => {
        this.setState({ curIdx });
        this.autoSlide();

        const selected = document.querySelector(".slider-selected");
        if (selected) {
            selected.classList.remove("slider-selected");
        }
        const child = document.querySelector(`.slider-content > *:nth-child(${curIdx + 1})`);
        if (child) {
            child.classList.add("slider-selected");
        }

        if (this.props.onSwitch) {
            this.props.onSwitch(curIdx);
        }
    }

    /**
     * Moves the Carousel to the given Direction
     * @param {Number} direction
     * @returns {Void}
     */
    gotoDir = (direction) => {
        const { total, count, wrapAtEnd } = this.props;
        const lastIdx = total - count;
        let   curIdx  = this.state.curIdx + direction;

        if (curIdx > lastIdx) {
            curIdx = wrapAtEnd ? 0 : lastIdx;
        } else if (curIdx < 0) {
            curIdx = wrapAtEnd ? lastIdx : 0;
        }
        this.gotoIndex(curIdx);
    }



    /**
     * Moves the Carousel to the given Direction
     * @param {Number} direction
     * @returns {Function}
     */
    handleDir = (direction) => (e) => {
        this.gotoDir(direction);
        e.preventDefault();
        e.stopPropagation();
    }

    /**
     * Handles the Touch Start
     * @param {Event} e
     * @returns {Void}
     */
    handleTouchStart = (e) => {
        const { isMounted } = this.state;
        if (isMounted && this.getAmount() > 1) {
            this.clearTimeout();
            this.setState({ touchStart : e.touches[0].clientX });
        }
    }

    /**
     * Handles the Touch Move
     * @param {Event} e
     * @returns {Void}
     */
    handleTouchMove = (e) => {
        const { isMounted, touchStart } = this.state;
        if (isMounted && this.getAmount() > 1) {
            this.setState({ touchDiff : e.touches[0].clientX - touchStart });
        }
    }

    /**
     * Handles the Touch End
     * @returns {Void}
     */
    handleTouchEnd = () => {
        const { isMounted, curIdx, touchDiff } = this.state;
        if (!isMounted || this.getAmount() === 1) {
            return;
        }
        this.setState({ touchStart : 0, touchDiff : 0 });
        if (Math.abs(touchDiff) < 100) {
            if (this.props.onClick) {
                this.props.onClick(curIdx);
            }
        } else if (touchDiff < 0) {
            this.gotoDir(1);
        } else {
            this.gotoDir(-1);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            settings, className, useFade, smallNav, outsideNav, withDots,
            total, count, style, children,
        } = this.props;
        const { curIdx, touchDiff } = this.state;

        const amount  = this.getAmount();
        const dots    = Utils.createArrayOf(total);
        const hasNav  = total > 1;
        const hasDots = hasNav && withDots;
        const opacity = touchDiff !== 0 ? Math.min(0.4, Math.abs(touchDiff) / window.outerWidth) : 0;
        const styles  = Utils.merge({
            "--slider-timer"   : `${settings.home_sliderTime}s`,
            "--slider-amount"  : amount,
            "--slider-total"   : total,
            "--slider-count"   : count,
            "--slider-current" : curIdx,
            "--slider-move"    : `${touchDiff}px`,
            "--slider-opacity" : opacity,
        }, style);

        const classes = new ClassList("slider-container", className);
        classes.addIf("slider-slide",    !useFade);
        classes.addIf("slider-fade",     useFade);
        classes.addIf("slider-animated", touchDiff === 0);

        const navClasses = new ClassList("slider-nav");
        navClasses.addIf("slider-nav-small",   smallNav);
        navClasses.addIf("slider-nav-outside", outsideNav);

        return <div
            className={classes.get()}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
            style={styles}
        >
            <div className="slider-wrapper">
                <div className="slider-content">
                    {children}
                </div>
            </div>
            {hasNav && <nav className={navClasses.get()}>
                <button className="slider-prev" onClick={this.handleDir(-1)}>
                    <Icon variant="left" />
                </button>
                <button className="slider-next" onClick={this.handleDir(1)}>
                    <Icon variant="right" />
                </button>
            </nav>}
            {hasDots && <nav className="slider-dots">
                <ul className="no-list">
                    {dots.map((elem, index) => <li key={index}>
                        <button
                            className={curIdx === index ? "slider-active" : ""}
                            onClick={() => this.gotoIndex(index)}
                        >
                            {index}
                        </button>
                    </li>)}
                </ul>
            </nav>}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings   : PropTypes.object.isRequired,
        total      : PropTypes.number.isRequired,
        index      : PropTypes.number,
        onClick    : PropTypes.func,
        onSwitch   : PropTypes.func,
        className  : PropTypes.string,
        useFade    : PropTypes.bool,
        wrapAtEnd  : PropTypes.bool,
        smallNav   : PropTypes.bool,
        outsideNav : PropTypes.bool,
        withDots   : PropTypes.bool,
        autoSlide  : PropTypes.bool,
        style      : PropTypes.object,
        count      : PropTypes.number,
        children   : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className  : "",
        useFade    : false,
        wrapAtEnd  : false,
        smallNav   : false,
        outsideNav : false,
        withDots   : false,
        autoSlide  : false,
        style      : {},
        count      : 1,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(Slider.mapStateToProps)(Slider);
