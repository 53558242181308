import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchInvoices }    from "Actions/Client/VoucherActions";
import { setPage }          from "Actions/Client/ClientActions";
import Href                 from "Utils/Core/Href";
import Utils                from "Utils/Common/Utils";

// Components
import ClientTitle          from "Components/Utils/Title/ClientTitle";
import Pagination           from "Components/Utils/Common/Pagination";
import Table                from "Components/Utils/Table/Table";
import TableHead            from "Components/Utils/Table/TableHead";
import TableBody            from "Components/Utils/Table/TableBody";
import TableRow             from "Components/Utils/Table/TableRow";
import TableCell            from "Components/Utils/Table/TableCell";



/**
 * The Invoice Page
 */
class InvoicePage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        if (!this.props.settings.vouchers_hasInvoices) {
            Href.goto("ACCOUNT_DATA");
            return;
        }
        const params = this.getParams();
        this.props.fetchInvoices(params);
        this.props.setPage(params.page);
    }

    /**
     * Load the Data if the Props change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);

        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchInvoices(newParams);
            this.props.setPage(newParams.page);
        }
    }

    /**
     * Returns the Params
     * @param {Object} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        return { page : Number(params.page) || 1 };
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, loading, list, total } = this.props;
        const { page                           } = this.getParams();

        if (!settings.vouchers_hasInvoices) {
            return <React.Fragment />;
        }
        return <>
            <ClientTitle message="VOUCHERS_INVOICES" icon="voucher" />
            <Table
                none="VOUCHERS_NONE_INVOICES"
                loading={loading}
                hasContent={!!list.length}
                hasActions
                hasSpacing
            >
                <TableHead>
                    <TableCell message="VOUCHERS_DESCRIPTION" />
                    <TableCell message="VOUCHERS_DATE"        />
                    <TableCell message="VOUCHERS_AMOUNT"      align="right" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.invoiceID}>
                        <TableCell message={elem.name}            isBold />
                        <TableCell message={elem.voucherTimeDate} nowrap />
                        <TableCell message={`$ ${elem.amount}`}   align="right" nowrap />
                        <TableCell
                            href={Href.voucherUrl("invoices", elem.hash)}
                            target="_blank"
                            message="GENERAL_DOWNLOAD"
                            align="right"
                            isLink
                        />
                    </TableRow>)}
                </TableBody>
            </Table>
            <Pagination
                currentPage={page}
                totalPages={total}
                url="INVOICES"
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchInvoices : PropTypes.func.isRequired,
        setPage       : PropTypes.func.isRequired,
        match         : PropTypes.object.isRequired,
        settings      : PropTypes.object.isRequired,
        loading       : PropTypes.bool.isRequired,
        list          : PropTypes.array.isRequired,
        total         : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            loading  : state.voucher.loading,
            list     : state.voucher.invoices.list,
            total    : state.voucher.invoices.total,
        };
    }
}

export default connect(InvoicePage.mapStateToProps, {
    fetchInvoices, setPage,
})(InvoicePage);
