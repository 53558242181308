import Href                 from "Utils/Core/Href";
import { fetchCore }        from "Actions/Core/CoreActions";
import { fetchStore }       from "Actions/Store/StoreActions";

// Actions
import {
    fetchHome, fetchPage, fetchEntry,
} from "Actions/Content/ContentActions";



/**
 * Handle the Preview Messages
 * @param {Object}  store
 * @param {Boolean} isPreview
 * @returns {Void}
 */
export default function registerPreview(store, isPreview) {
    if (!isPreview) {
        return;
    }

    window.addEventListener("message", (event) => {
        switch (event.data.section) {
        case "core":
            fetchCore()(store.dispatch);
            break;
        case "store":
            fetchStore()(store.dispatch);
            break;
        case "products":
            Href.goto("PRODUCTS");
            break;
        case "content":
            if (event.data.page) {
                Href.goto(event.data.page);
                fetchPage(event.data.page)(store.dispatch);
            } else if (event.data.entry) {
                Href.goto("ENTRY", event.data.entry);
                fetchEntry(event.data.entry)(store.dispatch);
            } else {
                Href.goto("HOME");
                fetchHome()(store.dispatch);
            }
            break;
        default:
        }
    });
}
