import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";



/**
 * The Product Card Image
 */
class ProductCardImage extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, data } = this.props;

        const showNew    = Boolean(settings.products_showNewTag && data.isNew);
        const imageClass = "product-card-image" + (settings.products_spaceAroundImage ? " product-card-image-space" : "");

        return <ProductImage
            className={imageClass}
            variant="small"
            data={data}
            showNew={showNew}
            showUnavailable={data.isUnavailable}
            showRented={settings.products_showRentedList && data.isRented}
            zoomOnHover={settings.products_zoomImageOnHover}
            withPreview
        />;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
        data     : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(ProductCardImage.mapStateToProps)(ProductCardImage);
