export default {
    // Main Urls
    HOME                        : "/",
    ERROR                       : "/404",

    // Auth Urls
    LOGIN                       : "/login",
    REGISTER                    : "/register",
    RECOVER                     : "/recover",
    RESET                       : "/reset",
    CODE                        : "/reset/:code",


    // Products Urls
    PRODUCTS                    : "/products",
    PRODUCTS_PAGE               : "/products/:page([0-9]+)",
    PRODUCTS_CATEGORY           : "/products/:category([a-z0-9-]{2,})",
    PRODUCTS_CATEGORY_PAGE      : "/products/:category([a-z0-9-]{2,})/:page([0-9]+)",
    PRODUCTS_SUBCATEGORY        : "/products/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    PRODUCTS_SUBCATEGORY_PAGE   : "/products/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    PRODUCTS_TERCATEGORY        : "/products/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    PRODUCTS_TERCATEGORY_PAGE   : "/products/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Offer Urls
    OFFER                       : "/offer",
    OFFER_OFFER                 : "/offer/:offer",
    OFFER_PAGE                  : "/offer/:offer/:page([0-9]+)",
    OFFER_CATEGORY              : "/offer/:offer/:category([a-z0-9-]{2,})",
    OFFER_CATEGORY_PAGE         : "/offer/:offer/:category([a-z0-9-]{2,})/:page([0-9]+)",
    OFFER_SUBCATEGORY           : "/offer/:offer/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    OFFER_SUBCATEGORY_PAGE      : "/offer/:offer/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    OFFER_TERCATEGORY           : "/offer/:offer/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    OFFER_TERCATEGORY_PAGE      : "/offer/:offer/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Brand Urls
    BRAND                       : "/brand",
    BRAND_BRAND                 : "/brand/:brand",
    BRAND_PAGE                  : "/brand/:brand/:page([0-9]+)",
    BRAND_CATEGORY              : "/brand/:brand/:category([a-z0-9-]{2,})",
    BRAND_CATEGORY_PAGE         : "/brand/:brand/:category([a-z0-9-]{2,})/:page([0-9]+)",
    BRAND_SUBCATEGORY           : "/brand/:brand/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    BRAND_SUBCATEGORY_PAGE      : "/brand/:brand/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    BRAND_TERCATEGORY           : "/brand/:brand/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    BRAND_TERCATEGORY_PAGE      : "/brand/:brand/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Artist Urls
    ARTIST                      : "/artist",
    ARTIST_ARTIST               : "/artist/:artist",
    ARTIST_PAGE                 : "/artist/:artist/:page([0-9]+)",
    ARTIST_CATEGORY             : "/artist/:artist/:category([a-z0-9-]{2,})",
    ARTIST_CATEGORY_PAGE        : "/artist/:artist/:category([a-z0-9-]{2,})/:page([0-9]+)",
    ARTIST_SUBCATEGORY          : "/artist/:artist/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    ARTIST_SUBCATEGORY_PAGE     : "/artist/:artist/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    ARTIST_TERCATEGORY          : "/artist/:artist/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    ARTIST_TERCATEGORY_PAGE     : "/artist/:artist/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",
    ARTIST_BIO                  : "/artistbio",
    ARTIST_BIO_ARTIST           : "/artistbio/:artist",

    // Search Urls
    SEARCH                      : "/search",
    SEARCH_TERM                 : "/search/:search",
    SEARCH_PAGE                 : "/search/:search/:page([0-9]+)",
    SEARCH_CATEGORY             : "/search/:search/:category([a-z0-9-]{2,})",
    SEARCH_CATEGORY_PAGE        : "/search/:search/:category([a-z0-9-]{2,})/:page([0-9]+)",
    SEARCH_SUBCATEGORY          : "/search/:search/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    SEARCH_SUBCATEGORY_PAGE     : "/search/:search/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    SEARCH_TERCATEGORY          : "/search/:search/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    SEARCH_TERCATEGORY_PAGE     : "/search/:search/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Newest Urls
    NEWESTS                     : "/new",
    NEWESTS_PAGE                : "/new/:page([0-9]+)",
    NEWESTS_CATEGORY            : "/new/:category([a-z0-9-]{2,})",
    NEWESTS_CATEGORY_PAGE       : "/new/:category([a-z0-9-]{2,})/:page([0-9]+)",
    NEWESTS_SUBCATEGORY         : "/new/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    NEWESTS_SUBCATEGORY_PAGE    : "/new/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    NEWESTS_TERCATEGORY         : "/new/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    NEWESTS_TERCATEGORY_PAGE    : "/new/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Importants Urls
    IMPORTANTS                  : "/featured",
    IMPORTANTS_PAGE             : "/featured/:page([0-9]+)",
    IMPORTANTS_CATEGORY         : "/featured/:category([a-z0-9-]{2,})",
    IMPORTANTS_CATEGORY_PAGE    : "/featured/:category([a-z0-9-]{2,})/:page([0-9]+)",
    IMPORTANTS_SUBCATEGORY      : "/featured/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    IMPORTANTS_SUBCATEGORY_PAGE : "/featured/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    IMPORTANTS_TERCATEGORY      : "/featured/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    IMPORTANTS_TERCATEGORY_PAGE : "/featured/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Favorites Urls
    FAVORITES                   : "/favorites",
    FAVORITES_PAGE              : "/favorites/:page([0-9]+)",
    FAVORITES_CATEGORY          : "/favorites/:category([a-z0-9-]{2,})",
    FAVORITES_CATEGORY_PAGE     : "/favorites/:category([a-z0-9-]{2,})/:page([0-9]+)",
    FAVORITES_SUBCATEGORY       : "/favorites/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    FAVORITES_SUBCATEGORY_PAGE  : "/favorites/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    FAVORITES_TERCATEGORY       : "/favorites/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    FAVORITES_TERCATEGORY_PAGE  : "/favorites/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Purchases Urls
    PURCHASES                   : "/purchases",
    PURCHASES_PAGE              : "/purchases/:page([0-9]+)",
    PURCHASES_CATEGORY          : "/purchases/:category([a-z0-9-]{2,})",
    PURCHASES_CATEGORY_PAGE     : "/purchases/:category([a-z0-9-]{2,})/:page([0-9]+)",
    PURCHASES_SUBCATEGORY       : "/purchases/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    PURCHASES_SUBCATEGORY_PAGE  : "/purchases/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    PURCHASES_TERCATEGORY       : "/purchases/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    PURCHASES_TERCATEGORY_PAGE  : "/purchases/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // History Urls
    HISTORY                     : "/history",
    HISTORY_PAGE                : "/history/:page([0-9]+)",
    HISTORY_CATEGORY            : "/history/:category([a-z0-9-]{2,})",
    HISTORY_CATEGORY_PAGE       : "/history/:category([a-z0-9-]{2,})/:page([0-9]+)",
    HISTORY_SUBCATEGORY         : "/history/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    HISTORY_SUBCATEGORY_PAGE    : "/history/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    HISTORY_TERCATEGORY         : "/history/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    HISTORY_TERCATEGORY_PAGE    : "/history/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Product Urls
    PRODUCT                     : "/product",
    PRODUCT_PRODUCT             : "/product/:product",
    PRODUCT_CATEGORY            : "/product/:category([a-z0-9-]{2,})/:product",
    PRODUCT_SUBCATEGORY         : "/product/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:product",
    PRODUCT_TERCATEGORY         : "/product/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:product",


    // Content Urls
    PAGE                        : "/:slug([a-z0-9-]{2,})",
    PAGE_PAGE                   : "/:slug([a-z0-9-]{2,})/:page([0-9]+)",
    ENTRY                       : "/entry",
    ENTRY_ENTRY                 : "/entry/:entry",
    ENTRY_PAGE                  : "/entry/:entry/:page([0-9]+)",
    CHAT                        : "/chat",
    NOTIFICATIONS               : "/notificactions",

    // Cart Urls
    CART                        : "/cart",
    CART_ORDER                  : "/cart/:order",


    // Account Urls
    ACCOUNT                     : "/account",
    ACCOUNT_DATA                : "/account/data",

    ADDRESSES                   : "/account/addresses",
    ADDRESSES_PAGE              : "/account/addresses/:page",
    ADDRESSES_CREATE            : "/account/addresses/crear",
    ADDRESSES_VIEW              : "/account/address",
    ADDRESSES_ONE               : "/account/address/:address",

    QUERIES                     : "/account/queries",
    QUERIES_TYPE                : "/account/queries/:type(open|closed)",
    QUERIES_TYPE_PAGE           : "/account/queries/:type(open|closed)/:page",
    QUERIES_OPEN                : "/account/queries/open",
    QUERIES_CLOSED              : "/account/queries/closed",
    QUERIES_CREATE              : "/account/queries/create",
    QUERIES_VIEW                : "/account/query",
    QUERIES_ONE                 : "/account/query/:query",

    ORDERS                      : "/account/orders",
    ORDERS_PAGE                 : "/account/orders/:page",
    ORDERS_VIEW                 : "/account/order",
    ORDERS_ONE                  : "/account/order/:order",

    VOUCHERS                    : "/account/voucher",
    INVOICES                    : "/account/voucher/invoices",
    INVOICES_PAGE               : "/account/voucher/invoices/:page",
    RECEIPTS                    : "/account/voucher/receipts",
    RECEIPTS_PAGE               : "/account/voucher/receipts/:page",
    CREDIT_NOTES                : "/account/voucher/credit-notes",
    CREDIT_NOTES_PAGE           : "/account/voucher/credit-notes/:page",
    DEBIT_NOTES                 : "/account/voucher/debit-notes",
    DEBIT_NOTES_PAGE            : "/account/voucher/debit-notes/:page",
};
