import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleMenu }       from "Actions/Store/StoreActions";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Category Menu
 */
class CategoryMenu extends React.Component {
    // The Current State
    state = {
        showSub     : false,
        categoryID  : 0,
        categoryIdx : 0,
    };

    /**
     * Toggles the Menu
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        const { open, categories, toggleMenu } = this.props;
        if (categories.length > 0 && open && !prevProps.open) {
            toggleMenu(true);
            this.showCategory(categories[0].id, 0);
        }
    }

    /**
     * Shows the Category SubMenu
     * @param {Number} id
     * @param {Number} index
     * @returns {Void}
     */
    showCategory = (id, index) => {
        this.setState({
            showSub     : true,
            categoryID  : id,
            categoryIdx : index,
        });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, categories, open, top, onEnter, onLeave, onClose } = this.props;
        const { showSub, categoryID, categoryIdx                           } = this.state;

        if (!open || !categories.length) {
            return <React.Fragment />;
        }

        const hasSub   = settings.categories_hasSubcategories;
        const hasTer   = settings.categories_hasTercategories;
        const category = showSub ? categories[categoryIdx] : {};
        const showSubs = Boolean(hasSub && showSub && category && category.childs.length);

        if (!hasSub) {
            return <nav
                className="submenu-content submenu-subcategory submenu-with-sub"
                style={{ top : `${top}px` }}
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}
            >
                <h2>{NLS.get("CATEGORIES_NAME")}</h2>
                <ul className="no-list">
                    {categories.map((elem) => <li key={elem.id}>
                        <HyperLink
                            variant="opacity"
                            href={Href.url("PRODUCTS", elem.url)}
                            message={elem.name}
                            onClick={onClose}
                        />
                    </li>)}
                </ul>
            </nav>;
        }

        return <nav
            className="submenu-content submenu-category"
            style={{ top : `${top}px` }}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <ul className="submenu-category-list no-list">
                {categories.map((elem, index) => <li
                    key={elem.id}
                    className={categoryID === elem.id ? "submenu-selected" : ""}
                >
                    <HyperLink
                        variant="none"
                        href={Href.url("PRODUCTS", elem.url)}
                        message={elem.name}
                        afterIcon={elem.childs.length > 0 ? "right" : ""}
                        onClick={onClose}
                        onMouseEnter={() => this.showCategory(elem.id, index)}
                    />
                </li>)}
            </ul>
            {showSubs && <div className={"submenu-with-sub" + (hasTer ? " submenu-with-ter" : "")}>
                <h2>{category.name}</h2>
                <ul className="no-list">
                    {category.childs.map((elem) => <li key={elem.id}>
                        <HyperLink
                            variant="opacity"
                            href={Href.url("PRODUCTS", category.url, elem.url)}
                            message={elem.name}
                            onClick={onClose}
                        />
                        {elem.childs.length > 0 && <ul className="no-list">
                            {elem.childs.map((subelem) => <li key={subelem.id}>
                                <HyperLink
                                    variant="opacity"
                                    href={Href.url("PRODUCTS", category.url, elem.url, subelem.url)}
                                    message={subelem.name}
                                    onClick={onClose}
                                />
                            </li>)}
                        </ul>}
                    </li>)}
                </ul>
            </div>}
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleMenu : PropTypes.func.isRequired,
        settings   : PropTypes.object.isRequired,
        categories : PropTypes.array.isRequired,
        open       : PropTypes.bool.isRequired,
        top        : PropTypes.number.isRequired,
        onEnter    : PropTypes.func.isRequired,
        onLeave    : PropTypes.func.isRequired,
        onClose    : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings   : state.core.settings,
            categories : state.store.categories,
        };
    }
}

export default connect(CategoryMenu.mapStateToProps, {
    toggleMenu,
})(CategoryMenu);
