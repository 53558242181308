import API                  from "Utils/Core/API";

// Types
import {
    CHAT_ELEM, CHAT_MESSAGES, CHAT_NEW_MESSAGES,
    CHAT_VIEWING, STORE_LAST_UPDATE,
} from "Utils/Core/Types";



/**
 * Sets the Viewing Chat state
 * @param {Boolean} isViewing
 * @returns {Function}
 */
export function setViewingChat(isViewing) {
    return async (dispatch) => {
        return dispatch({ type : CHAT_VIEWING, isViewing });
    };
}

/**
 * Fetches the Chat Messages
 * @param {Number} time
 * @returns {Function}
 */
export function fetchMessages(time = 0) {
    return async (dispatch) => {
        const data = await API.Chat.getMessages({ time }, time === 0);
        dispatch({ type : CHAT_MESSAGES, data });
    };
}

/**
 * Sends a Chat Message
 * @param {Object} params
 * @returns {Function}
 */
export function sendMessage(params) {
    return async (dispatch) => {
        const data = await API.Chat.sendMessage(params);
        dispatch({ type : CHAT_NEW_MESSAGES, data });
        dispatch({ type : STORE_LAST_UPDATE });
        return data.success;
    };
}

/**
 * Marks the Chat as Read
 * @param {Number} time
 * @returns {Function}
 */
export function markAsRead() {
    return async (dispatch) => {
        const data = await API.Chat.markAsRead();
        dispatch({ type : CHAT_ELEM, data });
    };
}
