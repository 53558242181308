import Utils                from "Utils/Common/Utils";

// Types
import {
    CONTENT_LOADING, CONTENT_HOME, CONTENT_PAGE, CONTENT_ENTRY, CONTENT_ARTIST,
    STORE_FAVORITE, STORE_HISTORY,
} from "Utils/Core/Types";



// The initial State
const initialState = {
    loading : false,
    error   : false,
    home    : {
        slides   : [],
        sections : [],
    },
    page    : {
        name     : "GENERAL_LOADING",
        sections : [],
    },
    entry   : {
        name     : "GENERAL_LOADING",
        sections : [],
    },
    artist  : {
        products : [],
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, CONTENT_HOME, CONTENT_PAGE, CONTENT_ENTRY, CONTENT_ARTIST)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case CONTENT_LOADING:
        return {
            ...state,
            loading  : true,
            error    : false,
        };

    case CONTENT_HOME:
        return {
            ...state,
            loading  : false,
            error    : false,
            home     : action.data,
        };
    case CONTENT_PAGE:
        return {
            ...state,
            loading  : false,
            error    : false,
            page     : action.data,
        };
    case CONTENT_ENTRY:
        return {
            ...state,
            loading  : false,
            error    : false,
            entry    : action.data,
        };
    case CONTENT_ARTIST:
        return {
            ...state,
            loading  : false,
            error    : false,
            artist   : action.data,
        };

    // Update the Favorite
    case STORE_FAVORITE: {
        const productID = action.data.productID;
        const sections  = Utils.clone(state.sections);
        if (state.sections.length) {
            for (const [ index, section ] of sections.entries()) {
                if (section.type === "favorites") {
                    sections[index].products = action.data.list;
                } else if (section.isProduct) {
                    sections[index] = Utils.setFavorite(section, productID);
                }
            }
        }
        return { ...state, sections };
    }

    // Update the History
    case STORE_HISTORY:
        return {
            ...state,
            history : action.data.list,
        };

    default:
        return state;
    }
};
