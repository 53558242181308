import React                from "react";
import PropTypes            from "prop-types";

// Components
import Html                 from "Components/Utils/Common/Html";

// Styles
import "Styles/Components/Utils/Media/Embed.css";



/**
 * The Embed
 */
class Embed extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, title, source } = this.props;

        if (!source) {
            return <React.Fragment />;
        }
        return <div className={className}>
            {source.startsWith("<iframe") ? <Html
                className="embed"
                content={source}
            /> : <div className="embed">
                <iframe
                    title={title}
                    src={source}
                    width="500"
                    height="400"
                    frameBorder="0"
                    allowFullScreen=""
                />
            </div>}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className : PropTypes.string,
        title     : PropTypes.string.isRequired,
        source    : PropTypes.string.isRequired,
    }
}

export default Embed;
