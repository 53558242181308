import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/Core/NLS";
import ClassList            from "Utils/Common/ClassList";

// Components
import ProductCard          from "Components/Product/Card/ProductCard";
import Loading              from "Components/Utils/Common/Loading";
import Pagination           from "Components/Utils/Common/Pagination";

// Styles
import "Styles/Components/Product/List/ProductsList.css";



/**
 * The Products List
 */
class ProductsList extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            settings, preferences, production, loading,
            products, pages, onFavorite,
        } = this.props;

        // Show the Loader
        if (loading) {
            return <Loading />;
        }

        // There is no content
        if (!products.length) {
            return <div className="nothing">
                <h3>{NLS.get("PRODUCTS_NONE_AVAILABLE")}</h3>
            </div>;
        }

        const showAside = settings.products_showAside;
        const columns   = preferences.columns === 3 && !showAside ? 4 : preferences.columns;
        const classes   = new ClassList("products-list");
        classes.addIf("products-list-aside", showAside);

        // Show the Content
        return <section className={classes.get()}>
            <div className={`products-grid products-grid-${columns}`}>
                {products.map((elem, index) => <ProductCard
                    key={index}
                    data={elem}
                    url={production.prodUrl}
                    isWide={columns === 1}
                    onFavorite={onFavorite}
                />)}
            </div>
            <Pagination
                currentPage={production.page}
                totalPages={pages}
                getUrl={production.getPaginationUrl}
            />
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings    : PropTypes.object.isRequired,
        preferences : PropTypes.object.isRequired,
        loading     : PropTypes.bool.isRequired,
        products    : PropTypes.array.isRequired,
        pages       : PropTypes.number,
        onFavorite  : PropTypes.func,
        onClose     : PropTypes.func,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings    : state.core.settings,
            preferences : state.store.preferences,
        };
    }
}

export default connect(ProductsList.mapStateToProps)(ProductsList);
