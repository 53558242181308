import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";
import Responsive           from "Utils/App/Responsive";

// Styles
import "Styles/Components/Utils/Media/Video.css";



/**
 * The Video
 */
class Video extends React.Component {
    /**
     * Returns the Youtube Embed Url
     * @param {String} source
     * @returns {String}
     */
    getVideoEmbed(source) {
        let videoID = "";
        if (!source) {
            return "";
        }

        // Try with Youtube
        if (source.startsWith("https://youtu.be/")) {
            videoID = source.replace("https://youtu.be/", "");
        } else if (source.startsWith("https://www.youtube.com/watch?v=")) {
            videoID = source.replace("https://www.youtube.com/watch?v=", "");
        }
        if (videoID) {
            if (videoID.includes("&")) {
                videoID = videoID.split("&")[0];
            }
            return `https://www.youtube-nocookie.com/embed/${videoID}?version=3&modestbranding=1&rel=0&showinfo=0`;
        }

        // Try with Vimeo
        if (source.startsWith("https://vimeo.com/")) {
            videoID = source.replace("https://vimeo.com/", "");
        } else if (source.startsWith("https://www.vimeo.com/")) {
            videoID = source.replace("https://www.vimeo.com/", "");
        }
        if (videoID) {
            return `https://player.vimeo.com/video/${videoID}?title=0&byline=0&portrait=0`;
        }
    }



    /**
     * Goes to the Url
     * @param {String} href
     * @returns {Function}
     */
    handleClick = () => {
        const { href } = this.props;
        if (href) {
            Href.gotoUrl(href);
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            responsive, isEmbed, className, href, mobileSource, source,
            title, width, height, maxWidth, controls, autoPlay,
        } = this.props;

        if (!source) {
            return <React.Fragment />;
        }
        const style = { "--video-ratio" : `${(height / width) * 100}%` };
        if (maxWidth) {
            style.maxWidth = `${width}px`;
        }

        if (isEmbed) {
            const src = this.getVideoEmbed(source);
            if (!src) {
                return <React.Fragment />;
            }
            return <div className={className + (href ? " video-click" : "")} onClick={this.handleClick}>
                <div className="video" style={style}>
                    <iframe
                        width={width}
                        height={height}
                        title={title}
                        src={src}
                        frameBorder="0"
                        allow="encrypted-media;"
                        allowFullScreen
                    />
                </div>
            </div>;
        }

        const video = Responsive.getUrl(responsive, source, mobileSource);
        return <div className={className + (href ? " video-click" : "")} onClick={this.handleClick}>
            <div className="video" style={style}>
                <video
                    width={width}
                    height={height}
                    src={video}
                    preload="auto"
                    autoPlay={autoPlay}
                    muted={autoPlay}
                    loop={autoPlay}
                    controls={controls}
                >
                    <source type="video/mp4" src={video} />
                </video>
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        responsive   : PropTypes.object.isRequired,
        className    : PropTypes.string,
        isEmbed      : PropTypes.bool,
        title        : PropTypes.string.isRequired,
        source       : PropTypes.string.isRequired,
        mobileSource : PropTypes.string,
        href         : PropTypes.string,
        width        : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        height       : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        maxWidth     : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        autoPlay     : PropTypes.bool,
        controls     : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @type {Object} defaultProps
     */
    static defaultProps = {
        className : "",
        isEmbed   : false,
        width     : "1900",
        height    : "800",
        maxWidth  : "",
        autoPlay  : true,
        controls  : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
        };
    }
}

export default connect(Video.mapStateToProps)(Video);
