// Constants
const QUERY        = 1;
const CONTACT      = 2;
const IMAGES       = 3;
const HOME         = 4;
const SUBSCRIPTION = 5;



/**
 * Returns the Contact Title and Icon
 * @param {Number} origin
 * @returns {Object}
 */
function getData(origin) {
    switch (origin) {
    case IMAGES:
        return {
            icon    : "image",
            title   : "PRODUCTS_REQUEST_IMAGES",
            message : "PRODUCTS_REQUEST_IMAGES_HELP",
        };
    case HOME:
        return {
            icon    : "home",
            title   : "PRODUCTS_SEE_IN_YOUR_HOME",
            message : "PRODUCTS_SEE_IN_YOUR_HOME_HELP",
        };
    case SUBSCRIPTION:
        return {
            icon    : "payment",
            title   : "ORDERS_CANCEL_SUBSCRIPTION",
            message : "ORDERS_CANCEL_SUBSCRIPTION_HELP",
        };
    default:
        return {
            icon    : "email",
            title   : "CONTACT_NAME",
            message : "",
        };
    }
}

/**
 * Returns true if the Subject is displayed
 * @param {Number} origin
 * @param {Object} settings
 * @returns {Boolean}
 */
function reqSubject(origin, settings) {
    return origin === CONTACT && settings.contact_requestSubject;
}

/**
 * Returns true if the Image is displayed
 * @param {Number} origin
 * @returns {Boolean}
 */
function reqImage(origin) {
    return origin === HOME;
}

/**
 * Returns true if the Message is required
 * @param {Number} origin
 * @returns {Boolean}
 */
function reqMessage(origin) {
    return origin === CONTACT;
}




// The Public API
export default {
    QUERY,
    CONTACT,
    IMAGES,
    HOME,
    SUBSCRIPTION,

    getData,
    reqSubject,
    reqImage,
    reqMessage,
};
