import React                from "react";

// Components
import HeaderWrapper        from "Components/Header/HeaderWrapper";
import HeaderLogo           from "Components/Header/HeaderLogo";
import MenuBar              from "Components/Header/Bar/MenuBar";
import UserBar              from "Components/Header/Bar/UserBar";
import SubMenuBar           from "Components/Header/Bar/SubMenuBar";



/**
 * The One Line Header
 * One Line with Logo on the Left
 */
class OneLineHeader extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <HeaderWrapper design="one-line" withSearch>
            <HeaderLogo />
            <div className="header-header">
                <div className="header-menu">
                    <MenuBar />
                    <UserBar showSearch />
                </div>
                <SubMenuBar />
            </div>
        </HeaderWrapper>;
    }
}

export default OneLineHeader;
