import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";

// Styles
import "Styles/Components/Product/List/ProductsContent.css";



/**
 * The Products Content
 */
class ProductsContent extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, production, getUrl, loading, children } = this.props;
        const childrenWithProps = React.Children.map(children, (child) => (
            React.cloneElement(child, { production, getUrl, loading })
        ));

        const showAside = settings.products_showAside;
        return <Wrapper className="products-wrapper" style={{ "--product-amount" : showAside ? 3 : 4 }}>
            {childrenWithProps}
        </Wrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        production : PropTypes.object,
        getUrl     : PropTypes.func,
        loading    : PropTypes.bool,
        children   : PropTypes.any,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(ProductsContent.mapStateToProps)(ProductsContent);
