import Utils                from "Utils/Common/Utils";

// Types
import {
    CART_LOADING, CART_ELEM, CART_ASSIGN,
    CART_REDIRECT, CART_SHIPMENT, CART_PRICES, CART_PAYMENT,
    ADDRESS_EDITED, ADDRESS_DELETED, AUTH_LOGOUT,
} from "Utils/Core/Types";



// The initial State
const initialState = {
    loading  : true,
    error    : false,
    redirect : "",
    prices   : [],
    elem     : {
        orderHash      : window.localStorage.getItem("orderHash") || "",
        clientID       : 0,
        isProducts     : true,
        isShipment     : false,
        isPayment      : false,
        amount         : 0,
        items          : [],
        offers         : [],
        totals         : [],
        minimumTotal   : "",
        bellowMinimum  : false,
        shipmentFormat : null,
        shipmentMode   : "",
        shipmentID     : 0,
        paymentType    : 0,
        modes          : [],
        shipments      : [],
        payments       : [],
        addresses      : [],
        subsidiaries   : [],
        addressID      : 0,
        transportID    : 0,
        subsidiaryID   : 0,
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, CART_ELEM)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case CART_LOADING:
        return {
            ...state,
            loading : true,
            error   : false,
        };

    case CART_ELEM: {
        if (action.data.amount === 0) {
            return {
                ...state,
                loading : false,
                error   : false,
                elem    : initialState.elem,
            };
        }
        if (!action.data.clientID) {
            window.localStorage.setItem("orderHash", action.data.orderHash);
        }
        return {
            ...state,
            loading : false,
            error   : false,
            elem    : action.data,
        };
    }

    case CART_ASSIGN: {
        window.localStorage.removeItem("orderHash");
        return {
            ...state,
            elem : { ...state.elem, clientID : action.data.clientID },
        };
    }

    case CART_REDIRECT:
        return {
            ...state,
            redirect : action.data,
        };
    case CART_PRICES:
        return {
            ...state,
            prices : action.data.prices,
        };

    case CART_SHIPMENT:
        return {
            ...state,
            elem : {
                ...state.elem,
                shipmentFormat : null,
                shipmentMode   : action.data.shipmentMode,
                shipmentID     : action.data.shipmentID,
                subsidiaryID   : action.data.subsidiaryID,
                addressID      : action.data.addressID,
            },
        };
    case CART_PAYMENT:
        return {
            ...state,
            elem : {
                ...state.elem,
                paymentType : action.paymentType,
            },
        };

    case ADDRESS_EDITED:
    case ADDRESS_DELETED:
        return {
            ...state,
            error : false,
            elem  : {
                ...state.elem,
                addresses : action.data.list,
            },
        };

    case AUTH_LOGOUT:
        return {
            ...state,
            elem : initialState.elem,
        };

    default:
        return state;
    }
};
