import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import SubMenu              from "Utils/App/SubMenu";

// Components
import CategoryMenu         from "Components/Header/Menu/CategoryMenu";
import SubcategoryMenu      from "Components/Header/Menu/SubcategoryMenu";
import OfferMenu            from "Components/Header/Menu/OfferMenu";
import BrandMenu            from "Components/Header/Menu/BrandMenu";
import ArtistMenu           from "Components/Header/Menu/ArtistMenu";
import ClientMenu           from "Components/Header/Menu/ClientMenu";
import FavoriteMenu         from "Components/Header/Menu/FavoriteMenu";
import NotificationMenu     from "Components/Header/Menu/NotificationMenu";

// Styles
import "Styles/Components/Core/SubMenu.css";



/**
 * The SubMenu Bar
 */
class SubMenuBar extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { submenu, submenuData, leftAlign, useLeft } = this.props;
        const { id, top, left, href                      } = submenuData;

        const style = { top : `${top}px` };
        if (useLeft) {
            style.left = `${left}px`;
        }

        return <section className={"submenu-container" + (leftAlign ? " submenu-container-left" : "")}>
            <CategoryMenu
                open={submenu === "category"}
                top={top}
                onEnter={SubMenu.enter}
                onLeave={SubMenu.hide}
                onClose={SubMenu.close}
            />
            <SubcategoryMenu
                open={submenu === "subcategory"}
                top={top}
                categoryID={id}
                onEnter={SubMenu.enter}
                onLeave={SubMenu.hide}
                onClose={SubMenu.close}
            />
            <OfferMenu
                open={submenu === "offer"}
                top={top}
                href={href}
                onEnter={SubMenu.enter}
                onLeave={SubMenu.hide}
                onClose={SubMenu.close}
            />
            <BrandMenu
                open={submenu === "brand"}
                top={top}
                href={href}
                onEnter={SubMenu.enter}
                onLeave={SubMenu.hide}
                onClose={SubMenu.close}
            />
            <ArtistMenu
                open={submenu === "artist"}
                top={top}
                href={href}
                onEnter={SubMenu.enter}
                onLeave={SubMenu.hide}
                onClose={SubMenu.close}
            />

            <ClientMenu
                open={submenu === "client"}
                style={style}
                onEnter={SubMenu.enter}
                onLeave={SubMenu.hide}
                onClose={SubMenu.close}
            />
            <FavoriteMenu
                open={submenu === "favorite"}
                style={style}
                onEnter={SubMenu.enter}
                onLeave={SubMenu.hide}
                onClose={SubMenu.close}
            />
            <NotificationMenu
                open={submenu === "notification"}
                style={style}
                onEnter={SubMenu.enter}
                onLeave={SubMenu.hide}
                onClose={SubMenu.close}
            />
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        submenu     : PropTypes.string.isRequired,
        submenuData : PropTypes.object.isRequired,
        leftAlign   : PropTypes.bool,
        useLeft     : PropTypes.bool,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            submenu     : state.store.submenu,
            submenuData : state.store.submenuData,
        };
    }
}

export default connect(SubMenuBar.mapStateToProps)(SubMenuBar);
