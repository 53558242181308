import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";
import Responsive           from "Utils/App/Responsive";
import Pages                from "Utils/App/Pages";

// Components
import TerTitle             from "Components/Utils/Title/TerTitle";
import Slider               from "Components/Utils/Common/Slider";
import ImageLink            from "Components/Utils/Media/ImageLink";

// Styles
import "Styles/Components/Content/Artist/ArtistList.css";



/**
 * The Artist List
 */
class ArtistList extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            responsive, pages,
            isHidden, className, message, list, asSlider, showButton,
        } = this.props;

        if (isHidden || !list.length) {
            return <React.Fragment />;
        }
        const count = Responsive.calcImageCols(responsive);
        const url   = Pages.getUrl(pages, Pages.ARTISTS);

        return <section className={className + " artist-list"}>
            <TerTitle
                message={message}
                button="GENERAL_VIEW_ALL"
                url={url}
                showButton={showButton}
            />
            {asSlider && <Slider total={list.length} count={count} outsideNav>
                {list.map((elem) => <ImageLink
                    key={elem.id}
                    location="bottom"
                    href={Href.url("ARTIST", elem.url)}
                    source={elem.image}
                    name={elem.name}
                />)}
            </Slider>}
            {!asSlider && <ul className="artist-grid no-list">
                {list.map((elem) => <li key={elem.id}>
                    <ImageLink
                        location="bottom"
                        href={Href.url("ARTIST", elem.url)}
                        source={elem.image}
                        name={elem.name}
                    />
                </li>)}
            </ul>}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        responsive : PropTypes.object.isRequired,
        pages      : PropTypes.object.isRequired,
        isHidden   : PropTypes.bool,
        className  : PropTypes.string,
        message    : PropTypes.string,
        list       : PropTypes.array.isRequired,
        asSlider   : PropTypes.bool,
        showButton : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        isHidden   : false,
        className  : "",
        asSlider   : false,
        showButton : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
            pages      : state.core.pages,
        };
    }
}

export default connect(ArtistList.mapStateToProps)(ArtistList);
