import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import HeaderWrapper        from "Components/Header/HeaderWrapper";
import HeaderLogo           from "Components/Header/HeaderLogo";
import MenuIcon             from "Components/Header/Icon/MenuIcon";
import SearchIcon           from "Components/Header/Icon/SearchIcon";
import CartIcon             from "Components/Header/Icon/CartIcon";



/**
 * The Mobile Header
 */
class MobileHeader extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings } = this.props;

        const design    = settings.header_centerLogo ? "mobile-center" : "mobile";
        const leftMenu  = settings.header_menuLeft;
        const rightMenu = !settings.header_menuLeft;

        return <HeaderWrapper design={design} withSearch>
            <section className="header-primary">
                {leftMenu && <MenuIcon position="left" />}
            </section>
            <HeaderLogo />
            <section className="header-secondary">
                <SearchIcon />
                <CartIcon />
                {rightMenu && <MenuIcon position="right" />}
            </section>
        </HeaderWrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(MobileHeader.mapStateToProps)(MobileHeader);
