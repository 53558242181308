import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import MenuItems            from "Utils/App/MenuItems";
import ClassList            from "Utils/Common/ClassList";
import DateTime             from "Utils/Common/DateTime";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Core/Floater.css";



/**
 * The Floater
 */
class Floater extends React.Component {
    // The Current State
    state = {
        isCollapsed : true,
    }

    /**
     * Toggles the Collapsed
     * @returns {Void}
     */
    toggleCollapse = () => {
        this.setState({ isCollapsed : !this.state.isCollapsed });
    }

    /**
     * Returns true if there is a Schedule
     * @returns {Boolean}
     */
    hasSchedule() {
        const { settings } = this.props;
        return settings.contact_scheduleDays && settings.contact_scheduleFrom && settings.contact_scheduleTo;
    }

    /**
     * Returns true if the Schedule is displayed
     * @returns {Boolean}
     */
    showSchedule() {
        const { responsive, settings } = this.props;
        if (responsive.isMicro && !settings.floater_scheduleMobile) {
            return false;
        }
        if (!responsive.isMicro && !settings.floater_scheduleDesktop) {
            return false;
        }
        return this.hasSchedule();
    }

    /**
     * Disables the buttons when we are outside of the schedule
     * @returns {Boolean}
     */
    isDisabled() {
        const { settings } = this.props;
        if (!settings.floater_useSchedule || !this.hasSchedule()) {
            return false;
        }
        const nowDate = new DateTime();
        if (!settings.contact_scheduleDays.includes(String(nowDate.getWeekDay()))) {
            return true;
        }
        const fromDate = DateTime.fromHour(settings.contact_scheduleFrom);
        const toDate   = DateTime.fromHour(settings.contact_scheduleTo);
        if (fromDate.isGreaterThan(nowDate) || nowDate.isGreaterThan(toDate)) {
            return true;
        }
        return false;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, isAuthenticated, menus } = this.props;
        const { isCollapsed                      } = this.state;

        const floaterItems = MenuItems.getFloater(menus, settings, isAuthenticated);
        if (!floaterItems.length) {
            return <React.Fragment />;
        }

        const hasMessage    = Number(settings.floater_design) !== 1;
        const isDisabled    = this.isDisabled();
        const showSchedule  = this.showSchedule();
        const showItems     = !settings.floater_collapseIcons || !isCollapsed;
        const disabledTypes = [ "whatsapp", "messenger", "phone", "email", "contact", "chat" ];

        const classes       = new ClassList("floater-container", "no-list", `floater-design${settings.floater_design}`);
        classes.addIf("floater-left",     settings.floater_leftSide);
        classes.addIf("floater-big",      settings.floater_bigIcons);
        classes.addIf("floater-visible",  showItems);
        classes.addIf("floater-disabled", isDisabled);

        return <ul className={classes.get()}>
            {floaterItems.map(({ key, type, href, target, message, icon, options }) => <li key={key}>
                <HyperLink
                    className={`floater-${type}`}
                    variant="none"
                    href={href}
                    target={target}
                    message={hasMessage ? message : ""}
                    icon={icon}
                    isDisabled={disabledTypes.includes(type) && isDisabled}
                    {...options}
                />
            </li>)}
            {settings.floater_collapseIcons && <li>
                <HyperLink
                    className="floater-collapse"
                    variant="none"
                    href="#"
                    onClick={this.toggleCollapse}
                    message={hasMessage ? settings.floater_collapseText : ""}
                    icon="message"
                />
            </li>}
            {showSchedule && <li className="floater-schedule">
                {DateTime.getSchedule(settings.contact_scheduleDays, settings.contact_scheduleFrom, settings.contact_scheduleTo)}
            </li>}
        </ul>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        responsive      : PropTypes.object.isRequired,
        settings        : PropTypes.object.isRequired,
        menus           : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive      : state.core.responsive,
            settings        : state.core.settings,
            menus           : state.store.menus,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(Floater.mapStateToProps, null)(Floater);
