import { getNotiRedirect }  from "Actions/Content/NotificationActions";
import Auth                 from "Utils/Core/Auth";
import API                  from "Utils/Core/API";

// Module Variables
let oneSignal;
let store;



/**
 * Initialize the Push Service
 * @param {Store} theStore
 * @returns {Void}
 */
function init(theStore) {
    oneSignal = window.plugins ? window.plugins.OneSignal : null;
    store     = theStore;

    if (!oneSignal) {
        return;
    }

    oneSignal
        .startInit("f54073ed-038d-479a-9f28-32b8f3ebe1f4")
        .handleNotificationOpened((data) => {
            const url = data.notification.payload.additionalData.url;
            if (url) {
                store.dispatch(getNotiRedirect(url));
            }
        })
        .endInit();

    Auth.onSet(setDevice);
    Auth.onUnset(unsetDevice);
}

/**
 * Sets the Device
 * @param {Object} credential
 * @returns {Void}
 */
async function setDevice(credential) {
    const playerID = await getPlayerID();

    if (credential.clientID && playerID) {
        if (!localStorage.playerID) {
            API.Device.add({ clientID : credential.clientID, playerID });
        } else if (localStorage.playerID !== playerID) {
            API.Device.edit({
                clientID    : credential.clientID,
                oldPlayerID : localStorage.playerID,
                newPlayerID : playerID,
            });
        }
        localStorage.setItem("playerID", playerID);
    }
}

/**
 * Unsets the Device
 * @param {Object} credential
 * @returns {Void}
 */
async function unsetDevice(credential) {
    if (credential.clientID && localStorage.playerID) {
        await API.Device.remove({
            clientID : credential.clientID,
            playerID : localStorage.playerID,
        });
        localStorage.removeItem("playerID");
    }
}



/**
 * Returns the Player ID
 * @returns {Promise}
 */
function getPlayerID() {
    return new Promise((resolve) => {
        oneSignal.getIds((data) => {
            resolve(data.userId);
        });
    });
}




// The Public API
export default {
    init,
    getPlayerID,
};
