import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";
import Responsive           from "Utils/App/Responsive";
import DateTime             from "Utils/Common/DateTime";

// Components
import TerTitle             from "Components/Utils/Title/TerTitle";
import Pagination           from "Components/Utils/Common/Pagination";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Html                 from "Components/Utils/Common/Html";
import ImageLink            from "Components/Utils/Media/ImageLink";

// Styles
import "Styles/Components/Content/Entry/EntryList.css";



/**
 * The Entry List
 */
class EntryList extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            responsive, message, list, total,
            showAll, firstBigger, perPage, url, page,
        } = this.props;

        if (!list.length) {
            return <React.Fragment />;
        }
        const totalItems = total + (firstBigger ? 1 : 0);
        const showBigger = firstBigger && page <= 1;
        const count      = Responsive.calcEntryCols(responsive);
        const style      = { "--entry-cols" : count };

        return <section
            className={"entry-list " + (showBigger ? " entry-first-bigger" : "")}
            style={style}
        >
            <TerTitle message={message} />
            {list.map((elem, index) => <div key={elem.entryID}>
                <section className="entry-list-info">
                    {!!elem.category && <h4 className="entry-list-category">
                        {elem.category}
                    </h4>}
                    <h2 className="entry-list-title">
                        <HyperLink
                            variant="opacity"
                            message={elem.name}
                            href={Href.url("ENTRY", elem.entryID)}
                        />
                    </h2>
                    {(index === 0 && showBigger) && <Html variant="p" className="entry-list-summary">
                        {elem.descriptionHtml}
                    </Html>}
                    <p className="entry-list-author">
                        {NLS.format("ENTRIES_AUTHOR_DATE", elem.credentialName, DateTime.formatString(elem.createdTime))}
                    </p>
                </section>
                <ImageLink
                    className="entry-list-image"
                    href={elem.link || Href.url("ENTRY", elem.entryID)}
                    source={elem.imageUrl}
                    name={elem.name}
                />
            </div>)}

            <Pagination
                isHidden={!showAll}
                currentPage={page}
                totalItems={totalItems}
                perPage={perPage}
                url={url}
            />
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        responsive  : PropTypes.object.isRequired,
        message     : PropTypes.string,
        list        : PropTypes.array.isRequired,
        total       : PropTypes.number.isRequired,
        firstBigger : PropTypes.bool.isRequired,
        showAll     : PropTypes.bool.isRequired,
        perPage     : PropTypes.number.isRequired,
        url         : PropTypes.string.isRequired,
        page        : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
        };
    }
}

export default connect(EntryList.mapStateToProps)(EntryList);
