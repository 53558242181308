import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/Core/NLS";

// Components
import TitleWrapper         from "Components/Utils/Title/TitleWrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Title
 */
class Title extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            settings, className, showTitle, message, submessage, description,
            href, url, background, height, noBotomSpace,
        } = this.props;

        const showContent = Boolean(showTitle && message);
        const hasLink     = Boolean(href || url);

        return <TitleWrapper
            className={className}
            background={background || settings.title_bgImage}
            height={height}
            noBotomSpace={noBotomSpace}
            overlay={showContent && settings.title_addOverlay}
        >
            {hasLink && <HyperLink
                variant="icon"
                icon="back"
                href={href}
                url={url}
            />}
            {showContent && <div className="title-content">
                <header className="title-header">
                    <h2>{NLS.get(message)}</h2>
                    {!!submessage  && <h3>{submessage}</h3>}
                </header>
                {!!description && <div className="title-description">
                    {description}
                </div>}
            </div>}
        </TitleWrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings     : PropTypes.object.isRequired,
        className    : PropTypes.string,
        showTitle    : PropTypes.bool,
        message      : PropTypes.string,
        submessage   : PropTypes.string,
        description  : PropTypes.string,
        href         : PropTypes.string,
        url          : PropTypes.string,
        background   : PropTypes.string,
        height       : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        noBotomSpace : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className    : "",
        showTitle    : true,
        noBotomSpace : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(Title.mapStateToProps)(Title);
