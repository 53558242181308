import API                  from "Utils/Core/API";

// Types
import {
    ADDRESS_LOADING, ADDRESS_UNSET,
    ADDRESS_LIST, ADDRESS_ELEM,
    ADDRESS_EDITED, ADDRESS_DELETED, ADDRESS_ACK,
} from "Utils/Core/Types";



/**
 * Fetches the Address List
 * @param {Object} params
 * @returns {Function}
 */
export function fetchAddresses(params) {
    return async (dispatch) => {
        dispatch({ type : ADDRESS_LOADING });
        const data = await API.Address.getAddresses(params);
        dispatch({ type : ADDRESS_LIST, data });
    };
}

/**
 * Fetches the Address Data
 * @param {Object} addressID
 * @returns {Function}
 */
export function fetchAddress(addressID) {
    return async (dispatch) => {
        dispatch({ type : ADDRESS_UNSET });
        const data = await API.Address.getAddress({ addressID });
        dispatch({ type : ADDRESS_ELEM, data });
    };
}



/**
 * Creates/Edits an Address
 * @param {Object} params
 * @returns {Function}
 */
export function editAddress(params) {
    return async (dispatch) => {
        const data = await API.Address.editAddress(params);
        dispatch({ type : ADDRESS_EDITED, data });
        return { success : data.success, addressID : data.addressID };
    };
}

/**
 * Deletes an Address
 * @param {Number} addressID
 * @returns {Function}
 */
export function deleteAddress(addressID) {
    return async (dispatch) => {
        const data = await API.Address.deleteAddress({ addressID });
        dispatch({ type : ADDRESS_DELETED, data });
        return data.success;
    };
}

/**
 * Acknowledge a Address Edit
 * @returns {Function}
 */
export function ackAddress() {
    return async (dispatch) => {
        dispatch({ type : ADDRESS_ACK });
    };
}
