import Utils                from "Utils/Common/Utils";

// Types
import {
    CLIENT_ACCOUNT, CLIENT_PAGE,
} from "Utils/Core/Types";



// The initial State
const initialState = {
    error : false,
    elem  : {},
    page  : 0,
    type  : "",
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, CLIENT_ACCOUNT)) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case CLIENT_ACCOUNT:
        return {
            ...state,
            error : false,
            elem  : action.data,
        };

    case CLIENT_PAGE:
        return {
            ...state,
            error : false,
            page  : action.data.page,
            type  : action.data.type || "",
        };

    default:
        return state;
    }
};
