import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setPreview }       from "Actions/Store/ProductActions";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";
import ClassList            from "Utils/Common/ClassList";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import MediaSlider          from "Components/Utils/Media/MediaSlider";
import Image                from "Components/Utils/Media/Image";
import Video                from "Components/Utils/Media/Video";

// Styles
import "Styles/Components/Product/Item/ProductImage.css";



/**
 * The Product Image
 */
class ProductImage extends React.Component {
    // The Current State
    state = {
        index : 0,
    };

    /**
     * Opens the Preview
     * @param {Event} e
     * @returns {Void}
     */
    openPreview = (e) => {
        const { setPreview, data } = this.props;
        e.stopPropagation();
        e.preventDefault();
        setPreview({ ...data, index : this.state.index });
    }

    /**
     * Handles the Slider Switch
     * @param {Number} index
     * @return {Void}
     */
    handleSwitch = (index) => {
        this.setState({ index });
    }

    /**
     * Handle the Click
     * @param {Event} e
     * @return {Void}
     */
    handleClick = (e) => {
        const { onClick, clickPreview, clickView, data } = this.props;
        if (onClick) {
            onClick();
        } else if (clickPreview && data.hasImages) {
            this.openPreview(e);
        } else if (clickView) {
            Href.goto("PRODUCT", data.productUrl);
        }
    }



    /**
     * Renders the Image
     * @returns {Object}
     */
    renderImage() {
        const { variant, data, withZoom, showVideo, sliderIndex } = this.props;
        const { name, hasImages, image, images                  } = data;

        const hasManyImages = Boolean(hasImages && images.length > 1);

        // Show a Slider for many images
        if (hasManyImages) {
            return <MediaSlider
                variant={variant}
                data={images}
                index={sliderIndex}
                onSwitch={this.handleSwitch}
                withZoom={withZoom}
                showVideo={showVideo}
                onPreview={this.openPreview}
                centerImage
                smallNav
            />;
        }

        // Show the Single Image
        if (hasImages) {
            if (showVideo && image.video) {
                return <Video
                    source={image.video}
                    title={image.name}
                />;
            }
            return <Image
                source={image[variant]}
                zoomed={image.large}
                name={image.name}
                withVideo={!!data.video}
                withZoom={withZoom}
                onPreview={this.openPreview}
            />;
        }

        // Just show the Name
        return <h3>{name}</h3>;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            className, data, withPreview, clickPreview, clickView, zoomOnHover,
            showNew, showUnavailable, showRented,
        } = this.props;

        const showPreview = withPreview && data.hasImages;
        const classes     = new ClassList("product-image", className);
        classes.addIf("product-image-click", clickPreview || clickView);
        classes.addIf("product-image-hover", zoomOnHover);

        return <div className={classes.get()} onClick={this.handleClick}>
            {this.renderImage()}
            {showNew && <div className="product-image-new">
                {NLS.get("PRODUCTS_IS_NEW")}
            </div>}
            {showUnavailable && <div className="product-image-unavailable">
                {NLS.get("PRODUCTS_UNAVAILABLE")}
            </div>}
            {showRented && <div className="product-image-unavailable">
                {NLS.get("PRODUCTS_IS_RENTED")}
            </div>}
            {showPreview && <HyperLink
                className="product-image-zoom"
                variant="icon"
                icon="zoom"
                onClick={this.openPreview}
            />}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setPreview      : PropTypes.func.isRequired,
        data            : PropTypes.object.isRequired,
        variant         : PropTypes.string,
        className       : PropTypes.string,
        onClick         : PropTypes.func,
        withPreview     : PropTypes.bool,
        clickPreview    : PropTypes.bool,
        clickView       : PropTypes.bool,
        zoomOnHover     : PropTypes.bool,
        withZoom        : PropTypes.bool,
        showVideo       : PropTypes.bool,
        showNew         : PropTypes.bool,
        showUnavailable : PropTypes.bool,
        sliderIndex     : PropTypes.number,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className       : "",
        variant         : "small",
        withPreview     : false,
        clickPreview    : false,
        clickView       : false,
        withZoom        : false,
        showVideo       : false,
        showNew         : false,
        showUnavailable : false,
        sliderIndex     : 0,
    }
}

export default connect(null, { setPreview })(ProductImage);
