import React                from "react";
import PropTypes            from "prop-types";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";
import Analitics            from "Utils/Core/Analitics";

// Components
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";



/**
 * The Cart Completed
 */
class CartCompleted extends React.Component {
    /**
     * Save the Analitics
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { orderID, elem } = this.props;
        if (!prevProps.orderID && orderID !== prevProps.orderID) {
            Analitics.purchase(orderID, elem.items);
        }
    }

    /**
     * Renders the Content
     * @returns {Object}
     */
    render() {
        const { orderID, elem                                                  } = this.props;
        const { paymentPending, paymentHours, paymentMethod, paymentValidation } = elem;

        const message = paymentHours && paymentMethod ? "CART_COMPLETED_METHOD" : "CART_COMPLETED_HOURS";

        return <Card className="cart-completed" withBorder>
            <header className="spacing">
                <h3>{NLS.get("CART_COMPLETED_TITLE")}</h3>
                <h4>{NLS.get("CART_COMPLETED_CONTACT")}</h4>
                {paymentPending && <h5>
                    {NLS.get("CART_COMPLETED_PENDING")}<br />
                    {!!paymentHours && <>{NLS.format(message, paymentHours, paymentMethod)}<br /></>}
                    {paymentValidation && <>{NLS.format("CART_COMPLETED_VALIDATION")}< br /></>}
                    {NLS.get("CART_COMPLETED_NOTICE")}
                </h5>}
            </header>
            <footer className="spacing">
                <h4>{NLS.get("CART_COMPLETED_NEXT")}</h4>
                <Button
                    variant="primary"
                    icon="cart"
                    message="CART_COMPLETED_ORDER"
                    href={Href.url("ORDERS_VIEW", orderID)}
                />
                <HyperLink
                    variant="black"
                    url="PRODUCTS"
                    message="CART_COMPLETED_MORE"
                />
            </footer>
        </Card>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        orderID : PropTypes.number.isRequired,
        elem    : PropTypes.object,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        elem : {},
    }
}

export default CartCompleted;
