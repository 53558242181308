import React                from "react";
import PropTypes            from "prop-types";



/**
 * The Auth Aside
 */
class AuthAside extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data } = this.props;

        return <aside className="auth-aside">
            {data.isVideo && <div className="auth-video">
                <video width="1900" height="800" src={data.url} preload="auto" muted loop autoPlay>
                    <source type="video/mp4" src={data.url} />
                </video>
            </div>}
        </aside>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data : PropTypes.object,
    }
}

export default AuthAside;
