import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import ProductPrice         from "Components/Product/Item/ProductPrice";
import Timer                from "Components/Utils/Common/Timer";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Product/Item/ProductBanner.css";



/**
 * The Product Banner
 */
class ProductBanner extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, data, className } = this.props;
        const {
            id, isPrimary, totalDays, totalHours, startTime, endTime,
            discountName, product,
        } = data;

        const type = isPrimary ? "banner-primary" : "banner-secondary";
        const time = totalDays > 3 ? NLS.pluralize("BANNER_DAYS", totalDays) : NLS.pluralize("BANNER_HOURS", totalHours);

        return <div className={`banner-card ${type} ${className}`}>
            <header className="banner-header">
                <div className="banner-title">
                    <h2>{NLS.get("BANNER_ONLY")}</h2><br />
                    <h3>{time}</h3>
                </div>
                <Timer
                    className="banner-timer"
                    startTime={startTime}
                    endTime={endTime}
                />
            </header>
            <section className="banner-content">
                <div className="banner-slider">
                    <ProductImage
                        className="banner-image"
                        variant="small"
                        data={product}
                        onClick={() => Href.gotoProduct(product, settings)}
                        withPreview
                    />
                </div>
                <div className="banner-product">
                    <h2>{product.name}</h2>
                    {settings.products_showPrice && <div className="banner-price">
                        <div className="banner-price-title">{discountName}</div>
                        <ProductPrice
                            className="banner-price-value"
                            data={product}
                            withPercent
                        />
                    </div>}
                    <div className="banner-show">
                        <Button
                            className="banner-btn"
                            variant="primary"
                            message="BANNER_SHOW"
                            href={Href.url("OFFER", id)}
                        />
                    </div>
                </div>
            </section>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings  : PropTypes.object.isRequired,
        data      : PropTypes.object.isRequired,
        className : PropTypes.string,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(ProductBanner.mapStateToProps)(ProductBanner);
