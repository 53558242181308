import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleNav }        from "Actions/Store/StoreActions";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Cart Icon
 */
class CartIcon extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { toggleNav, settings, cart } = this.props;

        if (!settings.orders_isActive) {
            return <React.Fragment />;
        }
        return <div className="header-cart">
            <HyperLink
                className="header-icon"
                variant="icon"
                url="CART"
                onClick={() => toggleNav(false)}
                icon="cart"
                badge={cart}
            />
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleNav : PropTypes.func.isRequired,
        settings  : PropTypes.object.isRequired,
        cart      : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            cart     : state.store.cart,
        };
    }
}

export default connect(CartIcon.mapStateToProps, {
    toggleNav,
})(CartIcon);
