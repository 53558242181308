export default {

    // General
    GENERAL_LOADING                 : "Loading ...",
    GENERAL_ALL                     : "All",
    GENERAL_ALL_FEM                 : "All",
    GENERAL_HOME                    : "Home",
    GENERAL_PREV                    : "Back",
    GENERAL_NEXT                    : "Next",
    GENERAL_BACK                    : "Volver",
    GENERAL_NO                      : "No",
    GENERAL_YES                     : "Yes",
    GENERAL_CREATE                  : "Create",
    GENERAL_DAYS                    : "Days",
    GENERAL_DOWNLOAD                : "Download",
    GENERAL_DOWNLOAD_FILE           : "Download file",
    GENERAL_EDIT                    : "Edit",
    GENERAL_EMAIL                   : "Email",
    GENERAL_EMAIL_CONFIRM           : "Confirm email",
    GENERAL_NAME                    : "Name",
    GENERAL_SAVE                    : "Save",
    GENERAL_DELETE                  : "Delete",
    GENERAL_CANCEL                  : "Cancel",
    GENERAL_HOURS                   : "Hours",
    GENERAL_MINUTES                 : "Minutes",
    GENERAL_MINUTES_SHORT           : "Min",
    GENERAL_PASSWORD                : "Password",
    GENERAL_PASSWORD_CONFIRM        : "Confirm password",
    GENERAL_SECONDS                 : "Seconds",
    GENERAL_SECONDS_SHORT           : "Seg",
    GENERAL_SELECT_FILE             : "Select file",
    GENERAL_STATUS                  : "Status",
    GENERAL_VIEW                    : "View",
    GENERAL_VIEW_ARTICLE            : "View article",
    GENERAL_VIEW_ALL                : "View all",
    GENERAL_VIEW_MORE               : "View more",
    GENERAL_IMAGE                   : "Image",
    GENERAL_MESSAGE                 : "Message",
    GENERAL_ERROR                   : "An error has been occurred. We are working to fix it.",
    GENERAL_ERROR_IMAGE_SIZE        : "The file is too large.",
    GENERAL_ERROR_IMAGE_TYPE        : "The file must be an image.",


    // Auth
    AUTH_LOGIN_NAME                 : "Login",
    AUTH_LOGIN_CODE                 : "Client Code / Email",
    AUTH_LOGIN_EMAIL                : "Email",
    AUTH_LOGIN_ACTION               : "Login",
    AUTH_LOGIN_LINK                 : "Login with my account",
    AUTH_LOGIN_RESETED              : "You can now login with your new password",
    AUTH_LOGIN_CREATED              : "Thanks for registering! You can now login.",
    AUTH_LOGIN_VALIDATE             : "Thanks for registering! We are going to validate your account and then contact you by email.",
    AUTH_LOGIN_ENTER                : "Login to the store",
    AUTH_RECOVER_NAME               : "Recover password",
    AUTH_RECOVER_CODE               : "Please type your client code or email and we will send an email with link so you can create a new password.",
    AUTH_RECOVER_EMAIL              : "Please type your email and we will send an email with link so you can create a new password.",
    AUTH_RECOVER_ACTION             : "Reset",
    AUTH_RECOVER_LINK               : "Recover my password",
    AUTH_RESET_NAME                 : "Reset password",
    AUTH_RESET_TEXT                 : "We send you and email with the reset code, please check your email and fill it here with the new password.",
    AUTH_RESET_CODE                 : "Code",
    AUTH_RESET_ACTION               : "Reset",
    AUTH_REGISTER_NAME              : "Create User",
    AUTH_REGISTER_ACTION            : "Create",
    AUTH_REGISTER_TITLE             : "First time login?",
    AUTH_REGISTER_LINK              : "Create My User",
    AUTH_TERMS                      : "Terms and conditions",
    AUTH_TERMS_TEXT1A               : "Using {0} implies accepting the ",
    AUTH_TERMS_TEXT1B               : "Please accept the ",
    AUTH_TERMS_TEXT2                : "terms and conditions",
    AUTH_TERMS_TEXT3                : " in which the service is offered.",
    AUTH_CONSTRUCTION               : "Type your password to login",
    AUTH_ERROR_LOGIN                : "You don’t have permission to login.",
    AUTH_ERROR_REGISTER             : "You don’t have permission to register.",
    AUTH_ERROR_TERMS                : "Please confirm the terms and conditions.",
    AUTH_ERROR_CODE                 : "Please fill your client code and email.",
    AUTH_ERROR_EMAIL                : "Please fill your email.",
    AUTH_ERROR_PASSWORD_EMPTY       : "Please fill your password.",
    AUTH_ERROR_PASSWORD_INCORRECT   : "The password is incorrect.",
    AUTH_ERROR_PASSWORD_INVALID     : "The password must have a minimum of 6 characters, a numbers and a capital letter.",
    AUTH_ERROR_PASSWORD_CONFIRM     : "Please confirm your password.",
    AUTH_ERROR_PASSWORD_MATCH       : "The passwords do not match.",
    AUTH_ERROR_CODE_EMPTY           : "Please fill the code.",
    AUTH_ERROR_CODE_INVALID         : "The code does not exist.",
    AUTH_ERROR_CODE_EXPIRED         : "The code has expired. Please request it again.",
    AUTH_ERROR_SPAM                 : "You have reach the limit. Please wait around 30 minutos and retry.",
    AUTH_ERROR_CREDENTIALS_CODE     : "The code or email or the password is incorrect.",
    AUTH_ERROR_CREDENTIALS_EMAIL    : "The password or the email is incorrect.",
    AUTH_ERROR_RECOVER              : "We can't find a client with the given code.",
    AUTH_SUCCESS_LOGIN              : "Welcome to the Store",
    AUTH_SUCCESS_RECOVER            : "We will send you and email with a code so you can reset your password.",



    // Header
    HEADER_PHONE_NUMBER             : "Contact by Phone: {0}",
    HEADER_WHATSAPP_NUMBER          : "Contact by Whatsapp: {0}",
    HEADER_SEARCH                   : "What are you looking for?",
    HEADER_WELCOME_CLIENT           : "Hi {0}!",
    HEADER_WELCOME_TITLE            : "Hi!",
    HEADER_WELCOME_LOGIN            : "Please login to use all the store functionalities",
    HEADER_NOTIFICATIONS_SINGULAR   : "{0} notification",
    HEADER_NOTIFICATIONS_PLURAL     : "{0} notifications",

    // Footer
    FOOTER_ABOUT_US                 : "About us",
    FOOTER_SITE_MAP                 : "Site Map",
    FOOTER_FOLLOW_US                : "Follow Us",
    FOOTER_COPYRIGHT                : "Copyright © {1} {0}. All Right Reserved. Term and Conditions, Privacy Notice, Cookies & Ad Choice",



    // Categories
    CATEGORIES_NAME                 : "Categories",
    CATEGORIES_SINGULAR             : "Category",
    CATEGORIES_TITLE                : "List of Categories",

    // Subcategories
    SUBCATEGORIES_NAME              : "Subcategories",
    SUBCATEGORIES_SINGULAR          : "Subcategory",
    SUBCATEGORIES_TITLE             : "List of Subcategory",

    // Tercategories
    TERCATEGORIES_NAME              : "Tercategories",
    TERCATEGORIES_SINGULAR          : "Tercategory",
    TERCATEGORIES_TITLE             : "List of Tercategories",

    // Offers
    OFFERS_NAME                     : "Offers",

    // Brands
    BRANDS_NAME                     : "Brands",
    BRANDS_SINGULAR                 : "Brand",
    BRANDS_TITLE                    : "Our Brands",
    BRANDS_RELATED_PRODUCTS         : "More from the Brand",
    BRANDS_NONE_AVAILABLE           : "There are no available brands",

    // Artists
    ARTISTS_NAME                    : "Artists",
    ARTISTS_SINGULAR                : "Artist",
    ARTISTS_TITLE                   : "Our Artists",
    ARTISTS_RELATED_PRODUCTS        : "More from the Artist",
    ARTISTS_BIRTH_YEAR              : "B: {0}",
    ARTISTS_SEE_ARTWORKS            : "See Artworks",
    ARTISTS_SEE_BIOGRAPHY           : "See Bio",
    ARTISTS_BIOGRAPHY               : "Biography",
    ARTISTS_FROM                    : "From",
    ARTISTS_BORN_IN                 : "Born in",
    ARTISTS_ART_STYLE               : "Art style",
    ARTISTS_ARTWORKS                : "Artworks",
    ARTISTS_ABOUT_ME                : "About Me",
    ARTISTS_EXHIBITIONS             : "News & Exhibitions",
    ARTISTS_NONE_AVAILABLE          : "There are no available artists",

    // Search
    SEARCH_RESULTS                  : "Results",
    SEARCH_TITLE                    : "Search \"{0}\"",

    // Best Sold
    BESTSOLD_NAME                   : "Best Sellers",
    BESTSOLD_TITLE                  : "Most Selled {0}",

    // Importants
    IMPORTANTS_NAME                 : "Featured",
    IMPORTANTS_TITLE                : "Featured {0}",

    // Newests
    NEWESTS_NAME                    : "New",
    NEWESTS_TITLE                   : "New {0}",

    // Latest
    LATESTS_NAME                    : "Latest",
    LATESTS_TITLE                   : "Latest Added {0}",

    // Trending
    TRENDING_NAME                   : "Most Visited",
    TRENDING_TITLE                  : "Most Visited {0}",

    // Favorites
    FAVORITES_NAME                  : "My Favorites",
    FAVORITES_TITLE                 : "My Favorite {0}",
    FAVORITES_ERROR_PRODUCT         : "The product does not exist",
    FAVORITES_SUCCESS_ADD           : "The product has been added correctly.",
    FAVORITES_SUCCESS_REMOVE        : "The product has been removed correctly.",

    // Purchases
    PURCHASES_NAME                  : "Buyed",
    PURCHASES_TITLE                 : "Buyed {0}",

    // History
    HISTORY_NAME                    : "My History",
    HISTORY_TITLE                   : "My Visited {0}",
    HISTORY_ERROR_PRODUCT           : "The product does not exist",
    HISTORY_SUCCESS_ADD             : "The product has been added correctly.",



    // Products
    PRODUCTS_NAME                   : "Products",
    PRODUCTS_SINGULAR               : "Product",
    PRODUCTS_TITLE                  : "List of Products",
    PRODUCTS_VIEW_TITLE             : "View Product",
    PRODUCTS_RELATED_TITLE          : "Related {0}",
    PRODUCTS_RESULTS_SINGULAR       : "1 product",
    PRODUCTS_RESULTS_PLURAL         : "{0} products",
    PRODUCTS_FILTER                 : "Filter",
    PRODUCTS_FILTER_TITLE           : "Filter By",
    PRODUCTS_SORT                   : "Order By",
    PRODUCTS_SORT_POSITION          : "Position",
    PRODUCTS_SORT_NAME_AZ           : "Name A-Z",
    PRODUCTS_SORT_NAME_ZA           : "Name Z-A",
    PRODUCTS_SORT_CODE_AZ           : "Code A-Z",
    PRODUCTS_SORT_CODE_ZA           : "Code Z-A",
    PRODUCTS_SORT_LOWER_PRICE       : "Less Price",
    PRODUCTS_SORT_GREATER_PRICE     : "Higher Price",
    PRODUCTS_SORT_NEWER_PRODUCT     : "New to Old",
    PRODUCTS_SORT_OLDER_PRODUCT     : "Old to New",
    PRODUCTS_SORT_BEST_SOLD         : "Best sellers",
    PRODUCTS_SORT_TRENDING          : "Most visited",
    PRODUCTS_PRICE                  : "Price",
    PRODUCTS_PRICE_ALL              : "All",
    PRODUCTS_PRICE_TO               : "Up to {0}",
    PRODUCTS_PRICE_RANGE            : "From {0} to {1}",
    PRODUCTS_PRICE_FROM             : "From {0}",
    PRODUCTS_IS_NEW                 : "New",
    PRODUCTS_DISCOUNT               : "{0}% OFF",
    PRODUCTS_NO_DISCOUNT            : "Without Discount",
    PRODUCTS_NO_STOCK               : "Out of stock",
    PRODUCTS_PURCHASED              : "The product is already in the cart!",
    PRODUCTS_RENTED                 : "The product is currently rented!",
    PRODUCTS_UNAVAILABLE            : "Out of stock",
    PRODUCTS_IS_RENTED              : "Rented",
    PRODUCTS_BUY                    : "Buy",
    PRODUCTS_ADD_TO_CART            : "Add to cart",
    PRODUCTS_RENT                   : "Rent per month",
    PRODUCTS_RENT_FOR               : "or rent for",
    PRODUCTS_RENT_MONTH             : "per month",
    PRODUCTS_RENT_MONTH_SHORT       : "/month",
    PRODUCTS_VIEW_IN_A_ROOM         : "View in a room",
    PRODUCTS_REQUEST_IMAGES         : "Request more images",
    PRODUCTS_REQUEST_IMAGES_HELP    : "From here you can request more images of \"{0}\". The images will be sent to the indicated email as soon as possible.",
    PRODUCTS_SEE_IN_YOUR_HOME       : "See it in my home",
    PRODUCTS_SEE_IN_YOUR_HOME_HELP  : "From here you can request an image showing how \"{0}\" looks like in your home. Please include a good quality, well centered image. We will generate the image and send it to the indicated email as soon as possible.",
    PRODUCTS_SHOW_TO_CART           : "View Cart",
    PRODUCTS_BUY_MORE               : "Keep Buying",
    PRODUCTS_SHOW_MORE              : "Keep Reading",
    PRODUCTS_SHOW_LESS              : "Hide description",
    PRODUCTS_MORE_FILTERS           : "View More",
    PRODUCTS_LESS_FILTERS           : "View Less",
    PRODUCTS_STOCK_SINGULAR         : "We have {0} of this product",
    PRODUCTS_STOCK_PLURAL           : "We have {0} of this product",
    PRODUCTS_WITHOUT_TAX            : "The price does not include VAT",
    PRODUCTS_NONE_AVAILABLE         : "There are no available products",

    // Banner
    BANNER_ONLY                     : "Only for X",
    BANNER_HOURS_SINGULAR           : "{0} Hour",
    BANNER_HOURS_PLURAL             : "{0} Hours",
    BANNER_DAYS_SINGULAR            : "{0} Day",
    BANNER_DAYS_PLURAL              : "{0} Days",
    BANNER_BEFORE                   : "Before",
    BANNER_SHOW                     : "View Offer",



    // Cart
    CART_TITLE                      : "Shopping Cart",
    CART_PRODUCTS_SINGULAR          : "{0} product",
    CART_PRODUCTS_PLURAL            : "{0} products",
    CART_PURCHASES                  : "My Purchases",
    CART_PURCHASES_IN               : "My Purchases in: {0}",
    CART_AMOUNT                     : "Quantity",
    CART_DELETE                     : "Delete",
    CART_PRICE                      : "Price",
    CART_SUBTOTAL                   : "Sub-total",
    CART_DISCOUNT_UNITS             : "You get a discount for buying {0} or more.",
    CART_DISCOUNT_TOTAL             : "You get a discount by buying a total of {1}{0} or more.",
    CART_DISCOUNT_NEXT_UNITS        : "You get a discount if you buy {0} or more.",
    CART_DISCOUNT_NEXT_TOTAL        : "You get a discount if you buy up {1}{0} or more.",
    CART_STOCK                      : "Only {0} units left at promotion, the rest has price list.",
    CART_SUMMARY                    : "Order Summary",
    CART_TOTAL                      : "Total in {0}",
    CART_UNITS_SINGULAR             : "Total for {0} unit",
    CART_UNITS_PLURAL               : "Total for {0} units",
    CART_DISCOUNT_CLIENT            : "Discount in {0}",
    CART_DISCOUNT_FIRST             : "{0}% discount for first purchase",
    CART_DISCOUNT_PAYMENT           : "{0}% discount for payment method",
    CART_FINAL_PRICE                : "Final Price",
    CART_BELLOW_MINIMUM             : "The mínimum amount for buying is {0} without the shipping cost",
    CART_SHIPMENT_TITLE             : "What shipment do you prefer?",
    CART_SHIPMENT_VARIANT           : "What kind of shipment do you prefer?",
    CART_SHIPMENT_HELP              : "Please select type of shipment",
    CART_SHIPMENT_SELECTED          : "Shipment Method",
    CART_SHIPMENT_PRICE             : "Price of shipment",
    CART_SHIPMENT_FREE              : "Free Shipment",
    CART_SUBSIDIARY_TITLE           : "Where do you want to pick up the order?",
    CART_SUBSIDIARY_SELECTED        : "Sucursal",
    CART_SUBSIDIARY_SCHEDULE        : "Hour: {0}",
    CART_ADDRESS_TITLE              : "Where do you want to receive the order?",
    CART_ADDRESS_EDIT               : "Edit Address",
    CART_ADDRESS_ADD_FIRST          : "Add delivery address",
    CART_ADDRESS_ADD_NEW            : "Agregar otro domicilio",
    CART_ADDRESS_NONE_HELP          : "Please add delivery address",
    CART_ADDRESS_SELECTED           : "Address",
    CART_TYPE_TITLE                 : "What Shipment you want to use?",
    CART_PAYMENT_TITLE              : "What kind of payment you prefer?",
    CART_PAYMENT_HELP               : "Select type of payment",
    CART_PAYMENT_DATA               : "Payment information",
    CART_PAYMENT_SELECTED           : "Type of payment",
    CART_PAYMENT_DIALOG             : "Finish payment",
    CART_CREDIT_TITLE               : "Credit card information",
    CART_CREDIT_NUMBER              : "Card number",
    CART_CREDIT_HOLDER_NAME         : "Card holder name",
    CART_CREDIT_HOLDER_SURNAME      : "Card holder surname",
    CART_CREDIT_MONTH               : "Expiration month",
    CART_CREDIT_YEAR                : "Expiration year",
    CART_CARD_CODE                  : "CVV",
    CART_MESSAGE                    : "You can leave us a message",
    CART_WRITE_MESSAGE              : "My messages",
    CART_CONFIRM_PRODUCTS           : "Confirm products",
    CART_CONFIRM_SHIPMENT           : "Confirm shippment",
    CART_CONFIRM_ORDER              : "Confirm order",
    CART_ADD_PRODUCTS               : "Add more products",
    CART_EDIT_PRODUCTS              : "Modify products",
    CART_EDIT_SHIPMENT              : "Modify shipment",
    CART_BUY_MORE                   : "Add products",
    CART_RESERVED_MINS_SINGULAR     : "You have {0} minute to finish your purchase",
    CART_RESERVED_MINS_PLURAL       : "You have {0} minutes to finish your purchase",
    CART_RESERVED_SECS_SINGULAR     : "You have {0} second to finish your purchase.",
    CART_RESERVED_SECS_PLURAL       : "You have {0} seconds to finish your purchase",
    CART_RESERVED_NONE              : "You run out of time to complete the purchase. Please repeat the steps.",
    CART_NONE_AVAILABLE             : "There are no products in the cart.",
    CART_AUTH_HELP                  : "You need an account to continue the purchase.",
    CART_COMPLETED_TITLE            : "Thanks for your purchase!",
    CART_COMPLETED_CONTACT          : "You will be contacted shortly by one of our representatives.",
    CART_COMPLETED_NEXT             : "Now you can:",
    CART_COMPLETED_PENDING          : "Please remember to complete payment.",
    CART_COMPLETED_HOURS            : "You have {0} hours to complete the payment of the order.",
    CART_COMPLETED_METHOD           : "You have {0} hours to complete the payment for {1}.",
    CART_COMPLETED_VALIDATION       : "Please send us the receipt transfer. You can upload it on your order list.",
    CART_COMPLETED_NOTICE           : "The order will ship when the payment has been complete.",
    CART_COMPLETED_ORDER            : "View my order",
    CART_COMPLETED_MORE             : "Keep buying",
    CART_ERROR_EXISTS               : "Missing Shopping Cart.",
    CART_ERROR_CLIENT               : "You must login to complete the order",
    CART_ERROR_EMPTY                : "There are no products on the shopping cart.",
    CART_ERROR_STOCK                : "Out of stock for that product.",
    CART_ERROR_STOCK_ONE            : "Out of stock.",
    CART_ERROR_STOCK_LEFT           : "Only {0} units left for this product.",
    CART_ERROR_RESERVED             : "You run out of time, you need to confirm again the products.",
    CART_ERROR_PRODUCT_ALLOW        : "You do not have permission to buy.",
    CART_ERROR_PRODUCT_EMPTY        : "Please complete the product",
    CART_ERROR_PRODUCT_EXISTS       : "The product does not exist.",
    CART_ERROR_PRODUCT_RENT         : "It is not possible to rent the indicated product.",
    CART_ERROR_PRODUCT_PURCHASED    : "The indicated product is in the cart.",
    CART_ERROR_VARIANT_EXISTS       : "The variant does not exist.",
    CART_ERROR_AMOUNT               : "Please indicate the quantity as a number greater than zero.",
    CART_ERROR_VARIANT_AMOUNT       : "Please indicate the quantity of at least one variant as a number greater than zero.",
    CART_ERROR_SHIPMENT             : "Please select type of shipment",
    CART_ERROR_SHIPMENT_SUBSIDIARY  : "Please indicate in which branch you want to find the products.",
    CART_ERROR_SHIPMENT_TRANSPORT   : "You do not have permission to indicate the type of transport.",
    CART_ERROR_SHIPMENT_TYPE        : "The type of shipment does not exist.",
    CART_ERROR_SHIPMENT_VARIANT     : "The specified shipping variant does not exist.",
    CART_ERROR_SHIPMENT_ADDRESS     : "Please indicate a shipping address.",
    CART_ERROR_SHIPMENT_PRICE       : "Our apologies we don't have a delivery to your address. Please choose another shipment method.",
    CART_ERROR_PAYMENT              : "Please select a type of payment.",
    CART_ERROR_CARD_NUMBER          : "Please complete a valid card number.",
    CART_ERROR_HOLDER_NAME          : "Please complete the holder name.",
    CART_ERROR_HOLDER_SURNAME       : "Please complete the holder surname.",
    CART_ERROR_CARD_MONTH           : "Please complete a month between 1 and 12.",
    CART_ERROR_CARD_YEAR            : "Please complete a year in the format YYYY.",
    CART_ERROR_CARD_CODE            : "Please complete the CVV as a number.",
    CART_ERROR_MESSAGE              : "Please complete the message.",
    CART_ERROR_TRANSACTION          : "Sorry we can't process your payment.",
    CART_SUCCESS_ADD                : "Added to cart successfully!",
    CART_SUCCESS_EDIT               : "The quantity has been modified successfully.",
    CART_SUCCESS_REMOVE             : "The product has been removed from the cart successfully.",
    CART_SUCCESS_PRODUCTS           : "The products of your shopping cart has been confirmed.",
    CART_SUCCESS_SHIPMENT           : "The cart shipment was confirmed successfully.",
    CART_SUCCESS_PAYMENT            : "The cart payment was confirmed successfully.",
    CART_SUCCESS_UNCONFIRM          : "The cart was successfully unconfirmed",



    // Catalog
    CATALOG_NAME                    : "Catalog",
    CATALOG_NONE                    : "There is no catalog available",
    CATALOG_PAGE                    : "Page {0} de {1}",

    // Entries
    ENTRIES_NAME                    : "News",
    ENTRIES_VIEW                    : "View News",
    ENTRIES_AUTHOR_DATE             : "By {0} | {1}",
    ENTRIES_NONE_AVAILABLE          : "No news available",

    // Contact
    CONTACT_NAME                    : "Contact Us",
    CONTACT_YOUR_NAME               : "Your name and last name",
    CONTACT_YOUR_PHONE              : "Your phone number",
    CONTACT_YOUR_EMAIL              : "Your email",
    CONTACT_YOUR_SUBJECT            : "Subject",
    CONTACT_SEND                    : "Send",
    CONTACT_DATA                    : "Information about the contact",
    CONTACT_PHONE                   : "Phone",
    CONTACT_EMAIL                   : "Email",
    CONTACT_SCHEDULE                : "Opening Hours",
    CONTACT_ADDRESS                 : "Address",
    CONTACT_ERROR_ACTIVE            : "You do not have permission to send a contact.",
    CONTACT_ERROR_NAME              : "Please fill your name and last name.",
    CONTACT_ERROR_PHONE             : "Please fill your phone/cellphone.",
    CONTACT_ERROR_EMAIL_EMPTY       : "Please fill your email.",
    CONTACT_ERROR_EMAIL_INVALID     : "The email is invalid.",
    CONTACT_ERROR_SUBJECT           : "Please fill the subject.",
    CONTACT_ERROR_MESSAGE           : "Please fill the message.",
    CONTACT_ERROR_IMAGE_EMPTY       : "Please fill the image.",
    CONTACT_SUCCESS_SEND            : "Thanks for your message! We will contact you soon.",

    // Error
    ERROR_TITLE                     : "404",
    ERROR_MESSAGE                   : "It seems that the page does not exist",
    ERROR_LINK                      : "Go to Home",



    // Client
    CLIENT_NAME                     : "My Account",
    CLIENT_LOGOUT                   : "Exit",

    // Client: Account
    ACCOUNT_NAME                    : "Personal information",
    ACCOUNT_SOCIAL_REASON           : "Business Name",
    ACCOUNT_IVA_CATEGORY            : "Tax Condition",
    ACCOUNT_CUIT                    : "ID number",
    ACCOUNT_DNI                     : "DNI",
    ACCOUNT_FANTASY_NAME            : "Fantasy name",
    ACCOUNT_FIRST_NAME              : "Name",
    ACCOUNT_LAST_NAME               : "Last Name",
    ACCOUNT_PHONE                   : "Phone",
    ACCOUNT_CELLPHONE               : "Cellphone / WhatsApp",
    ACCOUNT_ADDRESS                 : "Address",
    ACCOUNT_ZIP_CODE                : "Zip Code",
    ACCOUNT_PROVINCE                : "State",
    ACCOUNT_LOCALITY                : "City / Neighborhood",
    ACCOUNT_NEW_PASSWORD            : "New Password",
    ACCOUNT_PASSWORD_CONFIRM        : "Confirm Password",
    ACCOUNT_ERROR_SOCIAL_REASON     : "Please complete the business name.",
    ACCOUNT_ERROR_IVA_EMPTY         : "Please complete the tax condition.",
    ACCOUNT_ERROR_IVA_INVALID       : "The indicated tax condition is invalid.",
    ACCOUNT_ERROR_CUIT_EMPTY        : "Please complete the ID number.",
    ACCOUNT_ERROR_CUIT_INVALID      : "The indicated ID number is invalid.",
    ACCOUNT_ERROR_DNI_EMPTY         : "Please complete the DNI.",
    ACCOUNT_ERROR_DNI_INVALID       : "The indicated DNI is invalid.",
    ACCOUNT_ERROR_FANTASY           : "Please complete {0}.",
    ACCOUNT_ERROR_FIRST_NAME        : "Please complete the name of the recipient.",
    ACCOUNT_ERROR_LAST_NAME         : "Please complete the last name of the recipient.",
    ACCOUNT_ERROR_EMAIL_EMPTY       : "Please complete the email.",
    ACCOUNT_ERROR_EMAIL_INVALID     : "The indicated email is invalid.",
    ACCOUNT_ERROR_EMAIL_CONFIRM     : "Please confirm the email.",
    ACCOUNT_ERROR_EMAIL_MATCH       : "The emails do not match.",
    ACCOUNT_ERROR_PHONE             : "Please complete the phone or the cellphone",
    ACCOUNT_ERROR_ADDRESS           : "Please complete the address.",
    ACCOUNT_ERROR_ZIP_CODE          : "Please complete zip code.",
    ACCOUNT_ERROR_LOCALITY          : "Please complete city / neighborhood.",
    ACCOUNT_ERROR_PROVINCE_EMPTY    : "Please complete the state.",
    ACCOUNT_ERROR_PROVINCE_EXISTS   : "The indicated state does not exists.",
    ACCOUNT_ERROR_PASSWORD_INVALID  : "The password must be between 6 and 20 characters and contain at least one number and one letter.",
    ACCOUNT_ERROR_PASSWORD_CONFIRM  : "You must confirm the new password.",
    ACCOUNT_ERROR_PASSWORD_MATCH    : "The passwords do not match.",
    ACCOUNT_SUCCESS_EDIT            : "Account details were successfully edited.",

    // Client: Addresses
    ADDRESSES_NAME                  : "Delivery addresses",
    ADDRESSES_SINGULAR              : "Delivery address",
    ADDRESSES_VIEW                  : "See delivery address",
    ADDRESSES_CREATE                : "Add delivery address",
    ADDRESSES_EDIT                  : "Edit delivery address",
    ADDRESSES_DELETE                : "Delete delivery address",
    ADDRESSES_ALIAS                 : "Alias",
    ADDRESSES_ADDRESS               : "Address",
    ADDRESSES_STREET                : "Street name",
    ADDRESSES_STREET_NUMBER         : "Street number",
    ADDRESSES_BETWEEN_STREETS       : "Between streets",
    ADDRESSES_FLOOR                 : "Floor",
    ADDRESSES_FLOOR_FORMAT          : "Floor: {0}",
    ADDRESSES_APPARTMENT            : "Department",
    ADDRESSES_APPARTMENT_FORMAT     : "Department: {0}",
    ADDRESSES_LOCALITY              : "City / Neighborhood",
    ADDRESSES_PROVINCE              : "State",
    ADDRESSES_ZIP_CODE              : "Zip Code",
    ADDRESSES_CONTACT_NAME          : "Recipient's name",
    ADDRESSES_CONTACT_HELP          : "You can indicate who receives the shipment",
    ADDRESSES_DNI                   : "Recipient's DNI",
    ADDRESSES_EMAIL                 : "Recipient's email",
    ADDRESSES_PHONE                 : "Recipient's cellphone",
    ADDRESSES_IS_DEFAULT            : "Is Primary",
    ADDRESSES_NO_DEFAULT            : "Secondary",
    ADDRESSES_PENDING               : "The delivery address has a pending modification.",
    ADDRESSES_DELETE_TEXT           : "Do you really want to delete the delivery address \"{0}\"?",
    ADDRESSES_NONE_AVAILABLE        : "No delivery addresses available",
    ADDRESSES_ERROR_EDIT            : "You do not have permission to edit delivery addresses.",
    ADDRESSES_ERROR_EXISTS          : "The indicated delivery address does not exist.",
    ADDRESSES_ERROR_NAME            : "Please complete the name.",
    ADDRESSES_ERROR_STREET          : "Please complete the street name.",
    ADDRESSES_ERROR_STREET_NUMBER   : "Please complete the street number.",
    ADDRESSES_ERROR_STREET_INVALID  : "The street number must be a number.",
    ADDRESSES_ERROR_FLOOR           : "The floor must be a number.",
    ADDRESSES_ERROR_LOCALITY        : "Please complete the city / neighborhood.",
    ADDRESSES_ERROR_PROVINCE_EMPTY  : "Please complete the state.",
    ADDRESSES_ERROR_PROVINCE_EXISTS : "The indicated state you is invalid",
    ADDRESSES_ERROR_ZIP_CODE        : "Please complete the zip code.",
    ADDRESSES_ERROR_DNI_EMPTY       : "Please complete the DNI.",
    ADDRESSES_ERROR_DNI_INVALID     : "The DNI is invalid.",
    ADDRESSES_ERROR_PHONE           : "Please complete the cellphone.",
    ADDRESSES_ERROR_EMAIL_EMPTY     : "Please complete the email.",
    ADDRESSES_ERROR_EMAIL_INVALID   : "The email is invalid.",
    ADDRESSES_ERROR_IS_DEFAULT      : "You must have a primary delivery address.",
    ADDRESSES_ERROR_DELETE          : "You cannot delete the primary delivery address.",
    ADDRESSES_SUCCESS_CREATE        : "The delivery address was created successfully.",
    ADDRESSES_SUCCESS_EDIT          : "The delivery address was edited successfully.",
    ADDRESSES_SUCCESS_DELETE        : "The delivery address was deleted successfully.",

    // Client: Vouchers
    VOUCHERS_NAME                   : "My Vouchers",
    VOUCHERS_INVOICES               : "My Invoices",
    VOUCHERS_RECEIPTS               : "My Receipts",
    VOUCHERS_CREDIT_NOTES           : "My Credit Notes",
    VOUCHERS_DEBIT_NOTES            : "My Debit Notes",
    VOUCHERS_DESCRIPTION            : "Description",
    VOUCHERS_DATE                   : "Date",
    VOUCHERS_AMOUNT                 : "Amount",
    VOUCHERS_NONE_INVOICES          : "There are no invoices available.",
    VOUCHERS_NONE_RECEIPTS          : "There are no receipts available.",
    VOUCHERS_NONE_CREDIT_NOTES      : "There are no credit notes available.",
    VOUCHERS_NONE_DEBIT_NOTES       : "There are no debit notes available.",

    // Client: Orders
    ORDERS_NAME                     : "My Orders",
    ORDERS_SINGULAR                 : "Order",
    ORDERS_TITLE                    : "Order: #{0}",
    ORDERS_ID                       : "ID",
    ORDERS_DATE                     : "Created on",
    ORDERS_AMOUNT                   : "Quantity",
    ORDERS_TOTAL                    : "Total",
    ORDERS_TOTALS                   : "Totals",
    ORDERS_TOTAL_FOR                : "Total ({0})",
    ORDERS_PRODUCT                  : "Product",
    ORDERS_PRICE                    : "Price",
    ORDERS_SUBTOTAL                 : "Subtotal",
    ORDERS_SUMMARY                  : "Order Summary",
    ORDERS_SHIPPING                 : "Order Shipment",
    ORDERS_SHIPPING_TYPE            : "Shipment Type",
    ORDERS_SHIPPING_BOX             : "Box {0}",
    ORDERS_SUBSIDIARY               : "Delivery branch",
    ORDERS_ADDRESS                  : "Delivery Address",
    ORDERS_PAYMENT                  : "Order Payment",
    ORDERS_PAYMENT_METHOD           : "Payment Method",
    ORDERS_PAYMENT_STATUS           : "Payment Status",
    ORDERS_PAYMENT_DATA             : "Payment Information",
    ORDERS_PAYMENT_TIME             : "Time to pay",
    ORDERS_PAYMENT_TICKET           : "Ticket to pay",
    ORDERS_PAYMENT_TICKET_VIEW      : "See Ticket",
    ORDERS_PAYMENT_HOURS_SINGULAR   : "{0} hour",
    ORDERS_PAYMENT_HOURS_PLURAL     : "{0} hours",
    ORDERS_PAYMENT_SUBSCRIPTION     : "Subscription",
    ORDERS_PAYMENT_SUBSCRIBED_FOR   : "Subscribed for",
    ORDERS_PAYMENT_MONTHS_SINGULAR  : "1 month",
    ORDERS_PAYMENT_MONTHS_PLURAL    : "{0} months",
    ORDERS_CANCEL_SUBSCRIPTION      : "Cancel Subscription",
    ORDERS_CANCEL_SUBSCRIPTION_HELP : "Are you sure you want to cancel the subscription? You can leave us a message here:",
    ORDERS_CANCEL_RECEIVED          : "We got your cancellation. We will contact you soon.",
    ORDERS_VOUCHER_TITLE            : "Send receipt",
    ORDERS_VOUCHER_REJECTED         : "Receipt has been reject. Please try again or contact us",
    ORDERS_VOUCHER_MESSAGE          : "You can leave us a message on the receipt",
    ORDERS_VOUCHER_FILE             : "Please upload a receipt",
    ORDERS_PRODUCTS                 : "Items: {0}",
    ORDERS_NONE_AVAILABLE           : "No orders available.",
    ORDERS_ERROR_EXISTS             : "The order does not exist.",
    ORDERS_ERROR_VALIDATE           : "You cannot send a receipt for the indicated order.",
    ORDERS_ERROR_MESSAGE            : "Please complete the message of the receipt.",
    ORDERS_ERROR_IMAGE              : "Please complete the image of the receipt.",
    ORDERS_SUCCESS_VOUCHER          : "Thank you very much for sending us proof of payment.",

    // Client: Queries
    QUERIES_NAME                    : "My Queries",
    QUERIES_OPEN                    : "Open Queries",
    QUERIES_CLOSED                  : "Closed Queries",
    QUERIES_CREATE                  : "Create Query",
    QUERIES_SINGULAR                : "Query",
    QUERIES_SINGULAR_TITLE          : "Query: {0}",
    QUERIES_TYPE                    : "Type",
    QUERIES_OPEN_TYPE               : "open",
    QUERIES_CLOSED_TYPE             : "closed",
    QUERIES_SUBJECT                 : "Subject",
    QUERIES_ASSIGNED                : "Assigned to",
    QUERIES_LAST_REPLY              : "Last Answer",
    QUERIES_STATUS_OPEN             : "Open",
    QUERIES_STATUS_CLOSE            : "Closed",
    QUERIES_READING                 : "Read",
    QUERIES_READ                    : "Read",
    QUERIES_UNREAD                  : "Not read",
    QUERIES_PUBLISH                 : "Published on {0}",
    QUERIES_REPLY                   : "Answer",
    QUERIES_NONE_AVAILABLE          : "No queries available.",
    QUERIES_ERROR_EXISTS            : "The indicated query does not exist.",
    QUERIES_ERROR_TYPE_EMPTY        : "Please complete the type.",
    QUERIES_ERROR_TYPE_INVALID      : "The type does not exist.",
    QUERIES_ERROR_SUBJECT           : "Please complete the subject.",
    QUERIES_ERROR_MESSAGE           : "Please complete the message.",
    QUERIES_SUCCESS_CREATE          : "The query was created successfully.",
    QUERIES_SUCCESS_REPLY           : "The response was created successfully.",


    // Chat
    CHAT_NAME                       : "Chat",
    CHAT_LAST_CONECTION             : "Last {0}",
    CHAT_UNREAD                     : "{0} messages read",
    CHAT_WRITE_MESSAGE              : "Write a message here",
    CHAT_NONE_AVAILABLE             : "No messages available",
    CHAT_ERROR_MESSAGE              : "Please compelte the message.",


    // Notifications
    NOTIFICATIONS_NAME              : "Notifications",
    NOTIFICATIONS_ALERTS            : "Alerts",
    NOTIFICATIONS_NONE_AVAILABLE    : "Has no notifications",
    NOTIFICATIONS_ENABLE            : "Enable notifications",
    NOTIFICATIONS_ENABLED           : "Notifications are enabled!",
    NOTIFICATIONS_TITLE_OFFER       : "We have a new offer for you!",
    NOTIFICATIONS_TITLE_ORDER       : "Your order has been updated!",
    NOTIFICATIONS_TEXT_ORDER        : "The order status changed from \"{0} \" to \"{1}\". Click to see it.",
    NOTIFICATIONS_TITLE_VOUCHER     : "Your voucher has been updated!",
    NOTIFICATIONS_TEXT_VOUCHER      : "The voucher was marked as \"{0}\". Click to see it.",
    NOTIFICATIONS_TITLE_CART        : "Complete your purchase!",
    NOTIFICATIONS_TEXT_CART         : "Click to view and complete your purchase.",
    NOTIFICATIONS_TITLE_QUERY       : "Your query was responded!",
    NOTIFICATIONS_TEXT_QUERY        : "You have a new answer in your query \"{0}\". Click to see it.",
    NOTIFICATIONS_TITLE_INVOICE     : "You have a new Invoice!",
    NOTIFICATIONS_TEXT_INVOICE      : "Your invoice \"{0}\" is ready. You can download it from here.",
    NOTIFICATIONS_TITLE_RECEIPT     : "You have a new receipt!",
    NOTIFICATIONS_TEXT_RECEIPT      : "Your receipt \"{0}\" is ready. You can download it from here.",
    NOTIFICATIONS_TITLE_CREDITNOTE  : "You have a new Credit Note!",
    NOTIFICATIONS_TEXT_CREDITNOTE   : "Your credit note \"{0}\" is ready. You can download it from here.",
    NOTIFICATIONS_TITLE_DEBITNOTE   : "You have a new Debit Note!",
    NOTIFICATIONS_TEXT_DEBITNOTE    : "Your debit note \"{0}\" is ready. You can download it from here.",
    NOTIFICATIONS_TITLE_MESSAGE     : "You have new Messages!",
    NOTIFICATIONS_TEXT_MESSAGE      : "You have {0} unread messages. You can view them from here.",


    // Replazable Date Formats. {d: Day number, d0: 2 digit day, dn: Day name, d3: 3 letters day name, m: month, m0: 2 digit month, mn: Month name, m3: 3 letters month name, y: year, h: hours, i: minutes}
    DATE_DAY_NAMES                  : [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
    DATE_MONTH_NAMES                : [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],

    DATE_TIME                       : "{h}:{i}",
    DATE_DAY_TIME                   : "{d} {m3} {h}:{i}",
    DATE_DAY_MONTH                  : "{d} of {mn}",
    DATE_DAY_MONTH_YEAR             : "{d} of {mn}, {y}",
    DATE_MONTH_YEAR                 : "{mn} {y}",
    DATE_REDUCED                    : "{dn} {d0}/{m0}",
    DATE_COMPLETE                   : "{dn}, {d} of {mn}, {y}",
    DATE_SHORT_COMPLETE             : "{d3}, {d} of {m3}, {y}",
    DATE_DASHES                     : "{d0}-{m0}-{y}",
    DATE_DASHES_REVERSE             : "{y}-{m0}-{d0}",
    DATE_DASHES_TIME                : "{d0}-{m0}-{y} a las {h}:{i}",
    DATE_SLASHES                    : "{d0}/{m0}/{y}",
    DATE_SLASHES_DAY                : "{d0}/{m0}",
    DATE_SORTABLE                   : "{y}/{m0}/{d0}",

    DATE_NOW                        : "Ahora",
    DATE_MIN_AGO                    : "{i} minute ago",
    DATE_MINS_AGO                   : "{i} minutes ago",
    DATE_HOUR_AGO                   : "{h} hour ago",
    DATE_HOURS_AGO                  : "{h} hours ago",
    DATE_DAY_AGO                    : "{d} day ago",
    DATE_DAYS_AGO                   : "{d} days ago",
    DATE_IN_MIN                     : "In {i} minute",
    DATE_IN_MINS                    : "In {i} minutes",
    DATE_IN_HOUR                    : "In {h} hour",
    DATE_IN_HOURS                   : "In {h} hours",
    DATE_IN_DAY                     : "In {d} day",
    DATE_IN_DAYS                    : "In {d} days",
    DATE_TOMORROW_AT                : "Tomorrow at {h}:{i}",
    DATE_TODAY_AT                   : "Today at {h}:{i}",
    DATE_YESTERDAY_AT               : "Yesterday at {h}:{i}",
    DATE_THIS_YEAR                  : "{d}, {m3} at {h}:{i}",
    DATE_OTHER_YEAR                 : "{d}, {m3} {y} at {h}:{i}",

    DATE_YESTERDAY                  : "Yesterday",
    DATE_TODAY                      : "Today",
    DATE_TOMORROW                   : "Tomorrow",
    DATE_LAST_WEEK                  : "Last Week",
    DATE_THIS_WEEK                  : "This Week",
    DATE_NEXT_WEEK                  : "Next Week",
    DATE_PARSED_WEEK                : "Week from {0} to {1}",
    DATE_DURATION                   : "{0} - {1}",
    DATE_PERIOD                     : "{0} to {1}",
    DATE_SCHEDULE                   : "{0} from {1} to {2}",

};
