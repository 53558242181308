import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/Core/NLS";
import ClassList            from "Utils/Common/ClassList";

// Components
import Loading              from "Components/Utils/Common/Loading";

// Styles
import "Styles/Components/Utils/Common/Table.css";



/**
 * The Table
 */
class Table extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, loading, hasContent, hasSpacing, hasActions, noHover, none, children } = this.props;

        const classes = new ClassList(className, "table-container");
        classes.addIf("spacing",       hasSpacing);
        classes.addIf("table-actions", hasActions);
        classes.addIf("table-nohover", noHover);

        // Show the Loader
        if (loading) {
            return <Loading className="table-loading" />;
        }

        // There is no content
        if (!hasContent) {
            return <div className="table-none">
                <h3>{NLS.get(none)}</h3>
            </div>;
        }

        // Show the Content
        return <div className={classes.get()}>
            <table>
                {children.map((elem, key) => (
                    React.cloneElement(elem, { key, hasActions }))
                )}
            </table>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className  : PropTypes.string,
        loading    : PropTypes.bool,
        hasContent : PropTypes.bool,
        hasSpacing : PropTypes.bool,
        hasActions : PropTypes.bool,
        noHover    : PropTypes.bool,
        none       : PropTypes.string,
        children   : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className  : "",
        loading    : false,
        hasContent : false,
        hasActions : false,
        hasSpacing : false,
    }
}

export default Table;
