import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchSearch }      from "Actions/Store/ProductActions";
import NLS                  from "Utils/Core/NLS";
import Production           from "Utils/App/Production";

// Components
import Title                from "Components/Utils/Title/Title";
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsOptions      from "Components/Product/List/ProductsOptions";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsList         from "Components/Product/List/ProductsList";
import ProductsAside        from "Components/Product/Aside/ProductsAside";



/**
 * The Search Page
 */
class SearchPage extends React.Component {
    // The Production
    production = new Production(this.props.fetchSearch, "search");

    /**
     * Load the Data on Mount
     * @returns {Void}
     */
    componentDidMount() {
        const { loaded, settings, preferences, match, location } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params, location.search);
            this.production.fetch();
        }
    }

    /**
     * Load the Data if the Search changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { loaded, settings, preferences, match, location } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params, location.search);
            this.production.fetchIfRequired(prevProps, this.props);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, error, elem } = this.props;
        const { search               } = this.production.getParams();

        this.production.startRender(elem, "SEARCH", search);
        this.production.addStartCrumb("SEARCH_RESULTS");
        this.production.addCategoryCrumbs();

        const title   = NLS.format("SEARCH_TITLE", NLS.get(elem.name));
        const results = NLS.pluralize("PRODUCTS_RESULTS", elem.results);

        return <ProductsContainer
            production={this.production}
            loading={loading}
            error={error}
        >
            <Title message={title} submessage={results} />
            <ProductsOptions />
            <ProductsContent>
                <ProductsAside />
                <ProductsList
                    products={elem.products}
                    pages={elem.pages}
                />
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchSearch : PropTypes.func.isRequired,
        settings    : PropTypes.object.isRequired,
        loaded      : PropTypes.bool.isRequired,
        preferences : PropTypes.object.isRequired,
        loading     : PropTypes.bool.isRequired,
        error       : PropTypes.bool.isRequired,
        elem        : PropTypes.object.isRequired,
        match       : PropTypes.object.isRequired,
        location    : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings    : state.core.settings,
            loaded      : state.store.loaded,
            preferences : state.store.preferences,
            loading     : state.product.loading,
            error       : state.product.error,
            elem        : state.product.search,
        };
    }
}

export default connect(SearchPage.mapStateToProps, {
    fetchSearch,
})(SearchPage);
