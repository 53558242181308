import React                                    from "react";
import ReactDOM                                 from "react-dom";
import registerServiceWorker                    from "./registerServiceWorker";
import { pdfjs }                                from "react-pdf";
import { createBrowserHistory }                 from "history";

// Redux
import { createStore, applyMiddleware }         from "redux";
import thunk                                    from "redux-thunk";
import { composeWithDevTools }                  from "redux-devtools-extension";
import { Provider }                             from "react-redux";
import RootReducer                              from "Reducers/RootReducer";
import { setConfig, setPlatform }               from "Actions/Core/CoreActions";

// Misc
import App                                      from "./App";
import API                                      from "Utils/Core/API";
import NLS                                      from "Utils/Core/NLS";
import Auth                                     from "Utils/Core/Auth";
import Href                                     from "Utils/Core/Href";
import PushService                              from "Utils/Core/PushService";
import registerPreview                          from "Utils/Core/Preview";
import Metrics                                  from "Utils/Core/Metrics";
import SubMenu                                  from "Utils/App/SubMenu";



// Creates a Redux Store
const store = createStore(
    RootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);


// Creates the Browser History
const history = createBrowserHistory();


// Init the Settings
const isPreview = window.location.search === "?preview";
const isApp     = window.location.search === "?app";
const isCordova = !!window.cordova;
store.dispatch(setConfig(isApp, isCordova));


// Init the Components
const language = "tenetutienda-es";
API.init(store, isPreview, isApp);
NLS.init(language);
Auth.init(store);
Href.init(store, history, language);
Metrics.init();
SubMenu.init(store);
registerPreview(store, isPreview);


// Include the PDF Library
// @ts-ignore
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


// VH Hack
const onResize = () => {
    const fullHeight = window.innerHeight;
    document.body.style.setProperty("--full-height", `${fullHeight}px`);
};
window.addEventListener("resize", onResize);
onResize();


// Init the Cordova App
function onDeviceReady() {
    store.dispatch(setPlatform(window.device.platform.toLowerCase()));
    PushService.init(store);
    renderApp();
}


// Render the App
function renderApp() {
    ReactDOM.render(
        <Provider store={store}>
            <App history={history} />
        </Provider>,
        document.getElementById("root")
    );
}


// Start the App depending on the Environment
if (isCordova) {
    document.addEventListener("deviceready", onDeviceReady, false);
} else {
    renderApp();
    registerServiceWorker();
}
