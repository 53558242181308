import React                from "react";
import PropTypes            from "prop-types";



/**
 * The Html
 */
class Html extends React.Component {
    /**
     * Linkifies the Content
     * @param {String} content
     * @returns {String}
     */
    linkify(content) {
        // http://, https://, ftp://
        const urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim;
        // www. sans http:// or https://
        const pseudoUrlPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim;
        // Email addresses
        const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

        return content
            .replace(urlPattern, '<a href="$&">$&</a>')
            .replace(pseudoUrlPattern, '$1<a href="http://$2">$2</a>')
            .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>');
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { variant, className, style, linkify, maxLength, content, children } = this.props;

        let __html = String(content || children);

        if (linkify) {
            __html = this.linkify(__html);
        }
        if (maxLength && __html.length > maxLength) {
            __html = __html.substr(0, maxLength) + " ...";
        }


        switch (variant) {
        case "h3":
            return <h3
                className={className}
                style={style}
                dangerouslySetInnerHTML={{ __html }}
            />;
        case "p":
            return <p
                className={className}
                style={style}
                dangerouslySetInnerHTML={{ __html }}
            />;
        case "span":
            return <span
                className={className}
                style={style}
                dangerouslySetInnerHTML={{ __html }}
            />;
        default:
            return <div
                className={className}
                style={style}
                dangerouslySetInnerHTML={{ __html }}
            />;
        }
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        variant   : PropTypes.string,
        className : PropTypes.string,
        style     : PropTypes.object,
        maxLength : PropTypes.number,
        linkify   : PropTypes.bool,
        content   : PropTypes.string,
        children  : PropTypes.string,
    }
}

export default Html;
