import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStore }       from "Actions/Store/StoreActions";
import { setNotiRedirect }  from "Actions/Content/NotificationActions";
import { setCartRedirect }  from "Actions/Store/CartActions";
import Href                 from "Utils/Core/Href";

// Sections
import AuthPage             from "Components/Auth/AuthPage";
import HomePage             from "Components/Content/HomePage";
import PagePage             from "Components/Content/Page/PagePage";
import EntryPage            from "Components/Content/Entry/EntryPage";
import ArtistBioPage        from "Components/Content/Artist/ArtistBioPage";
import ChatPage             from "Components/Content/ChatPage";
import NotificationPage     from "Components/Content/Notifications/NotificationPage";
import ErrorPage            from "Components/Content/ErrorPage";

import ProductList          from "Components/Product/Page/ProductList";
import ProductElem          from "Components/Product/Page/ProductElem";
import OfferPage            from "Components/Product/Page/OfferPage";
import BrandPage            from "Components/Product/Page/BrandPage";
import ArtistPage           from "Components/Product/Page/ArtistPage";
import SearchPage           from "Components/Product/Page/SearchPage";
import NewestPage           from "Components/Product/Page/NewestPage";
import ImportantPage        from "Components/Product/Page/ImportantPage";
import FavoritePage         from "Components/Product/Page/FavoritePage";
import PurchasePage         from "Components/Product/Page/PurchasePage";
import HistoryPage          from "Components/Product/Page/HistoryPage";

import CartContainer        from "Components/Cart/CartContainer";
import CartResult           from "Components/Cart/CartResult";

import ClientContainer      from "Components/Client/ClientContainer";

// Components
import Header               from "Components/Header/Header";
import Updater              from "Components/Core/Updater";
import TopBar               from "Components/Core/TopBar";
import Navigation           from "Components/Core/Navigation";
import Floater              from "Components/Core/Floater";
import Footer               from "Components/Core/Footer";
import Bar                  from "Components/Core/Bar";
import ProductDialog        from "Components/Product/Item/ProductDialog";
import ProductPreview       from "Components/Product/Item/ProductPreview";
import ProductRoom          from "Components/Product/Item/ProductRoom";
import ProductAdded         from "Components/Product/Item/ProductAdded";
import ContactDialog        from "Components/Content/Contact/ContactDialog";

// Routes
import SwitchRoute          from "Components/Utils/Route/SwitchRoute";
import AuthRoute            from "Components/Utils/Route/AuthRoute";
import UserRoute            from "Components/Utils/Route/UserRoute";
import CartRoute            from "Components/Utils/Route/CartRoute";
import ClientRoute          from "Components/Utils/Route/ClientRoute";

// Styles
import "Styles/Components/Core/Container.css";



/**
 * The Container
 */
class Container extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { orderHash, cartRedirect, fetchStore, setCartRedirect } = this.props;
        fetchStore(orderHash);
        if (cartRedirect) {
            setCartRedirect("");
        }
    }

    /**
     * Redirect if required
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { isAuthenticated, notiRedirect, setNotiRedirect } = this.props;
        if (prevProps.notiRedirect !== notiRedirect && notiRedirect && isAuthenticated) {
            Href.goto(notiRedirect);
            setNotiRedirect("");
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, isApp, isAuthenticated, location, menuOpen } = this.props;

        return <>
            {isAuthenticated && <Updater />}
            <TopBar />
            <Header />
            <Navigation />

            <main className={"content-container" + (menuOpen ? " content-backdrop" : "")}>
                <SwitchRoute initialUrl="/">
                    <AuthRoute url="LOGIN"                        component={AuthPage}         isHidden={!settings.store_isOpen} />
                    <AuthRoute url="REGISTER"                     component={AuthPage}         isHidden={!settings.store_isOpen} />
                    <AuthRoute url="RECOVER"                      component={AuthPage}         isHidden={!settings.store_isOpen} />
                    <AuthRoute url="RESET"                        component={AuthPage}         isHidden={!settings.store_isOpen} />
                    <AuthRoute url="CODE"                         component={AuthPage}         isHidden={!settings.store_isOpen} />

                    <UserRoute url="PRODUCTS_TERCATEGORY_PAGE"    component={ProductList}      exact />
                    <UserRoute url="PRODUCTS_TERCATEGORY"         component={ProductList}      exact />
                    <UserRoute url="PRODUCTS_SUBCATEGORY_PAGE"    component={ProductList}      exact />
                    <UserRoute url="PRODUCTS_SUBCATEGORY"         component={ProductList}      exact />
                    <UserRoute url="PRODUCTS_CATEGORY_PAGE"       component={ProductList}      exact />
                    <UserRoute url="PRODUCTS_CATEGORY"            component={ProductList}      exact />
                    <UserRoute url="PRODUCTS_PAGE"                component={ProductList}      exact />
                    <UserRoute url="PRODUCTS"                     component={ProductList}      exact />

                    <UserRoute url="OFFER_TERCATEGORY_PAGE"       component={OfferPage}        exact />
                    <UserRoute url="OFFER_TERCATEGORY"            component={OfferPage}        exact />
                    <UserRoute url="OFFER_SUBCATEGORY_PAGE"       component={OfferPage}        exact />
                    <UserRoute url="OFFER_SUBCATEGORY"            component={OfferPage}        exact />
                    <UserRoute url="OFFER_CATEGORY_PAGE"          component={OfferPage}        exact />
                    <UserRoute url="OFFER_CATEGORY"               component={OfferPage}        exact />
                    <UserRoute url="OFFER_PAGE"                   component={OfferPage}        exact />
                    <UserRoute url="OFFER_OFFER"                  component={OfferPage}        exact />

                    <UserRoute url="BRAND_TERCATEGORY_PAGE"       component={BrandPage}        exact />
                    <UserRoute url="BRAND_TERCATEGORY"            component={BrandPage}        exact />
                    <UserRoute url="BRAND_SUBCATEGORY_PAGE"       component={BrandPage}        exact />
                    <UserRoute url="BRAND_SUBCATEGORY"            component={BrandPage}        exact />
                    <UserRoute url="BRAND_CATEGORY_PAGE"          component={BrandPage}        exact />
                    <UserRoute url="BRAND_CATEGORY"               component={BrandPage}        exact />
                    <UserRoute url="BRAND_PAGE"                   component={BrandPage}        exact />
                    <UserRoute url="BRAND_BRAND"                  component={BrandPage}        exact />

                    <UserRoute url="ARTIST_TERCATEGORY_PAGE"      component={ArtistPage}       exact />
                    <UserRoute url="ARTIST_TERCATEGORY"           component={ArtistPage}       exact />
                    <UserRoute url="ARTIST_SUBCATEGORY_PAGE"      component={ArtistPage}       exact />
                    <UserRoute url="ARTIST_SUBCATEGORY"           component={ArtistPage}       exact />
                    <UserRoute url="ARTIST_CATEGORY_PAGE"         component={ArtistPage}       exact />
                    <UserRoute url="ARTIST_CATEGORY"              component={ArtistPage}       exact />
                    <UserRoute url="ARTIST_PAGE"                  component={ArtistPage}       exact />
                    <UserRoute url="ARTIST_ARTIST"                component={ArtistPage}       exact />
                    <UserRoute url="ARTIST_BIO_ARTIST"            component={ArtistBioPage}    exact />

                    <UserRoute url="SEARCH_TERCATEGORY_PAGE"      component={SearchPage}       exact />
                    <UserRoute url="SEARCH_TERCATEGORY"           component={SearchPage}       exact />
                    <UserRoute url="SEARCH_SUBCATEGORY_PAGE"      component={SearchPage}       exact />
                    <UserRoute url="SEARCH_SUBCATEGORY"           component={SearchPage}       exact />
                    <UserRoute url="SEARCH_CATEGORY_PAGE"         component={SearchPage}       exact />
                    <UserRoute url="SEARCH_CATEGORY"              component={SearchPage}       exact />
                    <UserRoute url="SEARCH_PAGE"                  component={SearchPage}       exact />
                    <UserRoute url="SEARCH_TERM"                  component={SearchPage}       exact />

                    <UserRoute url="NEWESTS_TERCATEGORY_PAGE"     component={NewestPage}       exact />
                    <UserRoute url="NEWESTS_TERCATEGORY"          component={NewestPage}       exact />
                    <UserRoute url="NEWESTS_SUBCATEGORY_PAGE"     component={NewestPage}       exact />
                    <UserRoute url="NEWESTS_SUBCATEGORY"          component={NewestPage}       exact />
                    <UserRoute url="NEWESTS_CATEGORY_PAGE"        component={NewestPage}       exact />
                    <UserRoute url="NEWESTS_CATEGORY"             component={NewestPage}       exact />
                    <UserRoute url="NEWESTS_PAGE"                 component={NewestPage}       exact />
                    <UserRoute url="NEWESTS"                      component={NewestPage}       exact />

                    <UserRoute url="IMPORTANTS_TERCATEGORY_PAGE"  component={ImportantPage}    exact />
                    <UserRoute url="IMPORTANTS_TERCATEGORY"       component={ImportantPage}    exact />
                    <UserRoute url="IMPORTANTS_SUBCATEGORY_PAGE"  component={ImportantPage}    exact />
                    <UserRoute url="IMPORTANTS_SUBCATEGORY"       component={ImportantPage}    exact />
                    <UserRoute url="IMPORTANTS_CATEGORY_PAGE"     component={ImportantPage}    exact />
                    <UserRoute url="IMPORTANTS_CATEGORY"          component={ImportantPage}    exact />
                    <UserRoute url="IMPORTANTS_PAGE"              component={ImportantPage}    exact />
                    <UserRoute url="IMPORTANTS"                   component={ImportantPage}    exact />

                    <ClientRoute url="FAVORITES_TERCATEGORY_PAGE" component={FavoritePage}     exact />
                    <ClientRoute url="FAVORITES_TERCATEGORY"      component={FavoritePage}     exact />
                    <ClientRoute url="FAVORITES_SUBCATEGORY_PAGE" component={FavoritePage}     exact />
                    <ClientRoute url="FAVORITES_SUBCATEGORY"      component={FavoritePage}     exact />
                    <ClientRoute url="FAVORITES_CATEGORY_PAGE"    component={FavoritePage}     exact />
                    <ClientRoute url="FAVORITES_CATEGORY"         component={FavoritePage}     exact />
                    <ClientRoute url="FAVORITES_PAGE"             component={FavoritePage}     exact />
                    <ClientRoute url="FAVORITES"                  component={FavoritePage}     exact />

                    <ClientRoute url="PURCHASES_TERCATEGORY_PAGE" component={PurchasePage}     exact />
                    <ClientRoute url="PURCHASES_TERCATEGORY"      component={PurchasePage}     exact />
                    <ClientRoute url="PURCHASES_SUBCATEGORY_PAGE" component={PurchasePage}     exact />
                    <ClientRoute url="PURCHASES_SUBCATEGORY"      component={PurchasePage}     exact />
                    <ClientRoute url="PURCHASES_CATEGORY_PAGE"    component={PurchasePage}     exact />
                    <ClientRoute url="PURCHASES_CATEGORY"         component={PurchasePage}     exact />
                    <ClientRoute url="PURCHASES_PAGE"             component={PurchasePage}     exact />
                    <ClientRoute url="PURCHASES"                  component={PurchasePage}     exact />

                    <ClientRoute url="HISTORY_TERCATEGORY_PAGE"   component={HistoryPage}      exact />
                    <ClientRoute url="HISTORY_TERCATEGORY"        component={HistoryPage}      exact />
                    <ClientRoute url="HISTORY_SUBCATEGORY_PAGE"   component={HistoryPage}      exact />
                    <ClientRoute url="HISTORY_SUBCATEGORY"        component={HistoryPage}      exact />
                    <ClientRoute url="HISTORY_CATEGORY_PAGE"      component={HistoryPage}      exact />
                    <ClientRoute url="HISTORY_CATEGORY"           component={HistoryPage}      exact />
                    <ClientRoute url="HISTORY_PAGE"               component={HistoryPage}      exact />
                    <ClientRoute url="HISTORY"                    component={HistoryPage}      exact />

                    <UserRoute url="PRODUCT_TERCATEGORY"          component={ProductElem}      exact />
                    <UserRoute url="PRODUCT_SUBCATEGORY"          component={ProductElem}      exact />
                    <UserRoute url="PRODUCT_CATEGORY"             component={ProductElem}      exact />
                    <UserRoute url="PRODUCT_PRODUCT"              component={ProductElem}      exact />

                    <ClientRoute url="CHAT"                       component={ChatPage}         exact />
                    <ClientRoute url="NOTIFICATIONS"              component={NotificationPage} exact />
                    <ClientRoute url="ACCOUNT"                    component={ClientContainer}        />

                    <CartRoute url="CART_ORDER"                   component={CartResult}       exact />
                    <CartRoute url="CART"                         component={CartContainer}    exact />

                    <UserRoute url="ENTRY_PAGE"                   component={EntryPage}        exact />
                    <UserRoute url="ENTRY_ENTRY"                  component={EntryPage}        exact />

                    <UserRoute url="PAGE_PAGE"                    component={PagePage}         exact />
                    <UserRoute url="PAGE"                         component={PagePage}         exact />

                    <UserRoute url="HOME"                         component={HomePage}         exact />
                    <UserRoute url="ERROR"                        component={ErrorPage}        exact />
                </SwitchRoute>
            </main>

            <Floater />
            {isApp ? <Bar path={location.pathname} /> : <Footer />}

            <ProductDialog />
            <ProductPreview />
            <ProductRoom />
            <ProductAdded />
            <ContactDialog />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStore      : PropTypes.func.isRequired,
        setCartRedirect : PropTypes.func.isRequired,
        setNotiRedirect : PropTypes.func.isRequired,
        isApp           : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        menuOpen        : PropTypes.bool.isRequired,
        orderHash       : PropTypes.string.isRequired,
        cartRedirect    : PropTypes.string.isRequired,
        notiRedirect    : PropTypes.string.isRequired,
        location        : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp           : state.core.isApp,
            settings        : state.core.settings,
            isAuthenticated : state.auth.isAuthenticated,
            menuOpen        : state.store.menuOpen,
            orderHash       : state.cart.elem.orderHash,
            cartRedirect    : state.cart.redirect,
            notiRedirect    : state.notification.redirect,
        };
    }
}

export default connect(Container.mapStateToProps, {
    fetchStore, setCartRedirect, setNotiRedirect,
})(Container);
