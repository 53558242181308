import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";
import Analitics            from "Utils/Core/Analitics";
import Responsive           from "Utils/App/Responsive";
import Pages                from "Utils/App/Pages";
import ClassList            from "Utils/Common/ClassList";
import Utils                from "Utils/Common/Utils";

// Components
import Container            from "Components/Core/Container";
import Construction         from "Components/Auth/Construction";
import AuthContainer        from "Components/Auth/AuthContainer";
import TermsGuest           from "Components/Auth/TermsGuest";
import Loader               from "Components/Utils/Common/Loader";
import UserRoute            from "Components/Utils/Route/UserRoute";
import AuthRoute            from "Components/Utils/Route/AuthRoute";
import GuestRoute           from "Components/Utils/Route/GuestRoute";
import ScrollToTop          from "Components/Utils/Route/ScrollToTop";

// Styles
import "Styles/Core/Properties.css";
import "Styles/Core/Fonts.css";
import "Styles/Core/Main.css";
import "Styles/Core/App.css";

// Router
import {
    Router, Switch, Redirect,
} from "react-router-dom";

// Actions
import {
    fetchCore, setResponsive,
} from "Actions/Core/CoreActions";




/**
 * The App
 */
class App extends React.Component {
    // The Current State
    state = {
        atTop : true,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchCore();
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
        document.addEventListener("scroll", this.handleScroll);
    }

    /**
     * Unloads the Data
     * @returns {Void}
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        document.removeEventListener("scroll", this.handleScroll);
    }

    /**
     * Data Loaded
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { loaded, settings, language } = this.props;
        if (!prevProps.loaded && loaded) {
            NLS.setLanguage(language);
            Analitics.init(settings);
        }
    }

    /**
     * Handles the Resize
     * @returns {Void}
     */
    handleResize = () => {
        const conditions = Responsive.getConditions();
        this.props.setResponsive(conditions);
    }

    /**
     * Handles the Scroll
     * @returns {Void}
     */
    handleScroll = () => {
        this.setState({ atTop : window.scrollY === 0 });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            isApp, isCordova, showConstruction, settings, pages, styles,
            isAuthenticated, loaded, loading, history,
        } = this.props;

        const classes = new ClassList("container");
        classes.addIf("app",                isApp);
        classes.addIf("cordova",            isCordova);
        classes.addIf("container-sticky",   settings.header_isFixed);
        classes.addIf("container-absolute", ((settings.header_isFixed && this.state.atTop) || !settings.header_isFixed) && settings.home_sliderBellowHeader && Href.isHome());
        classes.addIf("container-topbar",   settings.topbar_isActive && settings.topbar_text);
        classes.addIf("container-squared",  settings.main_squareBorders);

        const style = {};
        for (const [ name, value ] of Object.entries(styles)) {
            if (value) {
                const key  = Utils.getPropertyName(name);
                style[key] = Utils.getPropertyValue(value);
            }
        }

        const inConstruction = settings.construction_isActive && showConstruction;
        const showConstruct  = inConstruction && loaded;
        const showStore      = !inConstruction && loaded;
        const showAuth       = !settings.store_isOpen && !isAuthenticated;
        const terms          = Pages.get(pages, Pages.TERMS);
        const showTerms      = showAuth && !!terms;
        const showRedirect   = showAuth && settings.clients_isActive;
        const authRoutes     = showAuth ? Href.authRoutes() : [];

        return <div className={classes.get()} style={style}>
            <Loader variant="app" show={loading || !loaded} />

            {showConstruct && <Router history={history}>
                <Construction />
            </Router>}
            {showStore && <Router history={history}>
                <ScrollToTop>
                    <Switch>
                        {authRoutes.map((path) => <AuthRoute key={path} path={path} component={AuthContainer} />)}
                        {showTerms    && <GuestRoute path={terms.url}        component={TermsGuest} exact />}
                        {!showAuth    && <UserRoute  path={Href.url("HOME")} component={Container}        />}
                        {showRedirect && <Redirect from="*" to={Href.url("LOGIN")} />}
                    </Switch>
                </ScrollToTop>
            </Router>}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCore        : PropTypes.func.isRequired,
        setResponsive    : PropTypes.func.isRequired,
        isApp            : PropTypes.bool.isRequired,
        isCordova        : PropTypes.bool.isRequired,
        path             : PropTypes.string.isRequired,
        loaded           : PropTypes.bool.isRequired,
        loading          : PropTypes.bool.isRequired,
        language         : PropTypes.string.isRequired,
        showConstruction : PropTypes.bool.isRequired,
        settings         : PropTypes.object.isRequired,
        pages            : PropTypes.object.isRequired,
        styles           : PropTypes.object.isRequired,
        isAuthenticated  : PropTypes.bool.isRequired,
        history          : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp            : state.core.isApp,
            isCordova        : state.core.isCordova,
            path             : state.core.path,
            loaded           : state.core.loaded,
            loading          : state.core.loading,
            language         : state.core.language,
            showConstruction : state.core.showConstruction,
            settings         : state.core.settings,
            pages            : state.core.pages,
            styles           : state.core.styles,
            isAuthenticated  : state.auth.isAuthenticated,
        };
    }
}

export default connect(App.mapStateToProps, {
    fetchCore, setResponsive,
})(App);
