import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchFavorites }   from "Actions/Store/ProductActions";
import Production           from "Utils/App/Production";
import Utils                from "Utils/Common/Utils";

// Components
import Title                from "Components/Utils/Title/Title";
import ErrorPage            from "Components/Content/ErrorPage";
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsOptions      from "Components/Product/List/ProductsOptions";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsList         from "Components/Product/List/ProductsList";
import ProductsAside        from "Components/Product/Aside/ProductsAside";



/**
 * The Favorite Page
 */
class FavoritePage extends React.Component {
    // The Production
    production = new Production(this.props.fetchFavorites);

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { loaded, settings, preferences, match, location } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params, location.search);
            this.production.fetch();
        }
    }

    /**
     * Load the Data if the Favorite changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { loaded, settings, preferences, match, location } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params, location.search);
            this.production.fetchIfRequired(prevProps, this.props);
        }
    }

    /**
     * Handles Product Favorite update
     * @returns {Void}
     */
    handleFavorite = () => {
        this.production.fetch();
    }

    /**
     * Handles Product Dialog close
     * @param {Object} product
     * @returns {Void}
     */
    handleClose = (product) => {
        if (!product.isFavorite) {
            this.production.fetch();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, loading, error, elem } = this.props;

        if (!settings.products_hasFavorites) {
            return <ErrorPage />;
        }

        this.production.startRender(elem, "FAVORITES");
        this.production.addStartCrumb("FAVORITES_NAME");
        this.production.addCategoryCrumbs();

        return <ProductsContainer
            production={this.production}
            loading={loading}
            error={error}
        >
            <Title message={Utils.getProductTitle("FAVORITES_TITLE", settings)} />
            <ProductsOptions />
            <ProductsContent>
                <ProductsAside />
                <ProductsList
                    products={elem.products}
                    pages={elem.pages}
                    onFavorite={this.handleFavorite}
                    onClose={this.handleClose}
                />
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchFavorites : PropTypes.func.isRequired,
        settings       : PropTypes.object.isRequired,
        loaded         : PropTypes.bool.isRequired,
        preferences    : PropTypes.object.isRequired,
        loading        : PropTypes.bool.isRequired,
        error          : PropTypes.bool.isRequired,
        elem           : PropTypes.object.isRequired,
        match          : PropTypes.object.isRequired,
        location       : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings    : state.core.settings,
            loaded      : state.store.loaded,
            preferences : state.store.preferences,
            loading     : state.product.loading,
            error       : state.product.error,
            elem        : state.product.favorites,
        };
    }
}

export default connect(FavoritePage.mapStateToProps, {
    fetchFavorites,
})(FavoritePage);
