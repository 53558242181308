import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Responsive           from "Utils/App/Responsive";

// Components
import ProductPrice         from "Components/Product/Item/ProductPrice";
import Html                 from "Components/Utils/Common/Html";

// Styles
import "Styles/Components/Product/Card/ProductCardInfo.css";



/**
 * The Product Card Info
 */
class ProductCardInfo extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { responsive, settings, data, forWide, forHome                       } = this.props;
        const { productCode, name, brandName, artistName, description, hasVariants } = data;

        const isMobile   = Responsive.isMobile(responsive);
        const title      = name.replace(/\//g, " / ");
        const showPrice  = Boolean(!forWide && settings.products_showPrice && settings.products_showPriceTop && !hasVariants);
        const showCode   = Boolean(forWide  && settings.products_showCode && productCode);
        const showBrand  = Boolean(settings.products_showBrand && brandName);
        const showArtist = Boolean(settings.products_showArtist && artistName);
        const showDesc   = Boolean(description && ((isMobile && settings.products_showMobileDesc) || (!isMobile && settings.products_showDesktopDesc)));
        const fields     = data.fields.filter((field) => forHome ? field.showInHome : field.showInList);
        const showFields = Boolean(fields.length);

        return <div className="product-info">
            {showPrice && <ProductPrice
                className="product-card-price"
                data={data}
                withPercent
            />}
            {showCode && <div className="product-info-code">{productCode}</div>}
            {showBrand && <div className="product-info-brand">{brandName}</div>}
            {showArtist && <div className="product-info-artist">{artistName}</div>}
            <h3>{title}</h3>
            {showFields && fields.map((field) => <div key={field.fieldID} className="product-info-field">
                <b>{field.name}:</b> {field.value}
            </div>)}
            {showDesc && <Html
                className="product-info-desc"
                content={description}
                maxLength={250}
            />}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        responsive : PropTypes.object.isRequired,
        settings   : PropTypes.object.isRequired,
        data       : PropTypes.object.isRequired,
        forWide    : PropTypes.bool,
        forHome    : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        forWide : false,
        forHome : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
            settings   : state.core.settings,
        };
    }
}

export default connect(ProductCardInfo.mapStateToProps)(ProductCardInfo);
