import Utils                from "Utils/Common/Utils";

// Types
import {
    VOUCHER_LOADING,
    VOUCHER_INVOICES, VOUCHER_RECEIPTS,
    VOUCHER_CREDIT_NOTES, VOUCHER_DEBIT_NOTES,
} from "Utils/Core/Types";



// The initial State
const initialState = {
    loading     : true,
    error       : false,
    invoices    : {
        list  : [],
        total : 0,
    },
    receipts    : {
        list  : [],
        total : 0,
    },
    creditNotes : {
        list  : [],
        total : 0,
    },
    debitNotes  : {
        list  : [],
        total : 0,
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, VOUCHER_INVOICES, VOUCHER_RECEIPTS, VOUCHER_CREDIT_NOTES, VOUCHER_DEBIT_NOTES)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case VOUCHER_LOADING:
        return {
            ...state,
            loading     : true,
            error       : false,
        };

    case VOUCHER_INVOICES:
        return {
            ...state,
            loading     : false,
            error       : false,
            invoices    : action.data,
        };
    case VOUCHER_RECEIPTS:
        return {
            ...state,
            loading     : false,
            error       : false,
            receipts    : action.data,
        };
    case VOUCHER_CREDIT_NOTES:
        return {
            ...state,
            loading     : false,
            error       : false,
            creditNotes : action.data,
        };
    case VOUCHER_DEBIT_NOTES:
        return {
            ...state,
            loading     : false,
            error       : false,
            debitNotes  : action.data,
        };
    default:
        return state;
    }
};
