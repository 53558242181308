import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleMenu }       from "Actions/Store/StoreActions";
import Href                 from "Utils/Core/Href";

// Components
import ImageLink            from "Components/Utils/Media/ImageLink";
import Button               from "Components/Utils/Form/Button";



/**
 * The Brand Menu
 */
class BrandMenu extends React.Component {
    /**
     * Toggles the Menu
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        const { open, brands, toggleMenu } = this.props;
        if (brands.length && open && !prevProps.open) {
            toggleMenu(true);
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, brands, open, top, href, onEnter, onLeave } = this.props;

        if (!open || !brands.length) {
            return <React.Fragment />;
        }
        return <nav
            className="submenu-content submenu-images"
            style={{ top : `${top}px` }}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <ul className="submenu-images-grid submenu-grid no-list">
                {brands.map((elem) => <li key={elem.id}>
                    <ImageLink
                        variant="opacity"
                        location={settings.brands_showNames ? "bottom" : "none"}
                        href={Href.url("BRAND", elem.url)}
                        onClick={onLeave}
                        source={elem.image}
                        name={elem.name}
                    />
                </li>)}
            </ul>
            <div className="submenu-more">
                <Button
                    variant="primary"
                    href={href}
                    message="GENERAL_VIEW_MORE"
                    onClick={onLeave}
                />
            </div>
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleMenu : PropTypes.func.isRequired,
        settings   : PropTypes.object.isRequired,
        brands     : PropTypes.array.isRequired,
        open       : PropTypes.bool.isRequired,
        top        : PropTypes.number.isRequired,
        href       : PropTypes.string.isRequired,
        onEnter    : PropTypes.func.isRequired,
        onLeave    : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            brands   : state.store.brands,
        };
    }
}

export default connect(BrandMenu.mapStateToProps, {
    toggleMenu,
})(BrandMenu);
