// Constants
const OFFERS    = "offers";
const BRANDS    = "brands";
const ARTISTS   = "artists";
const ENTRIES   = "entries";
const CATALOGUE = "catalogue";
const CONTACT   = "contact";
const TERMS     = "terms";



/**
 * Returns true if the Page Exists
 * @param {Object} pages
 * @param {String} type
 * @returns {Boolean}
 */
function has(pages, type) {
    return Boolean(pages[type]);
}

/**
 * Returns the Page
 * @param {Object} pages
 * @param {String} type
 * @returns {String}
 */
function get(pages, type) {
    if (pages[type]) {
        return {
            id   : pages[type].id,
            url  : getUrl(pages, type),
            name : getName(pages, type),
        };
    }
    return null;
}

/**
 * Returns the Page Url
 * @param {Object} pages
 * @param {String} type
 * @returns {String}
 */
function getUrl(pages, type) {
    if (pages[type]) {
        return "/" + pages[type].slug;
    }
    return "";
}

/**
 * Returns the Page Name
 * @param {Object} pages
 * @param {String} type
 * @returns {String}
 */
 function getName(pages, type) {
    if (pages[type]) {
        return pages[type].name;
    }
    return "";
}

/**
 * Returns the Params
 * @param {Object} params
 * @returns {Object}
 */
function getParams(params) {
    return {
        slug  : params.slug,
        entry : params.entry,
        page  : Number(params.page) || 1,
    };
}




// The Public API
export default {
    OFFERS,
    BRANDS,
    ARTISTS,
    ENTRIES,
    CATALOGUE,
    CONTACT,
    TERMS,

    has,
    get,
    getUrl,
    getName,
    getParams,
};
