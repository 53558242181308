import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchArtist }      from "Actions/Content/ContentActions";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";
import Responsive           from "Utils/App/Responsive";
import Pages                from "Utils/App/Pages";

// Components
import ErrorPage            from "Components/Content/ErrorPage";
import ArtistData           from "Components/Content/Artist/ArtistData";
import ProductImage         from "Components/Product/Item/ProductImage";
import Title                from "Components/Utils/Title/Title";
import Loading              from "Components/Utils/Common/Loading";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Breadcrumb           from "Components/Utils/Common/Breadcrumb";
import Html                 from "Components/Utils/Common/Html";
import Slider               from "Components/Utils/Common/Slider";

// Styles
import "Styles/Components/Content/Artist/ArtistBio.css";



/**
 * The Artist Bio Page
 */
class ArtistBioPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetchArtist();
    }

    /**
     * Reload the Data
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.match.params.artist !== prevProps.match.params.artist) {
            this.fetchArtist();
        }
    }

    /**
     * Load the Artist
     * @returns {Void}
     */
    fetchArtist() {
        const { match, fetchArtist } = this.props;
        fetchArtist(match.params.artist);
    }



    /**
     * Generates the Crumbs and back Url
     * @returns {Object}
     */
    generateCrumbs() {
        const { pages, elem } = this.props;

        const page   = Pages.get(pages, Pages.ARTISTS);
        const crumbs = [];
        if (!page) {
            return [];
        }
        crumbs.push({
            to   : page.url,
            name : page.name,
        });
        crumbs.push({
            to   : Href.url("ARTIST_BIO", elem.slug),
            name : NLS.get("ARTISTS_BIOGRAPHY"),
        });
        return crumbs;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { responsive, pages, loading, error, elem, match } = this.props;

        if (error) {
            return <ErrorPage />;
        }
        const count   = Responsive.calcArtistCols(responsive);
        const backUrl = Pages.getUrl(pages, Pages.ARTISTS);
        const crumbs  = this.generateCrumbs();

        return <>
            <Title message={elem.name || "GENERAL_LOADING"} href={backUrl} />
            <Wrapper maxWidth={1200} withSpacing>
                {loading ? <Loading /> : <>
                    <Breadcrumb data={crumbs} withBorder />
                    <section className="artist-bio">
                        <ArtistData
                            elem={elem}
                            artist={match.params.artist}
                            showButton
                        />
                        <div className="artist-bio-content">
                            {!!elem.bioImage && <img src={elem.bioImageUrl} alt={elem.name} />}
                            <Slider total={elem.products.length} count={count} outsideNav>
                                {elem.products.map((product) => <div key={product.productID}>
                                    <ProductImage
                                        variant="small"
                                        data={product}
                                        zoomOnHover
                                        clickView
                                    />
                                </div>)}
                            </Slider>
                            <div className="artist-bio-about">
                                {!!elem.description && <>
                                    <h2>{NLS.get("ARTISTS_ABOUT_ME")}</h2>
                                    <Html content={elem.description} />
                                </>}
                                {!!elem.exhibitions && <>
                                    <h2>{NLS.get("ARTISTS_EXHIBITIONS")}</h2>
                                    <Html content={elem.exhibitions} />
                                </>}
                            </div>
                        </div>
                    </section>
                </>}
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchArtist : PropTypes.func.isRequired,
        responsive  : PropTypes.object.isRequired,
        pages       : PropTypes.object.isRequired,
        loading     : PropTypes.bool.isRequired,
        error       : PropTypes.bool.isRequired,
        elem        : PropTypes.object.isRequired,
        match       : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
            pages      : state.core.pages,
            loading    : state.content.loading,
            error      : state.content.error,
            elem       : state.content.artist,
        };
    }
}

export default connect(ArtistBioPage.mapStateToProps, {
    fetchArtist,
})(ArtistBioPage);
