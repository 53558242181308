import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchPage }        from "Actions/Content/ContentActions";
import Pages                from "Utils/App/Pages";
import Utils                from "Utils/Common/Utils";

// Components
import ErrorPage            from "Components/Content/ErrorPage";
import PageContent          from "Components/Content/Page/PageContent";
import Title                from "Components/Utils/Title/Title";



/**
 * The Page Page
 */
class PagePage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = Pages.getParams(this.props.match.params);
        this.props.fetchPage(params.slug, params.page);
    }

    /**
     * Load the New Data
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = Pages.getParams(prevProps.match.params);
        const newParams = Pages.getParams(this.props.match.params);

        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchPage(newParams.slug, newParams.page);
        }
    }





    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, error, elem, match } = this.props;

        if (!loading && error) {
            return <ErrorPage />;
        }
        return <>
            <Title
                message={elem.name}
                background={elem.bannerImageUrl}
                height={elem.headerHeight}
                showTitle={elem.showTitle}
                noBotomSpace={!loading && !elem.showBorder}
            />
            <PageContent
                loading={loading}
                elem={elem}
                match={match}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchPage : PropTypes.func.isRequired,
        loading   : PropTypes.bool.isRequired,
        error     : PropTypes.bool.isRequired,
        elem      : PropTypes.object.isRequired,
        match     : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading : state.content.loading,
            error   : state.content.error,
            elem    : state.content.page,
        };
    }
}

export default connect(PagePage.mapStateToProps, {
    fetchPage,
})(PagePage);
