import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/Core/NLS";

// Components
import Price                from "Components/Utils/Common/Price";

// Styles
import "Styles/Components/Product/Item/ProductPrice.css";



/**
 * The Product Price
 */
class ProductPrice extends React.Component {
    /**
     * Returns the Price Data
     * @param {Object} data
     * @returns {Object}
     */
    getPriceData(data) {
        if (data.onlyForRent) {
            return {
                currencySymbol  : data.currencySymbol,
                priceFormat     : data.rentPriceFormat,
                hasDiscount     : false,
                discountPrice   : 0,
                discountPercent : 0,
                semaphoreClass  : data.semaphoreClass,
                forRent         : true,
            };
        }
        return data;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, className, amount, withPercent } = this.props;

        const priceData = this.getPriceData(data);
        const {
            currencySymbol, priceFormat,
            hasDiscount, discountPrice, discountPercent,
            semaphoreClass, forRent,
        } = priceData;

        const showAmount   = amount > 0;
        const showDiscount = hasDiscount;
        const hasPercent   = withPercent && Boolean(Number(discountPercent));
        const hasSemaphore = Boolean(semaphoreClass);

        return <div className={`product-price ${className}`}>
            {showAmount && <div className="product-price-amount">{`${amount}✕ `}</div>}
            {showDiscount && <div>
                <Price
                    className="product-price-old"
                    symbol={currencySymbol}
                    price={priceFormat}
                />
                <div className="product-price-new">
                    <Price
                        className="product-new-value"
                        symbol={currencySymbol}
                        price={discountPrice}
                    />
                    {hasPercent && <div className="product-discount">
                        {NLS.format("PRODUCTS_DISCOUNT", discountPercent)}
                    </div>}
                </div>
            </div>}
            {!showDiscount && <div className={`product-price-cnt ${forRent ? "product-with-month" : ""}`}>
                <Price
                    symbol={currencySymbol}
                    price={priceFormat}
                />
                {forRent && <div className="product-price-month">
                    {NLS.get("PRODUCTS_RENT_MONTH_SHORT")}
                </div>}
            </div>}
            {hasSemaphore && <div
                className={`product-semaphore product-semaphore-${semaphoreClass}`}
            />}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data        : PropTypes.object.isRequired,
        className   : PropTypes.string,
        amount      : PropTypes.number,
        withPercent : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        amount      : 0,
        withPercent : false,
    }
}

export default ProductPrice;
