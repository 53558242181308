import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { addProduct }       from "Actions/Store/CartActions";
import { setAdded }         from "Actions/Store/ProductActions";
import { toggleFavorite }   from "Actions/Store/StoreActions";
import Href                 from "Utils/Core/Href";
import Analitics            from "Utils/Core/Analitics";
import Responsive           from "Utils/App/Responsive";
import ClassList            from "Utils/Common/ClassList";

// Components
import ProductCardContent   from "Components/Product/Card/ProductCardContent";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Product/Card/ProductCard.css";



/**
 * The Product Card
 */
class ProductCard extends React.Component {
    // The Current State
    state = {
        favorites : 0,
        loading   : false,
        error     : "",
    }



    /**
     * Handles the Click
     * @returns {Void}
     */
    handleClick = () =>  {
        const { data, settings, url } = this.props;
        Href.gotoProduct(data, settings, url);
    }

    /**
     * Handles the Button
     * @param {Event} e
     * @returns {Void}
     */
    handleButton = async (e) => {
        const { data, orderHash, settings, url, addProduct, setAdded } = this.props;
        e.stopPropagation();
        e.preventDefault();

        if (settings.products_btnMode === 2 || settings.products_btnMode === 3) {
            this.setState({ loading : true, error : "" });
            try {
                await addProduct({
                    orderHash : orderHash,
                    productID : data.productID,
                    amount    : 1,
                });
                Analitics.addToCart(data, 1, false);
                this.setState({ loading : false });
                setAdded(data);
            } catch (response) {
                this.setState({ loading : false, error : response.errors.form });
            }
        } else {
            Href.gotoProduct(data, settings, url);
        }
    }

    /**
     * Handles the Favorite
     * @param {Event} e
     * @returns {Void}
     */
    handleFavorite = async (e) => {
        const { data, toggleFavorite, onFavorite } = this.props;
        e.preventDefault();
        await toggleFavorite(data.productID);
        this.setState({ favorites : this.state.favorites + 1 });
        if (onFavorite) {
            onFavorite(data.productID);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { responsive, settings, isAuthenticated, data, isWide, forHome } = this.props;

        const isApp        = Responsive.isMobile(responsive) || responsive.isTablet;
        const showFavorite = isAuthenticated && settings.products_hasFavorites;
        const showButton   = (isApp && settings.products_showMobileBtn) || (!isApp && settings.products_showDesktopBtn);
        const multiButton  = settings.orders_isActive && settings.products_btnMode === 3;
        const button       = settings.orders_isActive && !data.isRented ? "PRODUCTS_ADD_TO_CART" : "PRODUCTS_VIEW_TITLE";

        const classes      = new ClassList("product-card");
        classes.addIf("product-narrow",           !isWide);
        classes.addIf("product-wide",             isWide);
        classes.addIf("product-hover",            !isApp && !settings.products_showDesktopBtn);
        classes.addIf("product-with-variants",    data.hasVariants);
        classes.addIf("product-without-variants", !data.hasVariants);
        classes.addIf("product-hide-variants",    isApp && settings.products_hideVariants);
        classes.addIf("product-big-title",        settings.products_showBigTitle);
        classes.addIf("product-big-price",        settings.products_showPriceBig);
        classes.addIf("product-align-prices",     settings.products_alignPrices);
        classes.addIf("product-center-text",      !isWide && settings.products_centerText);
        classes.addIf("product-show-border",      settings.products_showBorder);
        classes.addIf("product-show-button",      showButton);
        classes.addIf("product-outside-button",   showButton && settings.products_showBorder && !settings.products_showInsideBtn);
        classes.addIf("product-inside-button",    showButton && settings.products_showBorder && settings.products_showInsideBtn);
        classes.addIf("product-hanging-button",   showButton && !settings.products_showBorder);

        return <div className={classes.get()} onClick={this.handleClick}>
            <ProductCardContent
                data={data}
                isWide={isWide}
                forHome={forHome}
            />
            <div className={"product-button " + (multiButton ? "product-button-multi" : "product-button-single")}>
                {multiButton ? <>
                    <Button
                        variant="primary"
                        message="PRODUCTS_BUY"
                        icon="cart"
                        onClick={this.handleButton}
                    />
                    <Button
                        className="product-button-view"
                        variant="cancel"
                        message="GENERAL_VIEW"
                        icon="view"
                        onClick={this.handleClick}
                    />
                </> : <Button
                    variant="primary"
                    message={button}
                    icon="cart"
                    onClick={this.handleButton}
                    fullWidth
                />}
            </div>
            {showFavorite && <HyperLink
                className="product-card-favorite"
                variant="icon"
                icon={data.isFavorite ? "favorite" : "unfavorite"}
                onClick={this.handleFavorite}
            />}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        addProduct      : PropTypes.func.isRequired,
        setAdded        : PropTypes.func.isRequired,
        toggleFavorite  : PropTypes.func.isRequired,
        responsive      : PropTypes.object.isRequired,
        settings        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        orderHash       : PropTypes.string.isRequired,
        data            : PropTypes.object.isRequired,
        url             : PropTypes.string,
        onFavorite      : PropTypes.func,
        isWide          : PropTypes.bool,
        forHome         : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        isWide  : false,
        forHome : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive      : state.core.responsive,
            settings        : state.core.settings,
            isAuthenticated : state.auth.isAuthenticated,
            orderHash       : state.cart.elem.orderHash,
        };
    }
}

export default connect(ProductCard.mapStateToProps, {
    addProduct, setAdded, toggleFavorite,
})(ProductCard);
