import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleSearch }     from "Actions/Store/StoreActions";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Search Icon
 */
class SearchIcon extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { toggleSearch } = this.props;

        return <div className="header-search">
            <HyperLink
                className="header-icon"
                variant="icon"
                onClick={() => toggleSearch(true)}
                icon="search"
            />
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleSearch : PropTypes.func.isRequired,
    }
}

export default connect(null, {
    toggleSearch,
})(SearchIcon);
