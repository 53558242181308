import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchCart }        from "Actions/Store/CartActions";
import NLS                  from "Utils/Core/NLS";
import Utils                from "Utils/Common/Utils";

// Components
import CartCompleted        from "Components/Cart/Utils/CartCompleted";
import ProductContent       from "Components/Cart/Product/ProductContent";
import AccountContent       from "Components/Cart/Shipment/AccountContent";
import ShipmentContent      from "Components/Cart/Shipment/ShipmentContent";
import PaymentContent       from "Components/Cart/Payment/PaymentContent";
import PaymentDialog        from "Components/Cart/Payment/PaymentDialog";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Card                 from "Components/Utils/Common/Card";
import Title                from "Components/Utils/Title/Title";
import Button               from "Components/Utils/Form/Button";
import Alert                from "Components/Utils/Form/Alert";

// Styles
import "Styles/Components/Content/Cart.css";



/**
 * The Cart Container
 */
class CartContainer extends React.Component {
    // The Current State
    state = {
        success    : "",
        error      : "",
        orderID    : 0,
        order      : null,
        showDialog : "",
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchCart(this.props.elem.orderHash);
    }

    /**
     * Handles the Cart Submit
     * @param {String=} checkout
     * @param {Number=} orderID
     * @param {Object=} order
     * @returns {Void}
     */
    handleSubmit = (checkout, orderID, order) => {
        window.scrollTo(0, 0);
        if (checkout) {
            if (this.props.isApp) {
                this.setState({
                    showDialog : checkout,
                    loading    : false,
                });
            } else {
                window.location.href = checkout;
            }
        } else if (orderID && order) {
            this.setState({ success : "", error : "", orderID, order });
        }
    }

    /**
     * Opens the Alert
     * @param {String=} success
     * @param {String=} error
     * @returns {Void}
     */
    openAlert = (success = "", error = "") => {
        this.setState({ success, error });
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
    }

    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.setState({ showDialog : "" });
    }



    /**
     * Renders the Content
     * @returns {Object}
     */
    renderContent() {
        const { isAuthenticated, settings, loading, elem  } = this.props;
        const { amount, isProducts, isShipment, isPayment } = elem;
        const { success, error, orderID, order            } = this.state;

        // Show the Completed Cart
        if (orderID && isAuthenticated) {
            return <CartCompleted orderID={orderID} elem={order} />;
        }

        // Show the Loader
        if (loading || Utils.isEmpty(settings)) {
            return <Card className="loading" withBorder withSpacing>
                <h3>{NLS.get("GENERAL_LOADING")}</h3>
            </Card>;
        }

        // There is no Content
        if (amount === 0) {
            return <Card className="nothing nothing-center" withSpacing withBorder>
                <h3>{NLS.get("CART_NONE_AVAILABLE")}</h3>
                <Button
                    variant="primary"
                    url="PRODUCTS"
                    message="CART_BUY_MORE"
                />
            </Card>;
        }

        let showProducts = isProducts || (elem.hideShipment && elem.hidePayment && isAuthenticated);
        let showAccount  = !isProducts && !isAuthenticated
        let showShipment = ((isShipment && !elem.hideShipment) || (isPayment && elem.hidePayment)) && isAuthenticated;
        let showPayment  = isPayment && !elem.hidePayment && isAuthenticated;

        if (!showProducts && !showAccount && !showShipment && !showPayment) {
            showProducts = true;
        }

        // Show the Content
        return <>
            <div className="cart-alerts">
                <Alert variant="success" message={success} onClose={this.closeAlert} />
                <Alert variant="error"   message={error}   onClose={this.closeAlert} />
            </div>

            <div className="cart-container">
                {showProducts && <ProductContent
                    openAlert={this.openAlert}
                    closeAlert={this.closeAlert}
                    onSubmit={this.handleSubmit}
                />}
                {showAccount && <AccountContent
                    openAlert={this.openAlert}
                    closeAlert={this.closeAlert}
                    onSubmit={this.handleSubmit}
                />}
                {showShipment && <ShipmentContent
                    openAlert={this.openAlert}
                    closeAlert={this.closeAlert}
                    onSubmit={this.handleSubmit}
                />}
                {showPayment && <PaymentContent
                    openAlert={this.openAlert}
                    closeAlert={this.closeAlert}
                    onSubmit={this.handleSubmit}
                />}
            </div>
        </>;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { amount     } = this.props.elem;
        const { showDialog } = this.state;

        const message = amount > 0 ? NLS.pluralize("CART_PRODUCTS", amount) : "";

        return <>
            <Title message="CART_TITLE" submessage={message} />
            <Wrapper withSpacing>
                {this.renderContent()}
            </Wrapper>

            <PaymentDialog
                open={!!showDialog}
                checkout={showDialog}
                onClose={this.closeDialog}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCart       : PropTypes.func.isRequired,
        isApp           : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        loading         : PropTypes.bool.isRequired,
        elem            : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp           : state.core.isApp,
            settings        : state.core.settings,
            isAuthenticated : state.auth.isAuthenticated,
            loading         : state.cart.loading,
            elem            : state.cart.elem,
        };
    }
}

export default connect(CartContainer.mapStateToProps, {
    fetchCart,
})(CartContainer);
