import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";

// Components
import ClientTitle          from "Components/Utils/Title/ClientTitle";
import Content              from "Components/Utils/Common/Content";
import Form                 from "Components/Utils/Form/Form";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    fetchQueryCreate, createQuery,
} from "Actions/Client/QueryActions";



/**
 * The Query Create
 */
class QueryCreate extends React.Component {
    // The Initial Data
    initialData = {
        queryTypeID : "",
        subject     : "",
        message     : "",
        image       : "",
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
        error   : "",
    }



    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        if (!this.props.settings.queries_isActive) {
            Href.goto("ACCOUNT_DATA");
            return;
        }
        this.props.fetchQueryCreate();
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { createQuery   } = this.props;
        const { data, loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, errors : {}, error : "" });
        try {
            const queryID = await createQuery(data);
            this.setState({ loading : false });
            Href.goto("QUERIES_VIEW", queryID);
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ error : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, create, page                     } = this.props;
        const { data, loading : isDisabled, errors, error } = this.state;

        const backUrl  = Href.url("QUERIES_OPEN", page);
        const hasTypes = create.types.length > 0;

        return <>
            <ClientTitle message="QUERIES_CREATE" icon="query" />
            <Content loading={loading} hasContent withCard>
                <Form
                    className="query-create"
                    columns="3"
                    error={error}
                    onSubmit={this.handleSubmit}
                    onClose={this.closeAlert}
                >
                    {hasTypes && <TextField
                        type="select"
                        name="queryTypeID"
                        label="QUERIES_TYPE"
                        options={create.types}
                        value={data.queryTypeID}
                        error={errors.queryTypeID}
                        onChange={this.handleChange}
                        isRequired
                        withNone
                    />}
                    <TextField
                        className={!hasTypes ? "columns-triple" : "columns-double"}
                        name="subject"
                        label="QUERIES_SUBJECT"
                        value={data.subject}
                        error={errors.subject}
                        onChange={this.handleChange}
                        isRequired
                    />

                    <TextField
                        type="textarea"
                        name="message"
                        className="columns-triple"
                        label="GENERAL_MESSAGE"
                        value={data.message}
                        error={errors.message}
                        onChange={this.handleChange}
                        isRequired
                        isLarge
                        autoGrow
                    />
                    <TextField
                        type="file"
                        name="image"
                        className="columns-triple"
                        label="GENERAL_IMAGE"
                        error={errors.image}
                        onChange={this.handleChange}
                    />

                    <div className="columns-btn">
                        <Button
                            variant="primary"
                            message="GENERAL_SAVE"
                            isDisabled={isDisabled}
                            fullWidth
                        />
                        <Button
                            variant="cancel"
                            message="GENERAL_CANCEL"
                            href={backUrl}
                            fullWidth
                        />
                    </div>
                </Form>
            </Content>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchQueryCreate : PropTypes.func.isRequired,
        createQuery      : PropTypes.func.isRequired,
        settings         : PropTypes.object.isRequired,
        loading          : PropTypes.bool.isRequired,
        create           : PropTypes.object.isRequired,
        page             : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            loading  : state.query.loading,
            create   : state.query.create,
            page     : state.client.page,
        };
    }
}

export default connect(QueryCreate.mapStateToProps, {
    fetchQueryCreate, createQuery,
})(QueryCreate);
