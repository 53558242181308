import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import HeaderWrapper        from "Components/Header/HeaderWrapper";
import HeaderLogo           from "Components/Header/HeaderLogo";
import HeaderBar            from "Components/Header/Bar/HeaderBar";
import UserBar              from "Components/Header/Bar/UserBar";
import MenuBar              from "Components/Header/Bar/MenuBar";
import SubMenuBar           from "Components/Header/Bar/SubMenuBar";



/**
 * The Three Lines Header
 * Three lines with a Big logo on the Center
 */
class ThreeLinesHeader extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <HeaderWrapper design="three-lines">
            <HeaderBar showSuperior />
            <HeaderLogo />
            <div className="header-menu">
                <MenuBar />
                <UserBar />
            </div>
            <SubMenuBar />
        </HeaderWrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(ThreeLinesHeader.mapStateToProps)(ThreeLinesHeader);
