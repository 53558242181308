import React                from "react";

// Sections
import LoginPage            from "Components/Auth/Route/LoginPage";
import RegisterPage         from "Components/Auth/Route/RegisterPage";
import RecoverPage          from "Components/Auth/Route/RecoverPage";
import ResetPage            from "Components/Auth/Route/ResetPage";

// Components
import SwitchRoute          from "Components/Utils/Route/SwitchRoute";
import GuestRoute           from "Components/Utils/Route/GuestRoute";



/**
 * The Auth Router
 */
class AuthRouter extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <SwitchRoute>
            <GuestRoute url="LOGIN"    component={LoginPage}    exact />
            <GuestRoute url="REGISTER" component={RegisterPage} exact />
            <GuestRoute url="RECOVER"  component={RecoverPage}  exact />
            <GuestRoute url="RESET"    component={ResetPage}    exact />
            <GuestRoute url="CODE"     component={ResetPage}    exact />
        </SwitchRoute>;
    }
}

export default AuthRouter;
