export const CORE_CONFIG            = "CORE_CONFIG";
export const CORE_PLATFORM          = "CORE_PLATFORM";
export const CORE_DATA              = "CORE_DATA";
export const CORE_PATH              = "CORE_PATH";
export const CORE_RESPONSIVE        = "CORE_RESPONSIVE";
export const CORE_LOADING           = "CORE_LOADING";
export const CORE_REDIRECT          = "CORE_REDIRECT";
export const CORE_RESULT            = "CORE_RESULT";

export const AUTH_CURRENT_USER      = "AUTH_CURRENT_USER";
export const AUTH_DATA              = "AUTH_DATA";
export const AUTH_FOCUSED           = "AUTH_FOCUSED";
export const AUTH_RESET             = "AUTH_RESET";
export const AUTH_REGISTER          = "AUTH_REGISTER";
export const AUTH_LOGOUT            = "AUTH_LOGOUT";

export const STORE_DATA             = "STORE_DATA";
export const STORE_UPDATES          = "STORE_UPDATES";
export const STORE_LAST_UPDATE      = "STORE_LAST_UPDATE";
export const STORE_MENU             = "STORE_MENU";
export const STORE_SUBMENU          = "STORE_SUBMENU";
export const STORE_NAVIGATION       = "STORE_NAVIGATION";
export const STORE_SEARCH           = "STORE_SEARCH";
export const STORE_FILTER           = "STORE_FILTER";
export const STORE_PREFERENCE       = "STORE_PREFERENCE";
export const STORE_FAVORITE         = "STORE_FAVORITE";
export const STORE_HISTORY          = "STORE_HISTORY";



// Store Types
export const PRODUCT_LOADING        = "PRODUCT_LOADING";
export const PRODUCT_LIST           = "PRODUCT_LIST";
export const PRODUCT_OFFER          = "PRODUCT_OFFER";
export const PRODUCT_BRAND          = "PRODUCT_BRAND";
export const PRODUCT_ARTIST         = "PRODUCT_ARTIST";
export const PRODUCT_SEARCH         = "PRODUCT_SEARCH";
export const PRODUCT_IMPORTANTS     = "PRODUCT_IMPORTANTS";
export const PRODUCT_NEWESTS        = "PRODUCT_NEWESTS";
export const PRODUCT_FAVORITES      = "PRODUCT_FAVORITES";
export const PRODUCT_PURCHASES      = "PRODUCT_PURCHASES";
export const PRODUCT_HISTORY        = "PRODUCT_HISTORY";
export const PRODUCT_ELEM           = "PRODUCT_ELEM";
export const PRODUCT_DIALOG         = "PRODUCT_DIALOG";
export const PRODUCT_PREVIEW        = "PRODUCT_PREVIEW";
export const PRODUCT_ROOM           = "PRODUCT_ROOM";
export const PRODUCT_ADDED          = "PRODUCT_ADDED";

export const CART_LOADING           = "CART_LOADING";
export const CART_ELEM              = "CART_ELEM";
export const CART_ASSIGN            = "CART_ASSIGN";
export const CART_REDIRECT          = "CART_REDIRECT";
export const CART_SHIPMENT          = "CART_SHIPMENT";
export const CART_PRICES            = "CART_PRICES";
export const CART_PAYMENT           = "CART_PAYMENT";
export const CART_COMPLETE          = "CART_COMPLETE";



// Content Types
export const CONTENT_LOADING        = "CONTENT_LOADING";
export const CONTENT_HOME           = "CONTENT_HOME";
export const CONTENT_PAGE           = "CONTENT_PAGE";
export const CONTENT_ENTRY          = "CONTENT_ENTRY";
export const CONTENT_ARTIST         = "CONTENT_ARTIST";


export const CHAT_ELEM              = "CHAT_ELEM";
export const CHAT_MESSAGES          = "CHAT_MESSAGES";
export const CHAT_NEW_MESSAGES      = "CHAT_NEW_MESSAGES";
export const CHAT_VIEWING           = "CHAT_VIEWING";

export const NOTIFICATION_DISCARDED = "NOTIFICATION_DISCARDED";
export const NOTIFICATION_REDIRECT  = "NOTIFICATION_REDIRECT";



// Client Types
export const CLIENT_ACCOUNT         = "CLIENT_ACCOUNT";
export const CLIENT_PAGE            = "CLIENT_PAGE";

export const ADDRESS_LOADING        = "ADDRESS_LOADING";
export const ADDRESS_UNSET          = "ADDRESS_UNSET";
export const ADDRESS_LIST           = "ADDRESS_LIST";
export const ADDRESS_ELEM           = "ADDRESS_ELEM";
export const ADDRESS_EDITED         = "ADDRESS_EDITED";
export const ADDRESS_DELETED        = "ADDRESS_DELETED";
export const ADDRESS_ACK            = "ADDRESS_ACK";

export const VOUCHER_LOADING        = "VOUCHER_LOADING";
export const VOUCHER_INVOICES       = "VOUCHER_INVOICES";
export const VOUCHER_RECEIPTS       = "VOUCHER_RECEIPTS";
export const VOUCHER_CREDIT_NOTES   = "VOUCHER_CREDIT_NOTES";
export const VOUCHER_DEBIT_NOTES    = "VOUCHER_DEBIT_NOTES";

export const QUERY_LOADING          = "QUERY_LOADING";
export const QUERY_LIST             = "QUERY_LIST";
export const QUERY_ELEM             = "QUERY_ELEM";
export const QUERY_CREATE           = "QUERY_CREATE";
export const QUERY_CREATED          = "QUERY_CREATED";
export const QUERY_ACK              = "QUERY_ACK";
export const QUERY_CONTACT          = "QUERY_CONTACT";

export const ORDER_LOADING          = "ORDER_LOADING";
export const ORDER_LIST             = "ORDER_LIST";
export const ORDER_ELEM             = "ORDER_ELEM";
