import React                from "react";
import PropTypes            from "prop-types";
import Href                 from "Utils/Core/Href";

// Components
import Slider               from "Components/Utils/Common/Slider";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Html                 from "Components/Utils/Common/Html";
import Button               from "Components/Utils/Form/Button";
import Image                from "Components/Utils/Media/Image";
import Video                from "Components/Utils/Media/Video";

// Styles
import "Styles/Components/Utils/Media/MediaSlider.css";



/**
 * The Media Slider
 */
class MediaSlider extends React.Component {
    // The Current State
    state = {
        curIdx : 0,
    };

    /**
     * Set the Initial State
     * @returns {Void}
     */
    componentDidMount() {
        this.setState({ curIdx : this.props.index || 0 });
    }

    /**
     * Handles the Slider Switch
     * @param {Number} curIdx
     * @return {Void}
     */
    handleSwitch = (curIdx) => {
        this.setState({ curIdx });
        if (this.props.onSwitch) {
            this.props.onSwitch(curIdx);
        }
    }

    /**
     * Handles the Click
     * @param {Object} curIdx
     * @returns {Void}
     */
    handleClick = (curIdx) => {
        const { withZoom, withClick, onClick } = this.props;
        const elem = this.props.data[curIdx];
        if (elem && !withZoom && withClick) {
            if (elem.url) {
                Href.goto(elem.url);
            }
            if (onClick) {
                onClick(elem);
            }
        }
    }

    /**
     * Handles the Zoom
     * @param {Boolean} zooming
     * @param {Object}  zoomBounds
     * @returns {Void}
     */
    handleZoom = (zooming, zoomBounds) => {
        this.setState({ zooming, zoomBounds });
    }



    /**
     * Renders an Element
     * @param {Object} elem
     * @returns {Object}
     */
    renderElem(elem) {
        const { variant, withZoom, showVideo, onPreview } = this.props;

        if (elem.isVideo) {
            return <Video
                className="media-slider-video"
                source={elem[variant]}
                title={elem.name}
                width="1900"
                height="800"
            />;
        }
        if (elem.video && showVideo) {
            return <Video
                className="media-slider-embed"
                source={elem.video}
                title={elem.name}
                isEmbed
            />;
        }
        return <Image
            source={elem[variant]}
            zoomed={elem.large}
            name={elem.name}
            withVideo={!!elem.video}
            onPreview={onPreview}
            onZoom={this.handleZoom}
            withZoom={withZoom}
            hideZoomed
        />;
    }

    /**
     * Renders the Zoom
     * @param {Object} elem
     * @returns {Object}
     */
    renderZoom(source, name) {
        const { zooming, zoomBounds } = this.state;
        if (zooming) {
            const style    = { left      : `${zoomBounds.left}px` };
            const imgStyle = { transform : `translate(-${zoomBounds.x}px, -${zoomBounds.y}px)` };
            return <div className="image-zoomed" style={style}>
                <img src={source} alt={name} style={imgStyle} />
            </div>;
        }
    }

    /**
     * Renders the Slide Content
     * @param {Object} elem
     * @returns {Object}
     */
    renderContent(elem) {
        if (!elem.text && !elem.button) {
            return <React.Fragment />;
        }
        return <div className="media-slider-content">
            <Wrapper>
                {!!elem.text && <Html content={elem.text} />}
                {!!elem.button && <Button
                    variant="primary"
                    message={elem.button}
                    href={elem.url}
                />}
            </Wrapper>
        </div>;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            data, style, index, className,
            useFade, autoSlide, smallNav, withDots, withClick, centerImage,
        } = this.props;
        const { curIdx } = this.state;

        if (!data || !data.length) {
            return <React.Fragment />;
        }
        const current = data ? data[curIdx] : {};
        const total   = data ? data.length  : 0;

        return <>
            <Slider
                className={"media-slider " + className + (centerImage ? " media-slider-centered" : "")}
                total={total}
                index={index}
                useFade={useFade}
                autoSlide={autoSlide}
                smallNav={smallNav}
                withDots={withDots}
                style={style}
                onSwitch={this.handleSwitch}
                onClick={this.handleClick}
                wrapAtEnd
            >
                {data.map((elem, index) => <div
                    key={index}
                    className={"media-slider-slide" + (withClick && !!elem.url ? " media-slider-click" : "")}
                    onClick={() => this.handleClick(index)}
                >
                    {this.renderElem(elem)}
                    {this.renderContent(elem)}
                </div>)}
            </Slider>
            {this.renderZoom(current.large, current.name)}
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data        : PropTypes.array.isRequired,
        variant     : PropTypes.string,
        index       : PropTypes.number,
        onClick     : PropTypes.func,
        onSwitch    : PropTypes.func,
        onPreview   : PropTypes.func,
        className   : PropTypes.string,
        useFade     : PropTypes.bool,
        autoSlide   : PropTypes.bool,
        smallNav    : PropTypes.bool,
        withDots    : PropTypes.bool,
        withClick   : PropTypes.bool,
        withZoom    : PropTypes.bool,
        centerImage : PropTypes.bool,
        showVideo   : PropTypes.bool,
        style       : PropTypes.object,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        variant     : "image",
        useFade     : false,
        autoSlide   : false,
        smallNav    : false,
        withDots    : false,
        withClick   : false,
        withZoom    : false,
        centerImage : false,
        showVideo   : false,
        style       : {},
    }
}

export default MediaSlider;
