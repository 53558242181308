import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleFilter }     from "Actions/Store/StoreActions";
import NLS                  from "Utils/Core/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";



/**
 * The Products Filter List
 */
class FilterList extends React.Component {
    // The Current State
    state = {
        showAll : false,
    }

    /**
     * Returns true if it should show the Filter
     * @returns {Void}
     */
    toggleAll = () => {
        this.setState({ showAll : !this.state.showAll });
    }

    /**
     * Returns true if it should show the Filter
     * @returns {Boolean}
     */
    shouldShow() {
        const { settings, data, type, hasParent } = this.props;
        if (!data || !data.length) {
            return false;
        }
        switch (type) {
        case "category":
            return Boolean(settings.products_showCategoryFilter && settings.categories_isActive);
        case "subcategory":
            return Boolean(settings.products_showCategoryFilter && settings.categories_hasSubcategories && hasParent);
        case "tercategory":
            return Boolean(settings.products_showCategoryFilter && settings.categories_hasTercategories && hasParent);
        case "brand":
            return settings.products_showBrandFilter;
        case "artist":
            return settings.products_showArtistFilter;
        case "field":
            return true;
        default:
        }
        return false;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, data, type, message, withAll, isSelected, getUrl, toggleFilter } = this.props;
        const { showAll                                                                  } = this.state;

        if (!this.shouldShow()) {
            return <React.Fragment />;
        }
        const showMore   = settings.products_filtersAmount > 0 && data.length > settings.products_filtersAmount;
        const shouldHide = showMore && !showAll;

        return <div className="aside-options">
            <h4>{NLS.get(message)}</h4>
            <ul className="no-list">
                {withAll && <li>
                    <HyperLink
                        variant="opacity"
                        href={getUrl(type)}
                        onClick={() => toggleFilter(false)}
                        icon={isSelected(type) ? "check" : "blank"}
                        message="GENERAL_ALL_FEM"
                    />
                </li>}
                {data.map((elem, index) => <li
                    key={elem.id}
                    className={shouldHide && index > settings.products_filtersAmount ? "aside-hidden" : ""}
                >
                    <HyperLink
                        variant="opacity"
                        href={getUrl(type, elem)}
                        onClick={() => toggleFilter(false)}
                        icon={isSelected(type, elem.id) ? "check" : "blank"}
                    >
                        {elem.name}
                        {!!elem.total && <span className="aside-amount">{elem.total}</span>}
                    </HyperLink>
                </li>)}
                {showMore && <li>
                    <Button
                        variant="outlined"
                        onClick={this.toggleAll}
                        message={showAll ? "PRODUCTS_LESS_FILTERS" : "PRODUCTS_MORE_FILTERS"}
                        isSmall
                    />
                </li>}
            </ul>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleFilter : PropTypes.func.isRequired,
        settings     : PropTypes.object.isRequired,
        data         : PropTypes.array.isRequired,
        isSelected   : PropTypes.func.isRequired,
        getUrl       : PropTypes.func.isRequired,
        hasParent    : PropTypes.bool,
        withAll      : PropTypes.bool,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(FilterList.mapStateToProps, {
    toggleFilter,
})(FilterList);
