import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";
import Notifications        from "Utils/Common/Notifications";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Timer                from "Components/Utils/Common/Timer";
import Icon                 from "Components/Utils/Media/Icon";
import ProductImage         from "Components/Product/Item/ProductImage";

// Actions
import {
    discardNotification,
} from "Actions/Content/NotificationActions";

// Styles
import "Styles/Components/Core/Notification.css";



/**
 * The Notification Item
 */
class NotificationItem extends React.Component {
    // The Current State
    state = {
        touchElem  : null,
        touchIndex : 0,
        touchStart : 0,
        touchDiff  : 0,
    }

    /**
     * Goes to the Url
     * @param {String} href
     * @returns {Function}
     */
    handleClick = (href) => () => {
        const { onClose } = this.props;
        if (href) {
            Href.gotoUrl(href);
        }
        if (onClose) {
            onClose();
        }
    }

    /**
     * Handles the Notification Delete
     * @param {Event} e
     * @returns {Void}
     */
    handleDelete = (e) => {
        const { data, index, onDelete, discardNotification } = this.props;
        discardNotification(data.type, data.id, index);
        if (onDelete) {
            onDelete();
        }
        e.stopPropagation();
    }



    /**
     * Handles the Touch Start
     * @param {Event} e
     * @returns {Void}
     */
    handleTouchStart = (e) => {
        const { data, index } = this.props;
        this.setState({
            touchElem  : data,
            touchIndex : index,
            touchStart : e.touches[0].clientX,
        });
    }

    /**
     * Handles the Touch Move
     * @param {Event} e
     * @returns {Void}
     */
    handleTouchMove = (e) => {
        const currentX  = e.touches[0].clientX;
        const touchDiff = this.state.touchStart - currentX;
        this.setState({ touchDiff });
    }

    /**
     * Handles the Touch End
     * @returns {Void}
     */
    handleTouchEnd = () => {
        const { touchElem, touchIndex, touchDiff } = this.state;
        this.setState({ touchStart : 0, touchDiff : 0 });
        if (touchDiff > 75 || touchDiff < -75) {
            this.props.discardNotification(touchElem.type, touchElem.id, touchIndex);
        } else if (touchDiff >= 2 && touchDiff <= -2) {
            this.handleClick(touchElem.url)();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data        } = this.props;
        const { title, body } = Notifications.getData(data);
        const { touchDiff   } = this.state;

        const style = { "transform" : `translateX(${0 - touchDiff}px)` };
        let content = null;

        // Generates the Content
        switch (data.type) {
        case "lightning": {
            const time = data.totalDays > 3 ? NLS.pluralize("BANNER_DAYS", data.totalDays) : NLS.pluralize("BANNER_HOURS", data.totalHours);
            content = <div className="notification-lightning">
                <h3>{body}</h3>
                <div className="notification-lightning-content">
                    <div className="notification-lightning-header">
                        <div className="notification-lightning-title">
                            <h4>{NLS.get("BANNER_ONLY")}</h4><br />
                            <h5>{time}</h5>
                        </div>
                        <Timer
                            className="notification-lightning-timer"
                            startTime={data.startTime}
                            endTime={data.endTime}
                            useShort
                        />
                    </div>
                    <ProductImage
                        className="notification-lightning-image"
                        variant="small"
                        data={data}
                    />
                </div>
            </div>;
            break;
        }

        case "promotion":
            content = <div className="notification-image">
                <h3>{body}</h3>
                <img src={data.image} alt={body} />
            </div>;
            break;

        default:
            content = <div className="notification-alert">
                <Icon variant={data.icon} />
                <div>
                    <h3>{title}</h3>
                    <p>{body}</p>
                </div>
            </div>;
        }


        // Returns the Notification
        return <div className="notification-wrapper" onClick={this.handleClick(data.url)}>
            <div
                className="notification"
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
                onTouchEnd={this.handleTouchEnd}
                style={style}
            >
                <HyperLink
                    variant="secondary"
                    className="notification-delete"
                    message="GENERAL_DELETE"
                    onClick={this.handleDelete}
                />
                {content}
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        discardNotification : PropTypes.func.isRequired,
        onDelete            : PropTypes.func,
        onClose             : PropTypes.func,
        data                : PropTypes.object.isRequired,
        index               : PropTypes.number.isRequired,
    }
}

export default connect(null, {
    discardNotification,
})(NotificationItem);
