import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchPage }        from "Actions/Content/ContentActions";
import NLS                  from "Utils/Core/NLS";
import Pages                from "Utils/App/Pages";

// Components
import Sections             from "Components/Content/Sections/Sections";
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Loading              from "Components/Utils/Common/Loading";

// Styles
import "Styles/Components/Auth/Terms.css";



/**
 * The Guest Terms Page
 */
class TermsGuest extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const page = Pages.get(this.props.pages, Pages.TERMS);
        if (page) {
            this.props.fetchPage(page.url.substring(1));
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, pages, loading, elem, match } = this.props;

        const hasTerms   = Pages.has(pages, Pages.TERMS);
        const hasContent = hasTerms && !loading;

        return <div className="terms-container">
            <aside className="terms-aside">
                <h1>{settings.header_logo ? <img src={settings.header_logo} alt={settings.siteName} /> : settings.siteName}</h1>
            </aside>
            <main>
                <header className="terms-header">
                    <div className="terms-title">
                        <HyperLink variant="icon" icon="back" url="LOGIN" />
                        <h2>{NLS.get(elem.name || "AUTH_TERMS")}</h2>
                    </div>
                </header>
                <Card className="terms-card" withSpacing withBorder>
                    {!hasTerms && NLS.get("AUTH_TERMS")}
                    {loading && <Loading />}
                    {hasContent && <Sections sections={elem.sections} match={match} />}
                </Card>
            </main>
        </div>;
    }


    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchPage : PropTypes.func.isRequired,
        settings  : PropTypes.object.isRequired,
        pages     : PropTypes.object.isRequired,
        loading   : PropTypes.bool.isRequired,
        elem      : PropTypes.object.isRequired,
        match     : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            pages    : state.core.pages,
            loading  : state.content.loading,
            elem     : state.content.page,
        };
    }
}

export default connect(TermsGuest.mapStateToProps, {
    fetchPage,
})(TermsGuest);
