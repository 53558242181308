import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import MobileHeader         from "Components/Header/Design/MobileHeader";
import SimpleHeader         from "Components/Header/Design/SimpleHeader";
import BigLogoHeader        from "Components/Header/Design/BigLogoHeader";
import CenterLogoHeader     from "Components/Header/Design/CenterLogoHeader";
import CenterMenuHeader     from "Components/Header/Design/CenterMenuHeader";
import CenterAllHeader      from "Components/Header/Design/CenterAllHeader";
import CenterBigHeader      from "Components/Header/Design/CenterBigHeader";
import OneLineHeader        from "Components/Header/Design/OneLineHeader";
import OneMenuHeader        from "Components/Header/Design/OneMenuHeader";
import OneCenterHeader      from "Components/Header/Design/OneCenterHeader";
import ThreeLinesHeader     from "Components/Header/Design/ThreeLinesHeader";

// Styles
import "Styles/Components/Core/Header.css";



/**
 * The Header
 */
class Header extends React.Component {
    /**
     * Set the Header height
     * @returns {Void}
     */
    componentDidMount() {
        const header = document.querySelector(".header-container");
        if (header) {
            const bounds = header.getBoundingClientRect();
            document.body.style.setProperty("--header-height", `${bounds.height}px`);
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { responsive, settings } = this.props;

        if (responsive.isLaptop) {
            return <MobileHeader />;
        }
        switch (settings.header_design) {
        case "bigLogo":
            return <BigLogoHeader />;
        case "centerLogo":
            return <CenterLogoHeader />;
        case "centerMenu":
            return <CenterMenuHeader />;
        case "centerAll":
            return <CenterAllHeader />;
        case "centerBig":
            return <CenterBigHeader />;
        case "oneLine":
            return <OneLineHeader />;
        case "oneMenu":
            return <OneMenuHeader />;
        case "oneCenter":
            return <OneCenterHeader />;
        case "threeLines":
            return <ThreeLinesHeader />;
        default:
            return <SimpleHeader />;
        }
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        responsive : PropTypes.object.isRequired,
        settings   : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
            settings   : state.core.settings,
        };
    }
}

export default connect(Header.mapStateToProps)(Header);
