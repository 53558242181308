import { combineReducers }      from "redux";

import CoreReducer              from "./Core/CoreReducer";
import AuthReducer              from "./Core/AuthReducer";

import StoreReducer             from "./Store/StoreReducer";
import ProductReducer           from "./Store/ProductReducer";
import CartReducer              from "./Store/CartReducer";

import ContentReducer           from "./Content/ContentReducer";
import ChatReducer              from "./Content/ChatReducer";
import NotificationReducer      from "./Content/NotificationReducer";

import ClientReducer            from "./Client/ClientReducer";
import AddressReducer           from "./Client/AddressReducer";
import VoucherReducer           from "./Client/VoucherReducer";
import QueryReducer             from "./Client/QueryReducer";
import OrderReducer             from "./Client/OrderReducer";



// The Combined Reducers
export default combineReducers({
    core         : CoreReducer,
    auth         : AuthReducer,

    store        : StoreReducer,
    product      : ProductReducer,
    cart         : CartReducer,

    content      : ContentReducer,
    chat         : ChatReducer,
    notification : NotificationReducer,

    client       : ClientReducer,
    address      : AddressReducer,
    voucher      : VoucherReducer,
    query        : QueryReducer,
    order        : OrderReducer,
});
