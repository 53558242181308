import DateTime             from "Utils/Common/DateTime";
import Utils                from "Utils/Common/Utils";

// Types
import {
    QUERY_LOADING, QUERY_LIST, QUERY_ELEM,
    QUERY_CREATE, QUERY_CREATED, QUERY_ACK,
    QUERY_CONTACT,
} from "Utils/Core/Types";



// The initial State
const initialState = {
    loading  : true,
    error    : false,
    list     : [],
    total    : 0,
    hasTypes : true,
    elem     : {
        queryID : 0,
        replies : [],
    },
    create   : {
        types : [],
    },
    created  : {
        queryID : 0,
        success : "",
    },
    contact  : {},
};

/**
 * Parses the List
 * @param {Array.<Object>} list
 * @returns {Array.<Object>}
 */
function parseList(list) {
    for (const elem of list) {
        parseElem(elem);
    }
    return list;
}

/**
 * Parses the Elem
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.lastReplyDate = DateTime.formatDate(elem.lastReply, "dashesTime");
    for (const reply of elem.replies || []) {
        reply.createdDate = DateTime.formatDate(reply.createdTime, "dashesTime");
    }
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, QUERY_LIST, QUERY_ELEM, QUERY_CREATE, QUERY_CREATED)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case QUERY_LOADING:
        return {
            ...state,
            loading  : true,
            error    : false,
        };

    case QUERY_LIST:
        return {
            ...state,
            loading  : false,
            error    : false,
            list     : parseList(action.data.list),
            total    : action.data.total,
            hasTypes : action.data.hasTypes,
        };
    case QUERY_ELEM:
        return {
            ...state,
            loading  : false,
            error    : false,
            elem     : parseElem(action.data),
        };
    case QUERY_CREATE:
        return {
            ...state,
            loading  : false,
            error    : false,
            create   : action.data,
        };

    case QUERY_CREATED:
        return {
            ...state,
            created   : action.data,
        };
    case QUERY_ACK:
        return {
            ...state,
            created   : { ...initialState.created },
        };

    case QUERY_CONTACT:
        return {
            ...state,
            contact   : action.data || {},
        };

    default:
        return state;
    }
};
