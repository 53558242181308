import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchAuth }        from "Actions/Core/AuthActions";
import NLS                  from "Utils/Core/NLS";
import Pages                from "Utils/App/Pages";
import ClassList            from "Utils/Common/ClassList";
import Utils                from "Utils/Common/Utils";

// Components
import AuthHeader           from "Components/Auth/Utils/AuthHeader";
import AuthWrapper          from "Components/Auth/Utils/AuthWrapper";
import AuthTitle            from "Components/Auth/Utils/AuthTitle";
import AuthRouter           from "Components/Auth/Utils/AuthRouter";
import AuthAside            from "Components/Auth/Utils/AuthAside";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Auth/Auth.css";



/**
 * The Auth Container
 */
class AuthContainer extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        if (!this.props.loaded) {
            this.props.fetchAuth();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { responsive, settings, pages, background, isFocused } = this.props;

        const data    = Utils.getLoginData(responsive, settings, background);
        const terms   = Pages.get(pages, Pages.TERMS);

        const classes = new ClassList("auth-container");
        classes.addIf("auth-closed",   !settings.store_isOpen);
        classes.addIf("auth-focused",  isFocused);
        classes.addIf("auth-centered", settings.auth_centerLogo);

        return <section className={classes.get()} style={data.style}>
            <div className="auth-content">
                <AuthHeader data={data} />
                <AuthWrapper>
                    <AuthTitle variant="auth" />
                    <AuthRouter />
                </AuthWrapper>
                {!!terms && <footer className="auth-terms">
                    {NLS.format("AUTH_TERMS_TEXT1A", data.name)}
                    <HyperLink
                        className="auth-terms-anchor"
                        variant="black"
                        url={terms.url}
                        message="AUTH_TERMS_TEXT2"
                    />
                    {NLS.get("AUTH_TERMS_TEXT3")}
                </footer>}
            </div>
            <AuthAside data={data.bg} />
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAuth  : PropTypes.func.isRequired,
        responsive : PropTypes.object.isRequired,
        settings   : PropTypes.object.isRequired,
        pages      : PropTypes.object.isRequired,
        loaded     : PropTypes.bool.isRequired,
        background : PropTypes.object.isRequired,
        isFocused  : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
            settings   : state.core.settings,
            pages      : state.core.pages,
            loaded     : state.auth.loaded,
            background : state.auth.background,
            isFocused  : state.auth.isFocused,
        };
    }
}

export default connect(AuthContainer.mapStateToProps, {
    fetchAuth,
})(AuthContainer);
