import NLS                  from "Utils/Core/NLS";

// Constants
const POSITION      = 1;
const NAME_AZ       = 2;
const NAME_ZA       = 3;
const CODE_AZ       = 4;
const CODE_ZA       = 5;
const LOWER_PRICE   = 6;
const GREATER_PRICE = 7;
const NEWER_PRODUCT = 8;
const OLDER_PRODUCT = 9;
const BEST_SOLD     = 10;
const TRENDING      = 11;



/**
 * Returns the Sort Options
 * @param {Object} settings
 * @returns {Array.<Object>}
 */
function getOptions(settings) {
    const result = [];

    // By Position
    if (settings.products_hasPositions) {
        result.push({ key : POSITION, value : NLS.get("PRODUCTS_SORT_POSITION") });
    }

    // By Name
    result.push({ key : NAME_AZ, value : NLS.get("PRODUCTS_SORT_NAME_AZ") });
    result.push({ key : NAME_ZA, value : NLS.get("PRODUCTS_SORT_NAME_ZA") });

    // By Code
    if (settings.products_hasCodes) {
        result.push({ key : CODE_AZ, value : NLS.get("PRODUCTS_SORT_CODE_AZ") });
        result.push({ key : CODE_ZA, value : NLS.get("PRODUCTS_SORT_CODE_ZA") });
    }

    // By Price
    result.push({ key : LOWER_PRICE,   value : NLS.get("PRODUCTS_SORT_LOWER_PRICE")   });
    result.push({ key : GREATER_PRICE, value : NLS.get("PRODUCTS_SORT_GREATER_PRICE") });

    // By Creation
    result.push({ key : NEWER_PRODUCT, value : NLS.get("PRODUCTS_SORT_NEWER_PRODUCT") });
    result.push({ key : OLDER_PRODUCT, value : NLS.get("PRODUCTS_SORT_OLDER_PRODUCT") });

    // By Purchases
    if (settings.products_hasPurchases) {
        result.push({ key : BEST_SOLD, value : NLS.get("PRODUCTS_SORT_BEST_SOLD") });
    }

    // By Views
    result.push({ key : TRENDING, value : NLS.get("PRODUCTS_SORT_TRENDING") });

    return result;
}




// The Public API
export default {
    POSITION,
    NAME_AZ,
    NAME_ZA,
    CODE_AZ,
    CODE_ZA,
    LOWER_PRICE,
    GREATER_PRICE,
    NEWER_PRODUCT,
    OLDER_PRODUCT,
    TRENDING,
    BEST_SOLD,

    getOptions,
};
