import React                from "react";
import PropTypes            from "prop-types";
import ClassList            from "Utils/Common/ClassList";

// Styles
import "Styles/Components/Utils/Common/Wrapper.css";



/**
 * The Wrapper
 */
class Wrapper extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, withSpacing, centered, fullWidth, maxWidth, style, children } = this.props;

        const classes = new ClassList("wrapper", className);
        classes.addIf("wrapper-fit",      !fullWidth);
        classes.addIf("wrapper-spacing",  withSpacing);
        classes.addIf("wrapper-centered", centered);

        const styles = style || {};
        if (!fullWidth && maxWidth) {
            styles.maxWidth = `${maxWidth}px`;
        }

        return <section className={classes.get()} style={styles}>
            {children}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className   : PropTypes.string,
        withSpacing : PropTypes.bool,
        centered    : PropTypes.bool,
        fullWidth   : PropTypes.bool,
        maxWidth    : PropTypes.number,
        style       : PropTypes.object,
        children    : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        withSpacing : false,
        centered    : false,
        fullWidth   : false,
        maxWidth    : 0,
    }
}

export default Wrapper;
