import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import ClassList            from "Utils/Common/ClassList";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";

// Styles
import "Styles/Components/Utils/Title/Title.css";



/**
 * The Title Wrapper
 */
class TitleWrapper extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            settings, className, background,
            overlay, height, noBotomSpace, children,
        } = this.props;

        const style = {};
        if (background) {
            style.backgroundImage = `url("${background}")`;
        }
        if (height) {
            style.height = `${height}px`;
        }

        const classes = new ClassList("title-container", className);
        classes.addIf("title-space",      !noBotomSpace && settings.title_bottomSpace);
        classes.addIf("title-shadows",    settings.title_addShadows);
        classes.addIf("title-center",     settings.title_centerText);
        classes.addIf("title-uppercase",  settings.title_inUppercase);
        classes.addIf("title-with-image", !!background);

        return <header className={classes.get()} style={style}>
            {overlay && <div className="title-overlay" />}
            <Wrapper className="title-wrapper">
                {children}
            </Wrapper>
        </header>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings     : PropTypes.object.isRequired,
        className    : PropTypes.string,
        background   : PropTypes.string,
        height       : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        noBotomSpace : PropTypes.bool,
        overlay      : PropTypes.bool,
        children     : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className    : "",
        noBotomSpace : false,
        overlay      : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(TitleWrapper.mapStateToProps)(TitleWrapper);
