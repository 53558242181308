import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/Core/NLS";
import ClassList            from "Utils/Common/ClassList";

// Components
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Utils/Title/TerTitle.css";



/**
 * The TerTitle
 */
class TerTitle extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            settings, isHidden, className, message,
            showButton, button, href, url, onClick,
        } = this.props;

        if (isHidden || !message) {
            return <React.Fragment />;
        }
        const hasButton = Boolean(showButton && (href || url || onClick));
        const addSpace  = hasButton && settings.sections_centerTitles;

        const classes = new ClassList("tertitle", className);
        classes.addIf("tertitle-buttoned",  hasButton);
        classes.addIf("tertitle-centered",  settings.sections_centerTitles);
        classes.addIf("tertitle-uppercase", settings.sections_uppercaseTitles);

        return <header className={classes.get()}>
            {addSpace && <div />}
            <h2>{NLS.get(message)}</h2>
            {hasButton && <Button
                variant="primary"
                message={button}
                href={href}
                url={url}
                onClick={onClick}
            />}
        </header>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings   : PropTypes.object.isRequired,
        isHidden   : PropTypes.bool,
        className  : PropTypes.string,
        message    : PropTypes.string,
        button     : PropTypes.string,
        href       : PropTypes.string,
        url        : PropTypes.string,
        onClick    : PropTypes.func,
        showButton : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        isHidden   : false,
        className  : "",
        message    : "",
        showButton : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(TerTitle.mapStateToProps)(TerTitle);
