// Actions
import {
    toggleMenu, openSubmenu
} from "Actions/Store/StoreActions";

// Module variables
let store        = null;
let openTimeout  = null;
let closeTimeout = null;



/**
 * Initialize the API
 * @param {Object} theStore
 * @returns {Void}
 */
function init(theStore) {
    store = theStore;
}



/**
 * Shows a Submenu
 * @param {String}  submenu
 * @param {Number=} submenuID
 * @param {String=} element
 * @param {String=} content
 * @param {String=} href
 * @returns {Function}
 */
function show(submenu, submenuID = 0, element = "", content = "", href = "") {
    if (submenu && !openTimeout) {
        clearTimeouts();
        openTimeout = window.setTimeout(() => open(submenu, submenuID, element, content, href), 200);
    }
}

/**
 * Open a Submenu
 * @param {String}  submenu
 * @param {Number=} submenuID
 * @param {String=} element
 * @param {String=} content
 * @param {String=} href
 * @returns {Function}
 */
function open(submenu, submenuID = 0, element = "", content = "", href = "") {
    openTimeout = null;

    let submenuTop  = 0;
    let submenuLeft = 0;
    if (element && content) {
        const subNode    = document.querySelector(".submenu-container");
        const cntNode    = document.querySelector(`.${content}`);
        const elemNode   = document.querySelector(`.${element}`);
        const subBounds  = subNode.getBoundingClientRect();
        const cntBounds  = cntNode.getBoundingClientRect();
        const nodeBounds = elemNode.getBoundingClientRect();
        submenuTop  = nodeBounds.bottom - subBounds.top + 8;
        submenuLeft = cntBounds.left - subBounds.left;
    }
    store.dispatch(openSubmenu(submenu, submenuID, submenuTop, submenuLeft, href));
}

/**
 * Hides the Submenu
 * @returns {Function}
 */
function hide() {
    clearTimeouts();
    closeTimeout = window.setTimeout(close, 500);
}

/**
 * Closes the Submenu
 * @returns {Void}
 */
function close() {
    clearTimeouts();
    store.dispatch(toggleMenu(false));
    store.dispatch(openSubmenu());
}

/**
 * Enters the Submenu
 * @returns {Void}
 */
function enter() {
    clearTimeouts();
}

/**
 * Clears the Timeouts
 * @returns {Void}
 */
function clearTimeouts() {
    if (openTimeout) {
        window.clearTimeout(openTimeout);
        openTimeout = null;
    }
    if (closeTimeout) {
        window.clearTimeout(closeTimeout);
        closeTimeout = null;
    }
}




// The Public API
export default {
    init,
    show,
    hide,
    close,
    enter,
};
