import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleNav }        from "Actions/Store/StoreActions";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Menu Icon
 */
class MenuIcon extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { toggleNav, navOpen, position } = this.props;

        return <div className={`header-menu-${position}`}>
            <HyperLink
                className="header-icon"
                variant="icon"
                onClick={() => toggleNav(!navOpen)}
                icon={navOpen ? "close" : "menu"}
            />
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleNav : PropTypes.func.isRequired,
        navOpen   : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            navOpen : state.store.navOpen,
        };
    }
}

export default connect(MenuIcon.mapStateToProps, {
    toggleNav,
})(MenuIcon);
