import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setContact }       from "Actions/Client/QueryActions";
import NLS                  from "Utils/Core/NLS";
import QueryOrigin          from "Utils/App/QueryOrigin";

// Components
import ContactForm          from "Components/Content/Contact/ContactForm";
import Dialog               from "Components/Utils/Dialog/Dialog";
import DialogContent        from "Components/Utils/Dialog/DialogContent";
import Card                 from "Components/Utils/Common/Card";



/**
 * The Contact Dialog
 */
class ContactDialog extends React.Component {
    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.props.setContact(null);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { contact } = this.props;

        const open = Boolean(contact.origin);
        const { icon, title, message } = QueryOrigin.getData(contact.origin);

        return <Dialog
            open={open}
            icon={icon}
            message={title}
            onClose={this.closeDialog}
        >
            <DialogContent>
                <Card withSpacing noBottomSpace>
                    {NLS.format(message, contact.content)}
                </Card>
                <ContactForm
                    className="spacing"
                    origin={contact.origin}
                    orderID={contact.orderID}
                    productID={contact.productID}
                />
            </DialogContent>
        </Dialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setContact : PropTypes.func.isRequired,
        settings   : PropTypes.object.isRequired,
        contact    : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            contact  : state.query.contact,
        };
    }
}

export default connect(ContactDialog.mapStateToProps, {
    setContact,
})(ContactDialog);
