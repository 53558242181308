import API                  from "Utils/Core/API";

// Types
import {
    CART_LOADING, CART_ELEM, CART_ASSIGN, CART_COMPLETE,
    CART_REDIRECT, CART_SHIPMENT, CART_PRICES, CART_PAYMENT,
} from "Utils/Core/Types";



/**
 * Fetches the Cart
 * @param {String} orderHash
 * @returns {Function}
 */
export function fetchCart(orderHash) {
    return async (dispatch) => {
        dispatch({ type : CART_LOADING });
        const data = await API.Cart.getCart({ orderHash });
        dispatch({ type : CART_ELEM, data });
    };
}

/**
 * Assigns the Cart
 * @param {Number} clientID
 * @param {String} orderHash
 * @returns {Function}
 */
export function assignCart(clientID, orderHash) {
    return async (dispatch) => {
        dispatch({ type : CART_ASSIGN, data : { clientID, orderHash } });
    };
}



/**
 * Adds a Product to a Cart
 * @param {Object} params
 * @returns {Function}
 */
export function addProduct(params) {
    return async (dispatch) => {
        const data = await API.Cart.addProduct(params);
        dispatch({ type : CART_ELEM, data });
        return data.success;
    };
}

/**
 * Edits a Product amount in a Cart
 * @param {String} orderHash
 * @param {Number} productID
 * @param {Number} variantID
 * @param {Number} amount
 * @returns {Function}
 */
export function editProduct(orderHash, productID, variantID, amount) {
    return async (dispatch) => {
        const data = await API.Cart.editProduct({ orderHash, productID, variantID, amount });
        dispatch({ type : CART_ELEM, data });
        return data.success;
    };
}

/**
 * Removes a Product from a Cart
 * @param {String} orderHash
 * @param {Number} productID
 * @param {Number} variantID
 * @returns {Function}
 */
export function removeProduct(orderHash, productID, variantID) {
    return async (dispatch) => {
        const data = await API.Cart.removeProduct({ orderHash, productID, variantID });
        dispatch({ type : CART_ELEM, data });
        return data;
    };
}



/**
 * Sets the Cart Redirect
 * @param {String} data
 * @returns {Function}
 */
export function setCartRedirect(data) {
    return (dispatch) => dispatch({ type : CART_REDIRECT, data });
}

/**
 * Sets the Cart Shipment
 * @param {Number} shipmentMode
 * @param {Number} shipmentID
 * @param {Number} subsidiaryID
 * @param {Number} addressID
 * @returns {Function}
 */
export function setShipment(shipmentMode, shipmentID, subsidiaryID, addressID) {
    return async (dispatch) => {
        dispatch({
            type : CART_SHIPMENT,
            data : { shipmentMode, shipmentID, subsidiaryID, addressID },
        });
    };
}

/**
 * Gets the Shipment Price
 * @param {Number} shipmentMode
 * @param {Number} addressID
 * @returns {Function}
 */
export function priceShipment(shipmentMode, addressID) {
    return async (dispatch) => {
        const data = await API.Cart.priceShipment({ shipmentMode, addressID });
        dispatch({ type : CART_PRICES, data });
        return data;
    };
}

/**
 * Tryes a Shipment
 * @param {Number} shipmentID
 * @param {Number} addressID
 * @returns {Function}
 */
export function tryShipment(shipmentID, addressID) {
    return async (dispatch) => {
        const data = await API.Cart.tryShipment({ shipmentID, addressID });
        dispatch({ type : CART_ELEM, data });
    };
}

/**
 * Sets the Cart Payment
 * @param {Number} paymentType
 * @returns {Function}
 */
export function setPayment(paymentType) {
    return async (dispatch) => {
        dispatch({ type : CART_PAYMENT, paymentType });
    };
}

/**
 * Tryes a Payment
 * @param {String} paymentType
 * @returns {Function}
 */
export function tryPayment(paymentType) {
    return async (dispatch) => {
        const data = await API.Cart.tryPayment({ paymentType });
        dispatch({ type : CART_ELEM, data });
    };
}



/**
 * Confirms the Cart Products
 * @param {String} orderHash
 * @param {String} message
 * @returns {Function}
 */
export function confirmProducts(orderHash, message) {
    return async (dispatch) => {
        const data = await API.Cart.confirmProducts({ orderHash, message });
        if (data.order) {
            dispatch({ type : CART_COMPLETE, data : { amount : 0 } });
        } else {
            dispatch({ type : CART_ELEM, data });
        }
        return data;
    };
}

/**
 * Confirms the Cart Shipment
 * @param {Object} params
 * @returns {Function}
 */
export function confirmShipment(params) {
    return async (dispatch) => {
        const data = await API.Cart.confirmShipment(params);
        if (data.order) {
            dispatch({ type : CART_COMPLETE, data : { amount : 0 } });
        } else {
            dispatch({ type : CART_ELEM, data });
        }
        return data;
    };
}

/**
 * Confirms the Cart Payment
 * @param {Object} params
 * @returns {Function}
 */
export function confirmPayment(params) {
    return async (dispatch) => {
        const data = await API.Cart.confirmPayment(params);
        dispatch({ type : CART_COMPLETE, data : { amount : 0 } });
        return data;
    };
}



/**
 * Unconfirms the Cart Products
 * @param {String} orderHash
 * @returns {Function}
 */
export function unconfirmToProducts(orderHash) {
    return async (dispatch) => {
        const data = await API.Cart.unconfirmToProducts({ orderHash });
        dispatch({ type : CART_ELEM, data });
    };
}

/**
 * Unconfirms the Cart Shipment
 * @returns {Function}
 */
export function unconfirmToShipment() {
    return async (dispatch) => {
        const data = await API.Cart.unconfirmToShipment();
        dispatch({ type : CART_ELEM, data });
    };
}
