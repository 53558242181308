import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Core/TopBar.css";



/**
 * The TopBar
 */
class TopBar extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings } = this.props;

        if (!settings.topbar_isActive || !settings.topbar_text) {
            return <React.Fragment />;
        }
        return <header className="topbar-container">
            <Wrapper className="topbar-wrapper">
                {!!settings.topbar_link ? <HyperLink
                    variant="opacity"
                    message={settings.topbar_text}
                    href={settings.topbar_link}
                    target="_blank"
                /> : settings.topbar_text}
            </Wrapper>
        </header>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(TopBar.mapStateToProps)(TopBar);
