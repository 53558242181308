import React                from "react";
import PropTypes            from "prop-types";
import { Document, Page }   from "react-pdf";
import NLS                  from "Utils/Core/NLS";

// Components
import TerTitle             from "Components/Utils/Title/TerTitle";
import Loader               from "Components/Utils/Common/Loader";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Content/Sections/Catalogue.css";



/**
 * The Latest Catalogue
 */
class LatestCatalogue extends React.Component {
    // The Current State
    state = {
        isMounted   : false,
        loaded      : false,
        width       : 900,
        currentPage : 1,
        totalPages  : 0,
    }

    /**
     * Sets the Mounted state
     * @returns {Void}
     */
    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.setState({ isMounted : true });
    }

    /**
     * Unsets the Mounted state
     * @returns {Void}
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        this.setState({ isMounted : true });
    }

    /**
     * Handle a Resize
     * @returns {Void}
     */
    handleResize = () => {
        const width = window.innerWidth - 24 * 2;
        if (this.state.width !== width && width > 400 && width < 900) {
            this.setState({ width });
        }
    }

    /**
     * Sets the total pages of the pdf
     * @param {Object} data
     * @returns {Void}
     */
    onLoad = (data) => {
        if (this.state.isMounted) {
            this.setState({ loaded : true, totalPages : data.numPages });
        }
    }

    /**
     * Handles the Prev Page button
     * @returns {Void}
     */
    handlePrevPage = () => {
        const { isMounted, currentPage } = this.state;
        if (isMounted && currentPage > 1) {
            window.scrollTo(0, 0);
            this.setState({ currentPage : currentPage - 1 });
        }
    }

    /**
     * Handles the Next Page button
     * @returns {Void}
     */
    handleNextPage = () => {
        const { isMounted, currentPage, totalPages } = this.state;
        if (isMounted && currentPage < totalPages) {
            window.scrollTo(0, 0);
            this.setState({ currentPage : currentPage + 1 });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, message, catalogue, showNone } = this.props;
        const { loaded, width, currentPage, totalPages  } = this.state;

        const hasCatalogue = Boolean(catalogue && catalogue.name);
        const prevDisabled = currentPage === 1;
        const nextDisabled = currentPage >= totalPages;
        const showPages    = loaded && totalPages > 1;

        return <section className={className}>
            <TerTitle message={message} />
            {!hasCatalogue && showNone && <div className="catalogue-none">
                {NLS.get("CATALOG_NONE")}
            </div>}
            {hasCatalogue && <>
                <div className="catalogue-container">
                    <Document
                        file={catalogue.url}
                        onLoadSuccess={this.onLoad}
                        loading={<Loader variant="app" show />}
                    >
                        <Page pageNumber={currentPage} width={width} />
                    </Document>
                </div>
                {showPages && <div className="catalogue-pages">
                    <Button
                        className="catalogue-prev"
                        message="GENERAL_PREV"
                        variant="primary"
                        icon="left"
                        onClick={this.handlePrevPage}
                        isDisabled={prevDisabled}
                    />
                    <p>{NLS.format("CATALOG_PAGE", String(currentPage), String(totalPages))}</p>
                    <Button
                        className="catalogue-next"
                        message="GENERAL_NEXT"
                        variant="primary"
                        afterIcon="right"
                        onClick={this.handleNextPage}
                        isDisabled={nextDisabled}
                    />
                </div>}
                <div className="catalogue-download">
                    <HyperLink
                        variant="primary"
                        message="GENERAL_DOWNLOAD"
                        href={catalogue.url}
                        target="_blank"
                        isButton
                    />
                </div>
            </>}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className : PropTypes.string,
        message   : PropTypes.string,
        showNone  : PropTypes.bool,
        catalogue : PropTypes.object,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
        showNone  : false,
    }
}

export default LatestCatalogue;
