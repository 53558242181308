import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import ClassList            from "Utils/Common/ClassList";

// Components
import HeaderLogo           from "Components/Header/HeaderLogo";
import SearchBar            from "Components/Header/Bar/SearchBar";
import SuperiorBar          from "Components/Header/Bar/SuperiorBar";
import UserBar              from "Components/Header/Bar/UserBar";



/**
 * The Header Bar
 */
class HeaderBar extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, showLogo, showSuperior, showUser } = this.props;

        const classes = new ClassList("header-content");
        classes.addIf("header-search-right", settings.header_searchRight);

        return <section className={classes.get()}>
            {showLogo && <HeaderLogo />}
            <div className="header-secondary">
                <SearchBar />
                {showSuperior && <SuperiorBar />}
                {showUser && <UserBar />}
            </div>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings     : PropTypes.object.isRequired,
        showLogo     : PropTypes.bool,
        showSuperior : PropTypes.bool,
        showUser     : PropTypes.bool,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(HeaderBar.mapStateToProps)(HeaderBar);
