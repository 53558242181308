import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { addProduct }       from "Actions/Store/CartActions";
import { setContact }       from "Actions/Client/QueryActions";
import { setRoom }          from "Actions/Store/ProductActions";
import NLS                  from "Utils/Core/NLS";
import Analitics            from "Utils/Core/Analitics";
import Metrics              from "Utils/Core/Metrics";
import QueryOrigin          from "Utils/App/QueryOrigin";
import ClassList            from "Utils/Common/ClassList";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import ProductPrice         from "Components/Product/Item/ProductPrice";
import ProductDesc          from "Components/Product/Item/ProductDesc";
import ProductShare         from "Components/Product/Item/ProductShare";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Alert                from "Components/Utils/Form/Alert";
import NumberField          from "Components/Utils/Form/NumberField";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    toggleFavorite, addToHistory,
} from "Actions/Store/StoreActions";

// Styles
import "Styles/Components/Product/Item/ProductContent.css";



/**
 * The Product Content
 */
class ProductContent extends React.Component {
    // The Initial Data
    initialData = {
        amount  : 1,
        variant : {},
    }

    // The Current State
    state = {
        data       : { ...this.initialData },
        stock      : 1,
        discount   : 1,
        isFavorite : false,
        loading    : false,
        success    : "",
        errors     : {},
        error      : "",
    }



    /**
     * Set the State of the Product
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidMount() {
        this.productLoaded();
    }

    /**
     * Set the State if the Product changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (prevProps.product.productID !== this.props.product.productID) {
            this.productLoaded();
        }
    }

    /**
     * Set the State of the Product
     * @returns {Void}
     */
    productLoaded() {
        const { isAuthenticated, settings, product, addToHistory } = this.props;
        if (product.productID) {
            this.setState({
                data       : { amount : 1, variant : {} },
                stock      : {},
                discount   : 1,
                isFavorite : product.isFavorite,
                loading    : false,
                success    : "",
                errors     : {},
                error      : "",
            });
            if (isAuthenticated && settings.products_hasHistory) {
                addToHistory(product.productID);
            }
            Analitics.productView(product);
            Metrics.productView(product.productID);
        }
    }



    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const { data, errors } = this.state;

        if (this.props.product.hasVariants) {
            const variant = data.variant;
            if (!value) {
                delete variant[name];
            } else {
                variant[name] = value;
            }
            this.setState({
                data   : { ...data,   variant      },
                errors : { ...errors, variant : "" },
            });
        } else {
            this.setState({
                data   : { ...data,   [name] : value },
                errors : { ...errors, [name] : ""    },
            });
        }
    }

    /**
     * Handles the Submit
     * @param {Event}    e
     * @param {Boolean=} forRent
     * @returns {Promise}
     */
    handleSubmit = async (e, forRent = false) => {
        e.preventDefault();
        const { product, orderHash, addProduct      } = this.props;
        const { data : { amount, variant }, loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, success : "", error : "" });
        try {
            const success = await addProduct({
                orderHash : orderHash,
                productID : product.productID,
                amount    : amount,
                forRent   : forRent ? 1 : 0,
                variant   : JSON.stringify(variant),
            });
            Analitics.addToCart(product, amount, forRent);
            this.setState({ loading : false, success, data : { ...this.initialData } });
        } catch (response) {
            if (response.errors) {
                const stock = response.data || {};
                this.setState({ loading : false, error : response.errors.form, stock });
            }
        }
    }

    /**
     * Handles the Favorite
     * @param {Event} e
     * @returns {Promise}
     */
    handleFavorite = async (e) => {
        e.preventDefault();
        const { product, toggleFavorite, onFavorite } = this.props;
        if (this.state.loading) {
            return;
        }

        this.setState({ loading : true, error : "" });
        try {
            await toggleFavorite(product.productID);
            if (onFavorite) {
                onFavorite(product.productID);
            }
            this.setState({ loading : false, isFavorite : !this.state.isFavorite });
        } catch (error) {
            this.setState({ loading : false, error });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
    }



    /**
     * View the Image in a Room
     * @returns {Void}
     */
    viewInARoom = () => {
        this.props.setRoom({
            productID : this.props.product.productID,
            content   : this.props.product.name,
        });
    };

    /**
     * Show a Contact Dialog
     * @param {Number} origin
     * @returns {Void}
     */
    showContact = (origin) => {
        this.props.setContact({
            origin,
            productID : this.props.product.productID,
            content   : this.props.product.name,
        });
    }

    /**
     * Sets the Discount value
     * @param {Number} discount
     * @returns {Void}
     */
    setDiscount = (discount) => {
        this.setState({ discount });
    }

    /**
     * Returns the Price data
     * @param {Object} product
     * @returns {Object}
     */
    getPriceData(product) {
        const discount = this.state.discount - 1;
        const result   = {
            currencySymbol  : product.currencySymbol || "",
            priceFormat     : product.priceFormat    || 0,
            semaphoreClass  : product.semaphoreClass,
            hasDiscount     : false,
            discountPrice   : 0,
            discountPercent : 0,
            discountOffer   : false,
            discountName    : "",
            onlyForRent     : product.onlyForRent,
            rentPriceFormat : product.rentPriceFormat,
        };
        if (discount >= 0 && product.discounts && product.discounts[discount]) {
            result.hasDiscount     = true;
            result.discountOffer   = true;
            result.discountPrice   = product.discounts[discount].price;
            result.discountPercent = product.discounts[discount].percent;
        }
        return result;
    }



    /**
     * Renders the Footer
     * @returns {React.ReactElement}
     */
    renderSuccess(message) {
        const { onClose, onBuyMore } = this.props;

        return <>
            <h3 className="product-success">{NLS.get(message)}</h3>
            <div className="product-content-success">
                <Button
                    variant="primary"
                    icon="cart"
                    message="PRODUCTS_SHOW_TO_CART"
                    url="CART"
                    onClick={onClose}
                />
                <Button
                    variant="primary"
                    icon="product"
                    message="PRODUCTS_BUY_MORE"
                    onClick={onBuyMore}
                />
            </div>
        </>;
    }

    /**
     * Renders the Footer
     * @returns {React.ReactElement}
     */
    renderFooter() {
        const { settings, product             } = this.props;
        const { data, loading, success, error } = this.state;

        // Not allowed
        if (!settings.orders_isActive) {
            return <React.Fragment />;
        }

        // Product disabled
        if (product.isDisabled) {
            return <div className="product-content-without">
                {NLS.get("PRODUCTS_NO_STOCK")}
            </div>;
        }

        // Product purchased
        if (product.isUnique && product.amountInCart > 0) {
            return this.renderSuccess("PRODUCTS_PURCHASED");
        }

        // Show the Success
        if (success) {
            return this.renderSuccess(success);
        }

        const showBuy  = !product.onlyForRent && (product.amountInCart > 0 ? !product.forRent : true) && !product.isRented;
        const showRent = product.hasRentPrice && (product.amountInCart > 0 ? product.forRent  : true) && !product.isRented;
        const has2btn  = showBuy && showRent;

        return <>
            <Alert variant="error" message={error} onClose={this.closeAlert} />
            {product.showStock && <div className="product-content-stock">
                {NLS.pluralize("PRODUCTS_STOCK", product.stock)}
            </div>}
            <div className={"product-content-input" + (has2btn ? " product-content-input-rent" : "")}>
                {product.showAmount && <div className="product-content-amount">
                    <NumberField
                        name="amount"
                        value={data.amount}
                        onChange={this.handleChange}
                    />
                </div>}
                {product.isRented && <h3 className="product-error">
                    {NLS.get("PRODUCTS_RENTED")}
                </h3>}
                {showBuy && <Button
                    className="product-content-button"
                    variant="primary"
                    icon="cart"
                    message="PRODUCTS_ADD_TO_CART"
                    onClick={this.handleSubmit}
                    isDisabled={loading}
                />}
                {showRent && <Button
                    className="product-content-button"
                    variant="primary"
                    icon="cart"
                    message="PRODUCTS_RENT"
                    onClick={(e) => this.handleSubmit(e, true)}
                    isDisabled={loading}
                />}
            </div>
        </>;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isAuthenticated, settings, product, showTitle     } = this.props;
        const { success, data : { variant }, discount, isFavorite } = this.state;
        const {
            productCode, name, description, brandName, artistName, hasVariants, variants,
            discounts, discountMulti, isNew, hasRentPrice, onlyForRent,
        } = product;

        const showInput       = Boolean(!success);
        const showNew         = Boolean(settings.products_showNewTag && isNew);
        const showCode        = Boolean(settings.products_showDetailCode && !!productCode);
        const showBrand       = Boolean(settings.products_showDetailBrand && brandName);
        const showArtist      = Boolean(settings.products_showDetailArtist && artistName);
        const showPrice       = settings.products_showPrice;
        const showPriceBigger = settings.products_showPrice && settings.products_showPriceBig;
        const showPrices      = !hasVariants && showPrice;
        const showRentPrice   = hasRentPrice && !onlyForRent;
        const showDiscounts   = Boolean(settings.products_showOfferFilter && showPrice && discountMulti && discounts && discounts.length > 0);
        const showDescription = Boolean(description);
        const showFavorite    = isAuthenticated && settings.products_hasFavorites;
        const showShare       = Boolean(settings.products_showShare && name && product.image);
        const fields          = product.fields ? product.fields.filter((field) => field.showInView) : [];
        const showFields      = Boolean(fields.length);
        const showButtons     = settings.products_viewInARoom || settings.products_requestMoreImages || settings.products_seeInYourHome;

        const classes         = new ClassList("product-content", "spacing");
        classes.addIf("product-content-big-price",     showPriceBigger);
        classes.addIf("product-content-prices-rent",   showRentPrice);
        classes.addIf("product-content-center-footer", settings.products_centerDetailBuy);

        return <div className={classes.get()}>
            <aside className="product-content-aside">
                <ProductImage
                    className="product-content-image"
                    variant="medium"
                    data={product}
                    showNew={showNew}
                    withPreview
                    clickPreview
                    withZoom
                />
                {showButtons && <div className="product-content-buttons">
                    {settings.products_viewInARoom && <HyperLink
                        variant="primary"
                        message="PRODUCTS_VIEW_IN_A_ROOM"
                        icon="room"
                        onClick={() => this.viewInARoom()}
                    />}
                    {settings.products_requestMoreImages && <HyperLink
                        variant="primary"
                        message="PRODUCTS_REQUEST_IMAGES"
                        icon="image"
                        onClick={() => this.showContact(QueryOrigin.IMAGES)}
                    />}
                    {settings.products_seeInYourHome && <HyperLink
                        variant="primary"
                        message="PRODUCTS_SEE_IN_YOUR_HOME"
                        icon="home"
                        onClick={() => this.showContact(QueryOrigin.HOME)}
                    />}
                </div>}
            </aside>

            <div className="product-content-info">
                <header>
                    {showFavorite && <HyperLink
                        className="product-content-favorite"
                        variant="icon"
                        icon={isFavorite ? "favorite" : "unfavorite"}
                        onClick={this.handleFavorite}
                    />}
                    {showCode && <h5 className="product-content-code">{productCode}</h5>}
                    {showBrand && <h4 className="product-content-brand">{brandName}</h4>}
                    {showArtist && <h4 className="product-content-artist">{artistName}</h4>}
                    {showTitle && <h3 className="product-content-title">{name}</h3>}
                </header>
                {showFields && <ul className="product-content-fields no-list">
                    {fields.map((field) => <li key={field.fieldID}>
                        <b>{field.name}:</b> {field.value}
                    </li>)}
                </ul>}
                {showDescription && <ProductDesc
                    className="product-content-desc"
                    description={description}
                    maxLength={500}
                    withMore
                />}
                {hasVariants && <ul className="product-content-variants no-list">
                    {variants.map((subelem) => <li key={subelem.variantID}>
                        <h4>{subelem.name}</h4>
                        {showPrice && <ProductPrice
                            className="product-content-price"
                            data={this.getPriceData(subelem)}
                        />}
                        {showInput && <NumberField
                            variant="medium"
                            name={subelem.variantID}
                            value={variant[subelem.variantID] || 0}
                            minValue={0}
                            onChange={this.handleChange}
                        />}
                    </li>)}
                </ul>}
                {showPrices && <div className="product-content-prices">
                    <ProductPrice
                        className="product-content-price"
                        data={this.getPriceData(product)}
                        withPercent
                    />
                    {showRentPrice && <div className="product-content-rent">
                        {NLS.get("PRODUCTS_RENT_FOR")}
                        <ProductPrice
                            className="product-content-rentprice"
                            data={{
                                currencySymbol : product.currencySymbol || "",
                                priceFormat    : product.rentPriceFormat,
                            }}
                        />
                        {NLS.get("PRODUCTS_RENT_MONTH")}
                    </div>}
                </div>}
                {showDiscounts && <ul className="product-content-discounts no-list">
                    <li
                        className={!discount ? "product-content-selected" : ""}
                        onClick={() => this.setDiscount(0)}
                    >
                        {NLS.get("PRODUCTS_NO_DISCOUNT")}
                    </li>
                    {discounts.map((subelem) => <li
                        key={subelem.key}
                        className={subelem.key === discount ? "product-content-selected" : ""}
                        onClick={() => this.setDiscount(subelem.key)}
                    >
                        {subelem.name}
                    </li>)}
                </ul>}
                {showShare && <ProductShare
                    message={name}
                    url={product.productUrl}
                    image={product.image.large}
                />}
                <footer className="product-content-footer">
                    {this.renderFooter()}
                </footer>
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        addProduct      : PropTypes.func.isRequired,
        setContact      : PropTypes.func.isRequired,
        setRoom         : PropTypes.func.isRequired,
        toggleFavorite  : PropTypes.func.isRequired,
        addToHistory    : PropTypes.func.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        orderHash       : PropTypes.string.isRequired,
        product         : PropTypes.object.isRequired,
        onClose         : PropTypes.func,
        onBuyMore       : PropTypes.func,
        onFavorite      : PropTypes.func,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAuthenticated : state.auth.isAuthenticated,
            settings        : state.core.settings,
            orderHash       : state.cart.elem.orderHash,
        };
    }
}

export default connect(ProductContent.mapStateToProps, {
    addProduct, setContact, setRoom,
    toggleFavorite, addToHistory,
})(ProductContent);
