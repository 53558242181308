import React                from "react";
import PropTypes            from "prop-types";

// Components
import Sections             from "Components/Content/Sections/Sections";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Card                 from "Components/Utils/Common/Card";



/**
 * The Page Content
 */
class PageContent extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, elem, match, children } = this.props;

        if (loading) {
            return <React.Fragment />;
        }
        if (elem.showBorder) {
            return <Wrapper maxWidth={elem.maxWidth} withSpacing>
                <Card withBorder={elem.showBorder} withSpacing={elem.showBorder}>
                    {children}
                    <Sections sections={elem.sections} match={match} />
                </Card>
            </Wrapper>;
        }
        if (elem.maxWidth) {
            return <div style={{ maxWidth : `${elem.maxWidth}px`, margin : "0 auto" }}>
                {children}
                <Sections sections={elem.sections} match={match} />
            </div>;
        }
        return <>
            {children}
            <Sections sections={elem.sections} match={match} />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        loading  : PropTypes.bool.isRequired,
        elem     : PropTypes.object.isRequired,
        match    : PropTypes.object.isRequired,
        children : PropTypes.any,
    }
}

export default PageContent;
