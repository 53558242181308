import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Responsive           from "Utils/App/Responsive";

// Components
import SubTitle             from "Components/Utils/Title/SubTitle";



/**
 * The Client Title
 */
class ClientTitle extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { responsive, message, icon, children } = this.props;

        return <SubTitle message={message} icon={icon} isHidden={Responsive.isMobile(responsive)}>
            {children}
        </SubTitle>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        responsive : PropTypes.object.isRequired,
        message    : PropTypes.string,
        icon       : PropTypes.string,
        children   : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        icon : "client",
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
        };
    }
}

export default connect(ClientTitle.mapStateToProps)(ClientTitle);
