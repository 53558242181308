import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/Core/NLS";
import MenuItems            from "Utils/App/MenuItems";
import ClassList            from "Utils/Common/ClassList";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";
import ImageLink            from "Components/Utils/Media/ImageLink";

// Styles
import "Styles/Components/Core/Footer.css";



/**
 * The Footer
 */
class Footer extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, pages, menus, isAuthenticated } = this.props;

        const year          = new Date().getFullYear();
        const aboutUsItems  = MenuItems.getAboutUs(menus,  settings, pages, isAuthenticated);
        const siteMapItems  = MenuItems.getSiteMap(menus,  settings, pages, isAuthenticated);
        const followUsItems = MenuItems.getFollowUs(menus, settings, isAuthenticated);
        const floaterItems  = MenuItems.getFloater(menus,  settings, isAuthenticated);
        const hasFloater    = floaterItems.length > 0;
        const hasFollowUs   = Boolean(followUsItems.length);
        const columns       = [
            { message : "FOOTER_ABOUT_US", items : aboutUsItems },
            { message : "FOOTER_SITE_MAP", items : siteMapItems },
        ];

        const classes = new ClassList("copyright-container");
        classes.addIf("copyright-right-floater", hasFloater && !settings.floater_leftSide);
        classes.addIf("copyright-left-floater",  hasFloater && settings.floater_leftSide);

        return <>
            <footer className="footer-container">
                <Wrapper className="footer-content">
                    <div className="footer-logo">
                        {settings.footer_logo ? <ImageLink
                            url="HOME"
                            source={settings.footer_logo}
                            name={settings.siteName}
                        /> : <h1>
                            <HyperLink
                                variant="primary"
                                url="HOME"
                                message={settings.siteName}
                            />
                        </h1>}
                    </div>
                    {columns.map(({ message, items }) => <div key={message}>
                        <h3>{NLS.get(message)}</h3>
                        <ul className="no-list">
                            {items.map(({ key, href, target, message, options }) => <li key={key}>
                                <HyperLink
                                    variant="opacity"
                                    href={href}
                                    target={target}
                                    message={message}
                                    {...options}
                                />
                            </li>)}
                        </ul>
                    </div>)}
                    {hasFollowUs && <div>
                        <h3>{NLS.get("FOOTER_FOLLOW_US")}</h3>
                        <ul className="footer-social no-list">
                            {followUsItems.map(({ key, href, target, message, icon, options }) => <li key={key}>
                                <HyperLink
                                    variant="opacity"
                                    href={href}
                                    target={target}
                                    message={message}
                                    icon={icon}
                                    {...options}
                                />
                            </li>)}
                        </ul>
                    </div>}
                </Wrapper>
            </footer>

            {settings.footer_showCopyright && <footer className={classes.get()}>
                <Wrapper className="copyright-content">
                    <div className="copyright-text">
                        {NLS.format("FOOTER_COPYRIGHT", settings.siteName, year)}
                    </div>
                </Wrapper>
            </footer>}
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings        : PropTypes.object.isRequired,
        pages           : PropTypes.object.isRequired,
        menus           : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            pages           : state.core.pages,
            menus           : state.store.menus,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(Footer.mapStateToProps, null)(Footer);
