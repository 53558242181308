import Href                 from "Utils/Core/Href";
import Pages                from "Utils/App/Pages";



/** All the Menu Items */
const MENU_ITEMS = {
    home          : { icon : "home",         url : "HOME",          message : "GENERAL_HOME"          },
    products      : { icon : "product",      url : "PRODUCTS",      message : "PRODUCTS_NAME"         },
    category      : { icon : "category",     url : "",              message : "CATEGORIES_SINGULAR"   },
    brand         : { icon : "brand",        url : "",              message : "BRANDS_SINGULAR"       },
    artist        : { icon : "artist",       url : "",              message : "ARTISTS_SINGULAR"      },
    page          : { icon : "page",         url : "",              message : ""                      },

    login         : { icon : "client",       url : "LOGIN",         message : "AUTH_LOGIN_ACTION"     },
    cart          : { icon : "cart",         url : "CART",          message : "CART_TITLE"            },
    chat          : { icon : "chat",         url : "CHAT",          message : "CHAT_NAME"             },
    notifications : { icon : "notification", url : "NOTIFICATIONS", message : "NOTIFICATIONS_ALERTS"  },

    client        : { icon : "client",       url : "ACCOUNT",       message : "CLIENT_NAME"           },
    clientData    : { icon : "client",       url : "ACCOUNT_DATA",  message : "CLIENT_NAME"           },
    account       : { icon : "account",      url : "ACCOUNT_DATA",  message : "ACCOUNT_NAME"          },
    addresses     : { icon : "address",      url : "ADDRESSES",     message : "ADDRESSES_NAME"        },
    vouchers      : { icon : "voucher",      url : "VOUCHERS",      message : "VOUCHERS_NAME"         },
    invoices      : { icon : "voucher",      url : "INVOICES",      message : "VOUCHERS_INVOICES"     },
    receipts      : { icon : "voucher",      url : "RECEIPTS",      message : "VOUCHERS_RECEIPTS"     },
    creditNotes   : { icon : "voucher",      url : "CREDIT_NOTES",  message : "VOUCHERS_CREDIT_NOTES" },
    debitNotes    : { icon : "voucher",      url : "DEBIT_NOTES",   message : "VOUCHERS_DEBIT_NOTES"  },
    orders        : { icon : "cart",         url : "ORDERS",        message : "ORDERS_NAME"           },
    queries       : { icon : "query",        url : "QUERIES",       message : "QUERIES_NAME"          },

    whatsapp      : { icon : "whatsapp",     url : "",              message : "CONTACT_NAME"          },
    phone         : { icon : "phone",        url : "",              message : "CONTACT_NAME"          },
    email         : { icon : "email",        url : "",              message : "CONTACT_NAME"          },
    link          : { icon : "link",         url : "",              message : "CONTACT_NAME"          },

    facebook      : { icon : "facebook",     url : "",              message : "Facebook"              },
    messenger     : { icon : "messenger",    url : "",              message : "Messenger"             },
    twitter       : { icon : "twitter",      url : "",              message : "Twitter"               },
    instagram     : { icon : "instagram",    url : "",              message : "Instagram"             },
    youtube       : { icon : "youtube",      url : "",              message : "YouTube"               },
    pinterest     : { icon : "pinterest",    url : "",              message : "Pinterest"             },
};



/**
 * The Menu Items Class
 */
class MenuItems {
    /**
     * Creates a Menu Instance
     * @constructor
     */
    constructor() {
        this.data = [];
    }

    /**
     * Returns the Menu Items
     * @returns {Array.<Object>}
     */
    get() {
        return this.data;
    }

    /**
     * Adds a Menu Item
     * @param {String}  type
     * @param {String}  submenu
     * @param {Number}  badge
     * @param {String=} url
     * @returns {Void}
     */
    add(type, submenu, badge, url) {
        if (MENU_ITEMS[type]) {
            const { icon, message } = MENU_ITEMS[type];
            let href = url;
            if (!href) {
                href = Href.url(MENU_ITEMS[type].url);
            }
            this.data.push({ key : type, type, icon, href, message, submenu, badge });
        }
    }

    /**
     * Adds a Menu Item If
     * @param {Boolean} condition
     * @param {String}  type
     * @param {String}  submenu
     * @param {Number}  badge
     * @param {String=} url
     * @returns {Void}
     */
    addIf(condition, type, submenu, badge, url) {
        if (condition) {
            this.add(type, submenu, badge, url);
        }
    }

    /**
     * Adds a Page to the Menu
     * @param {Object} pages
     * @param {String} type
     * @returns {Void}
     */
    addPage(pages, type) {
        const page = Pages.get(pages, type)
        if (page) {
            this.data.push({
                key     : `page-${page.id}`,
                type    : "page",
                href    : page.url,
                message : page.name,
            });
        }
    }
}



/**
 * Creates a Menu
 * @param {String}  location
 * @param {Object}  menus
 * @param {Object}  settings
 * @param {Object}  content
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function createMenu(location, menus, settings, content, isAuthenticated) {
    const items = [];
    if (menus[location]) {
        for (const menuItem of menus[location]) {
            const item = createMenuItem(menuItem, settings, content, isAuthenticated);
            if (item) {
                items.push(item);
            }
        }
    }
    return items;
}

/**
 * Creates a Menu Item
 * @param {Object}  item
 * @param {Object}  settings
 * @param {Boolean} isAuthenticated
 * @returns {Object}
 */
function createMenuItem(item, settings, isAuthenticated) {
    const menu     = MENU_ITEMS[item.type];
    const key      = item.id;
    const type     = item.type;
    const icon     = menu ? menu.icon : "";
    let   href     = menu && menu.url ? Href.url(menu.url) : "";
    let   message  = item.name || (menu ? menu.message : "");
    let   title    = "";
    let   target   = "_self";
    let   onlyIcon = !!item.onlyIcon;
    let   canAdd   = true;
    const options  = {
        showBold : !!item.showBold,
    };

    switch (type) {
    case "home":
        if (item.sectionID && item.sectionIsActive) {
            href = `/#${item.sectionID}`;
        }
        break;
    case "category":
        canAdd  = settings.categories_isActive && !item.categoryIsDeleted && item.categoryIsActive;
        href    = Href.url("PRODUCTS", item.categorySlug);
        message = item.name || item.categoryName;
        break;
    case "brand":
        canAdd  = settings.brands_isActive && !item.brandIsDeleted && item.brandIsActive;
        href    = Href.url("BRAND", item.brandSlug);
        message = item.name || item.brandName;
        break;
    case "artist":
        canAdd  = settings.artists_isActive && !item.artistIsDeleted && item.artistIsActive;
        href    = Href.url("ARTIST", item.artistSlug);
        message = item.name || item.artistName;
        break;
    case "page":
        canAdd  = !item.pageIsDeleted && item.pageIsActive;
        href    = `/${item.pageSlug}`;
        message = item.name || item.pageName;
        break;
    case "cart":
        canAdd  = settings.orders_isActive;
        break;
    case "clientData":
    case "orders":
    case "notifications":
        canAdd  = isAuthenticated;
        break;
    case "queries":
        canAdd  = isAuthenticated && settings.queries_isActive;
        break;
    case "chat":
        canAdd  = isAuthenticated && settings.chats_isActive;
        break;
    case "phone":
        href    = item.phone || settings.contact_phone;
        message = item.name  || settings.contact_phone;
        canAdd  = !!href;
        options.forPhone = true;
        break;
    case "whatsapp":
        href    = item.whatsapp || settings.contact_whatsapp;
        message = item.name     || settings.contact_whatsapp;
        canAdd  = !!href;
        options.forWhatsApp = true;
        break;
    case "email":
        href    = item.email || settings.contact_email;
        message = item.name  || settings.contact_email;
        canAdd  = !!href;
        options.forEmail = true;
        break;
    case "facebook":
        href    = item.link || settings.contact_facebook;
        message = item.name || "Facebook";
        target  = "_blank";
        canAdd  = !!href;
        break;
    case "messenger":
        href    = item.username || settings.contact_messenger;
        message = item.name     || "Messenger";
        canAdd  = !!href;
        options.forMessenger = true;
        break;
    case "twitter":
        href    = item.link || settings.contact_twitter;
        message = item.name || "Twitter";
        target  = "_blank";
        canAdd  = !!href;
        break;
    case "instagram":
        href    = item.link || settings.contact_instagram;
        message = item.name || "Instagram";
        target  = "_blank";
        canAdd  = !!href;
        break;
    case "youtube":
        href    = item.link || settings.contact_youtube;
        message = item.name || "YouTube";
        target  = "_blank";
        canAdd  = !!href;
        break;
    case "pinterest":
        href    = item.link || settings.contact_pinterest;
        message = item.name || "Pinterest";
        target  = "_blank";
        canAdd  = !!href;
        break;
    case "link":
        href    = item.href;
        message = item.name;
        title   = item.title;
        target  = item.targetBlank ? "_blank" : "_self";
        break;
    default:
    }


    let submenu   = "";
    let submenuID = 0;
    switch (item.submenu) {
    case "category":
        if (type === "category") {
            submenu   = settings.categories_hasSubcategories ? "subcategory" : "";
            submenuID = item.categoryID;
        } else if (settings.categories_isActive) {
            submenu   = item.submenu;
        }
        break;
    case "offer":
        if (settings.offers_hasPromotions) {
            submenu = item.submenu;
        }
        break;
    case "brand":
        if (settings.brands_isActive) {
            submenu = item.submenu;
        }
        break;
    case "artist":
        if (settings.artists_isActive) {
            submenu = item.submenu;
        }
        break;
    default:
    }


    if (canAdd) {
        return { key, type, icon, href, target, message, title, onlyIcon, submenu, submenuID, options };
    }
    return null;
}



/**
 * The Items for the Primary Menu
 * @param {Object}  menus
 * @param {Object}  settings
 * @param {Object}  pages
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function getPrimary(menus, settings, pages, isAuthenticated) {
    if (menus.primary) {
        return createMenu("primary", menus, settings, isAuthenticated);
    }
    const items = new MenuItems();
    items.add("products", "category");
    items.addPage(pages, Pages.OFFERS);
    items.addPage(pages, Pages.BRANDS);
    items.addPage(pages, Pages.ARTISTS);
    items.addPage(pages, Pages.CONTACT);
    return items.get();
}

/**
 * The Items for the Secondary Menu
 * @param {Object}  menus
 * @param {Object}  settings
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function getSecondary(menus, settings, isAuthenticated) {
    return createMenu("secondary", menus, settings, isAuthenticated);
}

/**
 * The Items for the Superior Menu
 * @param {Object}  menus
 * @param {Object}  settings
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function getSuperior(menus, settings, isAuthenticated) {
    return createMenu("superior", menus, settings, isAuthenticated);
}



/**
 * The Items for the Navigation Menu
 * @param {Object}  menus
 * @param {Object}  settings
 * @param {Object}  pages
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function getNavigation(menus, settings, pages, isAuthenticated) {
    if (menus.navigation) {
        return createMenu("navigation", menus, settings, isAuthenticated);
    }
    const items = new MenuItems();
    items.add("home");
    items.add("products");
    items.addPage(pages, Pages.OFFERS);
    items.addPage(pages, Pages.BRANDS);
    items.addPage(pages, Pages.ARTISTS);
    items.addPage(pages, Pages.ENTRIES);
    items.addPage(pages, Pages.CATALOGUE);
    items.addPage(pages, Pages.CONTACT);
    return items.get();
}

/**
 * The Items for the Navigation Menu
 * @param {Object}  menus
 * @param {Object}  settings
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function getSubNavigation(menus, settings, isAuthenticated) {
    return createMenu("subnavigation", menus, settings, isAuthenticated);
}



/**
 * The Items for the About Us Menu
 * @param {Object}  menus
 * @param {Object}  settings
 * @param {Object}  pages
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function getAboutUs(menus, settings, pages, isAuthenticated) {
    if (menus.aboutus) {
        return createMenu("aboutus", menus, settings, isAuthenticated);
    }
    const items = new MenuItems();
    items.addPage(pages, Pages.CONTACT);
    items.addIf(settings.queries_isActive && isAuthenticated, "queries");
    items.addPage(pages, Pages.ENTRIES);
    items.addPage(pages, Pages.TERMS);
    return items.get();
}

/**
 * The Items for the Site Map Menu
 * @param {Object}  menus
 * @param {Object}  settings
 * @param {Object}  pages
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function getSiteMap(menus, settings, pages, isAuthenticated) {
    if (menus.sitemap) {
        return createMenu("sitemap", menus, settings, isAuthenticated);
    }
    const items = new MenuItems();
    items.add("products");
    items.addPage(pages, Pages.OFFERS);
    items.addPage(pages, Pages.BRANDS);
    items.addPage(pages, Pages.ARTISTS);
    items.addPage(pages, Pages.CATALOGUE);
    items.addIf(isAuthenticated, "clientData");
    return items.get();
}

/**
 * The Items for the Follow Us Menu
 * @param {Object}  menus
 * @param {Object}  settings
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function getFollowUs(menus, settings, isAuthenticated) {
    if (menus.followus) {
        return createMenu("followus", menus, settings, isAuthenticated);
    }
    const items = [];
    if (settings.contact_facebook) {
        items.push({
            key     : "facebook",
            icon    : "facebook",
            message : "Facebook",
            href    : settings.contact_facebook,
            target  : "_blank",
        });
    }
    if (settings.contact_messenger) {
        items.push({
            key     : "messenger",
            icon    : "messenger",
            message : "Messenger",
            href    : settings.contact_messenger,
            options : { forMessenger : true },
        });
    }
    if (settings.contact_twitter) {
        items.push({
            key     : "twitter",
            icon    : "twitter",
            message : "Twitter",
            href    : settings.contact_twitter,
            target  : "_blank",
        });
    }
    if (settings.contact_instagram) {
        items.push({
            key     : "instagram",
            icon    : "instagram",
            message : "Instagram",
            href    : settings.contact_instagram,
            target  : "_blank",
        });
    }
    if (settings.contact_youtube) {
        items.push({
            key     : "youtube",
            icon    : "youtube",
            message : "YouTube",
            href    : settings.contact_youtube,
            target  : "_blank",
        });
    }
    if (settings.contact_pinterest) {
        items.push({
            key     : "pinterest",
            icon    : "pinterest",
            message : "Pinterest",
            href    : settings.contact_pinterest,
            target  : "_blank",
        });
    }
    return items;
}

/**
 * The Items for the Floater Menu
 * @param {Object}  menus
 * @param {Object}  settings
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function getFloater(menus, settings, isAuthenticated) {
    return createMenu("floater", menus, settings, isAuthenticated);
}

/**
 * The Items for the Contact Menu
 * @param {Object}  menus
 * @param {Object}  settings
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function getContact(menus, settings, isAuthenticated) {
    if (menus.contact) {
        return createMenu("contact", menus, settings, isAuthenticated);
    }
    const items = [];
    if (settings.contact_phone) {
        items.push({
            key     : "phone",
            icon    : "phone",
            message : "CONTACT_PHONE",
            href    : settings.contact_phone,
            options : { forPhone : true },
        });
    }
    if (settings.contact_whatsapp) {
        items.push({
            key     : "whatsapp",
            icon    : "whatsapp",
            message : "WhatsApp",
            href    : settings.contact_whatsapp,
            options : { forWhatsApp : true },
        });
    }
    if (settings.contact_email) {
        items.push({
            key     : "email",
            icon    : "email",
            message : "CONTACT_EMAIL",
            href    : settings.contact_email,
            options : { forEmail : true },
        });
    }
    return items;
}



/**
 * The Items for the Client Menu
 * @param {Object}  settings
 * @param {Boolean} withVoucherLink
 * @returns {Array.<Object>}
 */
function getClient(settings, withVoucherLink) {
    const items = new MenuItems();
    items.add("account");

    items.addIf(settings.addresses_isActive, "addresses");

    if (settings.vouchers_hasVouchers) {
        const subItems = settings.vouchers_onlyVoucher ? items : new MenuItems();
        subItems.addIf(settings.vouchers_hasInvoices,    "invoices");
        subItems.addIf(settings.vouchers_hasReceipts,    "receipts");
        subItems.addIf(settings.vouchers_hasCreditNotes, "creditNotes");
        subItems.addIf(settings.vouchers_hasDebitNotes,  "debitNotes");

        const subMenu = subItems.get();
        const subUrl  = withVoucherLink ? subMenu[0].href : "";
        items.addIf(!settings.vouchers_onlyVoucher, "voucher", subMenu, 0, subUrl);
    }

    items.addIf(settings.orders_isActive,  "orders");
    items.addIf(settings.queries_isActive, "queries");
    return items.get();
}

/**
 * The Items for the Sub Client Menu
 * @param {Object} pages
 * @returns {Array.<Object>}
 */
function getClientSecondary(pages) {
    const items = new MenuItems();
    items.addPage(pages, Pages.ENTRIES);
    items.addPage(pages, Pages.CATALOGUE);
    items.addPage(pages, Pages.TERMS);
    return items.get();
}

/**
 * The Items for the Bar Menu
 * @param {Object}  settings
 * @param {Object}  pages
 * @param {Number}  chats
 * @param {Number}  notifications
 * @param {Boolean} isAuthenticated
 * @param {Object}  responsive
 * @returns {Array.<Object>}
 */
function getBar(settings, pages, chats, notifications, isAuthenticated, responsive) {
    const items    = new MenuItems();
    const showChat = isAuthenticated && settings.chats_isActive;

    items.add("products");
    items.addPage(pages, Pages.OFFERS);
    items.addIf(showChat,          "chat",          null, chats);
    items.addIf(isAuthenticated,   "notifications", null, notifications);
    items.addIf(isAuthenticated,   responsive.isTablet ? "clientData" : "client");
    items.addIf(!isAuthenticated,  "login");

    return items.get();
}




// The Public API
export default {
    getPrimary,
    getSecondary,
    getSuperior,
    getNavigation,
    getSubNavigation,
    getAboutUs,
    getSiteMap,
    getFollowUs,
    getFloater,
    getContact,

    getClient,
    getClientSecondary,
    getBar,
};
