import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import SearchBar            from "Components/Header/Bar/SearchBar";



/**
 * The Header Wrapper
 */
class HeaderWrapper extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, design, withSearch, children } = this.props;

        return <header className={`header-container header-${design}`}>
            <Wrapper className="header-wrapper" fullWidth={settings.header_fullWidth}>
                {children}
            </Wrapper>
            {withSearch && <SearchBar isHidden />}
        </header>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings   : PropTypes.object.isRequired,
        design     : PropTypes.string.isRequired,
        withSearch : PropTypes.bool,
        children   : PropTypes.any,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(HeaderWrapper.mapStateToProps)(HeaderWrapper);
