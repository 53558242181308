import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/Core/NLS";



/**
 * The Loading
 */
class Loading extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className } = this.props;

        return <div className={"nothing " + className}>
            <h3>{NLS.get("GENERAL_LOADING")}</h3>
        </div>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        className : PropTypes.string,
    }

    /**
     * The Default Properties
     * @type {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }
}

export default Loading;
