import API                  from "Utils/Core/API";

// Types
import {
    CONTENT_LOADING, CONTENT_HOME, CONTENT_PAGE, CONTENT_ENTRY, CONTENT_ARTIST,
} from "Utils/Core/Types";



/**
 * Fetches the Home
 * @returns {Function}
 */
export function fetchHome() {
    return async (dispatch) => {
        dispatch({ type : CONTENT_LOADING });
        const data = await API.Store.getHome();
        dispatch({ type : CONTENT_HOME, data });
    };
}

/**
 * Fetches the Page
 * @param {String}  slug
 * @param {number=} page
 * @returns {Function}
 */
export function fetchPage(slug, page = 1) {
    return async (dispatch) => {
        dispatch({ type : CONTENT_LOADING });
        const data = await API.Store.getPage({ slug, page });
        dispatch({ type : CONTENT_PAGE, data });
    };
}

/**
 * Fetches the Entry
 * @param {Number}  entryID
 * @param {number=} page
 * @returns {Function}
 */
export function fetchEntry(entryID, page = 1) {
    return async (dispatch) => {
        dispatch({ type : CONTENT_LOADING });
        const data = await API.Store.getEntry({ entryID, page });
        dispatch({ type : CONTENT_ENTRY, data });
    };
}

/**
 * Fetches the Artist Biography
 * @param {String} artist
 * @returns {Function}
 */
export function fetchArtist(artist) {
    return async (dispatch) => {
        dispatch({ type : CONTENT_LOADING });
        const data = await API.Store.getArtist({ artist });
        dispatch({ type : CONTENT_ARTIST, data });
    };
}
