// Types
import {
    STORE_DATA, STORE_LAST_UPDATE,
    STORE_PREFERENCE, STORE_FAVORITE,
    STORE_MENU, STORE_SUBMENU, STORE_NAVIGATION, STORE_SEARCH, STORE_FILTER,
    CART_ELEM, CART_COMPLETE, AUTH_LOGOUT,
} from "Utils/Core/Types";



// The initial State
const initialState = {
    error       : false,
    loaded      : false,
    lastUpdate  : Math.floor(new Date().getTime() / 1000),

    menuOpen    : false,
    navOpen     : false,
    searchOpen  : false,
    filterOpen  : false,

    submenu     : "",
    submenuData : {
        id   : 0,
        top  : 0,
        left : 0,
        href : "",
    },

    menus       : {},
    categories  : [],
    brands      : [],
    artists     : [],
    offers      : [],
    favorites   : [],
    cart        : 0,

    preferences : {
        sort         : 1,
        columns      : 3,
        amount       : 1,
        price        : 1,
    },
};

/**
 * Returns the Preferences with the defaults
 * @param {Object}  preferences
 * @param {Boolean} hasPositions
 * @returns {Object}
 */
function getPreferences(preferences, hasPositions) {
    return {
        sort    : preferences.sort    || (hasPositions ? 1 : 2),
        columns : preferences.columns || 3,
        amount  : preferences.amount  || 1,
    };
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case STORE_DATA:
        return {
            ...state,
            error       : false,
            loaded      : true,
            favorites   : action.data.favorites,
            menus       : action.data.menus,
            categories  : action.data.categories,
            brands      : action.data.brands,
            artists     : action.data.artists,
            offers      : action.data.offers,
            cart        : action.data.cart,
            preferences : getPreferences(action.data.preferences, action.data.hasPositions),
        };

    case STORE_LAST_UPDATE:
        return {
            ...state,
            lastUpdate : Math.floor(new Date().getTime() / 1000),
        };

    // Updates a Preference
    case STORE_PREFERENCE:
        return {
            ...state,
            error       : false,
            preferences : {
                ...state.preferences,
                [action.data.key] : action.data.value,
            },
        };

    // Update the Favorite
    case STORE_FAVORITE:
        return {
            ...state,
            favorites : action.data.list,
        };

    // The Menu, Navigation, Search and Filter
    case STORE_MENU:
        return {
            ...state,
            menuOpen : !!action.open,
        };
    case STORE_SUBMENU:
        return {
            ...state,
            submenu     : action.data.submenu,
            submenuData : action.data,
        };
    case STORE_NAVIGATION:
        return {
            ...state,
            menuOpen : !!action.open,
            navOpen  : !!action.open,
        };
    case STORE_SEARCH:
        return {
            ...state,
            searchOpen : !!action.open,
        };
    case STORE_FILTER:
        return {
            ...state,
            filterOpen : !!action.open,
        };

    // Update the Cart
    case CART_ELEM:
    case CART_COMPLETE:
        return {
            ...state,
            cart : action.data.amount,
        };
    case AUTH_LOGOUT:
        return {
            ...state,
            cart : 0,
        };

    default:
        return state;
    }
};
