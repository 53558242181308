import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setAdded }         from "Actions/Store/ProductActions";
import NLS                  from "Utils/Core/NLS";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import ProductPrice         from "Components/Product/Item/ProductPrice";
import Price                from "Components/Utils/Common/Price";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Product/Item/ProductAdded.css";



/**
 * The Product Added
 */
class ProductAdded extends React.Component {
    // The Current State
    state = {
        isMounted : false,
        timeout   : null,
    }

    /**
     * Did Mounted
     * @returns {Void}
     */
    componentDidMount() {
        this.setState({
            isMounted : true,
        });
    }

    /**
     * End the Timeouts
     * @returns {Void}
     */
    componentWillUnmount() {
        this.clearTimeout();
        this.setState({ isMounted : false, timeout : null, });
    }


    /**
     * Data Updated
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { added } = this.props;
        if (prevProps.added && added && prevProps.added.productID !== added.productID) {
            this.clearTimeout();
            this.setTimeout();
        } else if (!prevProps.added && added) {
            this.setTimeout();
        } else if (prevProps.added && !added) {
            this.clearTimeout();
        }
    }

    /**
     * Sets the Timeout
     * @returns {Void}
     */
    setTimeout() {
        const timeout = window.setTimeout(() => this.handleClose(), 8000);
        this.setState({ timeout });
    }

    /**
     * Clears the Timeout
     * @returns {Void}
     */
    clearTimeout() {
        if (this.state.timeout) {
            window.clearTimeout(this.state.timeout);
            this.setState({ timeout : null });
        }
    }

    /**
     * Closes the Added
     * @returns {Void}
     */
    handleClose = () => {
        this.props.setAdded(null);
        this.clearTimeout();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { added, cart, settings } = this.props;

        if (!added) {
            return <React.Fragment />;
        }

        const showCode   = Boolean(settings.products_showCode && added.productCode);
        const showBrand  = Boolean(settings.products_showBrand && added.brandName);
        const showArtist = Boolean(settings.products_showArtist && added.artistName);
        const multiCoins = cart.totals.length > 1;

        return <div className="product-added">
            <div className="product-added-content">
                <ProductImage
                    className="product-added-image"
                    variant="small"
                    data={added}
                />
                <div className="product-added-product">
                    <h2 className="product-added-title">{NLS.get("CART_SUCCESS_ADD")}</h2>
                    {showCode && <h4>{added.productCode}</h4>}
                    {showBrand && <h4>{added.brandName}</h4>}
                    {showArtist && <h4>{added.artistName}</h4>}
                    <h3>{added.name}</h3>
                    {!!added.variantName && <p>{added.variantName}</p>}
                    <ProductPrice className="product-added-price" data={added} amount={1} />
                </div>
            </div>
            {cart.totals.map((total) => <div key={total.id} className="product-added-totals">
                <section className="product-added-total">
                    {multiCoins ?
                        <h4>{NLS.format("CART_TOTAL", total.name)}</h4> :
                        <h4>{NLS.pluralize("CART_UNITS", total.amount)}</h4>}
                    <Price symbol={total.currencySymbol} price={total.finalFormat} />
                </section>
            </div>)}
            <Button
                className="product-added-cart"
                variant="primary"
                icon="cart"
                message="PRODUCTS_SHOW_TO_CART"
                url="CART"
                onClick={this.handleClose}
            />
            <HyperLink
                className="product-added-close"
                variant="icon"
                icon="close"
                onClick={this.handleClose}
            />
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setAdded : PropTypes.func.isRequired,
        settings : PropTypes.object.isRequired,
        added    : PropTypes.object,
        cart     : PropTypes.object,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            added    : state.product.added,
            cart     : state.cart.elem,
        };
    }
}

export default connect(ProductAdded.mapStateToProps, {
    setAdded,
})(ProductAdded);
