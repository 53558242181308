import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/Core/NLS";
import Href                 from "Utils/Core/Href";
import Analitics            from "Utils/Core/Analitics";

// Components
import CartSummary          from "Components/Cart/Utils/CartSummary";
import Card                 from "Components/Utils/Common/Card";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    unconfirmToProducts, setCartRedirect,
} from "Actions/Store/CartActions";



/**
 * The Account Content
 */
class AccountContent extends React.Component {
    // The Current State
    state = {
        loading : false,
    }



    /**
     * Handles a Cart Click
     * @returns {Void}
     */
    handleClick = () => {
        this.props.setCartRedirect(Href.url("CART"));
    }

    /**
     * Handles a Cart Cancel
     * @returns {Promise}
     */
    handleCancel = async () => {
        const { elem, unconfirmToProducts, openAlert, closeAlert, onSubmit } = this.props;
        if (this.state.loading) {
            return;
        }

        this.setState({ loading : true });
        closeAlert();
        try {
            await unconfirmToProducts(elem.orderHash);
            Analitics.checkout(Analitics.stepProducts, elem.products);
            onSubmit();
        } catch (errors) {
            openAlert("", errors.form);
            this.setState({ loading : false });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, openAlert } = this.props;

        return <>
            <section className="cart-content">
                <Card className="cart-auth" withSpacing withBorder>
                    <header>
                        <h3>{NLS.get("CART_AUTH_HELP")}</h3>
                        <Button
                            variant="primary"
                            url="LOGIN"
                            onClick={this.handleClick}
                            message="AUTH_LOGIN_LINK"
                        />
                    </header>
                    {!!settings.clients_allowRegister && <footer>
                        <h3>{NLS.get("AUTH_REGISTER_TITLE")}</h3>
                        <Button
                            variant="primary"
                            url="REGISTER"
                            onClick={this.handleClick}
                            message="AUTH_REGISTER_LINK"
                        />
                    </footer>}
                </Card>
            </section>

            <CartSummary
                step="account"
                openAlert={openAlert}
                onCancel={this.handleCancel}
                isDisabled={true}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        unconfirmToProducts : PropTypes.func.isRequired,
        setCartRedirect     : PropTypes.func.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        settings            : PropTypes.object.isRequired,
        elem                : PropTypes.object.isRequired,
        openAlert           : PropTypes.func.isRequired,
        closeAlert          : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            elem     : state.cart.elem,
        };
    }
}

export default connect(AccountContent.mapStateToProps, {
    unconfirmToProducts, setCartRedirect,
})(AccountContent);
