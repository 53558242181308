import React                from "react";
import PropTypes            from "prop-types";

// Components
import Html                 from "Components/Utils/Common/Html";
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Product Desc
 */
class ProductDesc extends React.Component {
    // The Current State
    state = {
        fullText : false,
    }

    /**
     * Handles the Click
     * @returns {Void}
     */
    handleClick = () => {
        this.setState({ fullText : !this.state.fullText });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, description, maxLength, withMore } = this.props;
        const { fullText                                    } = this.state;

        const length   = fullText && withMore ? 0 : maxLength;
        const showMore = withMore && !fullText && description.length > maxLength;

        return <div className={`product-desc ${className}`}>
            <Html
                content={description}
                maxLength={length}
            />
            {showMore && <HyperLink
                variant="blue"
                message={fullText ? "PRODUCTS_SHOW_LESS" : "PRODUCTS_SHOW_MORE"}
                onClick={this.handleClick}
            />}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        description : PropTypes.string.isRequired,
        className   : PropTypes.string,
        maxLength   : PropTypes.number,
        withMore    : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
        maxLength : 0,
        withMore  : false,
    }
}

export default ProductDesc;
