import React                from "react";
import PropTypes            from "prop-types";



/**
 * The Table Foot
 */
class TableFoot extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { hasActions, children } = this.props;
        const childrenWithProps        = React.Children.map(children, (child) => (
            React.cloneElement(child, { isHead : true })
        ));

        return <tfoot>
            <tr>
                {childrenWithProps}
                {hasActions && <th />}
            </tr>
        </tfoot>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        hasActions : PropTypes.bool,
        children   : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        hasActions : false,
    }
}

export default TableFoot;
