import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchProduct }     from "Actions/Store/ProductActions";
import Href                 from "Utils/Core/Href";

// Components
import ProductContent       from "Components/Product/Item/ProductContent";
import ProductsView         from "Components/Product/List/ProductsView";
import BrandList            from "Components/Content/Sections/BrandList";
import ArtistList           from "Components/Content/Artist/ArtistList";
import Loading              from "Components/Utils/Common/Loading";
import Title                from "Components/Utils/Title/Title";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Breadcrumb           from "Components/Utils/Common/Breadcrumb";
import ErrorPage            from "Components/Content/ErrorPage";



/**
 * The Product Elem
 */
class ProductElem extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetchProduct();
    }

    /**
     * Reload the Data
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.match.params.product !== prevProps.match.params.product) {
            this.fetchProduct();
        }
    }

    /**
     * Load the Product
     * @returns {Void}
     */
    fetchProduct() {
        const { orderHash, match, fetchProduct } = this.props;
        fetchProduct(match.params.product, orderHash);
    }



    /**
     * Generates the Crumbs and back Url
     * @returns {Object}
     */
    generateCrumbs() {
        const { settings, elem : { product }, match : { params }} = this.props;
        const crumbs  = [];
        let   baseUrl = Href.url("PRODUCTS");

        crumbs.push({
            to   : baseUrl,
            name : settings.products_name || "PRODUCTS_NAME",
        });
        if (params.category && product.categoryID) {
            baseUrl += `/${product.categorySlug}`;
            crumbs.push({
                to   : baseUrl,
                name : product.categoryName,
            });
        }
        if (params.subcategory && product.subcategoryID) {
            baseUrl += `/${product.subcategorySlug}`;
            crumbs.push({
                to   : baseUrl,
                name : product.subcategoryName,
            });
        }
        if (params.tercategory && product.tercategoryID) {
            baseUrl += `/${product.tercategorySlug}`;
            crumbs.push({
                to   : baseUrl,
                name : product.tercategoryName,
            });
        }
        crumbs.push({
            to   : `${baseUrl}/${product.slug}`,
            name : product.name,
        });
        return { baseUrl, crumbs };
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, error, elem, settings } = this.props;
        const { baseUrl, crumbs                } = this.generateCrumbs();

        if (error) {
            return <ErrorPage />;
        }
        return <>
            <Title message={elem.product.name || "GENERAL_LOADING"} href={baseUrl} />
            <Wrapper withSpacing>
                {loading ? <Loading /> : <>
                    <Breadcrumb data={crumbs} withBorder />
                    <ProductContent
                        product={elem.product}
                        onBuyMore={() => Href.goto(baseUrl)}
                        showTitle
                    />

                    <ProductsView
                        isHidden={!settings.products_moreFromCategory}
                        className="section"
                        message="PRODUCTS_RELATED_TITLE"
                        href={Href.url("PRODUCTS", elem.product.categorySlug)}
                        list={elem.relCategory}
                        showButton
                    />
                    <ProductsView
                        isHidden={!settings.products_moreFromBrand}
                        className="section"
                        message="BRANDS_RELATED_PRODUCTS"
                        href={Href.url("BRAND", elem.product.brandSlug)}
                        list={elem.relBrand}
                        showButton
                    />
                    <ProductsView
                        isHidden={!settings.products_moreFromArtist}
                        className="section"
                        message="ARTISTS_RELATED_PRODUCTS"
                        href={Href.url("ARTIST", elem.product.artistSlug)}
                        list={elem.relArtist}
                        showButton
                    />

                    <BrandList
                        isHidden={!settings.products_showBrands}
                        className="section"
                        message="BRANDS_TITLE"
                        list={elem.brands}
                        asSlider
                        showButton
                    />
                    <ArtistList
                        isHidden={!settings.products_showArtists}
                        className="section"
                        message="ARTISTS_TITLE"
                        list={elem.artists}
                        asSlider
                        showButton
                    />
                </>}
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchProduct : PropTypes.func.isRequired,
        settings     : PropTypes.object.isRequired,
        loading      : PropTypes.bool.isRequired,
        error        : PropTypes.bool.isRequired,
        elem         : PropTypes.object.isRequired,
        orderHash    : PropTypes.string.isRequired,
        onFavorite   : PropTypes.func,
        match        : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings  : state.core.settings,
            loading   : state.product.loading,
            error     : state.product.error,
            elem      : state.product.elem,
            orderHash : state.cart.elem.orderHash,
        };
    }
}

export default connect(ProductElem.mapStateToProps, {
    fetchProduct,
})(ProductElem);
