import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { setPath }          from "Actions/Core/CoreActions";
import Analitics            from "Utils/Core/Analitics";
import Metrics              from "Utils/Core/Metrics";
import Utils                from "Utils/Common/Utils";



/**
 * Scrolls to the Top on location change
 */
class ScrollToTop extends React.Component {
    /**
     * Scroll to the Top
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const location     = this.props.location;
        const prevLocation = prevProps.location;

        if (location.pathname !== prevLocation.pathname) {
            Analitics.pageView(location.pathname);
            Metrics.pageView(location.pathname);
            this.props.setPath(location.pathname);
        }
        if (location.pathname !== prevLocation.pathname || location.search !== prevLocation.search) {
            const top = Utils.getScrollTop();
            if (top > 0) {
                this.speed = Math.max(Math.floor(top / 40), 100);
                this.scrollUp();
            }
        }
    }

    /**
     * Scrolls to the top smoothly
     * @returns {Void}
     */
    scrollUp = () => {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
        const top = Utils.getScrollTop();
        if (top > 0) {
            window.scrollTo(0, top - this.speed);
            this.timeout = window.setTimeout(this.scrollUp, 10);
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return this.props.children;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setPath  : PropTypes.func.isRequired,
        location : PropTypes.object.isRequired,
        children : PropTypes.any.isRequired,
    }
}

export default withRouter(connect(null, { setPath })(ScrollToTop));
