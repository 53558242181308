import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Responsive           from "Utils/App/Responsive";

// Components
import TerTitle             from "Components/Utils/Title/TerTitle";
import Pagination           from "Components/Utils/Common/Pagination";
import Slider               from "Components/Utils/Common/Slider";
import Html                 from "Components/Utils/Common/Html";
import Icon                 from "Components/Utils/Media/Icon";

// Styles
import "Styles/Components/Content/Sections/TestimonialList.css";



/**
 * The Testimonial List
 */
class TestimonialList extends React.Component {
    /**
     * Does the Content Render
     * @returns {Object}
     */
    renderContent() {
        const result = [];
        for (const elem of this.props.list) {
            const stars = [];
            for (let i = 0; i < elem.stars; i++) {
                stars.push(<Icon key={i} variant="favorite" />);
            }
            result.push(<div key={elem.id} className="testimonial-item">
                <div
                    className="testimonial-item-image"
                    style={{ "backgroundImage" : `url(${elem.imageUrl})` }}
                />
                <div className="testimonial-item-content">
                    <h3>{elem.title}</h3>
                    {!!stars.length && <div>{stars}</div>}
                    <Html variant="p" content={elem.description} />
                    <h4>{elem.author}</h4>
                </div>
            </div>);
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            responsive, message, list, total, asSlider, cols,
            showAll, perPage, url, page,
        } = this.props;

        if (!list.length) {
            return <React.Fragment />;
        }
        const count = Responsive.calcTestomonialsCols(responsive, cols);
        const style = { "--testimonial-cols" : count };

        return <section className="testimonial-list">
            <TerTitle message={message} />
            {asSlider ? <Slider
                className="testimonial-list-slider"
                total={list.length}
                count={count}
                outsideNav
            >
                {this.renderContent()}
            </Slider> : <div className="testimonial-list-grid" style={style}>
                {this.renderContent()}
                <Pagination
                    isHidden={!showAll}
                    currentPage={page}
                    totalItems={total}
                    perPage={perPage}
                    url={url}
                />
            </div>}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        responsive : PropTypes.object.isRequired,
        message    : PropTypes.string,
        list       : PropTypes.array.isRequired,
        total      : PropTypes.number.isRequired,
        asSlider   : PropTypes.bool,
        cols       : PropTypes.number,
        showAll    : PropTypes.bool.isRequired,
        perPage    : PropTypes.number.isRequired,
        url        : PropTypes.string.isRequired,
        page       : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
        };
    }
}

export default connect(TestimonialList.mapStateToProps)(TestimonialList);
