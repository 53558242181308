import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import ProductPrice         from "Components/Product/Item/ProductPrice";

// Styles
import "Styles/Components/Product/Card/ProductCardVariants.css";



/**
 * The Product Card Variants
 */
class ProductCardVariants extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, data        } = this.props;
        const { hasVariants, variants } = data;

        const showPrice = settings.products_showPrice;

        if (!hasVariants) {
            return <React.Fragment />;
        }
        return <ul className="product-variants no-list">
            {variants.map((subelem) => <li key={subelem.variantID}>
                <h4>{subelem.name}</h4>
                {showPrice && <ProductPrice
                    className="product-card-price"
                    data={subelem}
                />}
            </li>)}
        </ul>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
        data     : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(ProductCardVariants.mapStateToProps)(ProductCardVariants);
