import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { setNotiRedirect }  from "Actions/Content/NotificationActions";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";

// Components
import Icon                 from "Components/Utils/Media/Icon";

// Styles
import "Styles/Components/Utils/Common/HyperLink.css";



/**
 * A HyperLink
 */
class HyperLink extends React.Component {
    /**
     * Handles the Click
     * @param {Event} e
     * @returns {Void}
     */
    onClick = (e) => {
        const {
            url, history, onClick, target,
            forPhone, forEmail, forWhatsApp, forMessenger, dontStop,
            platform, setNotiRedirect, isDisabled,
        } = this.props;

        const href  = url ? Href.url(url) : this.props.href;
        let clicked = false;
        let handled = false;

        if (isDisabled) {
            e.stopPropagation();
            e.preventDefault();
            return;
        }
        if (onClick) {
            onClick(e);
            clicked = true;
        }

        // Href Links
        if (href && href !== "#" && !forPhone && !forEmail && !forWhatsApp && !forMessenger) {
            if (href === Href.url("LOGIN")) {
                setNotiRedirect(history.location.pathname);
            }

            // Internal Links that start with the URL
            if (Href.isInternal(href) && target !== "_blank") {
                history.push(Href.getUrl(href));
                handled = true;

            // Internal link that are paths
            } else if (!href.startsWith("http") && target !== "_blank") {
                history.push(href);
                handled = true;

            // Voucher paths in Android
            } else if (Href.forVoucher(href) && platform === "android") {
                navigator.app.loadUrl(href, { openExternal : true });
                handled = true;

            // Voucher paths in iOS
            } else if (Href.forVoucher(href) && platform === "ios") {
                window.cordova.InAppBrowser.open(href, "_system");
                handled = true;
            }
        }

        // WhatsApp in iOS
        if (!handled && forWhatsApp && platform === "ios") {
            window.cordova.InAppBrowser.open(`https://wa.me/${href}`, "_system");
            handled = true;
        }

        if (handled || (clicked && !dontStop)) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    /**
     * Returns the Href and Target
     * @returns {{href: String, target: String}}
     */
    getHref() {
        const { url, href, target, forPhone, forEmail, forWhatsApp, forMessenger, isApp } = this.props;
        const result = { href, target };

        if (url) {
            result.href   = Href.url(url);
            result.target = target;
        } else if (forPhone) {
            result.href = `tel:${href}`;
        } else if (forEmail) {
            result.href = `mailto:${href}`;
        } else if (forWhatsApp) {
            const number = String(href).replace(/ /g, "").replace(/-/g, "");
            if (isApp) {
                result.href   = `whatsapp://send?phone=${number}`;
            } else {
                result.href   = `https://wa.me/${number}`;
                result.target = "_blank";
            }
        } else if (forMessenger) {
            result.href   = `https://m.me/${href}`;
            result.target = "_blank";
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            isButton, variant, message, children, icon, afterIcon, badge,
            className, showBold, onMouseEnter, onMouseLeave, onTouchEnd, style,
        } = this.props;

        const { href, target } = this.getHref();
        const content   = children || NLS.get(message);
        const baseClass = isButton ? `btn btn-${variant}` : `link link-${variant}`;
        const iconClass = icon && variant !== "icon" && content ? "link-with" : "";

        return <a className={`${baseClass} ${className} ${iconClass} ${showBold ? "link-bold" : ""}`}
            href={href}
            target={target}
            onClick={this.onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onTouchEnd={onTouchEnd}
            style={style}
        >
            {!!icon      && <Icon className="link-preicon" variant={icon} />}
            {!!content   && <span className="link-content">{content}</span>}
            {!!afterIcon && <Icon className="link-aftericon" variant={afterIcon} />}
            {badge > 0   && <span className="badge">{badge}</span>}
        </a>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history         : PropTypes.object.isRequired,
        setNotiRedirect : PropTypes.func.isRequired,
        platform        : PropTypes.string,
        isApp           : PropTypes.bool,
        isButton        : PropTypes.bool,
        isDisabled      : PropTypes.bool,
        variant         : PropTypes.string,
        message         : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        href            : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        url             : PropTypes.string,
        target          : PropTypes.string,
        forPhone        : PropTypes.bool,
        forEmail        : PropTypes.bool,
        forWhatsApp     : PropTypes.bool,
        forMessenger    : PropTypes.bool,
        icon            : PropTypes.string,
        afterIcon       : PropTypes.string,
        badge           : PropTypes.number,
        className       : PropTypes.string,
        showBold        : PropTypes.bool,
        dontStop        : PropTypes.bool,
        onClick         : PropTypes.func,
        onMouseEnter    : PropTypes.func,
        onMouseLeave    : PropTypes.func,
        onTouchEnd      : PropTypes.func,
        style           : PropTypes.object,
        children        : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        isButton     : false,
        isDisabled   : false,
        variant      : "primary",
        href         : "#",
        target       : "_self",
        badge        : 0,
        className    : "",
        showBold     : false,
        forPhone     : false,
        forEmail     : false,
        forWhatsApp  : false,
        forMessenger : false,
        dontStop     : false,
        style        : {},
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp    : state.core.isApp,
            platform : state.core.platform,
        };
    }
}

export default withRouter(connect(HyperLink.mapStateToProps, {
    setNotiRedirect,
})(HyperLink));
