import API                  from "Utils/Core/API";


// Types
import {
    CORE_DATA, CORE_CONFIG, CORE_PLATFORM,
    CORE_PATH, CORE_RESPONSIVE,
    CORE_LOADING, CORE_REDIRECT, CORE_RESULT,
} from "Utils/Core/Types";



/**
 * Fetches the Core Data
 * @returns {Function}
 */
export function fetchCore() {
    return async (dispatch) => {
        const data = await API.Store.getSettings();
        dispatch({ type : CORE_DATA, data });
    };
}

/**
 * Sets the App Config
 * @param {Boolean} isApp
 * @param {Boolean} isCordova
 * @returns {{type: String, data: Object}}
 */
export function setConfig(isApp, isCordova) {
    return { type : CORE_CONFIG, data : { isApp, isCordova } };
}

/**
 * Sets the Platform
 * @param {String} data
 * @returns {{type: String, data: Object}}
 */
export function setPlatform(data) {
    return { type : CORE_PLATFORM, data };
}



/**
 * Sets the Path
 * @param {String} path
 * @returns {{type: String, data: String}}
 */
 export function setPath(path) {
    return async (dispatch) => {
        dispatch({ type : CORE_PATH, data : path });
    };
}

/**
 * Sets the Responsive Modes
 * @param {Object} data
 * @returns {Function}
 */
export function setResponsive(data) {
    return async (dispatch) => {
        dispatch({ type : CORE_RESPONSIVE, data });
    };
}



/**
 * Sets the Loading
 * @param {Boolean} data
 * @returns {{type: String, data: Boolean}}
 */
export function setLoading(data) {
    return { type : CORE_LOADING, data };
}

/**
 * Sets the Redirect
 * @param {String} data
 * @returns {{type: String, data: String}}
 */
export function setRedirect(data) {
    return { type : CORE_REDIRECT, data };
}



/**
 * Shows the Result
 * @param {String} type
 * @param {String} message
 * @returns {{type: String, data: Object}}
 */
export function showResult(type, message) {
    const data = { open : true, type, message };
    return { type : CORE_RESULT, data };
}

/**
 * Hides the Result
 * @returns {{type: String, data: Object}}
 */
export function hideResult() {
    const data = { open : false, type : "", message : "" };
    return { type : CORE_RESULT, data };
}
