// Constants
const MICRO_WIDTH   = 500;
const MINI_WIDTH    = 650;
const MOBILE_WIDTH  = 850;
const MOBILE_HEIGHT = 450;
const TABLET_WIDTH  = 1050;
const LAPTOP_WIDTH  = 1200;



/**
 * Returns the Responsive conditions
 * @returns {Object}
 */
function getConditions() {
    const width     = window.innerWidth;

    const isMicro   = width <= MICRO_WIDTH;
    const isMini    = width > MICRO_WIDTH  && width <= MINI_WIDTH;
    const isMobile  = width > MINI_WIDTH   && width <= MOBILE_WIDTH;
    const isTablet  = width > MOBILE_WIDTH && width <= TABLET_WIDTH;
    const isLaptop  = width <= LAPTOP_WIDTH;
    const isDesktop = width > LAPTOP_WIDTH;

    return { width, isMicro, isMini, isMobile, isTablet, isLaptop, isDesktop };
}

/**
 * Returns true if we are Mobile
 * @param {Object} conditions
 * @returns {Boolean}
 */
function isMobile(conditions) {
    return conditions.isMicro || conditions.isMini || conditions.isMobile;
}

/**
 * Returns the Url depending on the if we are Mobile
 * @param {Object}  conditions
 * @param {String}  desktopUrl
 * @param {String=} mobileUrl
 * @returns {String}
 */
function getUrl(conditions, desktopUrl, mobileUrl) {
    return isMobile(conditions) && mobileUrl ? mobileUrl : desktopUrl;
}

/**
 * Calculates the columns depending on the given params
 * @param {Object} conditions
 * @param {Number} maxCols
 * @param {Number} minCols
 * @param {Number} amount
 * @returns {Number}
 */
function calcColumns(conditions, maxCols, minCols, amount) {
    if (conditions.width > LAPTOP_WIDTH) {
        return maxCols;
    }
    const pcols  = 1 / maxCols;
    const pmin   = pcols * (maxCols - 1);
    const pwidth = conditions.width / LAPTOP_WIDTH;

    if (pmin > pwidth) {
        return minCols;
    }
    return maxCols;
}

/**
 * Calculates the Products columns depending on the conditions
 * @param {Object}  conditions
 * @param {Boolean} lessDesktop
 * @returns {Number}
 */
function calcProductsCols(conditions, lessDesktop) {
    if (conditions.isTablet) {
        return 4;
    }
    if (conditions.isMobile) {
        return 3;
    }
    if (conditions.isMicro || conditions.isMini) {
        return 2;
    }
    return lessDesktop ? 4 : 5;
}

/**
 * Calculates the Image columns depending on the conditions
 * @param {Object} conditions
 * @returns {Number}
 */
function calcImageCols(conditions) {
    if (conditions.isTablet) {
        return 4;
    }
    if (conditions.isMobile) {
        return 3;
    }
    if (conditions.isMicro || conditions.isMini) {
        return 2;
    }
    return 8;
}

/**
 * Calculates the Artist Products columns depending on the conditions
 * @param {Object} conditions
 * @returns {Number}
 */
function calcArtistCols(conditions) {
    if (conditions.isTablet) {
        return 4;
    }
    if (conditions.isMobile) {
        return 3;
    }
    if (conditions.isMicro || conditions.isMini) {
        return 2;
    }
    return 5;
}

/**
 * Calculates the Entries columns depending on the conditions
 * @param {Object} conditions
 * @returns {Number}
 */
function calcEntryCols(conditions) {
    if (isMobile(conditions)) {
        return 1;
    }
    return 2;
}

/**
 * Calculates the Testimonials columns depending on the conditions
 * @param {Object} conditions
 * @param {Number} maxCols
 * @returns {Number}
 */
function calcTestomonialsCols(conditions, maxCols) {
    if (isMobile(conditions)) {
        return 1;
    }
    if (conditions.isLaptop) {
        return Math.min(2, maxCols);
    }
    return maxCols;
}

/**
 * Calculates the Articles columns depending on the conditions
 * @param {Object} conditions
 * @param {Number} maxCols
 * @returns {Number}
 */
function calcArticlesCols(conditions, maxCols) {
    if (isMobile(conditions)) {
        return 1;
    }
    if (conditions.isLaptop) {
        return Math.min(2, maxCols);
    }
    return maxCols;
}




// The Public API
export default {
    MICRO_WIDTH,
    MINI_WIDTH,
    MOBILE_WIDTH,
    MOBILE_HEIGHT,
    TABLET_WIDTH,
    LAPTOP_WIDTH,

    getConditions,
    isMobile,
    getUrl,
    calcColumns,
    calcProductsCols,
    calcImageCols,
    calcArtistCols,
    calcEntryCols,
    calcTestomonialsCols,
    calcArticlesCols,
};
