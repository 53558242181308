import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Responsive           from "Utils/App/Responsive";

// Components
import TerTitle             from "Components/Utils/Title/TerTitle";
import Slider               from "Components/Utils/Common/Slider";
import Html                 from "Components/Utils/Common/Html";
import Button               from "Components/Utils/Form/Button";
import ImageLink            from "Components/Utils/Media/ImageLink";

// Styles
import "Styles/Components/Content/Sections/BlockList.css";



/**
 * The Block List
 */
class BlockList extends React.Component {
    /**
     * Does the Content Render
     * @returns {Object}
     */
    renderContent() {
        const { isMicro, list, cols } = this.props;
        const result = [];

        for (const elem of list) {
            switch (elem.type) {
            case "button":
                result.push(<Button
                    key={elem.id}
                    variant="primary"
                    href={elem.link}
                    message={elem.name}
                />);
                break;

            case "image": {
                let image = elem.desktopImageUrl;
                if (elem.mobileImageUrl && (isMicro || cols > 1)) {
                    image = elem.mobileImageUrl;
                }
                result.push(<ImageLink
                    key={elem.id}
                    href={elem.link}
                    source={image}
                    name={elem.name}
                    description={elem.description}
                    location={elem.location}
                    imageSize={elem.imageSize || "half"}
                />);
                break;
            }

            case "text":
                result.push(<Html
                    key={elem.id}
                    className="block-text"
                    content={elem.content}
                />);
                break;

            default:
            }
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { responsive, message, list, cols, minCols, asSlider } = this.props;

        if (!list.length) {
            return <React.Fragment />;
        }
        const count = Responsive.calcColumns(responsive, cols, minCols, list.length);
        const style = { "--block-cols" : count };

        return <section className="block-list">
            <TerTitle message={message} />
            {asSlider ? <Slider
                className="block-list-slider"
                total={list.length}
                count={count}
                outsideNav
            >
                {this.renderContent()}
            </Slider> : <div className="block-list-grid" style={style}>
                {this.renderContent()}
            </div>}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        responsive : PropTypes.object.isRequired,
        message    : PropTypes.string,
        list       : PropTypes.array.isRequired,
        cols       : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        minCols    : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        asSlider   : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
        minCols   : 1,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
        };
    }
}

export default connect(BlockList.mapStateToProps)(BlockList);
