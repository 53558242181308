import React                from "react";
import PropTypes            from "prop-types";

// Components
import HeaderWrapper        from "Components/Header/HeaderWrapper";
import HeaderLogo           from "Components/Header/HeaderLogo";
import MenuBar              from "Components/Header/Bar/MenuBar";
import UserBar              from "Components/Header/Bar/UserBar";
import SubMenuBar           from "Components/Header/Bar/SubMenuBar";



/**
 * The One Center Header
 * One Line with Logo on the Center
 */
class OneCenterHeader extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <HeaderWrapper design="one-center" withSearch>
            <div className="header-header">
                <MenuBar />
                <HeaderLogo />
                <UserBar showSearch />
            </div>
            <SubMenuBar />
        </HeaderWrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fullWidth : PropTypes.bool,
    }
}

export default OneCenterHeader;
