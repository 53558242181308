import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Responsive           from "Utils/App/Responsive";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import MultiLine            from "Components/Utils/Common/MultiLine";

// Styles
import "Styles/Components/Utils/Media/ImageLink.css";



/**
 * The Image Link
 */
class ImageLink extends React.Component {
    /**
     * Does the Content Render
     * @returns {Object}
     */
    renderContent() {
        const { responsive, source, mobileSource, name, description, location, imageSize } = this.props;

        const image = Responsive.getUrl(responsive, source, mobileSource);

        let content = "";
        if (location && location !== "none") {
            content = <div className={`image-link image-link-${location} image-link-${imageSize}`}>
                <div className="image-link-image">
                    <img src={image} alt={name} />
                </div>
                <div className="image-link-content">
                    <h3>{name}</h3>
                    {!!description && <MultiLine variant="p">{description}</MultiLine>}
                </div>
            </div>;
        } else if (image) {
            content = <img src={image} alt={name} />;
        } else {
            content = <h2>{name}</h2>;
        }
        return content;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, variant, source, name, href, url, target, onClick } = this.props;

        if (!source && !name) {
            return <React.Fragment />;
        }

        if (!href && !url) {
            return <div className={className}>
                {this.renderContent()}
            </div>;
        }

        return <HyperLink
            variant={variant}
            className={className}
            href={href}
            url={url}
            target={target}
            onClick={onClick}
        >
            {this.renderContent()}
        </HyperLink>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        responsive   : PropTypes.object.isRequired,
        className    : PropTypes.string,
        variant      : PropTypes.string,
        href         : PropTypes.string,
        url          : PropTypes.string,
        target       : PropTypes.string,
        onClick      : PropTypes.func,
        source       : PropTypes.string,
        mobileSource : PropTypes.string,
        name         : PropTypes.string,
        description  : PropTypes.string,
        location     : PropTypes.string,
        imageSize    : PropTypes.string,
    }

    /**
     * The Default Properties
     * @type {Object} defaultProps
     */
    static defaultProps = {
        className : "",
        variant   : "none",
        location  : "none",
        imageSize : "half",
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
        };
    }
}

export default connect(ImageLink.mapStateToProps)(ImageLink);
