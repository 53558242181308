import API                  from "Utils/Core/API";

// Types
import {
    ORDER_LOADING, ORDER_LIST, ORDER_ELEM,
} from "Utils/Core/Types";



/**
 * Fetches the Order List
 * @param {Object} params
 * @returns {Function}
 */
export function fetchOrders(params) {
    return async (dispatch) => {
        dispatch({ type : ORDER_LOADING });
        const data = await API.Order.getOrders(params);
        dispatch({ type : ORDER_LIST, data });
    };
}

/**
 * Fetches the Order Data
 * @param {Number} orderID
 * @returns {Function}
 */
export function fetchOrder(orderID) {
    return async (dispatch) => {
        dispatch({ type : ORDER_LOADING });
        const data = await API.Order.getOrder({ orderID });
        dispatch({ type : ORDER_ELEM, data });
    };
}



/**
 * Sends an Order Voucher
 * @param {Object} params
 * @returns {Function}
 */
export function sendVoucher(params) {
    return async (dispatch) => {
        const data = await API.Order.sendVoucher(params);
        dispatch({ type : ORDER_ELEM, data });
        return { success : data.success };
    };
}
