import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setPreview }       from "Actions/Store/ProductActions";
import Utils                from "Utils/Common/Utils";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Product/Item/ProductPreview.css";



/**
 * The Product Preview
 */
class ProductPreview extends React.Component {
    /**
     * Closes the Preview
     * @returns {Void}
     */
    handleClose = () => {
        this.props.setPreview(null);
    }

    /**
     * Handles the Dialog Backdrop Close
     * @param {Event} e
     * @returns {Void}
     */
    handleBackdrop = (e) => {
        if (!Utils.hasClass(e.target, "product-preview-image")) {
            this.handleClose();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { preview } = this.props;

        if (!preview) {
            return <React.Fragment />;
        }
        return <div className="product-preview">
            <div className="product-preview-content" onClick={this.handleBackdrop}>
                <ProductImage
                    className="product-preview-image"
                    variant="large"
                    data={preview}
                    sliderIndex={preview.index}
                    showVideo
                />
            </div>
            <HyperLink
                className="product-preview-close"
                variant="icon"
                icon="close"
                onClick={this.handleClose}
            />
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setPreview : PropTypes.func.isRequired,
        preview    : PropTypes.object,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            preview : state.product.preview,
        };
    }
}

export default connect(ProductPreview.mapStateToProps, {
    setPreview,
})(ProductPreview);
