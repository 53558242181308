import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";
import Responsive           from "Utils/App/Responsive";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import TerTitle             from "Components/Utils/Title/TerTitle";
import Pagination           from "Components/Utils/Common/Pagination";
import Slider               from "Components/Utils/Common/Slider";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Content/Artist/ArtistProducts.css";



/**
 * The Artist Products
 */
class ArtistProducts extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { responsive, message, list, total, perPage, url, page } = this.props;

        if (!list.length) {
            return <React.Fragment />;
        }
        const count = Responsive.calcArtistCols(responsive);

        return <section>
            <TerTitle message={message} />
            {list.map((elem) => <section key={elem.id} className="artist-products-item">
                <div className="artist-products-info">
                    <h2>{elem.name}</h2>
                    <div className="artist-products-bio">
                        {!!elem.nationality && <p>{elem.nationality}</p>}
                        {!!elem.birthYear && <p>{NLS.format("ARTISTS_BIRTH_YEAR", elem.birthYear)}</p>}
                    </div>
                    <div className="artist-products-links">
                        <HyperLink
                            variant="opacity"
                            message="ARTISTS_SEE_BIOGRAPHY"
                            icon="prompt"
                            href={Href.url("ARTIST_BIO", elem.url)}
                        />
                        <HyperLink
                            variant="opacity"
                            message="ARTISTS_SEE_ARTWORKS"
                            icon="artist"
                            href={Href.url("ARTIST", elem.url)}
                        />
                    </div>
                </div>
                <div className="artist-products-slide">
                    <Slider total={elem.products.length} count={count} outsideNav>
                        {elem.products.map((product) => <div key={product.productID}>
                            <ProductImage
                                variant="small"
                                data={product}
                                zoomOnHover
                                clickView
                            />
                        </div>)}
                    </Slider>
                </div>
            </section>)}

            <Pagination
                currentPage={page}
                totalItems={total}
                perPage={perPage}
                url={url}
            />
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        responsive : PropTypes.object.isRequired,
        message    : PropTypes.string,
        list       : PropTypes.array.isRequired,
        total      : PropTypes.number.isRequired,
        perPage    : PropTypes.number.isRequired,
        url        : PropTypes.string.isRequired,
        page       : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
        };
    }
}

export default connect(ArtistProducts.mapStateToProps)(ArtistProducts);
