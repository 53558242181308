import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/Core/NLS";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import ProductPrice         from "Components/Product/Item/ProductPrice";
import HyperLink            from "Components/Utils/Common/HyperLink";
import NumberField          from "Components/Utils/Form/NumberField";



/**
 * The Product List
 */
class ProductList extends React.Component {
    /**
     * Does the Element Render
     * @param {Object} elem
     * @param {Number} index
     * @returns {Object}
     */
    renderElem(elem, index) {
        const { settings, errors, onEdit, onDelete, loading } = this.props;

        const itemID     = `${elem.productID}-${elem.variantID}`;
        const showCode   = Boolean(settings.products_showCode && elem.productCode);
        const showBrand  = Boolean(settings.products_showBrand && elem.brandName);
        const showArtist = Boolean(settings.products_showArtist && elem.artistName);
        const hasError   = Boolean(errors && errors[itemID] !== undefined);
        const totalStock = hasError && errors[itemID] > -1 ? errors[itemID] : 0;

        return <React.Fragment key={index}>
            <tr>
                <td className="cart-image" rowSpan={hasError ? 2 : 1}>
                    <ProductImage
                        variant="small"
                        data={elem}
                        clickPreview
                    />
                </td>
                <td>
                    <div className="cart-product">
                        {showCode && <h4>{elem.productCode}</h4>}
                        {showBrand && <h4>{elem.brandName}</h4>}
                        {showArtist && <h4>{elem.artistName}</h4>}
                        <h3>{elem.name}</h3>
                        {!!elem.variantName && <p>{elem.variantName}</p>}
                        {!!elem.stock && <p className="cart-stock">
                            {NLS.format("CART_STOCK", elem.stock)}
                        </p>}
                    </div>
                </td>
                <td>
                    <div className="cart-column">
                        <h4>{NLS.get("CART_AMOUNT")}</h4>
                        {elem.showAmount ? <div className="cart-amount">
                            <NumberField
                                variant="medium"
                                name="amount"
                                value={elem.amount}
                                onChange={(name, amount) => onEdit(elem, amount)}
                                isDisabled={loading}
                            />
                        </div> : <div className="cart-price product-price">
                            {elem.amount}
                        </div>}
                    </div>
                </td>
                <td>
                    <div className="cart-column">
                        <h4>{NLS.get("CART_PRICE")}</h4>
                        <ProductPrice className="cart-price" data={elem.price} />
                    </div>
                </td>
                <td>
                    <div className="cart-column">
                        <h4>{NLS.get("CART_SUBTOTAL")}</h4>
                        <ProductPrice className="cart-price" data={elem.subtotal} />
                    </div>
                </td>
                <td>
                    <HyperLink
                        variant="icon"
                        icon="delete"
                        onClick={() => onDelete(elem)}
                    />
                </td>
            </tr>
            {hasError && <tr>
                <td colSpan="5" className="cart-column-error">
                    {totalStock ?
                        NLS.format("CART_ERROR_STOCK_LEFT", totalStock) :
                        NLS.get("CART_ERROR_STOCK_ONE")
                    }
                </td>
            </tr>}
        </React.Fragment>
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data } = this.props;

        return <table className="cart-table">
            <tbody>
                {data.map((elem, index) => this.renderElem(elem, index))}
            </tbody>
        </table>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
        data     : PropTypes.array.isRequired,
        errors   : PropTypes.object,
        onEdit   : PropTypes.func.isRequired,
        onDelete : PropTypes.func.isRequired,
        loading  : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(ProductList.mapStateToProps)(ProductList);
