import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/Core/NLS";
import DateTime             from "Utils/Common/DateTime";
import KeyCode              from "Utils/Common/KeyCode";
import Utils                from "Utils/Common/Utils";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Title/Title";
import Content              from "Components/Utils/Common/Content";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Html                 from "Components/Utils/Common/Html";
import TextField            from "Components/Utils/Form/TextField";

// Actions
import {
    setViewingChat, fetchMessages, sendMessage, markAsRead,
} from "Actions/Content/ChatActions";

// Styles
import "Styles/Components/Content/Chat.css";



/**
 * The Chat Page
 */
class ChatPage extends React.Component {
    // The Initial Data
    initialData = {
        message : "",
    }

    // The Current State
    state = {
        data      : { ...this.initialData },
        loading   : false,
        errors    : {},
        lastView  : "",
        timeout   : null,
        interval  : null,
        atBottom  : true,
        isFocused : false,
    }

    // References
    contentRef = React.createRef();
    unreadRef  = React.createRef();
    inputRef   = React.createRef();



    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.setViewingChat(true);
        if (!this.props.loaded) {
            this.props.fetchMessages();
        } else {
            this.scrollToUnread();
        }
        this.props.markAsRead();
        this.setLastView();
        this.setState({ interval : window.setInterval(this.setLastView, 30 * 1000) });
    }

    /**
     * Unloads the Data
     * @returns {Void}
     */
    componentWillUnmount() {
        this.props.setViewingChat(false);
        window.clearTimeout(this.state.timeout);
        window.clearInterval(this.state.interval);
    }

    /**
     * Scroll to the Bottom
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.messages.length !== prevProps.messages.length && this.state.atBottom) {
            this.scrollToUnread();
        }
        if (this.props.elem.lastViewCredential !== prevProps.elem.lastViewCredential) {
            this.setLastView();
        }
    }

    /**
     * Scrolls to the Unread Messages
     * @returns {Void}
     */
    scrollToUnread() {
        const node = Utils.getNode(this.unreadRef);
        if (node) {
            const parent = node.parentNode;
            parent.scrollTop = node.offsetTop - parent.offsetHeight / 2;
        } else {
            this.scrollToBottom();
        }
    }

    /**
     * Scrolls to the Bottom of the Chat
     * @returns {Void}
     */
    scrollToBottom = () => {
        const node = Utils.getNode(this.contentRef);
        if (node) {
            node.scrollTop = node.scrollHeight - node.offsetHeight;
        }
    }

    /**
     * Marks the Message as Read
     * @returns {Void}
     */
    markAsRead = () => {
        this.props.markAsRead();
        this.setState({ timeout : null });
    }

    /**
     * Set the Last View
     * @returns {Void}
     */
    setLastView = () => {
        const { lastViewCredential } = this.props.elem;
        if (lastViewCredential) {
            const lastView = DateTime.formatString(lastViewCredential);
            this.setState({ lastView });
        }
    }



    /**
     * Handles the Scroll
     * @param {Event} e
     * @returns {Void}
     */
    handleScroll = async (e) => {
        const elem   = e.target;
        let atBottom = false;
        let timeout  = this.state.timeout;

        if (elem.scrollTop === 0 && this.props.hasMore) {
            const first   = this.props.messages[0];
            const preNode = document.querySelector(`#chat-${first.messageID}`);
            const preTop  = preNode.offsetTop;
            await this.props.fetchMessages(first.createdTime);

            const node   = document.querySelector(`#chat-${first.messageID}`);
            const parent = node.parentNode.parentNode;
            parent.scrollTop = node.offsetTop - preTop;
        } else if (elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight) - 50) {
            atBottom = true;
            if (this.props.elem.unreadClient > 0) {
                if (timeout) {
                    window.clearTimeout(timeout);
                }
                timeout = window.setTimeout(this.markAsRead, 2000);
            }
        }
        this.setState({ atBottom, timeout });
    }

    /**
     * Handles the Close
     * @returns {Void}
     */
    handleClose = () => {
        this.inputRef.current.blurInput();
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Input KeyDown
     * @param {Event} e
     * @returns {Void}
     */
    handleKeyDown = (e) => {
        const { isApp } = this.props;

        if (e.keyCode === KeyCode.DOM_VK_RETURN && !e.shiftKey && !isApp) {
            this.handleSubmit(e);
            e.preventDefault();
        } else if (this.state.atBottom) {
            window.setTimeout(this.scrollToBottom, 20);
        }
    }

    /**
     * Handles the Input Focus
     * @returns {Void}
     */
    handleFocus = () => {
        if (this.props.isApp) {
            this.setState({ isFocused : true });
        }
        if (this.state.atBottom) {
            window.setTimeout(this.scrollToBottom, 20);
        }
    }

    /**
     * Handles the Input Blur
     * @param {Event} e
     * @returns {Void}
     */
    handleBlur = () => {
        if (this.props.isApp) {
            this.setState({ isFocused : false });
        }
        if (this.state.atBottom) {
            window.setTimeout(this.scrollToBottom, 20);
        }
    }

    /**
     * Does a Submit on Touch
     * @param {Event} e
     * @returns {Void}
     */
    handleTouch = (e) => {
        if (this.state.isFocused && !this.state.loading) {
            document.activeElement.blur();
            this.handleSubmit(e);
        }
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { data, loading, atBottom } = this.state;
        if (loading || !data.message.trim()) {
            return;
        }

        this.setState({ loading : true, errors : {} });
        try {
            data.message = data.message.trim();
            data.time    = this.props.lastUpdate;
            await this.props.sendMessage(data);
            this.setState({ loading : false, data : { ...this.initialData } });
            if (!atBottom) {
                this.scrollToBottom();
            }
        } catch (errors) {
            this.setState({ loading : false, errors });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, list, messages                        } = this.props;
        const { data, errors, atBottom, isFocused, lastView } = this.state;

        const hasMessages = Boolean(messages.length);
        const submessage  = lastView ? NLS.format("CHAT_LAST_CONECTION", lastView) : "";

        return <>
            <Title message="CHAT_NAME" submessage={submessage} />
            <Wrapper className={"chat-container" + (isFocused ? " chat-focused" : "")}>
                <Content
                    className="chat-content"
                    none="CHAT_NONE_AVAILABLE"
                    hasContent={hasMessages}
                    onTouchEnd={this.handleClose}
                    onScroll={this.handleScroll}
                    ref={this.contentRef}
                    withCard
                    withBorder
                >
                    {list.map((elem, index) => <React.Fragment key={index}>
                        {!!elem.day && <header className="chat-day">
                            <h3>{elem.day}</h3>
                        </header>}
                        {!!elem.unread && <header ref={this.unreadRef} className="chat-unread">
                            <h3>{NLS.format("CHAT_UNREAD", elem.unread)}</h3>
                        </header>}
                        <div className={`chat-messages chat-${elem.className}`}>
                            {!!elem.userName && <h3 className="chat-user">
                                {elem.userName}
                            </h3>}
                            {elem.list.map((subelem) => <div
                                key={subelem.messageID}
                                id={`chat-${subelem.messageID}`}
                                className="chat-message"
                            >
                                <Html content={subelem.messageHtml} linkify />
                                <footer className="chat-footer">
                                    {subelem.createdTimeHour}
                                </footer>
                            </div>)}
                        </div>
                    </React.Fragment>)}
                    {!atBottom && <div className="chat-bottom">
                        <HyperLink
                            className="chat-bottom-link"
                            variant="none"
                            icon="down"
                            badge={elem.unreadClient}
                            onClick={this.scrollToBottom}
                        />
                    </div>}
                </Content>
                <footer className="chat-reply">
                    <div className="chat-reply-content">
                        <TextField
                            passedRef={this.inputRef}
                            className="chat-reply-input"
                            type="textarea"
                            name="message"
                            placeholder="CHAT_WRITE_MESSAGE"
                            value={data.message}
                            error={errors.message}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            autoGrow
                            noResize
                        />
                        <HyperLink
                            className="chat-reply-link"
                            variant="menu"
                            icon="send"
                            onClick={this.handleSubmit}
                            onTouchEnd={this.handleTouch}
                        />
                    </div>
                </footer>
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setViewingChat : PropTypes.func.isRequired,
        fetchMessages  : PropTypes.func.isRequired,
        sendMessage    : PropTypes.func.isRequired,
        markAsRead     : PropTypes.func.isRequired,
        isApp          : PropTypes.bool.isRequired,
        lastUpdate     : PropTypes.number.isRequired,
        loaded         : PropTypes.bool.isRequired,
        hasMore        : PropTypes.bool.isRequired,
        elem           : PropTypes.object.isRequired,
        list           : PropTypes.array.isRequired,
        messages       : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp      : state.core.isApp,
            lastUpdate : state.store.lastUpdate,
            loaded     : state.chat.loaded,
            hasMore    : state.chat.hasMore,
            elem       : state.chat.elem,
            list       : state.chat.list,
            messages   : state.chat.messages,
        };
    }
}

export default connect(ChatPage.mapStateToProps, {
    setViewingChat, fetchMessages, sendMessage, markAsRead,
})(ChatPage);
