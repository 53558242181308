import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleMenu }       from "Actions/Store/StoreActions";
import Href                 from "Utils/Core/Href";
import Utils                from "Utils/Common/Utils";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Subcategory Menu
 */
class SubcategoryMenu extends React.Component {
    /**
     * Toggles the Menu
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        const { open, categories, categoryID, toggleMenu } = this.props;
        const category = Utils.getData(categories, "id", categoryID);
        if (category && open && !prevProps.open) {
            toggleMenu(true);
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, categories, open, top, categoryID, onEnter, onLeave, onClose } = this.props;
        const category = Utils.getData(categories, "id", categoryID);

        const hasSub = settings.categories_hasSubcategories;
        const hasTer = settings.categories_hasTercategories;

        if (!open || !category || !hasSub) {
            return <React.Fragment />;
        }

        return <nav
            className={"submenu-content submenu-subcategory submenu-with-sub" + (hasTer ? " submenu-with-ter" : "")}
            style={{ top : `${top}px` }}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <h2>{category.name}</h2>
            <ul className="no-list">
                {category.childs.map((elem) => <li key={elem.id}>
                    <HyperLink
                        variant="opacity"
                        href={Href.url("PRODUCTS", category.url, elem.url)}
                        message={elem.name}
                        onClick={onClose}
                    />
                    {elem.childs.length > 0 && <ul className="no-list">
                        {elem.childs.map((subelem) => <li key={subelem.id}>
                            <HyperLink
                                variant="opacity"
                                href={Href.url("PRODUCTS", category.url, elem.url, subelem.url)}
                                message={subelem.name}
                                onClick={onClose}
                            />
                        </li>)}
                    </ul>}
                </li>)}
            </ul>
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleMenu : PropTypes.func.isRequired,
        settings   : PropTypes.object.isRequired,
        categories : PropTypes.array.isRequired,
        open       : PropTypes.bool.isRequired,
        top        : PropTypes.number.isRequired,
        categoryID : PropTypes.number.isRequired,
        onEnter    : PropTypes.func.isRequired,
        onLeave    : PropTypes.func.isRequired,
        onClose    : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings   : state.core.settings,
            categories : state.store.categories,
        };
    }
}

export default connect(SubcategoryMenu.mapStateToProps, {
    toggleMenu,
})(SubcategoryMenu);
