import React                from "react";
import PropTypes            from "prop-types";
import Href                 from "Utils/Core/Href";



/**
 * The Auth Wrapper
 */
class AuthWrapper extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { topSpace, children } = this.props;

        const isWide = Href.isEqual("REGISTER");
        return <section className={"auth-main" + (isWide ? " auth-wide" : "") + (topSpace ? " auth-top" : "")}>
            {children}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        topSpace : PropTypes.bool,
        children : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        topSpace : false,
    }
}

export default AuthWrapper;
