import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { logout }           from "Actions/Core/AuthActions";
import { toggleMenu }       from "Actions/Store/StoreActions";
import MenuItems            from "Utils/App/MenuItems";
import Href                 from "Utils/Core/Href";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Client Menu
 */
class ClientMenu extends React.Component {
    /**
     * Toggles the Menu
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        const { open, isAuthenticated, toggleMenu } = this.props;
        if (isAuthenticated && open && !prevProps.open) {
            toggleMenu(true);
        }
    }

    /**
     * Handles the Logout
     * @returns {Void}
     */
    handleLogout = () => {
        const { onClose, logout } = this.props;
        onClose();
        logout();
    }

    /**
     * Returns the Item Class
     * @param {String} key
     * @param {String} url
     * @returns {String}
     */
    getItemClass(key, url) {
        if (Href.isEqual(url)) {
            return "client-selected";
        }
        if (key === "voucher" && Href.startsWith("VOUCHERS")) {
            return "client-selected";
        }
        if (key === "orders" && Href.startsWith("ORDERS_VIEW")) {
            return "client-selected";
        }
        if (key === "queries" && Href.startsWith("QUERIES_VIEW")) {
            return "client-selected";
        }
        if (key === "purchases" && Href.startsWith("PURCHASES")) {
            return "client-selected";
        }
        if (key === "favorite" && Href.startsWith("FAVORITES")) {
            return "client-selected";
        }
        if (key === "history" && Href.startsWith("HISTORY")) {
            return "client-selected";
        }
        return "";
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, isAuthenticated, open, style, onEnter, onLeave, onClose } = this.props;

        if (!open || !isAuthenticated) {
            return <React.Fragment />;
        }
        const clientMenu = MenuItems.getClient(settings, true);

        return <nav
            className="submenu-secondary submenu-client"
            style={style}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <ul className="no-list">
                {clientMenu.map(({ type, icon, href, message }) => <li key={type}>
                    <HyperLink
                        className={this.getItemClass(type, href)}
                        variant="none"
                        href={href}
                        message={message}
                        icon={icon}
                        onClick={onClose}
                    />
                </li>)}
                {settings.products_hasPurchases && <li>
                    <HyperLink
                        className={this.getItemClass("purchases", "PURCHASES")}
                        variant="none"
                        icon="purchases"
                        url="PURCHASES"
                        message="PURCHASES_NAME"
                        onClick={onClose}
                    />
                </li>}
                {settings.products_hasFavorites && <li>
                    <HyperLink
                        className={this.getItemClass("favorite", "FAVORITES")}
                        variant="none"
                        icon="favorite"
                        url="FAVORITES"
                        message="FAVORITES_NAME"
                        onClick={onClose}
                    />
                </li>}
                {settings.products_hasHistory && <li>
                    <HyperLink
                        className={this.getItemClass("history", "HISTORY")}
                        variant="none"
                        icon="history"
                        url="HISTORY"
                        message="HISTORY_NAME"
                        onClick={onClose}
                    />
                </li>}
                <li>
                    <HyperLink
                        variant="none"
                        message="CLIENT_LOGOUT"
                        icon="logout"
                        onClick={this.handleLogout}
                    />
                </li>
            </ul>
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        logout          : PropTypes.func.isRequired,
        toggleMenu      : PropTypes.func.isRequired,
        settings        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        open            : PropTypes.bool.isRequired,
        style           : PropTypes.object.isRequired,
        onEnter         : PropTypes.func.isRequired,
        onLeave         : PropTypes.func.isRequired,
        onClose         : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(ClientMenu.mapStateToProps, {
    logout, toggleMenu,
})(ClientMenu);
