import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Analitics            from "Utils/Core/Analitics";
import Utils                from "Utils/Common/Utils";

// Components
import CartOption           from "Components/Cart/Utils/CartOption";
import CartMessage          from "Components/Cart/Utils/CartMessage";
import CartSummary          from "Components/Cart/Utils/CartSummary";
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Title/SubTitle";
import TextField            from "Components/Utils/Form/TextField";
import Columns              from "Components/Utils/Form/Columns";
import Html                 from "Components/Utils/Common/Html";

// Actions
import {
    unconfirmToShipment, confirmPayment, setPayment, tryPayment,
} from "Actions/Store/CartActions";



/**
 * The Payment Content
 */
class PaymentContent extends React.Component {
    // The Current State
    state = {
        loading : false,
        errors  : {},
        data    : {
            paymentType   : "",
            cardNumber    : "",
            holderName    : "",
            holderSurname : "",
            cardMonth     : "",
            cardYear      : "",
            cardCode      : "",
            message       : "",
        },
    }



    /**
     * Set the Data When Loading
     * @returns {Void}
     */
    componentDidMount() {
        const { settings, elem                 } = this.props;
        const { payments, paymentType, message } = elem;

        if (paymentType) {
            const payment = Utils.getData(payments, "type", paymentType);
            if (Utils.isEmpty(payment)) {
                this.setState({ data : { paymentType : 0, message } });
            } else {
                this.setState({ data : { paymentType, message } });
            }
        } else if (settings.cart_hidePayment) {
            this.setPayment(payments[0].type);
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Selects the Payment Type
     * @param {String} paymentType
     * @returns {Void}
     */
    setPayment = (paymentType) => {
        this.setState({ data : { ...this.state.data, paymentType } });
        this.props.setPayment(paymentType);
        this.tryPayment(paymentType);
    }

    /**
     * Tryes a Payment
     * @param {String} paymentType
     * @returns {Promise}
     */
    async tryPayment(paymentType) {
        const { tryPayment, closeAlert, openAlert } = this.props;
        if (this.state.loading) {
            return;
        }

        closeAlert();
        this.setState({ loading : true });
        try {
            await tryPayment(paymentType);
            this.setState({ loading : false });
        } catch (response) {
            openAlert("", response.form);
            this.setState({ loading : false });
        }
    }



    /**
     * Handles a Cart Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { elem, confirmPayment, openAlert, closeAlert, onSubmit } = this.props;
        const { data, loading                                         } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true });
        closeAlert();
        try {
            const response = await confirmPayment(data);
            Analitics.checkout(Analitics.stepPayment, elem.products);
            onSubmit(response.checkout, response.orderID, response.order);
        } catch (errors) {
            openAlert("", errors.form);
            this.setState({ loading : false, errors });
        }
    }

    /**
     * Handles a Cart Cancel
     * @returns {Promise}
     */
    handleCancel = async () => {
        const { elem, unconfirmToShipment, openAlert, closeAlert, onSubmit } = this.props;
        if (this.state.loading) {
            return;
        }

        this.setState({ loading : true });
        closeAlert();
        try {
            await unconfirmToShipment();
            Analitics.checkout(Analitics.stepShipment, elem.products);
            onSubmit();
        } catch (errors) {
            openAlert("", errors.form);
            this.setState({ loading : false, errors });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, elem, openAlert } = this.props;
        const { payments                  } = elem;
        const { loading, data, errors     } = this.state;

        const hidePayment = Boolean(settings.cart_hidePayment && payments.length === 1);
        const payment     = Utils.getData(payments, "type", data.paymentType);
        const isDisabled  = loading || data.paymentType === 0;

        return <>
            <section className="cart-content">
                <CartOption
                    isHidden={hidePayment}
                    data={payments}
                    icon="payment"
                    message="CART_PAYMENT_TITLE"
                    help="CART_PAYMENT_HELP"
                    selected={data.paymentType}
                    onClick={this.setPayment}
                />

                {!!payment.data && <Card className="cart-payment-card" withBorder>
                    <SubTitle message="CART_PAYMENT_DATA" icon="info" />
                    <Html className="spacing" content={payment.data} />
                </Card>}

                {!!payment.reqCard && <Card className="cart-payment-card" withBorder>
                    <SubTitle message="CART_CREDIT_TITLE" icon="credit" />
                    <div className="cart-payment-credit">
                        <Columns amount="4">
                            <TextField
                                className="columns-quadruple"
                                type="number"
                                name="cardNumber"
                                label="CART_CREDIT_NUMBER"
                                error={errors.cardNumber}
                                onChange={this.handleChange}
                            />
                            <TextField
                                className="columns-double"
                                name="holderName"
                                label="CART_CREDIT_HOLDER_NAME"
                                error={errors.holderName}
                                onChange={this.handleChange}
                            />
                            <TextField
                                className="columns-double"
                                name="holderSurname"
                                label="CART_CREDIT_HOLDER_SURNAME"
                                error={errors.holderSurname}
                                onChange={this.handleChange}
                            />
                            <TextField
                                type="number"
                                name="cardMonth"
                                label="CART_CREDIT_MONTH"
                                error={errors.cardMonth}
                                onChange={this.handleChange}
                            />
                            <TextField
                                type="number"
                                name="cardYear"
                                label="CART_CREDIT_YEAR"
                                error={errors.cardYear}
                                onChange={this.handleChange}
                            />
                            <TextField
                                className="columns-double"
                                type="number"
                                name="cardCode"
                                label="CART_CARD_CODE"
                                error={errors.cardCode}
                                onChange={this.handleChange}
                            />
                        </Columns>
                    </div>
                </Card>}

                <CartMessage
                    step="payment"
                    value={data.message}
                    error={errors.message}
                    onChange={this.handleChange}
                />
            </section>

            <CartSummary
                step="payment"
                openAlert={openAlert}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                isDisabled={isDisabled}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        unconfirmToShipment : PropTypes.func.isRequired,
        confirmPayment      : PropTypes.func.isRequired,
        setPayment          : PropTypes.func.isRequired,
        tryPayment          : PropTypes.func.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        openAlert           : PropTypes.func.isRequired,
        closeAlert          : PropTypes.func.isRequired,
        settings            : PropTypes.object.isRequired,
        elem                : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            elem     : state.cart.elem,
        };
    }
}

export default connect(PaymentContent.mapStateToProps, {
    unconfirmToShipment, confirmPayment, setPayment, tryPayment,
})(PaymentContent);
