import React                from "react";
import PropTypes            from "prop-types";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Icon                 from "Components/Utils/Media/Icon";

// Styles
import "Styles/Components/Utils/Common/Breadcrumb.css";



/**
 * The Breadcrumb
 */
class Breadcrumb extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, withBorder } = this.props;

        if (!data.length) {
            return <React.Fragment />;
        }

        const content = <ul className="no-list breadcrumb">
            {data.map((elem, index) => <li key={index}>
                <HyperLink variant="opacity" href={elem.to} message={elem.name} />
                <Icon className="breadcrumb-chevron" variant="right" />
            </li>)}
        </ul>;

        if (withBorder) {
            return <section className="breadcrumb-bar">
                {content}
            </section>
        }
        return content;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data       : PropTypes.array.isRequired,
        withBorder : PropTypes.bool,
    }
}

export default Breadcrumb;
