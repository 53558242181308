import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchBrand }       from "Actions/Store/ProductActions";
import NLS                  from "Utils/Core/NLS";
import Production           from "Utils/App/Production";
import Pages                from "Utils/App/Pages";

// Components
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsHeader       from "Components/Product/List/ProductsHeader";
import ProductsOptions      from "Components/Product/List/ProductsOptions";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsMain         from "Components/Product/List/ProductsMain";
import ProductsInfo         from "Components/Product/List/ProductsInfo";
import ProductsList         from "Components/Product/List/ProductsList";
import ProductsAside        from "Components/Product/Aside/ProductsAside";



/**
 * The Brand Page
 */
class BrandPage extends React.Component {
    // The Production
    production = new Production(this.props.fetchBrand, "brand");

    /**
     * Load the Data on Mount
     * @returns {Void}
     */
    componentDidMount() {
        const { loaded, settings, preferences, match, location } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params, location.search);
            this.production.fetch();
        }
    }

    /**
     * Load the Data if the Brand changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { loaded, settings, preferences, match, location } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params, location.search);
            this.production.fetchIfRequired(prevProps, this.props);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, pages, loading, error, elem } = this.props;
        const { brand                                 } = this.production.getParams();

        this.production.startRender(elem, "BRAND", brand);
        this.production.addPageCrumb(pages, Pages.BRANDS);
        this.production.addStartCrumb(elem.name);
        this.production.addCategoryCrumbs();

        const showBanner     = Boolean(settings.brands_showBanner && elem.banner);
        const showLogo       = Boolean(settings.brands_showLogo   && elem.logo);
        const showHeaderLogo = !settings.brands_showInfo && showLogo;
        const showAsideLogo  = !settings.brands_showInfo && showLogo && !showBanner;
        const showHeaderDesc = !settings.brands_showInfo && settings.brands_showDescription;
        const showAsideDesc  = !settings.brands_showInfo && !settings.brands_showDescription;
        const results        = NLS.pluralize("PRODUCTS_RESULTS", elem.results);

        return <ProductsContainer
            production={this.production}
            loading={loading}
            error={error}
        >
            <ProductsHeader
                message={elem.name}
                submessage={results}
                description={elem.description}
                banner={elem.banner}
                logo={elem.logo}
                url={this.production.url}
                height={settings.brands_headerHeight}
                showBanner={showBanner}
                showLogo={showHeaderLogo}
                showTitle={settings.brands_showTitle}
                showDescription={showHeaderDesc}
            />
            <ProductsOptions hasLogo={showBanner && showHeaderLogo} />
            <ProductsContent>
                <ProductsAside
                    production={this.production}
                    name={elem.name}
                    results={elem.results}
                    logo={elem.logo}
                    description={elem.description}
                    logoInBanner={showHeaderLogo && showBanner}
                    showLogo={showAsideLogo}
                    showBanner={showBanner && !settings.brands_showTitle}
                    showDescription={showAsideDesc}
                    withoutBrands
                />
                <ProductsMain>
                    <ProductsInfo
                        isHidden={!settings.brands_showInfo}
                        name={elem.name}
                        logo={elem.logo}
                        url={this.production.url}
                        description={elem.description}
                    />
                    <ProductsList
                        products={elem.products}
                        pages={elem.pages}
                    />
                </ProductsMain>
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchBrand  : PropTypes.func.isRequired,
        loaded      : PropTypes.bool.isRequired,
        settings    : PropTypes.object.isRequired,
        pages       : PropTypes.object.isRequired,
        preferences : PropTypes.object.isRequired,
        loading     : PropTypes.bool.isRequired,
        error       : PropTypes.bool.isRequired,
        elem        : PropTypes.object.isRequired,
        match       : PropTypes.object.isRequired,
        location    : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings    : state.core.settings,
            pages       : state.core.pages,
            loaded      : state.store.loaded,
            preferences : state.store.preferences,
            loading     : state.product.loading,
            error       : state.product.error,
            elem        : state.product.brand,
        };
    }
}

export default connect(BrandPage.mapStateToProps, {
    fetchBrand,
})(BrandPage);
