import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { deleteAddress }    from "Actions/Client/AddressActions";
import NLS                  from "Utils/Core/NLS";
import Utils                from "Utils/Common/Utils";

// Components
import DeleteDialog         from "Components/Utils/Dialog/DeleteDialog";



/**
 * The Address Delete
 */
class AddressDelete extends React.Component {
    // The Current State
    state = {
        loading : false,
    }

    /**
     * Confirms the Delete
     * @returns {Void}
     */
    handleSubmit = async () => {
        const { elemID } = this.props;
        if (!elemID || this.state.loading) {
            return;
        }

        this.setState({ loading : true });
        try {
            const success = await this.props.deleteAddress(elemID);
            this.setState({ loading : false });
            this.props.onSubmit(success);
        } catch (errors) {
            this.setState({ loading : false });
            this.props.onSubmit("", errors.form);
        }
    }


    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, elemID, open, onClose } = this.props;
        const name    = Utils.getValue(data, "addressID", elemID, "name");
        const message = NLS.format("ADDRESSES_DELETE_TEXT", name);

        return <DeleteDialog
            open={open}
            message="ADDRESSES_DELETE"
            content={message}
            onSubmit={this.handleSubmit}
            onClose={onClose}
        />;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        deleteAddress : PropTypes.func.isRequired,
        open          : PropTypes.bool.isRequired,
        data          : PropTypes.array.isRequired,
        elemID        : PropTypes.number,
        onSubmit      : PropTypes.func.isRequired,
        onClose       : PropTypes.func.isRequired,
    }
}

export default connect(null, {
    deleteAddress,
})(AddressDelete);
