import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchHome }        from "Actions/Content/ContentActions";
import Href                 from "Utils/Core/Href";
import ClassList            from "Utils/Common/ClassList";

// Components
import Sections             from "Components/Content/Sections/Sections";
import MediaSlider          from "Components/Utils/Media/MediaSlider";

// Styles
import "Styles/Components/Content/Home.css";



/**
 * The Home Page
 */
class HomePage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchHome();
    }

    /**
     * Handle the Hash change
     * @returns {Void}
     */
    componentDidUpdate() {
        const hash = window.location.hash.substr(1);
        if (!this.props.data.loading && hash) {
            const node = document.querySelector(`.section-${hash}`);
            if (node) {
                node.scrollIntoView({
                    behavior : "smooth",
                    block    : "center",
                    inline   : "center",
                });
            }
            Href.removeHash();
        }
    }



    /**
     * Renders the Slider
     * @returns {Object[]}
     */
    renderSlider() {
        const { responsive, settings, data : { slides } } = this.props;
        if (!slides.length) {
            return <React.Fragment />;
        }

        const style   = {};
        const heights = [ settings.home_sliderHeight1, settings.home_sliderHeight2, settings.home_sliderHeight3 ];

        for (const elem of slides) {
            elem.image   = responsive.isMicro ? elem.mobileFile    : elem.desktopFile;
            elem.isVideo = responsive.isMicro ? elem.isMobileVideo : elem.isDesktopVideo;
        }
        for (const [ index, value ] of heights.entries()) {
            style[`--slider-height${index + 1}`] = `${value}px`;
        }

        const classes = new ClassList("home-slider");
        classes.addIf("home-slider-wrapper", !settings.home_sliderFullWidth);
        classes.addIf("home-slider-full",    settings.home_sliderFullHeight);
        classes.addIf("home-slider-bellow",  settings.home_sliderBellowHeader);

        return <MediaSlider
            className={classes.get()}
            data={slides}
            style={style}
            useFade={settings.home_sliderFade}
            autoSlide
            withDots
            withClick
        />;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data : { sections }, match } = this.props;

        return <div className="home-container">
            {this.renderSlider()}
            <Sections sections={sections} match={match} forHome />
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchHome  : PropTypes.func.isRequired,
        responsive : PropTypes.object.isRequired,
        settings   : PropTypes.object.isRequired,
        data       : PropTypes.object.isRequired,
        match      : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
            settings   : state.core.settings,
            data       : state.content.home,
        };
    }
}

export default connect(HomePage.mapStateToProps, {
    fetchHome,
})(HomePage);
