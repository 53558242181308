import esStrings            from "NLS/Strings/es.js";
import enStrings            from "NLS/Strings/en.js";


// Module variables
let curLanguage = null;
let strings     = null;



/**
 * Initialize the Internalization
 * @param {String} language
 * @returns {Void}
 */
function init(language) {
    setLanguage(language.split("-")[1]);
}

/**
 * Sets the Language
 * @param {String} language
 * @returns {Void}
 */
function setLanguage(language) {
    let newLanguage;
    if (language) {
        newLanguage = language;
    } else if (navigator.languages) {
        newLanguage = navigator.languages[0];
    } else if (navigator.language) {
        newLanguage = navigator.language;
    } else if (navigator.userLanguage) {
        newLanguage = navigator.userLanguage;
    }

    if (newLanguage && curLanguage !== newLanguage) {
        curLanguage = newLanguage;
        strings     = loadLanguage(curLanguage, { es : esStrings, en : enStrings });
    }
}

/**
 * Loads the Language Strings
 * @param {String} language
 * @param {Object} localeData
 * @returns {Object}
 */
function loadLanguage(language, localeData) {
    const languageNoRegion = language.toLowerCase().split(/[_-]+/)[0];
    const messages         = localeData[languageNoRegion] || localeData[language] || localeData.es;
    const defaultMessages  = localeData.es;

    for (const key of Object.keys(defaultMessages)) {
        if (!messages[key]) {
            messages[key] = defaultMessages[key];
        }
    }
    return messages;
}



/**
 * Returns a String from an ID
 * @param {String}  id
 * @param {String=} elem
 * @returns {String}
 */
function get(id, elem = null) {
    if (strings === null) {
        init();
    }
    const message = strings[id] || id;
    return elem !== null ? message[elem] : message;
}

/**
 * Returns the Entries from the NLS Object
 * @param {String} id
 * @returns {Array.<[key, value]>}
 */
function entries(id) {
    return Object.entries(get(id));
}

/**
 * Format a string by replacing placeholder symbols with passed in arguments.
 * @param {String}    id
 * @param {...String} args
 * @returns {String}
 */
function format(id, ...args) {
    const str = get(id);
    return (str || "").replace(/\{(\d+)\}/g, (match, num) => (
        args[num] !== undefined ? args[num] : match
    ));
}

/**
 * Returns a formated string using the correct plural string
 * @param {String} id
 * @param {Number} count
 * @returns {String}
 */
function pluralize(id, count) {
    return format(id + (count === 1 ? "_SINGULAR" : "_PLURAL"), count);
}




// The Public API
export default {
    init,
    setLanguage,

    get,
    entries,
    format,
    pluralize,
};
