import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import SubMenu              from "Utils/App/SubMenu";
import MenuItems            from "Utils/App/MenuItems";
import ClassList            from "Utils/Common/ClassList";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Core/Menu.css";



/**
 * The Menu Bar
 */
class MenuBar extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, pages, isAuthenticated, menus } = this.props;

        const primaryItems   = MenuItems.getPrimary(menus, settings, pages, isAuthenticated);
        const secondaryItems = MenuItems.getSecondary(menus, settings, isAuthenticated);
        const hasSecondary   = secondaryItems.length > 0;

        const classes        = new ClassList("header-menubar", "menu-content");
        classes.addIf("menu-upper", settings.header_inUppercase);

        return <section className={classes.get()}>
            <ul className="menu-nav menu-primary no-list">
                {primaryItems.map(({ key, href, target, message, submenu, submenuID, options }) => <li key={key}>
                    <HyperLink
                        className={`menubar-${key}`}
                        variant="menu"
                        href={href}
                        target={target}
                        message={message}
                        onMouseEnter={() => SubMenu.show(submenu, submenuID, `menubar-${key}`, "header-menubar", href)}
                        onMouseLeave={SubMenu.hide}
                        onClick={SubMenu.close}
                        afterIcon={settings.header_showArrows && submenu ? "down" : ""}
                        dontStop
                        {...options}
                    />
                </li>)}
            </ul>
            {hasSecondary && <ul className="menu-nav menu-secondary no-list">
                {secondaryItems.map(({ key, href, target, message, submenu, submenuID, options }) => <li key={key}>
                    <HyperLink
                        className={`menubar-${key}`}
                        variant="menu"
                        href={href}
                        target={target}
                        message={message}
                        onMouseEnter={() => SubMenu.show(submenu, submenuID, `menubar-${key}`, "header-menubar", href)}
                        onMouseLeave={SubMenu.hide}
                        onClick={SubMenu.close}
                        afterIcon={settings.header_showArrows && submenu ? "down" : ""}
                        dontStop
                        {...options}
                    />
                </li>)}
            </ul>}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings        : PropTypes.object.isRequired,
        pages           : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        menus           : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            pages           : state.core.pages,
            isAuthenticated : state.auth.isAuthenticated,
            menus           : state.store.menus,
        };
    }
}

export default connect(MenuBar.mapStateToProps)(MenuBar);
