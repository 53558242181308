import Utils                from "Utils/Common/Utils";

// Types
import {
    AUTH_CURRENT_USER,
    AUTH_FOCUSED, AUTH_DATA,
    AUTH_RESET, AUTH_REGISTER,
} from "Utils/Core/Types";



// The initial State
const initialState = {
    isAuthenticated : false,
    wasReseted      : false,
    wasCreated      : false,
    isFocused       : false,
    loaded          : false,
    background      : {
        desktop : {},
        mobile  : {},
    },
    credential      : { name : "" },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    switch (action.type) {
    case AUTH_CURRENT_USER:
        if (Utils.isEmpty(action.credential)) {
            return initialState;
        }

        return {
            ...state,
            isAuthenticated : !Utils.isEmpty(action.credential),
            wasReseted      : false,
            credential      : action.credential,
        };

    case AUTH_DATA:
        return {
            ...state,
            loaded     : true,
            background : action.data.background,
        };
    case AUTH_FOCUSED:
        return {
            ...state,
            isFocused  : action.isFocused,
        };

    case AUTH_RESET:
        return {
            ...state,
            wasReseted : true,
        };
    case AUTH_REGISTER:
        return {
            ...state,
            wasCreated : true,
        };
    default:
        return state;
    }
};
