import React                from "react";

// Components
import AuthWrapper          from "Components/Auth/Utils/AuthWrapper";
import AuthTitle            from "Components/Auth/Utils/AuthTitle";
import AuthRouter           from "Components/Auth/Utils/AuthRouter";



/**
 * The Auth Page
 */
class AuthPage extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <>
            <AuthTitle variant="app" />
            <AuthWrapper topSpace>
                <AuthRouter />
            </AuthWrapper>
        </>;
    }
}

export default AuthPage;
