import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/Core/NLS";
import Sort                 from "Utils/App/Sort";
import ClassList            from "Utils/Common/ClassList";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Breadcrumb           from "Components/Utils/Common/Breadcrumb";
import HyperLink            from "Components/Utils/Common/HyperLink";
import TextField            from "Components/Utils/Form/TextField";

// Actions
import {
    toggleFilter, setSort, setColumns,
} from "Actions/Store/StoreActions";

// Styles
import "Styles/Components/Product/List/ProductsOptions.css";



/**
 * The Products Options
 */
class ProductsOptions extends React.Component {
    /**
     * Handles the Sort Change
     * @param {Stirng} name
     * @param {Number} value
     * @returns {Void}
     */
    setSort = async (name, value) => {
        const { isAuthenticated, preferences, production, setSort } = this.props;
        if (value !== preferences.sort) {
            await setSort(value, isAuthenticated);
            production.fetch();
        }
    }

    /**
     * Handles the Column Change
     * @param {Number} value
     * @returns {Void}
     */
    setColumns = (value) => () => {
        const { isAuthenticated, preferences, setColumns } = this.props;
        if (value !== preferences.columns) {
            setColumns(value, isAuthenticated);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, preferences, production, crumbs, hideSort, hasLogo, toggleFilter } = this.props;
        const { sort, columns } = preferences;

        const hasFilters  = production ? production.hasFilters() : false;
        const breadcrumbs = production ? production.crumbs : crumbs;
        const options     = Sort.getOptions(settings);
        const showFilters = hasFilters && !settings.products_showAside;
        const showSort    = settings.products_showSortFilter && !hideSort;
        const showColumns = settings.products_showColumnFilter;
        const optionCount = 1 + (showSort ? 1 : 0) + (showColumns ? 1 : 0) + (hasFilters ? 1 : 0);
        const useGrid     = optionCount >= 3;
        const use2Rows    = useGrid && !showSort;
        const use2Cols    = useGrid && (!showColumns || !hasFilters);

        const classes  = new ClassList("prodoptions-container");
        classes.addIf("prodoptions-with",    hasFilters || showColumns || showSort);
        classes.addIf("prodoptions-grid",    useGrid);
        classes.addIf("prodoptions-2rows",   use2Rows);
        classes.addIf("prodoptions-2cols",   use2Cols);
        classes.addIf("prodoptions-logo",    hasLogo);
        classes.addIf("prodoptions-filters", showFilters);

        return <Wrapper className={classes.get()}>
            <div className="prodoptions-breadcrumb">
                <Breadcrumb data={breadcrumbs} />
            </div>
            {showSort && <div className="prodoptions-sort">
                <h4>{NLS.get("PRODUCTS_SORT")}</h4>
                <TextField
                    type="select"
                    name="sort"
                    options={options}
                    value={sort}
                    onChange={this.setSort}
                />
            </div>}
            {showColumns && <div className="prodoptions-columns">
                <HyperLink
                    variant="none"
                    className={`prodoptions-cols ${columns === 3 ? "prodoptions-selected" : ""}`}
                    onClick={this.setColumns(3)}
                >
                    <span />
                    <span />
                    <span />
                </HyperLink>
                <HyperLink
                    variant="none"
                    className={`prodoptions-cols prodoptions-wide ${columns === 1 ? "prodoptions-selected" : ""}`}
                    onClick={this.setColumns(1)}
                >
                    <span />
                </HyperLink>
            </div>}
            {hasFilters && <HyperLink
                className="prodoptions-filter"
                variant="opacity"
                message="PRODUCTS_FILTER"
                icon="filter"
                onClick={() => toggleFilter(true)}
            />}
        </Wrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleFilter    : PropTypes.func.isRequired,
        setSort         : PropTypes.func.isRequired,
        setColumns      : PropTypes.func.isRequired,
        settings        : PropTypes.object.isRequired,
        preferences     : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        production      : PropTypes.object,
        crumbs          : PropTypes.array,
        hideSort        : PropTypes.bool,
        hasLogo         : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        hideSort : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            preferences     : state.store.preferences,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(ProductsOptions.mapStateToProps, {
    toggleFilter, setSort, setColumns,
})(ProductsOptions);
