import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/Core/NLS";

// Components
import Card                 from "Components/Utils/Common/Card";
import Nothing              from "Components/Utils/Common/Nothing";



/**
 * The Content
 */
class Content extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            loading, hasContent, className, style,
            withCard, withSpacing, withBorder, none,
            onTouchEnd, onScroll, children,
        } = this.props;

        // Show the Loader
        if (loading) {
            return <Card
                className={className + " loading"}
                withBorder={withBorder}
                withSpacing
            >
                <h3>{NLS.get("GENERAL_LOADING")}</h3>
            </Card>;
        }

        // There is no content
        if (!hasContent) {
            return <Nothing
                className={className}
                withBorder={withBorder}
                message={none}
            />;
        }

        // Show the Content with a Card
        if (withCard) {
            return <Card
                className={className}
                withSpacing={withSpacing}
                withBorder={withBorder}
                onTouchEnd={onTouchEnd}
                onScroll={onScroll}
            >{children}</Card>;
        }


        // Show the Content with a Style
        if (className || style) {
            return <section className={className} style={style}>{children}</section>;
        }

        // Show the Children
        return children;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        loading     : PropTypes.bool,
        hasContent  : PropTypes.bool,
        className   : PropTypes.string,
        withCard    : PropTypes.bool,
        withSpacing : PropTypes.bool,
        withBorder  : PropTypes.bool,
        none        : PropTypes.string,
        style       : PropTypes.object,
        onTouchEnd  : PropTypes.func,
        onScroll    : PropTypes.func,
        children    : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        loading     : false,
        hasContent  : true,
        withCard    : false,
        withSpacing : false,
        withBorder  : false,
    }
}

export default Content;
