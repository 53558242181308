import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import HeaderWrapper        from "Components/Header/HeaderWrapper";
import HeaderLogo           from "Components/Header/HeaderLogo";
import SearchBar            from "Components/Header/Bar/SearchBar";
import SuperiorBar          from "Components/Header/Bar/SuperiorBar";
import UserBar              from "Components/Header/Bar/UserBar";
import MenuBar              from "Components/Header/Bar/MenuBar";
import SubMenuBar           from "Components/Header/Bar/SubMenuBar";



/**
 * The Center Big Header
 * Two lines with a Big logo on the Center
 */
class CenterBigHeader extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings } = this.props;

        return <HeaderWrapper design="center-big">
            <section className="header-content">
                <div className="header-primary">
                    {!settings.header_searchRight && <SearchBar />}
                    {settings.header_searchRight && <SuperiorBar />}
                    <MenuBar />
                </div>
                <HeaderLogo />
                <div className="header-secondary">
                    {settings.header_searchRight && <SearchBar />}
                    {!settings.header_searchRight && <SuperiorBar />}
                    <UserBar />
                </div>
            </section>
            <SubMenuBar />
        </HeaderWrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(CenterBigHeader.mapStateToProps)(CenterBigHeader);
