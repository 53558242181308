import {
    CORE_DATA, CORE_CONFIG, CORE_PLATFORM,
    CORE_PATH, CORE_RESPONSIVE,
    CORE_LOADING, CORE_REDIRECT, CORE_RESULT,
} from "Utils/Core/Types";



// The initial State
const initialState = {
    isApp            : false,
    isCordova        : false,
    isDeviceReady    : false,
    platform         : "browser",
    path             : "/",
    responsive       : {},

    loaded           : false,
    language         : "",
    showConstruction : false,
    settings         : {},
    styles           : {},
    pages            : {},
    entities         : {
        ivaCategories : [],
        withCUIT      : [],
        provinces     : [],
    },

    loading          : true,
    redirect         : "/",
    section          : "",
    result           : {
        open    : false,
        type    : "",
        message : "",
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    switch (action.type) {
    case CORE_DATA:
        return {
            ...state,
            loaded           : true,
            language         : action.data.language,
            showConstruction : action.data.showConstruction,
            settings         : action.data.settings,
            styles           : action.data.styles || {},
            pages            : action.data.pages  || {},
            entities         : action.data.entities,
        };
    case CORE_CONFIG:
        return {
            ...state,
            isApp            : action.data.isApp || action.data.isCordova,
            isCordova        : action.data.isCordova,
        };
    case CORE_PLATFORM:
        return {
            ...state,
            isDeviceReady    : true,
            platform         : action.data,
        };
    case CORE_PATH:
        return {
            ...state,
            path             : action.data,
        };
    case CORE_RESPONSIVE:
        return {
            ...state,
            responsive       : action.data,
        };

    case CORE_LOADING:
        return {
            ...state,
            loading          : action.data,
        };
    case CORE_REDIRECT:
        return {
            ...state,
            redirect         : action.data,
        };

    case CORE_RESULT:
        return {
            ...state,
            result           : action.data,
        };
    default:
        return state;
    }
};
