import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";
import KeyCode              from "Utils/Common/KeyCode";
import ClassList            from "Utils/Common/ClassList";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";

// Actions
import {
    toggleNav, toggleSearch,
} from "Actions/Store/StoreActions";

// Styles
import "Styles/Components/Core/Search.css";



/**
 * The Search Bar
 */
class SearchBar extends React.Component {
    // The Current State
    state = {
        isSetted   : false,
        search     : "",
        showSearch : false,
    }

    // References
    searchElem = null;



    /**
     * Load the Data if the Search changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { search, searchOpen } = this.props;
        if (search && search !== "GENERAL_LOADING" && this.state.search === "" && !this.state.isSetted) {
            this.setState({ search, isSetted : true });
        }
        if (!prevProps.searchOpen && searchOpen) {
            this.setState({ showSearch : true });
            window.setTimeout(() => {
                this.searchElem.focus();
            }, 100);
        }
    }

    /**
     * Handles the Search Input Change
     * @param {Event} e
     * @returns {Void}
     */
    onChange = (e) => {
        this.setState({ search : e.target.value });
    }

    /**
     * Handles the Key Press
     * @param {Event} e
     * @returns {Void}
     */
    onKeyPress = (e) => {
        if (e.keyCode === KeyCode.DOM_VK_RETURN) {
            this.submitSearch();
        }
    }

    /**
     * Handles the Search Input Focus
     * @returns {Void}
     */
    openSearch = () => {
        this.setState({ showSearch : true });
    }

    /**
     * Handles the Search Input Blur
     * @returns {Void}
     */
    closeSearch = () => {
        this.props.toggleSearch(false);
        window.setTimeout(() => this.setState({
            showSearch : false,
        }), 100);
    }

    /**
     * Handles the Search
     * @returns {Void}
     */
    submitSearch = () => {
        const { search, showSearch } = this.state;

        if (search) {
            const url = search.replace("/", "--").replace(/\s+/g, "_").toLocaleLowerCase();
            Href.goto("SEARCH", url);
        } else if (!search && showSearch) {
            this.closeSearch();
            this.searchElem.blur();
        } else if (!search && !showSearch) {
            this.openSearch();
            this.searchElem.focus();
        }
        this.props.toggleNav(false);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { responsive, settings, isHidden } = this.props;
        const { search, showSearch             } = this.state;

        const classes = new ClassList("searchbar-container");
        classes.addIf("searchbar-hidden", isHidden);
        classes.addIf("searchbar-open",   showSearch && (responsive.isLaptop || isHidden));
        classes.addIf("searchbar-fit",    !settings.header_fullWidth);

        return <div className={classes.get()}>
            <div className="searchbar-content">
                <HyperLink
                    className="searchbar-icon searchbar-back"
                    variant="icon"
                    icon="back"
                    onClick={this.closeSearch}
                />
                <div className="searchbar-bar searchbar-back" />
                <input
                    type="text"
                    className="searchbar-input"
                    value={search}
                    placeholder={NLS.get("HEADER_SEARCH")}
                    onChange={this.onChange}
                    onFocus={this.openSearch}
                    onBlur={this.closeSearch}
                    onKeyDown={this.onKeyPress}
                    ref={(elem) => { this.searchElem = elem; }}
                />
                <div className="searchbar-bar" />
                <HyperLink
                    className="searchbar-icon searchbar-search"
                    variant="icon"
                    onClick={this.submitSearch}
                    icon="search"
                />
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleNav    : PropTypes.func.isRequired,
        toggleSearch : PropTypes.func.isRequired,
        responsive   : PropTypes.object.isRequired,
        settings     : PropTypes.object.isRequired,
        searchOpen   : PropTypes.bool.isRequired,
        search       : PropTypes.string.isRequired,
        isHidden     : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        isHidden : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
            settings   : state.core.settings,
            searchOpen : state.store.searchOpen,
            search     : state.product.search.name,
        };
    }
}

export default connect(SearchBar.mapStateToProps, {
    toggleNav, toggleSearch,
})(SearchBar);
