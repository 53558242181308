import React                from "react";
import PropTypes            from "prop-types";
import Href                 from "Utils/Core/Href";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Icon                 from "Components/Utils/Media/Icon";

// Styles
import "Styles/Components/Utils/Common/Pagination.css";

// Constants
const WINDOW_WIDTH  = 400;
const PAGES_DESKTOP = 5;
const PAGES_MOBILE  = 3;



/**
 * The Pagination
 */
class Pagination extends React.Component {
    /**
     * Returns the Url depending on the options
     * @param {Number} page
     * @returns {String}
     */
    getUrl(page) {
        const { url, getUrl } = this.props;
        if (getUrl) {
            return getUrl(page);
        }
        return Href.url(url, page);
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isHidden, currentPage, totalPages, totalItems, perPage } = this.props;

        if (isHidden) {
            return <React.Fragment />;
        }
        const amount = totalPages !== undefined ? totalPages : Math.ceil(totalItems / perPage);
        if (amount <= 1) {
            return <React.Fragment />;
        }

        const displayPages = window.innerWidth < WINDOW_WIDTH ? PAGES_MOBILE : PAGES_DESKTOP;
        const middlePage   = Math.ceil(displayPages / 2);
        const prevPage     = Math.max(1,      currentPage - 1);
        const nextPage     = Math.min(amount, currentPage + 1);
        const fromPad      = currentPage > amount - middlePage ? displayPages - (amount - currentPage) : middlePage;
        const toPad        = currentPage < middlePage + 1      ? displayPages + 1 - currentPage        : middlePage;
        const fromPage     = Math.max(1,      currentPage - fromPad);
        const toPage       = Math.min(amount, currentPage + toPad);
        const pages        = [];

        for (let i = fromPage; i <= toPage; i++) {
            pages.push(i);
        }

        return <ul className="no-list pagination">
            <li className="pagination-arrow">
                <HyperLink
                    variant="none"
                    href={this.getUrl(prevPage)}
                    className={currentPage === 1 ? "pagination-disabled" : ""}
                >
                    <Icon variant="left" />
                </HyperLink>
            </li>
            {pages.map((page) => <li key={page}>
                <HyperLink
                    variant="none"
                    href={this.getUrl(page)}
                    message={String(page)}
                    className={currentPage === page ? "pagination-selected" : ""}
                />
            </li>)}
            <li className="pagination-arrow">
                <HyperLink
                    variant="none"
                    href={this.getUrl(nextPage)}
                    className={currentPage === amount ? "pagination-disabled" : ""}
                >
                    <Icon variant="right" />
                </HyperLink>
            </li>
        </ul>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isHidden    : PropTypes.bool,
        currentPage : PropTypes.number.isRequired,
        totalPages  : PropTypes.number,
        totalItems  : PropTypes.number,
        perPage     : PropTypes.number,
        url         : PropTypes.string,
        getUrl      : PropTypes.func,
    }
}

export default Pagination;
