export default {
    // Main Urls
    HOME                        : "/",
    ERROR                       : "/404",

    // Auth Urls
    LOGIN                       : "/ingresar",
    REGISTER                    : "/registrarse",
    RECOVER                     : "/recuperar",
    RESET                       : "/resetear",
    CODE                        : "/resetear/:code",


    // Products Urls
    PRODUCTS                    : "/productos",
    PRODUCTS_PAGE               : "/productos/:page([0-9]+)",
    PRODUCTS_CATEGORY           : "/productos/:category([a-z0-9-]{2,})",
    PRODUCTS_CATEGORY_PAGE      : "/productos/:category([a-z0-9-]{2,})/:page([0-9]+)",
    PRODUCTS_SUBCATEGORY        : "/productos/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    PRODUCTS_SUBCATEGORY_PAGE   : "/productos/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    PRODUCTS_TERCATEGORY        : "/productos/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    PRODUCTS_TERCATEGORY_PAGE   : "/productos/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Offer Urls
    OFFER                       : "/oferta",
    OFFER_OFFER                 : "/oferta/:offer",
    OFFER_PAGE                  : "/oferta/:offer/:page([0-9]+)",
    OFFER_CATEGORY              : "/oferta/:offer/:category([a-z0-9-]{2,})",
    OFFER_CATEGORY_PAGE         : "/oferta/:offer/:category([a-z0-9-]{2,})/:page([0-9]+)",
    OFFER_SUBCATEGORY           : "/oferta/:offer/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    OFFER_SUBCATEGORY_PAGE      : "/oferta/:offer/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    OFFER_TERCATEGORY           : "/oferta/:offer/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    OFFER_TERCATEGORY_PAGE      : "/oferta/:offer/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Brand Urls
    BRAND                       : "/marca",
    BRAND_BRAND                 : "/marca/:brand",
    BRAND_PAGE                  : "/marca/:brand/:page([0-9]+)",
    BRAND_CATEGORY              : "/marca/:brand/:category([a-z0-9-]{2,})",
    BRAND_CATEGORY_PAGE         : "/marca/:brand/:category([a-z0-9-]{2,})/:page([0-9]+)",
    BRAND_SUBCATEGORY           : "/marca/:brand/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    BRAND_SUBCATEGORY_PAGE      : "/marca/:brand/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    BRAND_TERCATEGORY           : "/marca/:brand/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    BRAND_TERCATEGORY_PAGE      : "/marca/:brand/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Artist Urls
    ARTIST                      : "/artista",
    ARTIST_ARTIST               : "/artista/:artist",
    ARTIST_PAGE                 : "/artista/:artist/:page([0-9]+)",
    ARTIST_CATEGORY             : "/artista/:artist/:category([a-z0-9-]{2,})",
    ARTIST_CATEGORY_PAGE        : "/artista/:artist/:category([a-z0-9-]{2,})/:page([0-9]+)",
    ARTIST_SUBCATEGORY          : "/artista/:artist/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    ARTIST_SUBCATEGORY_PAGE     : "/artista/:artist/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    ARTIST_TERCATEGORY          : "/artista/:artist/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    ARTIST_TERCATEGORY_PAGE     : "/artista/:artist/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",
    ARTIST_BIO                  : "/artistabio",
    ARTIST_BIO_ARTIST           : "/artistabio/:artist",

    // Search Urls
    SEARCH                      : "/buscar",
    SEARCH_TERM                 : "/buscar/:search",
    SEARCH_PAGE                 : "/buscar/:search/:page([0-9]+)",
    SEARCH_CATEGORY             : "/buscar/:search/:category([a-z0-9-]{2,})",
    SEARCH_CATEGORY_PAGE        : "/buscar/:search/:category([a-z0-9-]{2,})/:page([0-9]+)",
    SEARCH_SUBCATEGORY          : "/buscar/:search/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    SEARCH_SUBCATEGORY_PAGE     : "/buscar/:search/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    SEARCH_TERCATEGORY          : "/buscar/:search/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    SEARCH_TERCATEGORY_PAGE     : "/buscar/:search/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Newest Urls
    NEWESTS                     : "/nuevos",
    NEWESTS_PAGE                : "/nuevos/:page([0-9]+)",
    NEWESTS_CATEGORY            : "/nuevos/:category([a-z0-9-]{2,})",
    NEWESTS_CATEGORY_PAGE       : "/nuevos/:category([a-z0-9-]{2,})/:page([0-9]+)",
    NEWESTS_SUBCATEGORY         : "/nuevos/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    NEWESTS_SUBCATEGORY_PAGE    : "/nuevos/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    NEWESTS_TERCATEGORY         : "/nuevos/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    NEWESTS_TERCATEGORY_PAGE    : "/nuevos/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Importants Urls
    IMPORTANTS                  : "/destacados",
    IMPORTANTS_PAGE             : "/destacados/:page([0-9]+)",
    IMPORTANTS_CATEGORY         : "/destacados/:category([a-z0-9-]{2,})",
    IMPORTANTS_CATEGORY_PAGE    : "/destacados/:category([a-z0-9-]{2,})/:page([0-9]+)",
    IMPORTANTS_SUBCATEGORY      : "/destacados/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    IMPORTANTS_SUBCATEGORY_PAGE : "/destacados/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    IMPORTANTS_TERCATEGORY      : "/destacados/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    IMPORTANTS_TERCATEGORY_PAGE : "/destacados/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Favorites Urls
    FAVORITES                   : "/favoritos",
    FAVORITES_PAGE              : "/favoritos/:page([0-9]+)",
    FAVORITES_CATEGORY          : "/favoritos/:category([a-z0-9-]{2,})",
    FAVORITES_CATEGORY_PAGE     : "/favoritos/:category([a-z0-9-]{2,})/:page([0-9]+)",
    FAVORITES_SUBCATEGORY       : "/favoritos/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    FAVORITES_SUBCATEGORY_PAGE  : "/favoritos/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    FAVORITES_TERCATEGORY       : "/favoritos/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    FAVORITES_TERCATEGORY_PAGE  : "/favoritos/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Purchases Urls
    PURCHASES                   : "/comprados",
    PURCHASES_PAGE              : "/comprados/:page([0-9]+)",
    PURCHASES_CATEGORY          : "/comprados/:category([a-z0-9-]{2,})",
    PURCHASES_CATEGORY_PAGE     : "/comprados/:category([a-z0-9-]{2,})/:page([0-9]+)",
    PURCHASES_SUBCATEGORY       : "/comprados/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    PURCHASES_SUBCATEGORY_PAGE  : "/comprados/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    PURCHASES_TERCATEGORY       : "/comprados/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    PURCHASES_TERCATEGORY_PAGE  : "/comprados/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // History Urls
    HISTORY                     : "/historial",
    HISTORY_PAGE                : "/historial/:page([0-9]+)",
    HISTORY_CATEGORY            : "/historial/:category([a-z0-9-]{2,})",
    HISTORY_CATEGORY_PAGE       : "/historial/:category([a-z0-9-]{2,})/:page([0-9]+)",
    HISTORY_SUBCATEGORY         : "/historial/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})",
    HISTORY_SUBCATEGORY_PAGE    : "/historial/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:page([0-9]+)",
    HISTORY_TERCATEGORY         : "/historial/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})",
    HISTORY_TERCATEGORY_PAGE    : "/historial/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:page([0-9]+)",

    // Product Urls
    PRODUCT                     : "/producto",
    PRODUCT_PRODUCT             : "/producto/:product",
    PRODUCT_CATEGORY            : "/producto/:category([a-z0-9-]{2,})/:product",
    PRODUCT_SUBCATEGORY         : "/producto/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:product",
    PRODUCT_TERCATEGORY         : "/producto/:category([a-z0-9-]{2,})/:subcategory([a-z0-9-]{2,})/:tercategory([a-z0-9-]{2,})/:product",


    // Content Urls
    PAGE                        : "/:slug([a-z0-9-]{2,})",
    PAGE_PAGE                   : "/:slug([a-z0-9-]{2,})/:page([0-9]+)",
    ENTRY                       : "/entrada",
    ENTRY_ENTRY                 : "/entrada/:entry",
    ENTRY_PAGE                  : "/entrada/:entry/:page([0-9]+)",
    CHAT                        : "/chat",
    NOTIFICATIONS               : "/notificaciones",

    // Cart Urls
    CART                        : "/compras",
    CART_ORDER                  : "/compras/:order",


    // Account Urls
    ACCOUNT                     : "/cuenta",
    ACCOUNT_DATA                : "/cuenta/datos",

    ADDRESSES                   : "/cuenta/domicilios",
    ADDRESSES_PAGE              : "/cuenta/domicilios/:page",
    ADDRESSES_CREATE            : "/cuenta/domicilios/crear",
    ADDRESSES_VIEW              : "/cuenta/domicilio",
    ADDRESSES_ONE               : "/cuenta/domicilio/:address",

    QUERIES                     : "/cuenta/consultas",
    QUERIES_TYPE                : "/cuenta/consultas/:type(abiertas|cerradas)",
    QUERIES_TYPE_PAGE           : "/cuenta/consultas/:type(abiertas|cerradas)/:page",
    QUERIES_OPEN                : "/cuenta/consultas/abiertas",
    QUERIES_CLOSED              : "/cuenta/consultas/cerradas",
    QUERIES_CREATE              : "/cuenta/consultas/crear",
    QUERIES_VIEW                : "/cuenta/consulta",
    QUERIES_ONE                 : "/cuenta/consulta/:query",

    ORDERS                      : "/cuenta/pedidos",
    ORDERS_PAGE                 : "/cuenta/pedidos/:page",
    ORDERS_VIEW                 : "/cuenta/pedido",
    ORDERS_ONE                  : "/cuenta/pedido/:order",

    VOUCHERS                    : "/cuenta/comprobantes",
    INVOICES                    : "/cuenta/comprobantes/facturas",
    INVOICES_PAGE               : "/cuenta/comprobantes/facturas/:page",
    RECEIPTS                    : "/cuenta/comprobantes/recibos",
    RECEIPTS_PAGE               : "/cuenta/comprobantes/recibos/:page",
    CREDIT_NOTES                : "/cuenta/comprobantes/notas-de-credito",
    CREDIT_NOTES_PAGE           : "/cuenta/comprobantes/notas-de-credito/:page",
    DEBIT_NOTES                 : "/cuenta/comprobantes/notas-de-debito",
    DEBIT_NOTES_PAGE            : "/cuenta/comprobantes/notas-de-debito/:page",
};
