import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import ProductCardImage     from "Components/Product/Card/ProductCardImage";
import ProductCardInfo      from "Components/Product/Card/ProductCardInfo";
import ProductCardVariants  from "Components/Product/Card/ProductCardVariants";
import ProductPrice         from "Components/Product/Item/ProductPrice";



/**
 * The Product Card Content
 */
class ProductCardContent extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, data, isWide, forHome } = this.props;
        const { productCode, hasVariants        } = data;

        const hasCode        = Boolean(settings.products_showCode && productCode);
        const hasBottomPrice = settings.products_showPrice && !settings.products_showPriceTop && !isWide && !hasVariants;

        if (isWide) {
            return <>
                <ProductCardImage data={data} />
                <ProductCardInfo
                    data={data}
                    forHome={forHome}
                    forWide
                />
                <ProductCardVariants data={data} />
                <div className="product-wide-price">
                    <ProductPrice
                        className="product-card-price"
                        data={data}
                        withPercent
                    />
                </div>
            </>;
        }

        return <>
            <div className="product-card-top">
                {hasCode && <div className="product-card-code">{productCode}</div>}
                <ProductCardImage data={data} />
                <ProductCardInfo
                    data={data}
                    forHome={forHome}
                />
                <ProductCardVariants data={data} />
            </div>
            {hasBottomPrice && <ProductPrice
                className="product-bottom-price product-card-price"
                data={data}
                withPercent
            />}
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
        data     : PropTypes.object.isRequired,
        isWide   : PropTypes.bool,
        forHome  : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
     static defaultProps = {
        isWide  : false,
        forHome : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(ProductCardContent.mapStateToProps)(ProductCardContent);
