import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchImportants }  from "Actions/Store/ProductActions";
import Production           from "Utils/App/Production";
import Utils                from "Utils/Common/Utils";

// Components
import Title                from "Components/Utils/Title/Title";
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsOptions      from "Components/Product/List/ProductsOptions";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsList         from "Components/Product/List/ProductsList";
import ProductsAside        from "Components/Product/Aside/ProductsAside";



/**
 * The Important Page
 */
class ImportantPage extends React.Component {
    // The Production
    production = new Production(this.props.fetchImportants);

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { loaded, settings, preferences, match, location } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params, location.search);
            this.production.fetch();
        }
    }

    /**
     * Load the Data if the Important changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { loaded, settings, preferences, match, location } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params, location.search);
            this.production.fetchIfRequired(prevProps, this.props);
        }
    }

    /**
     * Handles Product Important update
     * @returns {Void}
     */
    handleImportant = () => {
        this.production.fetch();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, loading, error, elem } = this.props;

        this.production.startRender(elem, "IMPORTANTS");
        this.production.addStartCrumb("IMPORTANTS_NAME");
        this.production.addCategoryCrumbs();

        return <ProductsContainer
            production={this.production}
            loading={loading}
            error={error}
        >
            <Title message={Utils.getProductTitle("IMPORTANTS_TITLE", settings)} />
            <ProductsOptions />
            <ProductsContent>
                <ProductsAside />
                <ProductsList
                    products={elem.products}
                    pages={elem.pages}
                    onImportant={this.handleImportant}
                />
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchImportants : PropTypes.func.isRequired,
        settings        : PropTypes.object.isRequired,
        loaded          : PropTypes.bool.isRequired,
        preferences     : PropTypes.object.isRequired,
        loading         : PropTypes.bool.isRequired,
        error           : PropTypes.bool.isRequired,
        elem            : PropTypes.object.isRequired,
        match           : PropTypes.object.isRequired,
        location        : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings    : state.core.settings,
            loaded      : state.store.loaded,
            preferences : state.store.preferences,
            loading     : state.product.loading,
            error       : state.product.error,
            elem        : state.product.importants,
        };
    }
}

export default connect(ImportantPage.mapStateToProps, {
    fetchImportants,
})(ImportantPage);
