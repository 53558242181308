import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setProduct }       from "Actions/Store/ProductActions";

// Components
import ProductContent       from "Components/Product/Item/ProductContent";
import Dialog               from "Components/Utils/Dialog/Dialog";
import DialogContent        from "Components/Utils/Dialog/DialogContent";



/**
 * The Product Dialog
 */
class ProductDialog extends React.Component {
    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.props.setProduct(null);
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { product } = this.props;

        const open = Boolean(product.productID);

        return <Dialog open={open} onClose={this.closeDialog} isWide>
            <DialogContent>
                <ProductContent
                    product={product}
                    onClose={this.closeDialog}
                    onBuyMore={this.closeDialog}
                    showTitle
                />
            </DialogContent>
        </Dialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setProduct : PropTypes.func.isRequired,
        settings   : PropTypes.object.isRequired,
        product    : PropTypes.object.isRequired,
        onFavorite : PropTypes.func,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            product  : state.product.dialog,
        };
    }
}

export default connect(ProductDialog.mapStateToProps, {
    setProduct,
})(ProductDialog);
