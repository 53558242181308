import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchProducts }    from "Actions/Store/ProductActions";
import NLS                  from "Utils/Core/NLS";
import Production           from "Utils/App/Production";

// Components
import ProductsHeader       from "Components/Product/List/ProductsHeader";
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsOptions      from "Components/Product/List/ProductsOptions";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsMain         from "Components/Product/List/ProductsMain";
import ProductsInfo         from "Components/Product/List/ProductsInfo";
import ProductsList         from "Components/Product/List/ProductsList";
import ProductsAside        from "Components/Product/Aside/ProductsAside";



/**
 * The Products List
 */
class ProductList extends React.Component {
    // The Production
    production = new Production(this.props.fetchProducts);

    /**
     * Load the Data on Mount
     * @returns {Void}
     */
    componentDidMount() {
        const { loaded, settings, preferences, match, location } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params, location.search);
            this.production.fetch();
        }
    }

    /**
     * Load the Data if the Brand changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { loaded, settings, preferences, match, location } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params, location.search);
            this.production.fetchIfRequired(prevProps, this.props);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, loading, error, elem } = this.props;

        this.production.startRender(elem, "PRODUCTS");
        this.production.addStartCrumb(settings.products_name || "PRODUCTS_NAME");
        this.production.addCategoryCrumbs();

        const inCategory     = Boolean(elem.tercategory.name || elem.subcategory.name || elem.category.name);
        const title          = elem.tercategory.name || elem.subcategory.name || elem.category.name || settings.products_title || "PRODUCTS_TITLE";
        const description    = elem.tercategory.descriptionHtml || elem.subcategory.descriptionHtml || elem.category.descriptionHtml || "";
        const banner         = inCategory ? elem.banner : settings.products_bannerImage;
        const showBanner     = inCategory ? Boolean(settings.categories_showBanner && banner) : !!banner;
        const showTitle      = inCategory ? settings.categories_showDescription : settings.products_showTitle;
        const height         = inCategory ? settings.categories_headerHeight : settings.products_headerHeight;
        const showLogo       = Boolean(settings.categories_showLogo && elem.logo);
        const showHeaderLogo = !settings.categories_showInfo && showLogo;
        const showAsideLogo  = !settings.categories_showInfo && showLogo && !showBanner;
        const showHeaderDesc = !settings.categories_showInfo && settings.categories_showDescription;
        const showAsideDesc  = !settings.categories_showInfo && !settings.categories_showDescription;
        const results        = NLS.pluralize("PRODUCTS_RESULTS", elem.results);

        return <ProductsContainer
            production={this.production}
            loading={loading}
            error={error}
        >
            <ProductsHeader
                message={title}
                submessage={results}
                description={description}
                banner={banner}
                logo={elem.logo}
                url={this.production.url}
                height={height}
                showBanner={showBanner}
                showLogo={showHeaderLogo}
                showTitle={showTitle}
                showDescription={showHeaderDesc}
            />
            <ProductsOptions hasLogo={showBanner && showHeaderLogo} />
            <ProductsContent>
                <ProductsAside
                    name={title}
                    results={elem.results}
                    logo={elem.logo}
                    description={description}
                    logoInBanner={showHeaderLogo && showBanner}
                    showLogo={showAsideLogo}
                    showBanner={showBanner && !settings.categories_showTitle}
                    showDescription={showAsideDesc}
                />
                <ProductsMain>
                    <ProductsInfo
                        isHidden={!settings.categories_showInfo}
                        name={elem.name}
                        logo={elem.logo}
                        url={this.production.url}
                        description={description}
                    />
                    <ProductsList
                        products={elem.products}
                        pages={elem.pages}
                    />
                </ProductsMain>
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchProducts : PropTypes.func.isRequired,
        loaded        : PropTypes.bool.isRequired,
        settings      : PropTypes.object.isRequired,
        preferences   : PropTypes.object.isRequired,
        loading       : PropTypes.bool.isRequired,
        error         : PropTypes.bool.isRequired,
        elem          : PropTypes.object.isRequired,
        match         : PropTypes.object.isRequired,
        location      : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings    : state.core.settings,
            loaded      : state.store.loaded,
            preferences : state.store.preferences,
            loading     : state.product.loading,
            error       : state.product.error,
            elem        : state.product.products,
        };
    }
}

export default connect(ProductList.mapStateToProps, {
    fetchProducts,
})(ProductList);
