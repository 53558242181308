import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchCore }        from "Actions/Core/CoreActions";
import { fetchAuth, enter } from "Actions/Core/AuthActions";
import ClassList            from "Utils/Common/ClassList";
import Utils                from "Utils/Common/Utils";

// Components
import AuthHeader           from "Components/Auth/Utils/AuthHeader";
import AuthAside            from "Components/Auth/Utils/AuthAside";
import Form                 from "Components/Utils/Form/Form";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";
import Html                 from "Components/Utils/Common/Html";

// Styles
import "Styles/Components/Auth/Auth.css";



/**
 * The Construction Page
 */
class Construction extends React.Component {
    // The Current State
    state = {
        data    : { password : "" },
        loading : false,
        error   : "",
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        if (!this.props.loaded) {
            this.props.fetchAuth();
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data : { ...this.state.data, [name] : value },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Promise}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { data, loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, error : "" });
        try {
            await this.props.enter(data);
            this.props.fetchCore();
        } catch (errors) {
            this.setState({ loading : false, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ error : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { responsive, settings, background } = this.props;
        const { loading, error                   } = this.state;

        const data    = Utils.getLoginData(responsive, settings, background);
        const classes = new ClassList("auth-container", "auth-middle");
        classes.addIf("auth-centered", settings.auth_centerLogo);

        return <section className={classes.get()} style={data.style}>
            <div className="auth-content">
                <AuthHeader data={data} />
                <section className="auth-main">
                    <Html className="auth-message">
                        {settings.construction_message}
                    </Html>
                    {settings.construction_hasPassword && <Form
                        className="auth-form"
                        error={error}
                        onSubmit={this.handleSubmit}
                        onClose={this.closeAlert}
                    >
                        <TextField
                            name="password"
                            type="password"
                            autoComplete="new-password"
                            label="AUTH_CONSTRUCTION"
                            value={data.password}
                            onChange={this.handleChange}
                            isRequired
                            fullWidth
                            smallMargin
                            shrink
                        />
                        <Button
                            variant="primary"
                            message="AUTH_LOGIN_ACTION"
                            isDisabled={loading}
                            fullWidth
                        />
                    </Form>}
                </section>
            </div>
            <AuthAside data={data.bg} />
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCore  : PropTypes.func.isRequired,
        fetchAuth  : PropTypes.func.isRequired,
        enter      : PropTypes.func.isRequired,
        responsive : PropTypes.object.isRequired,
        settings   : PropTypes.object.isRequired,
        loaded     : PropTypes.bool.isRequired,
        background : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
            settings   : state.core.settings,
            loaded     : state.auth.loaded,
            background : state.auth.background,
        };
    }
}

export default connect(Construction.mapStateToProps, {
    fetchCore, fetchAuth, enter,
})(Construction);
