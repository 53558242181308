import API                  from "Utils/Core/API";

// Types
import {
    NOTIFICATION_DISCARDED, NOTIFICATION_REDIRECT,
} from "Utils/Core/Types";



/**
 * Sets the Notification Redirect
 * @param {String} data
 * @returns {{type: String, data: String}}
 */
export function getNotiRedirect(data) {
    return { type : NOTIFICATION_REDIRECT, data };
}

/**
 * Sets the Notification Redirect
 * @param {String} data
 * @returns {Function}
 */
export function setNotiRedirect(data) {
    return (dispatch) => dispatch(getNotiRedirect(data));
}



/**
 * Discards a Notification
 * @param {String} type
 * @param {Number} id
 * @param {Number} index
 * @returns {Function}
 */
export function discardNotification(type, id, index) {
    return (dispatch) => {
        API.Store.discardNotification({ type, id });
        dispatch({ type : NOTIFICATION_DISCARDED, data : index });
    };
}
