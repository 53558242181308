import Utils            from "Utils/Common/Utils";

// Types
import {
    PRODUCT_LOADING, PRODUCT_LIST, PRODUCT_OFFER, PRODUCT_BRAND, PRODUCT_ARTIST,
    PRODUCT_SEARCH, PRODUCT_IMPORTANTS, PRODUCT_NEWESTS,
    PRODUCT_FAVORITES, PRODUCT_PURCHASES, PRODUCT_HISTORY,
    PRODUCT_ELEM, PRODUCT_DIALOG, PRODUCT_PREVIEW, PRODUCT_ROOM, PRODUCT_ADDED,
    STORE_FAVORITE,
} from "Utils/Core/Types";



// The product State
const productState = {
    name          : "GENERAL_LOADING",
    banner        : "",
    logo          : "",
    url           : "",
    category      : {},
    categories    : [],
    subcategory   : {},
    subcategories : [],
    tercategory   : {},
    tercategories : [],
    brand         : {},
    brands        : [],
    artist        : {},
    artists       : [],
    products      : [],
    prices        : {},
    results       : 0,
    total         : 0,
    pages         : 1,
};

// The initial State
const initialState = {
    loading    : true,
    error      : false,
    products   : { ...productState },
    offer      : { ...productState },
    brand      : { ...productState },
    artist     : { ...productState },
    search     : { ...productState },
    newests    : { ...productState },
    importants : { ...productState },
    favorites  : { ...productState },
    purchases  : { ...productState },
    history    : { ...productState },
    elem       : {
        product     : {},
        relCategory : [],
        relBrand    : [],
        relArtist   : [],
        brands      : [],
        artists     : [],
    },
    dialog     : {},
    preview    : null,
    room       : null,
    added      : null,
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action,
        PRODUCT_LIST, PRODUCT_OFFER, PRODUCT_BRAND, PRODUCT_ARTIST, PRODUCT_SEARCH,
        PRODUCT_NEWESTS, PRODUCT_FAVORITES, PRODUCT_PURCHASES, PRODUCT_HISTORY, PRODUCT_ELEM
    )) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case PRODUCT_LOADING:
        return {
            ...state,
            loading    : true,
            error      : false,
        };

    // The Product Lists
    case PRODUCT_LIST:
        return {
            ...state,
            loading    : false,
            error      : false,
            products   : action.data,
        };
    case PRODUCT_OFFER:
        return {
            ...state,
            loading    : false,
            error      : false,
            offer      : action.data,
        };
    case PRODUCT_BRAND:
        return {
            ...state,
            loading    : false,
            error      : false,
            brand      : action.data,
        };
    case PRODUCT_ARTIST:
        return {
            ...state,
            loading    : false,
            error      : false,
            artist     : action.data,
        };
    case PRODUCT_SEARCH:
        return {
            ...state,
            loading    : false,
            error      : false,
            search     : action.data,
        };
    case PRODUCT_NEWESTS:
        return {
            ...state,
            loading    : false,
            error      : false,
            newests    : action.data,
        };
    case PRODUCT_IMPORTANTS:
        return {
            ...state,
            loading    : false,
            error      : false,
            importants : action.data,
        };

    case PRODUCT_FAVORITES:
        return {
            ...state,
            loading    : false,
            error      : false,
            favorites  : action.data,
        };
    case PRODUCT_PURCHASES:
        return {
            ...state,
            loading    : false,
            error      : false,
            purchases  : action.data,
        };
    case PRODUCT_HISTORY:
        return {
            ...state,
            loading    : false,
            error      : false,
            history    : action.data,
        };

    // The Product Element
    case PRODUCT_ELEM:
        return {
            ...state,
            loading    : false,
            error      : false,
            elem       : action.data,
        };
    case PRODUCT_DIALOG:
        return {
            ...state,
            dialog     : action.data || {},
        };
    case PRODUCT_PREVIEW:
        return {
            ...state,
            preview    : action.data,
        };
    case PRODUCT_ROOM:
        return {
            ...state,
            room       : action.data,
        };
    case PRODUCT_ADDED:
        return {
            ...state,
            added      : action.data,
        };

    // Update the Favorite
    case STORE_FAVORITE:
        return {
            ...state,
            error      : false,
            products   : Utils.setFavorite(state.products,   action.data.productID),
            offer      : Utils.setFavorite(state.offer,      action.data.productID),
            brand      : Utils.setFavorite(state.brand,      action.data.productID),
            artist     : Utils.setFavorite(state.artist,     action.data.productID),
            search     : Utils.setFavorite(state.search,     action.data.productID),
            newests    : Utils.setFavorite(state.newests,    action.data.productID),
            importants : Utils.setFavorite(state.importants, action.data.productID),
            favorites  : Utils.setFavorite(state.favorites,  action.data.productID),
            purchases  : Utils.setFavorite(state.purchases,  action.data.productID),
            history    : Utils.setFavorite(state.history,    action.data.productID),
            elem       : {
                ...state.elem,
                relCategory : Utils.setFavoriteList(state.elem.relCategory, action.data.productID),
                relBrand    : Utils.setFavoriteList(state.elem.relBrand,    action.data.productID),
                relArtist   : Utils.setFavoriteList(state.elem.relArtist,   action.data.productID),
            }
        };

    default:
        return state;
    }
};
