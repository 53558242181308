import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Route, Redirect }  from "react-router-dom";



/**
 * Only Authenticated Users can access the given Component
 */
class CartRoute extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, isAuthenticated, component : Component, ...rest } = this.props;

        return <Route {...rest} render={(props) => (
            ((isAuthenticated || settings.store_isOpen) && settings.orders_isActive)
                ? <Component {...props} />
                : <Redirect to={{
                    pathname : "/",
                    state    : { from : props.location },
                }} />
        )} />;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        component       : PropTypes.oneOfType([ PropTypes.func, PropTypes.object ]).isRequired,
        url             : PropTypes.string,
        path            : PropTypes.string,
        exact           : PropTypes.bool,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(CartRoute.mapStateToProps)(CartRoute);
