import API                  from "Utils/Core/API";

// Types
import {
    PRODUCT_LOADING, PRODUCT_LIST, PRODUCT_OFFER, PRODUCT_BRAND, PRODUCT_ARTIST,
    PRODUCT_SEARCH, PRODUCT_IMPORTANTS, PRODUCT_NEWESTS,
    PRODUCT_FAVORITES, PRODUCT_PURCHASES, PRODUCT_HISTORY,
    PRODUCT_ELEM, PRODUCT_DIALOG, PRODUCT_PREVIEW, PRODUCT_ROOM, PRODUCT_ADDED,
} from "Utils/Core/Types";



/**
 * Fetches the Product List
 * @param {Object} params
 * @returns {Function}
 */
export function fetchProducts(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getProducts(params);
        dispatch({ type : PRODUCT_LIST, data });
    };
}

/**
 * Fetches the Offer Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchOffer(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getOffer(params);
        dispatch({ type : PRODUCT_OFFER, data });
    };
}

/**
 * Fetches the Brand Products
 * @param {Object} params
 * @returns {Function}
 */
 export function fetchBrand(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getBrand(params);
        dispatch({ type : PRODUCT_BRAND, data });
    };
}

/**
 * Fetches the Artist Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchArtist(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getArtist(params);
        dispatch({ type : PRODUCT_ARTIST, data });
    };
}

/**
 * Fetches the Search Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchSearch(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getSearch(params);
        dispatch({ type : PRODUCT_SEARCH, data });
    };
}



/**
 * Fetches the Important Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchImportants(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getImportants(params);
        dispatch({ type : PRODUCT_IMPORTANTS, data });
    };
}

/**
 * Fetches the Newest Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchNewests(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getNewests(params);
        dispatch({ type : PRODUCT_NEWESTS, data });
    };
}

/**
 * Fetches the Favorite Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchFavorites(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getFavorites(params);
        dispatch({ type : PRODUCT_FAVORITES, data });
    };
}

/**
 * Fetches the Purchase Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchPurchases(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getPurchases(params);
        dispatch({ type : PRODUCT_PURCHASES, data });
    };
}

/**
 * Fetches the History Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchHistory(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getHistory(params);
        dispatch({ type : PRODUCT_HISTORY, data });
    };
}



/**
 * Fetches the Product Elem
 * @param {String}  product
 * @param {String=} orderHash
 * @returns {Function}
 */
export function fetchProduct(product, orderHash = "") {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getProduct({ product, orderHash });
        dispatch({ type : PRODUCT_ELEM, data });
    };
}



/**
 * Sets the Product Dialog
 * @param {Object} data
 * @returns {{type: String, data: Object}}
 */
export function setProduct(data) {
    return { type : PRODUCT_DIALOG, data };
}

/**
 * Sets the Product Preview
 * @param {Object} data
 * @returns {{type: String, data: Object}}
 */
export function setPreview(data) {
    return { type : PRODUCT_PREVIEW, data };
}

/**
 * Sets the Product Room
 * @param {Object} data
 * @returns {{type: String, data: Object}}
 */
export function setRoom(data) {
    return { type : PRODUCT_ROOM, data };
}

/**
 * Sets the Product Added
 * @param {Object} data
 * @returns {{type: String, data: Object}}
 */
export function setAdded(data) {
    return { type : PRODUCT_ADDED, data };
}
