import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { logout }           from "Actions/Core/AuthActions";
import { toggleNav }        from "Actions/Store/StoreActions";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";
import MenuItems            from "Utils/App/MenuItems";
import ClassList            from "Utils/Common/ClassList";
import Utils                from "Utils/Common/Utils";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Icon                 from "Components/Utils/Media/Icon";

// Styles
import "Styles/Components/Core/Navigation.css";



/**
 * The Navigation
 */
class Navigation extends React.Component {
    // The Current State
    state = {
        menu : "",
    }

    /**
     * Handles the Navigation Closing
     * @returns {Void}
     */
    handleClose = () => {
        this.props.toggleNav(false);
    }

    /**
     * Handles the Click
     * @param {String} menu
     * @param {Object} submenu
     * @returns {Function}
     */
    handleClick = (menu, submenu) => () => {
        if (!submenu) {
            this.handleClose();
        } else if (this.state.menu === menu) {
            this.setState({ menu : "" });
        } else {
            this.setState({ menu });
        }
    }

    /**
     * Handles the Logout
     * @returns {Void}
     */
    handleLogout = () => {
        this.props.toggleNav(false);
        this.props.logout();
    }



    /**
     * Does the Nav Render
     * @returns {Object}
     */
    renderNavItems() {
        const { settings, pages, menus, categories, isAuthenticated } = this.props;

        const items  = MenuItems.getNavigation(menus, settings, pages, isAuthenticated);
        const result = [];

        for (const { key, href, target, message, submenu, submenuID, options } of items) {
            const item = <HyperLink
                variant="dark"
                href={href}
                target={target}
                message={message}
                onClick={this.handleClose}
                {...options}
            />;

            let subitem = null;
            if (submenu === "category") {
                subitem = <ul className="no-list">
                    {categories.map(({ id, name, url }) => <li key={id}>
                        <HyperLink
                            variant="dark"
                            href={Href.url("PRODUCTS", url)}
                            message={name}
                            onClick={this.handleClose}
                        />
                    </li>)}
                </ul>;
            } else if (submenu === "subcategory" && submenuID) {
                const category = Utils.getData(categories, "id", submenuID);
                if (category) {
                    subitem = <ul className="no-list">
                        {category.childs.map(({ id, name, url }) => <li key={id}>
                            <HyperLink
                                variant="dark"
                                href={Href.url("PRODUCTS", category.url, url)}
                                message={name}
                                onClick={this.handleClose}
                            />
                        </li>)}
                    </ul>;
                }
            }

            result.push(<li key={key}>{item}{subitem}</li>);
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { navOpen, settings, menus, isAuthenticated, credential, notification } = this.props;
        const { menu } = this.state;

        if (!navOpen) {
            return <React.Fragment />;
        }

        const subnavItems = MenuItems.getSubNavigation(menus, settings, isAuthenticated);
        const clientItems = MenuItems.getClient(settings);
        const hasSubnav   = Boolean(subnavItems.length);

        const classes = new ClassList("navigation-container");
        classes.addIf("navigation-right", !settings.header_menuLeft);
        classes.addIf("navigation-left",  settings.header_menuLeft);
        classes.addIf("navigation-upper", settings.header_inUppercase);

        return <section className={classes.get()}>
            <header className="navigation-header">
                <Icon variant="client" />
                {isAuthenticated ? <div>
                    <HyperLink
                        className="navigation-title"
                        variant="dark"
                        url="ACCOUNT"
                        message={NLS.format("HEADER_WELCOME_CLIENT", credential.userName)}
                        onClick={this.handleClose}
                    />
                    <HyperLink
                        className="navigation-notifications"
                        variant="dark"
                        url="NOTIFICATIONS"
                        message={NLS.pluralize("HEADER_NOTIFICATIONS", notification.length)}
                        onClick={this.handleClose}
                    />
                </div> : <div>
                    <h2 className="navigation-title">{NLS.get("HEADER_WELCOME_TITLE")}</h2>
                    {settings.clients_isActive && <>
                        <h3 className="navigation-subtitle">{NLS.get("HEADER_WELCOME_LOGIN")}</h3>
                        <div className="navigation-login">
                            <HyperLink
                                variant="dark"
                                url="LOGIN"
                                message="AUTH_LOGIN_ACTION"
                                onClick={this.handleClose}
                            />
                            {settings.clients_allowRegister && <HyperLink
                                variant="dark"
                                url="REGISTER"
                                message="AUTH_REGISTER_LINK"
                                onClick={this.handleClose}
                            />}
                        </div>
                    </>}
                </div>}
            </header>

            <div className="navigation-content">
                <ul className="navigation-list no-list">
                    {this.renderNavItems()}
                </ul>

                {hasSubnav && <ul className="navigation-list no-list">
                    {subnavItems.map(({ key, href, target, message, icon, options }) => <li key={key}>
                        <HyperLink
                            variant="gray"
                            href={href}
                            target={target}
                            message={message}
                            onClick={this.handleClose}
                            {...options}
                        />
                    </li>)}
                </ul>}

                {isAuthenticated && <ul className="navigation-list no-list">
                    {clientItems.map(({ type, href, message, submenu, options }) => <li key={type}>
                        <HyperLink
                            variant="gray"
                            href={!submenu ? href : ""}
                            message={message}
                            afterIcon={submenu ? (type === menu ? "down" : "right") : ""}
                            onClick={this.handleClick(type, submenu)}
                            {...options}
                        />
                        {type === menu && <ul className="no-list">
                            {submenu.map(({ type, href, message }) => <li key={type}>
                                <HyperLink
                                    variant="gray"
                                    href={href}
                                    message={message}
                                    onClick={this.handleClose}
                                />
                            </li>)}
                        </ul>}
                    </li>)}
                    {settings.products_hasPurchases && <li>
                        <HyperLink
                            variant="gray"
                            url="PURCHASES"
                            message="PURCHASES_NAME"
                            onClick={this.handleClose}
                        />
                    </li>}
                    {settings.products_hasFavorites && <li>
                        <HyperLink
                            variant="gray"
                            url="FAVORITES"
                            message="FAVORITES_NAME"
                            onClick={this.handleClose}
                        />
                    </li>}
                    {settings.products_hasHistory && <li>
                        <HyperLink
                            variant="gray"
                            url="HISTORY"
                            message="HISTORY_NAME"
                            onClick={this.handleClose}
                        />
                    </li>}
                    <li>
                        <HyperLink
                            variant="gray"
                            message="CLIENT_LOGOUT"
                            onClick={this.handleLogout}
                        />
                    </li>
                </ul>}
            </div>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        logout          : PropTypes.func.isRequired,
        toggleNav       : PropTypes.func.isRequired,
        navOpen         : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        pages           : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        credential      : PropTypes.object.isRequired,
        menus           : PropTypes.object.isRequired,
        categories      : PropTypes.array.isRequired,
        notification    : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            pages           : state.core.pages,
            isAuthenticated : state.auth.isAuthenticated,
            credential      : state.auth.credential,
            navOpen         : state.store.navOpen,
            menus           : state.store.menus,
            categories      : state.store.categories,
            notification    : state.notification.list,
        };
    }
}

export default connect(Navigation.mapStateToProps, {
    logout, toggleNav,
})(Navigation);
