
import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleFilter }     from "Actions/Store/StoreActions";
import NLS                  from "Utils/Core/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Products Amount Filter
 */
class FilterAmount extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, data, isSelected, getUrl, toggleFilter } = this.props;

        const shouldShow = Boolean(settings.products_showOfferFilter && data && data.length > 1);
        if (!shouldShow) {
            return <React.Fragment />;
        }

        return <div className="aside-options">
            <h4>{NLS.get("OFFERS_NAME")}</h4>
            <ul className="no-list">
                {data.map((elem) => <li key={elem.id}>
                    <HyperLink
                        variant="opacity"
                        href={getUrl("amount", elem)}
                        onClick={() => toggleFilter(false)}
                        icon={isSelected("amount", elem.id) ? "check" : "blank"}
                        message={elem.filter}
                    />
                </li>)}
            </ul>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleFilter : PropTypes.func.isRequired,
        settings     : PropTypes.object.isRequired,
        data         : PropTypes.array,
        isSelected   : PropTypes.func.isRequired,
        getUrl       : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(FilterAmount.mapStateToProps, {
    toggleFilter,
})(FilterAmount);
