import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/Core/NLS";
import Analitics            from "Utils/Core/Analitics";
import Utils                from "Utils/Common/Utils";

// Components
import CartMessage          from "Components/Cart/Utils/CartMessage";
import CartSummary          from "Components/Cart/Utils/CartSummary";
import ProductList          from "Components/Cart/Product/ProductList";
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Title/SubTitle";

// Actions
import {
    editProduct, removeProduct, confirmProducts,
} from "Actions/Store/CartActions";



/**
 * The Product Content
 */
class ProductContent extends React.Component {
    // The Current State
    state = {
        loading  : false,
        hasError : false,
        stock    : {},
        errors   : {},
        data     : { message : "" },
    }

    /**
     * Set the Data When Loading
     * @returns {Void}
     */
    componentDidMount() {
        if (this.props.elem.message) {
            this.handleChange("message", this.props.elem.message);
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data, [name] : value },
            errors : { ...this.state.data, [name] : ""    },
        });
    }

    /**
     * Handles the Product Edit
     * @param {Object} product
     * @param {Number} amount
     * @returns {Promise}
     */
    handleEdit = async (product, amount) => {
        const { elem, editProduct, openAlert, closeAlert } = this.props;
        if (this.state.loading) {
            return;
        }

        this.setState({ loading : true });
        closeAlert();
        try {
            const oldAmount = product.amount;
            await editProduct(elem.orderHash, product.productID, product.variantID, amount);
            Analitics.editInCart(product, oldAmount, amount, product.forRent);
            this.setState({ loading : false, hasError : false, stock : {} });
        } catch (response) {
            if (response.errors) {
                openAlert("", response.errors.form);
            }
            const stock = response.data || {};
            this.setState({ loading : false, hasError : true, stock });
        }
    }

    /**
     * Handles a Product Delete
     * @param {Object} product
     * @returns {Promise}
     */
    handleDelete = async (product) => {
        const { elem, removeProduct, openAlert, closeAlert } = this.props;
        if (this.state.loading) {
            return;
        }

        this.setState({ loading : true, stock : {} });
        closeAlert();
        try {
            const response = await removeProduct(elem.orderHash, product.productID, product.variantID);
            Analitics.removeFromCart(product, product.amount, product.forRent);
            if (response.amount > 0) {
                openAlert(response.success);
                this.setState({ loading : false, hasError : false });
            }
        } catch (errors) {
            openAlert("", errors.form);
            this.setState({ loading : false });
        }
    }

    /**
     * Handles a Product Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { elem, confirmProducts, openAlert, closeAlert, onSubmit } = this.props;
        const { loading, data                                          } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, stock : {} });
        closeAlert();
        try {
            const response = await confirmProducts(elem.orderHash, data.message);
            Analitics.checkout(Analitics.stepProducts, elem.products);
            onSubmit(response.checkout, response.orderID, response.order);
        } catch (response) {
            if (response.errors) {
                openAlert("", response.errors.form);
            }
            const errors   = response.errors || {};
            let   hasError = false;
            let   stock    = {};
            if (!Utils.isEmpty(response.data)) {
                hasError = true;
                stock    = response.data;
            }
            this.setState({ loading : false, hasError, stock, errors });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, openAlert                        } = this.props;
        const { items, offers, totals, bellowMinimum   } = elem;
        const { loading, hasError, stock, data, errors } = this.state;

        const multiCoins = totals.length > 1;
        const isDisabled = Boolean(loading || hasError || bellowMinimum || errors.message);

        return <>
            <section className="cart-content">
                {items.length > 0 && items.map((elem, index) => (
                    <Card key={index} className="cart-card" withBorder>
                        <SubTitle
                            message={multiCoins ? NLS.format("CART_PURCHASES_IN", totals[index].name) : "CART_PURCHASES"}
                            icon="product"
                        />
                        <ProductList
                            data={elem}
                            errors={stock}
                            onEdit={this.handleEdit}
                            onDelete={this.handleDelete}
                            loading={loading}
                        />
                    </Card>
                ))}
                {offers.map((elem) => <Card key={elem.id} className="cart-card" withBorder>
                    <SubTitle message={elem.name} icon="offer" />
                    {elem.showPromo && <div className="cart-units">
                        {elem.index > 0 ? <p className="cart-discount">
                            {NLS.format(`CART_DISCOUNT_${elem.mode.toUpperCase()}`, elem.current, "$")}
                        </p> : <p className="cart-next">
                            {NLS.format(`CART_DISCOUNT_NEXT_${elem.mode.toUpperCase()}`, elem.next, "$")}
                        </p>}
                    </div>}
                    <ProductList
                        data={elem.items}
                        errors={stock}
                        onEdit={this.handleEdit}
                        onDelete={this.handleDelete}
                        loading={loading}
                    />
                </Card>)}

                <CartMessage
                    step="products"
                    value={data.message}
                    error={errors.message}
                    onChange={this.handleChange}
                />
            </section>

            <CartSummary
                step="products"
                openAlert={openAlert}
                onSubmit={this.handleSubmit}
                cancelUrl="PRODUCTS"
                isDisabled={isDisabled}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        editProduct     : PropTypes.func.isRequired,
        removeProduct   : PropTypes.func.isRequired,
        confirmProducts : PropTypes.func.isRequired,
        onSubmit        : PropTypes.func.isRequired,
        openAlert       : PropTypes.func.isRequired,
        closeAlert      : PropTypes.func.isRequired,
        elem            : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.cart.elem,
        };
    }
}

export default connect(ProductContent.mapStateToProps, {
    editProduct, removeProduct, confirmProducts,
})(ProductContent);
