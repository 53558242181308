import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { sendVoucher }      from "Actions/Client/OrderActions";

// Components
import EditDialog           from "Components/Utils/Dialog/EditDialog";
import TextField            from "Components/Utils/Form/TextField";
import Alert                from "Components/Utils/Form/Alert";



/**
 * The Order Voucher
 */
class OrderVoucher extends React.Component {
    // The Initial Data
    initialData = {
        orderID : "",
        message : "",
        image   : "",
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
        error   : "",
    }



    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { orderID, sendVoucher, onSubmit } = this.props;
        const { data, loading                  } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, errors : {}, error : "" });
        try {
            data.orderID = orderID;
            const { success } = await sendVoucher(data);
            this.setState({ loading : false });
            onSubmit(success);
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ error : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose                } = this.props;
        const { data, loading, errors, error } = this.state;

        return <EditDialog
            open={open}
            message="ORDERS_VOUCHER_TITLE"
            icon="order"
            isDisabled={loading}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            hasPrimary
            withSpacing
        >
            <Alert variant="error" message={error} onClose={this.closeAlert} />
            <TextField
                name="message"
                label="ORDERS_VOUCHER_MESSAGE"
                value={data.message}
                error={errors.message}
                onChange={this.handleChange}
                withMargin
                isRequired
            />
            <TextField
                type="file"
                name="image"
                label="ORDERS_VOUCHER_FILE"
                error={errors.image}
                onChange={this.handleChange}
                isRequired
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        sendVoucher : PropTypes.func.isRequired,
        open        : PropTypes.bool,
        orderID     : PropTypes.number,
        onSubmit    : PropTypes.func.isRequired,
        onClose     : PropTypes.func.isRequired,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        open : false,
    }
}

export default connect(null, {
    sendVoucher,
})(OrderVoucher);
