import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";
import Pages                from "Utils/App/Pages";

// Components
import Form                 from "Components/Utils/Form/Form";
import Columns              from "Components/Utils/Form/Columns";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Actions
import {
    register, setFocused,
} from "Actions/Core/AuthActions";
import {
    assignCart, setCartRedirect,
} from "Actions/Store/CartActions";



/**
 * The Register Page
 */
class RegisterPage extends React.Component {
    // The Current State
    state = {
        data : {
            socialReason    : "",
            ivaCategory     : "",
            cuit            : "",
            dni             : "",
            fantasyName     : "",
            firstName       : "",
            lastName        : "",
            phone           : "",
            cellphone       : "",
            address         : "",
            locality        : "",
            province        : "",
            zipCode         : "",
            email           : "",
            confirmEmail    : "",
            newPassword     : "",
            confirmPassword : "",
            confirmTerms    : "",
        },
        loading : false,
        errors  : {},
        error   : "",
    }



    /**
     * Handles the Focus Change
     * @param {Boolean} isFocused
     * @returns {Void}
     */
    handleFocus = (isFocused) => {
        if (this.props.isApp) {
            this.props.setFocused(isFocused);
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Does a Submit on Touch
     * @param {Event} e
     * @returns {Void}
     */
    handleTouch = (e) => {
        if (this.props.isFocused && !this.state.loading) {
            document.activeElement.blur();
            this.handleSubmit(e);
        }
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const {
            location, settings, orderHash, cartRedirect,
            register, assignCart, setCartRedirect,
        } = this.props;
        const { data, loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, errors : {}, error : "" });
        try {
            data.orderHash = orderHash;
            const response = await register(data);
            if (orderHash) {
                await assignCart(response.clientID, orderHash);
            }
            if (settings.clients_validateRegister) {
                setCartRedirect("");
                Href.goto("LOGIN");
            } else if (cartRedirect) {
                setCartRedirect("");
                Href.goto(cartRedirect);
            } else {
                const redirect = location.state ? location.state.from.pathname : "/";
                Href.goto(redirect);
            }
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ error : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, entities, pages          } = this.props;
        const { ivaCategories, withCUIT, provinces } = entities;
        const { data, loading, errors, error       } = this.state;

        const fantasyName  = settings.clients_fantasyName;
        const fantasyLabel = fantasyName || "ACCOUNT_FANTASY_NAME";
        const fantasyError = fantasyName && errors.fantasyName ? NLS.format("ACCOUNT_ERROR_FANTASY", fantasyName) : errors.fantasyName;
        const reqCUIT      = withCUIT.indexOf(Number(data.ivaCategory)) > -1 || !data.ivaCategory;
        const reqFiscal    = settings.clients_fiscalRequired;
        const reqName      = settings.clients_nameRequired;
        const reqAddress   = settings.clients_addressRequired;
        const showFiscal   = settings.clients_fiscalRequested;
        const showAddress  = settings.clients_addressRequested;
        const showConfirm  = settings.clients_confirmRequested;
        const terms        = Pages.get(pages, Pages.TERMS);
        const showTerms    = Boolean(settings.clients_termsRequested && terms);

        return <>
            <Form
                className="auth-form"
                error={error}
                onSubmit={this.handleSubmit}
                onClose={this.closeAlert}
            >
                <Columns>
                    <TextField
                        isHidden={!showFiscal}
                        name="socialReason"
                        label="ACCOUNT_SOCIAL_REASON"
                        value={data.socialReason}
                        error={errors.socialReason}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqFiscal}
                        shrink
                    />
                    <TextField
                        isHidden={!showFiscal}
                        name="fantasyName"
                        label={fantasyLabel}
                        value={data.fantasyName}
                        error={fantasyError}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        shrink
                    />

                    <TextField
                        isHidden={!showFiscal}
                        type="select"
                        name="ivaCategory"
                        label="ACCOUNT_IVA_CATEGORY"
                        options={ivaCategories}
                        value={data.ivaCategory}
                        error={errors.ivaCategory}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqFiscal}
                        withNone
                        shrink
                    />
                    <TextField
                        isHidden={!showFiscal || !reqCUIT}
                        name="cuit"
                        label="ACCOUNT_CUIT"
                        value={data.cuit}
                        error={errors.cuit}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqFiscal}
                        shrink
                    />
                    <TextField
                        isHidden={!showFiscal || reqCUIT}
                        name="dni"
                        label="ACCOUNT_DNI"
                        value={data.dni}
                        error={errors.dni}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqFiscal}
                        shrink
                    />

                    <TextField
                        name="firstName"
                        label="ACCOUNT_FIRST_NAME"
                        value={data.firstName}
                        error={errors.firstName}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqName}
                        shrink
                    />
                    <TextField
                        name="lastName"
                        label="ACCOUNT_LAST_NAME"
                        value={data.lastName}
                        error={errors.lastName}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqName}
                        shrink
                    />
                    <TextField
                        name="phone"
                        label="ACCOUNT_PHONE"
                        value={data.phone}
                        error={errors.phone}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        shrink
                    />
                    <TextField
                        name="cellphone"
                        label="ACCOUNT_CELLPHONE"
                        value={data.cellphone}
                        error={errors.phone}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        shrink
                    />
                </Columns>

                {showAddress && <Columns>
                    <TextField
                        name="address"
                        label="ACCOUNT_ADDRESS"
                        value={data.address}
                        error={errors.address}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqAddress}
                        shrink
                    />
                    <TextField
                        name="locality"
                        label="ACCOUNT_LOCALITY"
                        value={data.locality}
                        error={errors.locality}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqAddress}
                        shrink
                    />
                    <TextField
                        type="select"
                        name="province"
                        label="ACCOUNT_PROVINCE"
                        options={provinces}
                        value={data.province}
                        error={errors.province}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqAddress}
                        withNone
                        shrink
                    />
                    <TextField
                        name="zipCode"
                        label="ACCOUNT_ZIP_CODE"
                        value={data.zipCode}
                        error={errors.zipCode}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqAddress}
                        shrink
                    />
                </Columns>}

                <Columns>
                    <TextField
                        type="email"
                        name="email"
                        label="GENERAL_EMAIL"
                        value={data.email}
                        error={errors.email}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired
                        shrink
                    />
                    <TextField
                        isHidden={!showConfirm}
                        type="email"
                        name="confirmEmail"
                        label="GENERAL_EMAIL_CONFIRM"
                        value={data.confirmEmail}
                        error={errors.confirmEmail}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired
                        shrink
                    />
                    <TextField
                        type="password"
                        name="newPassword"
                        label="GENERAL_PASSWORD"
                        autoComplete="new-password"
                        value={data.newPassword}
                        error={errors.newPassword}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired
                        shrink
                    />
                    <TextField
                        isHidden={!showConfirm}
                        type="password"
                        name="confirmPassword"
                        label="GENERAL_PASSWORD_CONFIRM"
                        autoComplete="new-password"
                        value={data.confirmPassword}
                        error={errors.confirmPassword}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired
                        shrink
                    />
                </Columns>

                <TextField
                    isHidden={!showTerms}
                    className="auth-terms-space"
                    type="checkbox"
                    name="confirmTerms"
                    label="GENERAL_PASSWORD_CONFIRM"
                    value={data.confirmTerms}
                    error={errors.confirmTerms}
                    onChange={this.handleChange}
                >
                    {NLS.get("AUTH_TERMS_TEXT1B")}
                    <HyperLink
                        className="auth-terms-anchor"
                        variant="black"
                        url={terms.url}
                        message="AUTH_TERMS_TEXT2"
                    />
                    {NLS.get("AUTH_TERMS_TEXT3")}
                </TextField>
                <Button
                    variant="primary"
                    message="AUTH_REGISTER_ACTION"
                    onTouchEnd={this.handleTouch}
                    isDisabled={loading}
                    fullWidth
                />
            </Form>

            <p className="auth-link-route">
                <HyperLink
                    className="auth-link-anchor"
                    url="LOGIN"
                    variant="black"
                    message="AUTH_LOGIN_LINK"
                />
            </p>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        register        : PropTypes.func.isRequired,
        setFocused      : PropTypes.func.isRequired,
        assignCart      : PropTypes.func.isRequired,
        setCartRedirect : PropTypes.func.isRequired,
        location        : PropTypes.object.isRequired,
        isApp           : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        pages           : PropTypes.object.isRequired,
        entities        : PropTypes.object.isRequired,
        isFocused       : PropTypes.bool.isRequired,
        orderHash       : PropTypes.string.isRequired,
        cartRedirect    : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp        : state.core.isApp,
            settings     : state.core.settings,
            entities     : state.core.entities,
            pages        : state.core.pages,
            isFocused    : state.auth.isFocused,
            orderHash    : state.cart.elem.orderHash,
            cartRedirect : state.cart.redirect,
        };
    }
}

export default connect(RegisterPage.mapStateToProps, {
    register, setFocused, assignCart, setCartRedirect,
})(RegisterPage);
