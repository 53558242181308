import React                from "react";
import PropTypes            from "prop-types";

// Components
import ErrorPage            from "Components/Content/ErrorPage";



/**
 * The Products Container
 */
class ProductsContainer extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { production, loading, error, children } = this.props;

        if (error) {
            return <ErrorPage />;
        }
        return React.Children.map(children, (child) => (
            React.cloneElement(child, { production, loading })
        ));
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        production : PropTypes.object.isRequired,
        loading    : PropTypes.bool.isRequired,
        error      : PropTypes.bool.isRequired,
        children   : PropTypes.any,
    }
}

export default ProductsContainer;
