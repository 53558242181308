import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { logout }           from "Actions/Core/AuthActions";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";
import Responsive           from "Utils/App/Responsive";
import MenuItems            from "Utils/App/MenuItems";
import Pages                from "Utils/App/Pages";

// Sections
import AccountPage          from "./AccountPage";
import AddressList          from "./Address/AddressList";
import AddressElem          from "./Address/AddressElem";
import InvoicePage          from "./Voucher/InvoicePage";
import ReceiptPage          from "./Voucher/ReceiptPage";
import CreditNotePage       from "./Voucher/CreditNotePage";
import DebitNotePage        from "./Voucher/DebitNotePage";
import OrderList            from "./Order/OrderList";
import OrderElem            from "./Order/OrderElem";
import QueryList            from "./Query/QueryList";
import QueryElem            from "./Query/QueryElem";
import QueryCreate          from "./Query/QueryCreate";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Title/Title";
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";
import SwitchRoute          from "Components/Utils/Route/SwitchRoute";
import ClientRoute          from "Components/Utils/Route/ClientRoute";

// Styles
import "Styles/Components/Client/Client.css";



/**
 * The Client Container
 */
class ClientContainer extends React.Component {
    /**
     * A Blank Page
     * @returns {Function}
     */
    blankPage = () => {
        return <Card className="client-blank" />;
    }

    /**
     * Returns the Title Depending on the Path
     * @returns {String}
     */
    getTitle() {
        let result = "CLIENT_NAME";
        if (Href.startsWith("ACCOUNT_DATA")) {
            result = "ACCOUNT_NAME";
        } else if (Href.startsWith("ADDRESSES")) {
            result = "ADDRESSES_NAME";
        } else if (Href.startsWith("ADDRESSES_VIEW")) {
            result = "ADDRESSES_SINGULAR";
        } else if (Href.startsWith("INVOICES")) {
            result = "VOUCHERS_INVOICES";
        } else if (Href.startsWith("RECEIPTS")) {
            result = "VOUCHERS_RECEIPTS";
        } else if (Href.startsWith("CREDIT_NOTES")) {
            result = "VOUCHERS_CREDIT_NOTES";
        } else if (Href.startsWith("DEBIT_NOTES")) {
            result = "VOUCHERS_DEBIT_NOTES";
        } else if (Href.startsWith("ORDERS")) {
            result = "ORDERS_NAME";
        } else if (Href.startsWith("ORDERS_VIEW")) {
            result = "ORDERS_SINGULAR";
        } else if (Href.startsWith("QUERIES_CREATE")) {
            result = "QUERIES_CREATE";
        } else if (Href.startsWith("QUERIES")) {
            result = "QUERIES_NAME";
        } else if (Href.startsWith("QUERIES_VIEW")) {
            result = "QUERIES_SINGULAR";
        }
        return result;
    }

    /**
     * Returns the Back Url Depending on the Path
     * @returns {String}
     */
    getBackUrl() {
        const { responsive, type, page } = this.props;
        const showBack = Responsive.isMobile(responsive);
        let   result   = showBack ? "ACCOUNT" : "";

        if (Href.isEqual("ACCOUNT") || Href.isEqual("VOUCHERS")) {
            result = "";
        } else if (showBack && Href.startsWith("VOUCHERS")) {
            result = "VOUCHERS";
        } else if (Href.isEqual("ADDRESSES_CREATE") || (Href.startsWith("ADDRESSES_VIEW") && !Href.startsWith("ADDRESSES"))) {
            result = "ADDRESSES";
        } else if (Href.startsWith("ORDERS_VIEW") && !Href.startsWith("ORDERS")) {
            result = "ORDERS";
        } else if (Href.isEqual("QUERIES_CREATE") || (Href.startsWith("QUERIES_VIEW") && !Href.startsWith("QUERIES"))) {
            result = "QUERIES";
        }
        if (result) {
            result = Href.url(result);
        }
        if (result && type) {
            result = Href.url(result, type);
        }
        if (result && page > 1) {
            result = Href.url(result, page);
        }
        return result;
    }

    /**
     * Returns the ClassName for the Navigation Link
     * @param {String} icon
     * @param {String} url
     * @returns {String}
     */
    getClassName(icon, url) {
        const subUrls = [
            { list : "ADDRESSES", view : "ADDRESSES_VIEW" },
            { list : "ORDERS",    view : "ORDERS_VIEW"    },
            { list : "QUERIES",   view : "QUERIES_VIEW"   }
        ];

        const result = [];
        if (icon === "voucher") {
            result.push("client-sub");
        }
        let isSelected = false;
        if (Href.startsWith(url)) {
            isSelected = true;
        } else if (Href.isEqual(url)) {
            isSelected = true;
        } else {
            for (const { list, view } of subUrls) {
                if (url.startsWith(Href.url(list)) && Href.startsWith(view)) {
                    isSelected = true;
                }
            }
        }
        if (isSelected) {
            result.push("client-selected");
        }
        return result.join(" ");
    }

    /**
     * Returns the ClassName for the Navigation Link
     * @param {String} icon
     * @param {String} url
     * @returns {String}
     */
    getAfterIcon(icon, url) {
        if (icon === "voucher" && Href.startsWith(url)) {
            return "down";
        }
        if (icon === "voucher") {
            return "right";
        }
        return null;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, responsive, pages, credential, logout } = this.props;

        const isMobile       = Responsive.isMobile(responsive);
        const clientItems    = MenuItems.getClient(settings);
        const secondaryItems = MenuItems.getClientSecondary(pages);
        const contactPage    = Pages.get(pages, Pages.CONTACT);
        const isBlank        = Href.isEqual("ACCOUNT") || Href.isEqual("VOUCHERS");
        const title          = isMobile ? this.getTitle() : "CLIENT_NAME";
        const subtitle       = !isMobile || isBlank ? credential.name : "";
        const backUrl        = this.getBackUrl();

        return <>
            <Title
                className="client-title"
                message={title}
                submessage={subtitle}
                href={backUrl}
            />
            <Wrapper className={isBlank ? " client-menuonly" : ""} withSpacing>
                <div className="client-container">
                    <div className="client-sidebar">
                        <Card className="client-mainnav">
                            <ul className="client-nav no-list">
                                {clientItems.map(({ key, icon, href, message, submenu, options }) => <li key={key}>
                                    <HyperLink
                                        variant="none"
                                        href={href}
                                        message={message}
                                        icon={icon}
                                        afterIcon={this.getAfterIcon(key, href)}
                                        className={this.getClassName(key, href)}
                                        {...options}
                                    />
                                    {key === "voucher" && Href.startsWith(href) ? <ul className="no-list">
                                        {submenu.map(({ key, href, message }) => <li key={key}>
                                            <HyperLink
                                                variant="none"
                                                href={href}
                                                message={message}
                                                className={Href.isEqual(href) ? "client-selected" : ""}
                                            />
                                        </li>)}
                                    </ul> : ""}
                                </li>)}
                                {settings.products_hasPurchases && <li className="client-extra">
                                    <HyperLink
                                        variant="none"
                                        icon="purchases"
                                        url="PURCHASES"
                                        message="PURCHASES_NAME"
                                    />
                                </li>}
                                {settings.products_hasFavorites && <li className="client-extra">
                                    <HyperLink
                                        variant="none"
                                        icon="favorite"
                                        url="FAVORITES"
                                        message="FAVORITES_NAME"
                                    />
                                </li>}
                                {settings.products_hasHistory && <li className="client-extra">
                                    <HyperLink
                                        variant="none"
                                        icon="history"
                                        url="HISTORY"
                                        message="HISTORY_NAME"
                                    />
                                </li>}
                            </ul>
                        </Card>

                        <Card className="client-subnav">
                            <ul className="client-nav no-list">
                                {contactPage && <li>
                                    <HyperLink
                                        variant="none"
                                        icon="email"
                                        href={contactPage.url}
                                        message={contactPage.name}
                                    />
                                </li>}
                                {!!settings.contact_whatsapp && <li>
                                    <HyperLink
                                        variant="none"
                                        message={NLS.format("HEADER_WHATSAPP_NUMBER", settings.contact_whatsapp)}
                                        href={settings.contact_whatsapp}
                                        icon="whatsapp"
                                        forWhatsApp
                                    />
                                </li>}
                                {!!settings.contact_phone && <li>
                                    <HyperLink
                                        variant="none"
                                        message={NLS.format("HEADER_PHONE_NUMBER", settings.contact_phone)}
                                        href={settings.contact_phone}
                                        icon="phone"
                                        forPhone
                                    />
                                </li>}
                                {secondaryItems.map(({ key, icon, href, message, options }) => <li key={key}>
                                    <HyperLink
                                        variant="none"
                                        href={href}
                                        message={message}
                                        icon={icon}
                                        {...options}
                                    />
                                </li>)}
                                <li>
                                    <HyperLink
                                        variant="none"
                                        message="CLIENT_LOGOUT"
                                        icon="logout"
                                        onClick={logout}
                                    />
                                </li>
                            </ul>
                        </Card>
                    </div>

                    <div className="client-content">
                        <SwitchRoute>
                            <ClientRoute url="ACCOUNT"           component={this.blankPage} exact />
                            <ClientRoute url="ACCOUNT_DATA"      component={AccountPage}    exact />

                            <ClientRoute url="ADDRESSES"         component={AddressList}    exact />
                            <ClientRoute url="ADDRESSES_CREATE"  component={AddressElem}    exact />
                            <ClientRoute url="ADDRESSES_PAGE"    component={AddressList}    exact />
                            <ClientRoute url="ADDRESSES_ONE"     component={AddressElem}    exact />

                            <ClientRoute url="VOUCHERS"          component={this.blankPage} exact />
                            <ClientRoute url="INVOICES"          component={InvoicePage}    exact />
                            <ClientRoute url="INVOICES_PAGE"     component={InvoicePage}    exact />
                            <ClientRoute url="RECEIPTS"          component={ReceiptPage}    exact />
                            <ClientRoute url="RECEIPTS_PAGE"     component={ReceiptPage}    exact />
                            <ClientRoute url="CREDIT_NOTES"      component={CreditNotePage} exact />
                            <ClientRoute url="CREDIT_NOTES_PAGE" component={CreditNotePage} exact />
                            <ClientRoute url="DEBIT_NOTES"       component={DebitNotePage}  exact />
                            <ClientRoute url="DEBIT_NOTES_PAGE"  component={DebitNotePage}  exact />

                            <ClientRoute url="ORDERS"            component={OrderList}      exact />
                            <ClientRoute url="ORDERS_PAGE"       component={OrderList}      exact />
                            <ClientRoute url="ORDERS_ONE"        component={OrderElem}      exact />

                            <ClientRoute url="QUERIES"           component={QueryList}      exact />
                            <ClientRoute url="QUERIES_TYPE"      component={QueryList}      exact />
                            <ClientRoute url="QUERIES_TYPE_PAGE" component={QueryList}      exact />
                            <ClientRoute url="QUERIES_ONE"       component={QueryElem}      exact />
                            <ClientRoute url="QUERIES_CREATE"    component={QueryCreate}    exact />
                        </SwitchRoute>
                    </div>
                </div>
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        logout     : PropTypes.func.isRequired,
        location   : PropTypes.object.isRequired,
        settings   : PropTypes.object.isRequired,
        responsive : PropTypes.object.isRequired,
        pages      : PropTypes.object.isRequired,
        credential : PropTypes.object.isRequired,
        page       : PropTypes.number.isRequired,
        type       : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings   : state.core.settings,
            responsive : state.core.responsive,
            pages      : state.core.pages,
            credential : state.auth.credential,
            page       : state.client.page,
            type       : state.client.type,
        };
    }
}

export default connect(ClientContainer.mapStateToProps, {
    logout,
})(ClientContainer);
