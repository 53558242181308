import React                from "react";
import PropTypes            from "prop-types";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";

// Components
import Icon                 from "Components/Utils/Media/Icon";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Content/Artist/ArtistData.css";



/**
 * The Artist Data
 */
class ArtistData extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, artist, asGrid, showButton } = this.props;

        return <ul className={"artist-data no-list" + (asGrid ? " artist-data-grid" : "")}>
            {!!elem.nationality && <li>
                <Icon variant="nationality" />
                <div>
                    <label>{NLS.get("ARTISTS_FROM")}</label>
                    <p>{elem.nationality}</p>
                </div>
            </li>}
            {!!elem.birthYear && <li>
                <Icon variant="birthdate" />
                <div>
                    <label>{NLS.get("ARTISTS_BORN_IN")}</label>
                    <p>{elem.birthYear}</p>
                </div>
            </li>}
            {!!elem.artStyle && <li>
                <Icon variant="artist" />
                <div>
                    <label>{NLS.get("ARTISTS_ART_STYLE")}</label>
                    <p>{elem.artStyle}</p>
                </div>
            </li>}
            {!!elem.artworks && <li>
                <Icon variant="artworks" />
                <div>
                    <label>{NLS.get("ARTISTS_ARTWORKS")}</label>
                    <p>{elem.artworks}</p>
                </div>
            </li>}
            {showButton && <li>
                <Button
                    variant="primary"
                    message="ARTISTS_SEE_ARTWORKS"
                    href={Href.url("ARTIST", artist)}
                />
            </li>}
        </ul>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        elem       : PropTypes.object.isRequired,
        artist     : PropTypes.string,
        asGrid     : PropTypes.bool,
        showButton : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        artist     : "",
        asGrid     : false,
        showButton : false,
    }
}

export default ArtistData;
