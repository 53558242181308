import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleFilter }     from "Actions/Store/StoreActions";
import NLS                  from "Utils/Core/NLS";
import Responsive           from "Utils/App/Responsive";
import Utils                from "Utils/Common/Utils";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Html                 from "Components/Utils/Common/Html";
import EditDialog           from "Components/Utils/Dialog/EditDialog";
import FilterList           from "Components/Product/Aside/FilterList";
import FilterAmount         from "Components/Product/Aside/FilterAmount";
import FilterPrice          from "Components/Product/Aside/FilterPrice";

// Styles
import "Styles/Components/Product/List/ProductsAside.css";



/**
 * The Products Aside
 */
class ProductsAside extends React.Component {
    /**
     * Renders the Fields
     * @returns {Object[]}
     */
    renderFields() {
        const { isSelected, getUrl, fields } = this.props.production;
        if (!fields || Utils.isEmpty(fields)) {
            return <React.Fragment />;
        }
        const result = [];
        for (const [ key, field ] of Object.entries(fields)) {
            result.push(<FilterList
                key={key}
                type="field"
                message={field.name}
                data={field.list}
                isSelected={isSelected}
                getUrl={getUrl}
            />);
        }
        return result;
    }

    /**
     * Renders the Filter Content
     * @returns {Object}
     */
    renderContent() {
        const { production, withoutBrands, withoutArtists } = this.props;
        const {
            isSelected, getUrl, hasParent,
            amounts, categories, subcategories, tercategories, brands, artists, prices,
        } = production;

        return <>
            <FilterAmount
                data={amounts}
                isSelected={isSelected}
                getUrl={getUrl}
            />
            <FilterList
                type="category"
                message="CATEGORIES_SINGULAR"
                data={categories}
                isSelected={isSelected}
                getUrl={getUrl}
                withAll
            />
            <FilterList
                type="subcategory"
                message="SUBCATEGORIES_SINGULAR"
                data={subcategories}
                isSelected={isSelected}
                getUrl={getUrl}
                hasParent={hasParent("subcategory")}
                withAll
            />
            <FilterList
                type="tercategory"
                message="TERCATEGORIES_SINGULAR"
                data={tercategories}
                isSelected={isSelected}
                getUrl={getUrl}
                hasParent={hasParent("tercategory")}
                withAll
            />
            {!withoutBrands && <FilterList
                type="brand"
                message="BRANDS_SINGULAR"
                data={brands}
                getUrl={getUrl}
                isSelected={isSelected}
                withAll
            />}
            {!withoutArtists && <FilterList
                type="artist"
                message="ARTISTS_SINGULAR"
                data={artists}
                getUrl={getUrl}
                isSelected={isSelected}
                withAll
            />}
            {this.renderFields()}
            <FilterPrice
                data={prices}
                isSelected={isSelected}
                getUrl={getUrl}
            />
        </>;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            responsive, settings, production,
            showLogo, logo, logoInBanner,
            showBanner, name, results,
            showDescription, description,
            filterOpen, toggleFilter, children,
        } = this.props;

        const isMobile       = Responsive.isMobile(responsive);
        const hasLogo        = showLogo || (logoInBanner && isMobile);
        const hasBanner      = showBanner && (!logoInBanner || !isMobile);
        const hasDescription = Boolean(showDescription && description && !children);
        const hasChildren    = Boolean(showDescription && children);
        const hasFilters     = production.hasFilters();
        const hasAside       = settings.products_showAside && (hasLogo || hasBanner || hasDescription || hasFilters);
        const filterContent  = hasFilters ? this.renderContent() : {};

        return <>
            {hasAside && <aside className={"aside" + (logoInBanner ? " aside-top" : "")}>
                {hasLogo && <HyperLink
                    className="aside-logo"
                    variant="none"
                    href={production.url}
                >
                    {logo && <img src={logo} alt={name} />}
                    {!logo && <h2>{NLS.get(name)}</h2>}
                </HyperLink>}
                {hasBanner && <header className="aside-header">
                    <div className="aside-info">
                        <h3>{NLS.get(name)}</h3>
                        <p className="aside-results">
                            {NLS.pluralize("PRODUCTS_RESULTS", results)}
                        </p>
                    </div>
                </header>}
                <main className="aside-content">
                    {hasDescription && <Html
                        className="aside-description"
                        content={description}
                    />}
                    {hasChildren && children}
                    {hasFilters && <section className="aside-filters">
                        {filterContent}
                    </section>}
                </main>
            </aside>}

            {hasFilters && <EditDialog
                open={filterOpen}
                icon="filter"
                message="PRODUCTS_FILTER"
                onClose={() => toggleFilter(false)}
                withSpacing
            >
                {filterContent}
            </EditDialog>}
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleFilter    : PropTypes.func.isRequired,
        responsive      : PropTypes.object.isRequired,
        settings        : PropTypes.object.isRequired,
        preferences     : PropTypes.object.isRequired,
        filterOpen      : PropTypes.bool.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        production      : PropTypes.object,
        showLogo        : PropTypes.bool,
        logo            : PropTypes.string,
        showBanner      : PropTypes.bool,
        logoInBanner    : PropTypes.bool,
        name            : PropTypes.string,
        results         : PropTypes.number,
        showDescription : PropTypes.bool,
        description     : PropTypes.string,
        withoutBrands   : PropTypes.bool,
        withoutArtists  : PropTypes.bool,
        children        : PropTypes.any,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive      : state.core.responsive,
            settings        : state.core.settings,
            preferences     : state.store.preferences,
            filterOpen      : state.store.filterOpen,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(ProductsAside.mapStateToProps, {
    toggleFilter,
})(ProductsAside);
