import API                  from "Utils/Core/API";

// Types
import {
    CLIENT_ACCOUNT, CLIENT_PAGE,
} from "Utils/Core/Types";



/**
 * Fetches the Client Account
 * @returns {Function}
 */
export function fetchAccount() {
    return async (dispatch) => {
        const data = await API.Client.getAccount();
        dispatch({ type : CLIENT_ACCOUNT, data });
    };
}

/**
 * Edits the Client Account
 * @param {Object} params
 * @returns {Function}
 */
export function editAccount(params) {
    return async (dispatch) => {
        const data = await API.Client.editAccount(params);
        dispatch({ type : CLIENT_ACCOUNT, data });
        return data.success;
    };
}



/**
 * Sets the Client Page
 * @param {Number} page
 * @param {String} type
 * @returns {Function}
 */
export function setPage(page, type) {
    return async (dispatch) => {
        dispatch({ type : CLIENT_PAGE, data : { page, type } });
    };
}
