import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Html                 from "Components/Utils/Common/Html";

// Styles
import "Styles/Components/Product/List/ProductsInfo.css";



/**
 * The Products Info
 */
class ProductsInfo extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isHidden, url, logo, name, description, children } = this.props;

        if (isHidden || !logo || (!description && !children)) {
            return <React.Fragment />;
        }
        return <aside className="products-info">
            <HyperLink
                className="products-info-logo"
                variant="none"
                href={url}
            >
                <img src={logo} alt={name} />
            </HyperLink>
            <main className="aside-content">
                {children ? children : <Html
                    className="aside-description"
                    content={description}
                />}
            </main>
        </aside>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isHidden    : PropTypes.bool.isRequired,
        name        : PropTypes.string,
        logo        : PropTypes.string,
        url         : PropTypes.string,
        description : PropTypes.string,
        children    : PropTypes.any,
    }
}

export default connect(ProductsInfo.mapStateToProps)(ProductsInfo);
