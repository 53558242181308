import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";
import MenuItems            from "Utils/App/MenuItems";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Core/Bar.css";



/**
 * The Bar
 */
class Bar extends React.Component {
    /**
     * Returns the Item Class
     * @param {String} key
     * @param {String} href
     * @param {String} path
     * @returns {String}
     */
    getItemClass(key, href, path) {
        if (path.startsWith(href)) {
            return "bar-selected";
        }
        if (key === "clientData" && path.startsWith(Href.url("ACCOUNT"))) {
            return "bar-selected";
        }
        return "";
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { path, responsive, settings, pages, isAuthenticated, chats, notifications } = this.props;

        const items = MenuItems.getBar(settings, pages, chats, notifications, isAuthenticated, responsive);

        return <footer className="bar-container">
            <Wrapper className="bar-wrapper">
                <ul className="bar-list no-list">
                    {items.map(({ key, icon, href, message, badge }) => <li key={key}>
                        <HyperLink
                            className={this.getItemClass(key, href, path)}
                            variant="none"
                            href={href}
                            icon={icon}
                            message={message}
                            badge={badge}
                        />
                    </li>)}
                </ul>
            </Wrapper>
        </footer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        path            : PropTypes.string.isRequired,
        responsive      : PropTypes.object.isRequired,
        settings        : PropTypes.object.isRequired,
        pages           : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        chats           : PropTypes.number.isRequired,
        notifications   : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive      : state.core.responsive,
            settings        : state.core.settings,
            pages           : state.core.pages,
            isAuthenticated : state.auth.isAuthenticated,
            chats           : state.chat.elem.unreadClient,
            notifications   : state.notification.list.length,
        };
    }
}

export default connect(Bar.mapStateToProps)(Bar);
