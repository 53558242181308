import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import MenuItems            from "Utils/App/MenuItems";
import DateTime             from "Utils/Common/DateTime";
import Utils                from "Utils/Common/Utils";

// Components
import TerTitle             from "Components/Utils/Title/TerTitle";
import Icon                 from "Components/Utils/Media/Icon";
import HyperLink            from "Components/Utils/Common/HyperLink";
import MultiLine            from "Components/Utils/Common/MultiLine";

// Styles
import "Styles/Components/Content/Contact.css";



/**
 * The Contact Data
 */
class ContactData extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            isAuthenticated, settings, menus,
            message, showSchedule, showAddress, showSocial, colorSocial,
        } = this.props;

        const contactItems    = MenuItems.getContact(menus, settings, isAuthenticated);
        const displaySchedule = Boolean(showSchedule && settings.contact_scheduleDays && settings.contact_scheduleDays.length && settings.contact_scheduleFrom && settings.contact_scheduleTo);
        const displayAddress  = Boolean(showAddress && settings.contact_address);
        const displaySocial   = Boolean(showSocial && (settings.contact_facebook || settings.contact_messenger || settings.contact_twitter || settings.contact_instagram || settings.contact_youtube || settings.contact_pinterest))
        const showData        = contactItems.length > 0 || displaySchedule || displayAddress || displaySocial;

        if (!showData) {
            return <React.Fragment />;
        }

        return <section className={"contact-data " + (colorSocial ? " contact-data-color" : "")}>
            <TerTitle message={message} />

            <div className="contact-data-content">
                {contactItems.map(({ key, href, target, icon, message, title, options }) => <div
                    key={key}
                    className="contact-data-link"
                >
                    <HyperLink
                        variant="opacity"
                        href={href}
                        target={target}
                        icon={icon}
                        message={Utils.isEmpty(options) ? (title || message) : href}
                        {...options}
                    />
                </div>)}

                {displaySchedule && <div className="contact-data-item">
                    <Icon variant="schedule" />
                    <div>{DateTime.getSchedule(settings.contact_scheduleDays, settings.contact_scheduleFrom, settings.contact_scheduleTo)}</div>
                </div>}

                {displayAddress && <div className="contact-data-item">
                    <Icon variant="address" />
                    <MultiLine>{settings.contact_address}</MultiLine>
                </div>}

                {displaySocial && <ul className="contact-data-social no-list">
                    {!!settings.contact_facebook && <li>
                        <HyperLink
                            className="facebook"
                            variant="opacity"
                            icon="facebook"
                            href={settings.contact_facebook}
                            target="_blank"
                        />
                    </li>}
                    {!!settings.contact_messenger && <li>
                        <HyperLink
                            className="messenger"
                            variant="opacity"
                            icon="messenger"
                            href={settings.contact_messenger}
                            target="_blank"
                            forMessenger
                        />
                    </li>}
                    {!!settings.contact_twitter && <li>
                        <HyperLink
                            className="twitter"
                            variant="opacity"
                            icon="twitter"
                            href={settings.contact_twitter}
                            target="_blank"
                        />
                    </li>}
                    {!!settings.contact_instagram && <li>
                        <HyperLink
                            className="instagram"
                            variant="opacity"
                            icon="instagram"
                            href={settings.contact_instagram}
                            target="_blank"
                        />
                    </li>}
                    {!!settings.contact_youtube && <li>
                        <HyperLink
                            className="youtube"
                            variant="opacity"
                            icon="youtube"
                            href={settings.contact_youtube}
                            target="_blank"
                        />
                    </li>}
                    {!!settings.contact_pinterest && <li>
                        <HyperLink
                            className="pinterest"
                            variant="opacity"
                            icon="pinterest"
                            href={settings.contact_pinterest}
                            target="_blank"
                        />
                    </li>}
                </ul>}
            </div>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        menus           : PropTypes.object.isRequired,
        message         : PropTypes.string,
        showSchedule    : PropTypes.bool,
        showAddress     : PropTypes.bool,
        showSocial      : PropTypes.bool,
        colorSocial     : PropTypes.bool,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            isAuthenticated : state.auth.isAuthenticated,
            menus           : state.store.menus,
        };
    }
}

export default connect(ContactData.mapStateToProps)(ContactData);
