import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";

// Components
import QueryReply           from "./QueryReply";
import SubTitle             from "Components/Utils/Title/SubTitle";
import Content              from "Components/Utils/Common/Content";
import Status               from "Components/Utils/Common/Status";
import MultiLine            from "Components/Utils/Common/MultiLine";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Alert                from "Components/Utils/Form/Alert";

// Actions
import {
    fetchQuery, ackQuery,
} from "Actions/Client/QueryActions";

// Styles
import "Styles/Components/Client/Query.css";



/**
 * The Query Elem
 */
class QueryElem extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        if (!this.props.settings.queries_isActive) {
            Href.goto("ACCOUNT_DATA");
            return;
        }
        this.props.fetchQuery(this.props.match.params.query);
    }

    /**
     * Unloads the Data
     * @returns {Void}
     */
    componentWillUnmount() {
        this.props.ackQuery();
    }

    /**
     * Acknowledge a Query Create
     * @returns {Void}
     */
    closeAlert = () => {
        this.props.ackQuery();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, elem, created } = this.props;
        const { queryID, success       } = created;

        const message     = loading ? "QUERIES_SINGULAR" : NLS.format("QUERIES_SINGULAR_TITLE", elem.title);
        const showSuccess = queryID !== 0 && queryID === elem.queryID;

        return <>
            <SubTitle message={message} icon="query" />
            <Content loading={loading} hasContent withCard>
                <header className="query-header">
                    {!!elem.queryTypeID && <div>
                        <b>{NLS.get("QUERIES_TYPE")}</b>
                        {elem.queryTypeName}
                    </div>}
                    {!!elem.orderID && <div>
                        <b>{NLS.get("ORDERS_SINGULAR")}</b>
                        <HyperLink
                            variant="black"
                            message={`#${elem.orderID}`}
                            href={Href.url("ORDERS_VIEW", elem.orderID)}
                        />
                    </div>}
                    {!!elem.productID && <div>
                        <b>{NLS.get("PRODUCTS_SINGULAR")}</b>
                        <HyperLink
                            variant="black"
                            message={elem.productName}
                            href={Href.url("PRODUCT", elem.productUrl)}
                        />
                    </div>}
                    <div>
                        <b>{NLS.get("GENERAL_STATUS")}</b>
                        <Status
                            condition={!!elem.isOpen}
                            success="QUERIES_STATUS_OPEN"
                            error="QUERIES_STATUS_CLOSE"
                        />
                    </div>
                    <div>
                        <b>{NLS.get("QUERIES_READING")}</b>
                        <Status
                            condition={!elem.unread}
                            success="QUERIES_READ"
                            error="QUERIES_UNREAD"
                        />
                    </div>
                </header>
                <section className="query-content">
                    <Alert
                        open={showSuccess}
                        variant="success"
                        message={success}
                        onClose={this.closeAlert}
                    />

                    {elem.replies.map((reply) => <section key={reply.queryReplyID} className="query-reply">
                        <header className="query-reply-header">
                            <label>{reply.userName}</label>
                            <div>{NLS.format("QUERIES_PUBLISH", reply.createdDate)}</div>
                        </header>
                        <section className="query-reply-content">
                            <MultiLine>{reply.message}</MultiLine>
                            {reply.hasImage && <img src={reply.imageUrl} alt={reply.title} />}
                            {reply.hasFile && <div className="query-reply-download">
                                <HyperLink
                                    variant="black"
                                    message="GENERAL_DOWNLOAD_FILE"
                                    href={reply.fileUrl}
                                    target="_blank"
                                />
                            </div>}
                        </section>
                    </section>)}
                </section>
                <QueryReply queryID={elem.queryID} />
            </Content>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchQuery : PropTypes.func.isRequired,
        ackQuery   : PropTypes.func.isRequired,
        settings   : PropTypes.object.isRequired,
        loading    : PropTypes.bool.isRequired,
        elem       : PropTypes.object.isRequired,
        created    : PropTypes.object.isRequired,
        match      : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            loading  : state.query.loading,
            elem     : state.query.elem,
            created  : state.query.created,
        };
    }
}

export default connect(QueryElem.mapStateToProps, {
    fetchQuery, ackQuery,
})(QueryElem);
