import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Responsive           from "Utils/App/Responsive";

// Components
import TerTitle             from "Components/Utils/Title/TerTitle";
import Pagination           from "Components/Utils/Common/Pagination";
import Slider               from "Components/Utils/Common/Slider";
import Html                 from "Components/Utils/Common/Html";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Content/Sections/ArticleList.css";



/**
 * The Article List
 */
class ArticleList extends React.Component {
    /**
     * Does the Content Render
     * @returns {Object}
     */
    renderContent() {
        const result = [];
        for (const elem of this.props.list) {
            result.push(<div key={elem.id} className="article-item">
                <div className="article-item-image">
                    <img src={elem.imageUrl} alt={elem.title} />
                </div>
                <div className="article-item-content">
                    <h5>{elem.timeDate}</h5>
                    <h3>{elem.title}</h3>
                    <Html variant="p" content={elem.description} />
                    <h4>{elem.author}</h4>
                    <HyperLink
                        variant="black"
                        message="GENERAL_VIEW_ARTICLE"
                        href={elem.link}
                        target="_blank"
                    />
                </div>
            </div>);
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            responsive, message, list, total, asSlider, cols,
            showAll, perPage, url, page,
        } = this.props;

        if (!list.length) {
            return <React.Fragment />;
        }
        const count = Responsive.calcArticlesCols(responsive, cols);
        const style = { "--article-cols" : count };

        return <section className="article-list">
            <TerTitle message={message} />
            {asSlider ? <Slider
                className="article-list-slider"
                total={list.length}
                count={count}
                outsideNav
            >
                {this.renderContent()}
            </Slider> : <div className="article-list-grid" style={style}>
                {this.renderContent()}
                <Pagination
                    isHidden={!showAll}
                    currentPage={page}
                    totalItems={total}
                    perPage={perPage}
                    url={url}
                />
            </div>}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        responsive : PropTypes.object.isRequired,
        message    : PropTypes.string,
        list       : PropTypes.array.isRequired,
        total      : PropTypes.number.isRequired,
        asSlider   : PropTypes.bool,
        cols       : PropTypes.number,
        showAll    : PropTypes.bool.isRequired,
        perPage    : PropTypes.number.isRequired,
        url        : PropTypes.string.isRequired,
        page       : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
        };
    }
}

export default connect(ArticleList.mapStateToProps)(ArticleList);
