import React                from "react";
import PropTypes            from "prop-types";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Auth Header
 */
class AuthHeader extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data } = this.props;

        return <header className="auth-header">
            {data.logo ? <HyperLink variant="none" url="HOME">
                <img src={data.logo} alt={data.name} />
            </HyperLink> : <h1>
                <HyperLink
                    variant="opacity"
                    url="HOME"
                    message={data.name}
                />
            </h1>}
        </header>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data : PropTypes.object,
    }
}

export default AuthHeader;
