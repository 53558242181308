import React                from "react";
import PropTypes            from "prop-types";



/**
 * The Products Main
 */
class ProductsMain extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { production, getUrl, loading, children } = this.props;
        const childrenWithProps = React.Children.map(children, (child) => (
            React.cloneElement(child, { production, getUrl, loading })
        ));

        return <main>
            {childrenWithProps}
        </main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        production : PropTypes.object,
        getUrl     : PropTypes.func,
        loading    : PropTypes.bool,
        children   : PropTypes.any,
    }
}

export default ProductsMain;
