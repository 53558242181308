import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import Href                 from "Utils/Core/Href";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Header Logo
 */
class HeaderLogo extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings } = this.props;

        let logo = settings.header_logo;
        if (settings.home_sliderBellowHeader && settings.home_logo && Href.isHome()) {
            logo = settings.home_logo;
        }

        if (logo) {
            return <HyperLink className="header-logo" variant="none" url="HOME">
                <img src={logo} alt={settings.siteName} />
            </HyperLink>;
        }

        return <h1 className="header-logo">
            <HyperLink variant="opacity" url="HOME" message={settings.siteName} />
        </h1>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
        location : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default withRouter(connect(HeaderLogo.mapStateToProps)(HeaderLogo));
