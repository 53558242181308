import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import MenuItems            from "Utils/App/MenuItems";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Superior Bar
 */
class SuperiorBar extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isAuthenticated, settings, menus } = this.props;

        const superiorItems = MenuItems.getSuperior(menus, settings, isAuthenticated);

        if (!superiorItems.length) {
            return <React.Fragment />;
        }
        return <section className="header-superior">
            {superiorItems.map(({ key, href, target, message, onlyIcon, icon, options }) => <HyperLink
                key={key}
                variant={onlyIcon ? "menu-icon" : "menu"}
                href={href}
                target={target}
                message={onlyIcon ? "" : message}
                icon={icon}
                {...options}
            />)}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isAuthenticated : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        menus           : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAuthenticated : state.auth.isAuthenticated,
            settings        : state.core.settings,
            menus           : state.store.menus,
        };
    }
}

export default connect(SuperiorBar.mapStateToProps)(SuperiorBar);
