import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchOffer }       from "Actions/Store/ProductActions";
import NLS                  from "Utils/Core/NLS";
import Responsive           from "Utils/App/Responsive";
import Production           from "Utils/App/Production";
import Pages                from "Utils/App/Pages";

// Components
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsHeader       from "Components/Product/List/ProductsHeader";
import ProductsOptions      from "Components/Product/List/ProductsOptions";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsList         from "Components/Product/List/ProductsList";
import ProductsAside        from "Components/Product/Aside/ProductsAside";



/**
 * The Offer Page
 */
class OfferPage extends React.Component {
    // The Production
    production = new Production(this.props.fetchOffer, "offer");

    /**
     * Load the Data on Mount
     * @returns {Void}
     */
    componentDidMount() {
        const { loaded, settings, preferences, match, location } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params, location.search);
            this.production.fetch();
        }
    }

    /**
     * Load the Data if the Brand changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { loaded, settings, preferences, match, location } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params, location.search);
            this.production.fetchIfRequired(prevProps, this.props);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { responsive, settings, pages, loading, error, elem } = this.props;
        const { offer                                             } = this.production.getParams();

        this.production.startRender(elem, "OFFER", offer);
        this.production.addPageCrumb(pages, Pages.OFFERS);
        this.production.addStartCrumb(elem.name);
        this.production.addCategoryCrumbs();

        const banner     = Responsive.getUrl(responsive, elem.banner, elem.mobile);
        const showBanner = Boolean(settings.offers_showBanner && banner);
        const results    = NLS.pluralize("PRODUCTS_RESULTS", elem.results);

        return <ProductsContainer
            production={this.production}
            loading={loading}
            error={error}
        >
            <ProductsHeader
                message={elem.name}
                submessage={results}
                banner={banner}
                height={settings.offers_headerHeight}
                showBanner={showBanner}
            />
            <ProductsOptions />
            <ProductsContent>
                <ProductsAside
                    name={elem.name}
                    results={elem.results}
                    amounts={elem.amounts}
                    description={elem.description}
                    showBanner={showBanner}
                    showDescription
                />
                <ProductsList
                    products={elem.products}
                    pages={elem.pages}
                />
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchOffer  : PropTypes.func.isRequired,
        loaded      : PropTypes.bool.isRequired,
        responsive  : PropTypes.object.isRequired,
        settings    : PropTypes.object.isRequired,
        pages       : PropTypes.object.isRequired,
        preferences : PropTypes.object.isRequired,
        loading     : PropTypes.bool.isRequired,
        error       : PropTypes.bool.isRequired,
        elem        : PropTypes.object.isRequired,
        match       : PropTypes.object.isRequired,
        location    : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loaded      : state.store.loaded,
            responsive  : state.core.responsive,
            settings    : state.core.settings,
            pages       : state.core.pages,
            preferences : state.store.preferences,
            loading     : state.product.loading,
            error       : state.product.error,
            elem        : state.product.offer,
        };
    }
}

export default connect(OfferPage.mapStateToProps, {
    fetchOffer,
})(OfferPage);
