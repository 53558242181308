import React                from "react";

// Components
import HeaderWrapper        from "Components/Header/HeaderWrapper";
import HeaderLogo           from "Components/Header/HeaderLogo";
import HeaderBar            from "Components/Header/Bar/HeaderBar";
import MenuBar              from "Components/Header/Bar/MenuBar";
import UserBar              from "Components/Header/Bar/UserBar";
import SubMenuBar           from "Components/Header/Bar/SubMenuBar";



/**
 * The Big Logo Header
 * Two lines with a Big Logo on the Left
 */
class BigLogoHeader extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <HeaderWrapper design="big-logo">
            <HeaderLogo />
            <div className="header-header">
                <HeaderBar showSuperior />
                <div className="header-menu">
                    <MenuBar />
                    <UserBar />
                </div>
                <SubMenuBar />
            </div>
        </HeaderWrapper>;
    }
}

export default BigLogoHeader;
