import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/Core/NLS";
import Href                 from "Utils/Core/Href";

// Components
import Title                from "Components/Utils/Title/Title";



/**
 * The Auth Title
 */
class AuthTitle extends React.Component {
    /**
     * Returns the Title Depending on the Path
     * @returns {String}
     */
    getTitle() {
        let result = "";
        if (Href.isEqual("LOGIN")) {
            result = "AUTH_LOGIN_NAME";
        } else if (Href.isEqual("REGISTER")) {
            result = "AUTH_REGISTER_NAME";
        } else if (Href.isEqual("RECOVER")) {
            result = "AUTH_RECOVER_NAME";
        } else if (Href.startsWith("RESET")) {
            result = "AUTH_RESET_NAME";
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const variant = this.props.variant;
        const title   = this.getTitle();

        if (variant === "auth") {
            return <h2>{NLS.get(title)}</h2>;
        }
        return <Title message={title} />;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        variant : PropTypes.string.isRequired,
    }
}

export default AuthTitle;
