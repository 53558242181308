import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { setNotiRedirect }  from "Actions/Content/NotificationActions";
import Href                 from "Utils/Core/Href";
import NLS                  from "Utils/Core/NLS";
import ClassList            from "Utils/Common/ClassList";

// Components
import Icon                 from "Components/Utils/Media/Icon";

// Styles
import "Styles/Components/Utils/Form/Button.css";



/**
 * The Button
 */
class Button extends React.Component {
    /**
     * Handles the Click
     * @param {Event} e
     * @returns {Void}
     */
    handleClick = (e) => {
        const { onClick, url, target, history, setNotiRedirect } = this.props;

        const href  = url ? Href.url(url) : this.props.href;
        let handled = false;

        if (onClick) {
            onClick(e);
            handled = true;
        }
        if (href) {
            if (href === Href.url("LOGIN")) {
                setNotiRedirect(history.location.pathname);
            }
            if (target === "_blank") {
                window.open(href);
            } else {
                Href.gotoUrl(href);
            }
            handled = true;
        }
        if (handled) {
            e.preventDefault();
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            settings, isHidden, variant, className, message, icon, afterIcon,
            isDisabled, fullWidth, isSmall, onTouchEnd, children,
        } = this.props;
        const content = children || NLS.get(message);

        if (isHidden) {
            return <React.Fragment />;
        }
        const classes = new ClassList("btn", `btn-${variant}`, className);
        classes.addIf("btn-full",      fullWidth);
        classes.addIf("btn-small",     isSmall);
        classes.addIf("btn-border",    settings.main_borderButtons);
        classes.addIf("btn-uppercase", settings.main_uppercaseButtons);
        classes.addIf("btn-rounded",   settings.main_roundedBorders);

        return <button
            className={classes.get()}
            disabled={isDisabled}
            onClick={this.handleClick}
            onTouchEnd={onTouchEnd}
        >
            {!!icon      && <Icon className="btn-preicon" variant={icon} />}
            {!!content   && <span className="btn-content">{content}</span>}
            {!!afterIcon && <Icon className="btn-aftericon" variant={afterIcon} />}
        </button>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history         : PropTypes.object.isRequired,
        settings        : PropTypes.object.isRequired,
        setNotiRedirect : PropTypes.func.isRequired,
        variant         : PropTypes.string.isRequired,
        isHidden        : PropTypes.bool,
        className       : PropTypes.string,
        message         : PropTypes.string,
        icon            : PropTypes.string,
        afterIcon       : PropTypes.string,
        isDisabled      : PropTypes.bool,
        fullWidth       : PropTypes.bool,
        isSmall         : PropTypes.bool,
        href            : PropTypes.string,
        target          : PropTypes.string,
        onClick         : PropTypes.func,
        onTouchEnd      : PropTypes.func,
        children        : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
        target    : "_self",
        isHidden  : false,
        fullWidth : false,
        isSmall   : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default withRouter(connect(Button.mapStateToProps, {
    setNotiRedirect,
})(Button));
