import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchOrder }       from "Actions/Client/OrderActions";

// Components
import CartCompleted        from "Components/Cart/Utils/CartCompleted";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Title/Title";

// Styles
import "Styles/Components/Content/Cart.css";



/**
 * The Cart Result
 */
class CartResult extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchOrder(this.props.match.params.order);
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem } = this.props;

        return <>
            <Title message="CART_TITLE" />
            <Wrapper withSpacing>
                <CartCompleted orderID={elem.orderID} elem={elem} />
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchOrder : PropTypes.func.isRequired,
        match      : PropTypes.object.isRequired,
        elem       : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.order.elem,
        };
    }
}

export default connect(CartResult.mapStateToProps, {
    fetchOrder,
})(CartResult);
