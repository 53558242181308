import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/Core/NLS";
import KeyCode              from "Utils/Common/KeyCode";
import ClassList            from "Utils/Common/ClassList";
import Utils                from "Utils/Common/Utils";

// Styles
import "Styles/Images/Select.png";
import "Styles/Components/Utils/Form/TextField.css";



/**
 * The Text Field
 */
class TextField extends React.Component {
    // The Current State
    state = {
        isFocused : false,
        hasValue  : false,
        fileName  : "",
        rows      : 1,
    }

    // The Refs
    inputRef = React.createRef();



    /**
     * Called after is Mounted
     * @returns {Void}
     */
    componentDidMount() {
        const { type, autoFocus, value } = this.props;
        if (autoFocus) {
            this.focusInput();
        }
        if (type === "textarea" && value !== "") {
            this.handleTextareaChange();
        }
        this.setState({ hasValue : Boolean(value) });
    }

    /**
     * Called before is Unmounted
     * @returns {Void}
     */
    componentWillUnmount() {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
    }

    /**
     * Updated when a Prop change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.type === "file" && prevProps.value !== "" && this.props.value === "") {
            this.setState({ fileName : "" });
        }
        if (this.props.type === "textarea" && prevProps.value !== "" && this.props.value === "") {
            this.setState({ rows : 1 });
        }
        if (!prevProps.autoFocus && this.props.autoFocus) {
            this.focusInput();
        }
    }

    /**
     * Returns the Node
     * @returns {Object}
     */
    getNode() {
        Utils.getNode(this.props.passedRef || this.inputRef);
    }

    /**
     * Focus the Input
     * @returns {Void}
     */
    focusInput = () => {
        const node = this.getNode();
        if (node) {
            node.focus();
        }
    }

    /**
     * Blurs the Input
     * @returns {Void}
     */
    blurInput = () => {
        const node = this.getNode();
        if (node) {
            node.blur();
        }
    }



    /**
     * The Input got Focus
     * @returns {Void}
     */
    handleFocus = () => {
        this.setState({ isFocused : true });
        if (this.props.onFocus) {
            this.props.onFocus(true);
        }
    }

    /**
     * The Input lost Focus
     * @returns {Void}
     */
    handleBlur = () => {
        this.setState({ isFocused : false });
        if (this.props.onBlur) {
            this.props.onBlur(false);
        } else if (this.props.onFocus) {
            this.props.onFocus(false);
        }
    }

    /**
     * Handles the KeyInput
     * @param {Event} e
     * @returns {Void}
     */
    handleKey = (e) => {
        const { type, onSubmit, onKeyDown } = this.props;
        if (type !== "textarea" && e.keyCode === KeyCode.DOM_VK_RETURN && !!onSubmit) {
            onSubmit(e);
        }
        if (onKeyDown) {
            onKeyDown(e);
        }
    }

    /**
     * Handles the Input Change
     * @param {Event} e
     * @param {*}     value
     * @returns {Void}
     */
    handleChange = (e, value = e.target.value) => {
        this.setState({ hasValue : Boolean(value) });
        this.props.onChange(this.props.name, value);
    }

    /**
     * Handles the Checkbox Change
     * @param {Event} e
     * @returns {Void}
     */
    handleCheck = (e) => {
        this.props.onChange(this.props.name, e.target.checked ? 1 : 0);
    }

    /**
     * Handles the Radio Change
     * @param {Event} e
     * @returns {Void}
     */
    handleRadio = (e) => {
        this.props.onChange(this.props.name, e.target.checked ? e.target.value : 0);
    }



    /**
     * Handles the TextArea autogrow
     * @param {Event} e
     * @returns {Void}
     */
    handleTextareaChange = (e) => {
        const value = e.target.value;
        let   rows  = this.state.rows;

        if (this.props.autoGrow) {
            const maxRows      = 20;
            const spacing      = 16;
            const lineHeight   = 20;
            const previousRows = e.target.rows;
            e.target.rows      = 1;

            const currentRows = ~~((e.target.scrollHeight - spacing) / lineHeight);
            if (currentRows === previousRows) {
                e.target.rows = currentRows;
            }
            if (currentRows >= maxRows) {
                e.target.rows      = maxRows;
                e.target.scrollTop = e.target.scrollHeight;
            }
            rows = Math.min(currentRows, maxRows);
        }
        this.setState({ hasValue : Boolean(value), rows });
        this.props.onChange(this.props.name, value);
    }

    /**
     * Handles the File Click
     * @param {Event} e
     * @returns {Void}
     */
    handleFileClick = (e) => {
        const node = this.getNode();
        if (node) {
            node.click();
        }
        e.preventDefault();
    }

    /**
     * Handles the File Change
     * @param {Event} e
     * @returns {Void}
     */
    handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            this.setState({ fileName : file.name });
            this.handleChange(e, file);
        }
    }



    /**
     * Renders the Input depending on the Type
     * @returns {Object}
     */
    renderInput() {
        const { fileName, rows } = this.state;
        const {
            type, label, name, value, placeholder, isChecked, isDisabled, options, withNone,
            autoComplete, isLarge, noResize, noCapitalize, children, passedRef,
        } = this.props;

        const ref = passedRef || this.inputRef;

        switch (type) {
        case "textarea":
            return <textarea
                className={
                    "text-input text-textarea" +
                    (isLarge  ? " text-large"    : "") +
                    (noResize ? " text-noresize" : "")
                }
                ref={ref}
                rows={rows}
                name={name}
                value={value}
                placeholder={NLS.get(placeholder)}
                disabled={isDisabled}
                onChange={this.handleTextareaChange}
                onInput={this.handleTextareaChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onKeyDown={this.handleKey}
            />;

        case "select":
            return <select
                className="text-input"
                ref={ref}
                name={name}
                value={value}
                placeholder={NLS.get(placeholder)}
                disabled={isDisabled}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
            >
                {withNone && <option key="0" value="0" />}
                {options.map(({ key, value }) => (
                    <option key={key} value={key}>{value}</option>
                ))}
            </select>;

        case "file":
            return <div className="file-container">
                <div className="file-input" onClick={this.handleFileClick}>
                    {fileName}
                </div>
                <div className="file-box">
                    <span className="btn btn-primary" onClick={this.handleFileClick}>
                        {NLS.get("GENERAL_SELECT_FILE")}
                    </span>
                    <input
                        type="file"
                        name={name}
                        ref={ref}
                        onInput={this.handleFileChange}
                    />
                </div>
            </div>;

        case "toggle":
            return <div className="checkbox-container">
                <label>
                    <input
                        ref={ref}
                        type="radio"
                        name={name}
                        value="1"
                        checked={value}
                        disabled={isDisabled}
                        onChange={(e) => this.handleChange(e, 1)}
                    /><span />
                    {NLS.get("GENERAL_YES")}
                </label>
                <label>
                    <input
                        type="radio"
                        name={name}
                        value="0"
                        checked={!value}
                        disabled={isDisabled}
                        onChange={(e) => this.handleChange(e, 0)}
                    /><span />
                    {NLS.get("GENERAL_NO")}
                </label>
            </div>;

        case "checkbox":
            return <div className="checkbox-container">
                <label>
                    <input
                        ref={ref}
                        type="checkbox"
                        name={name}
                        value="1"
                        checked={value}
                        disabled={isDisabled}
                        onChange={this.handleCheck}
                    />
                    <span />
                    {children || NLS.get(label)}
                </label>
            </div>;

        case "radio":
            return <div className="checkbox-container">
                <label>
                    <input
                        ref={ref}
                        type="radio"
                        name={name}
                        value={value}
                        checked={isChecked}
                        disabled={isDisabled}
                        onChange={this.handleRadio}
                    />
                    <span />
                    {NLS.get(label)}
                </label>
            </div>;

        default:
            return <input
                className="text-input"
                ref={ref}
                type={type}
                name={name}
                value={value}
                placeholder={NLS.get(placeholder)}
                disabled={isDisabled}
                onChange={this.handleChange}
                onKeyDown={this.handleKey}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                autoComplete={autoComplete}
                autoCapitalize={noCapitalize ? "none" : ""}
            />;
        }
    }



    /**
     * Returns the ClassName
     * @param {Boolean} hasLabel
     * @returns {String}
     */
    getClassName(hasLabel) {
        const {
            settings, className, type, isRequired, value, error,
            withMargin, smallMargin, fullWidth, withNone, shrink,
        } = this.props;
        const { isFocused, hasValue } = this.state;

        const shrinkTypes   = [ "multiple", "date", "file", "checkbox", "radio" ];
        const withValue     = isFocused || hasValue || Boolean(value);
        const withTransform = !shrink && (!shrinkTypes.includes(type) || (type === "select" && withNone));
        const result        = new ClassList("text-field", `text-${type}`, className);

        result.addIf("text-labeled",      hasLabel);
        result.addIf("text-required",     isRequired);
        result.addIf("text-error",        !!error);
        result.addIf("text-margin",       withMargin);
        result.addIf("text-small-margin", smallMargin);
        result.addIf("text-full",         fullWidth);
        result.addIf("text-focused",      isFocused);
        result.addIf("text-transform",    withTransform);
        result.addIf("text-value",        withValue);
        result.addIf("text-rounded",      settings.main_roundedBorders);

        return result.get();
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isHidden, type, label, withLabel, helperText, error } = this.props;

        const hasLabel  = Boolean(withLabel && label && type !== "checkbox");
        const hasError  = Boolean(error);
        const hasHelper = !hasError && Boolean(helperText);
        const className = this.getClassName(hasLabel);

        if (isHidden) {
            return <React.Fragment />;
        }

        return <label className={className}>
            {hasLabel && <p className="text-label">
                {NLS.get(label)}
            </p>}
            <div className="text-content">
                {this.renderInput()}
            </div>
            {hasError && <p className="text-field-error">
                {NLS.get(error)}
            </p>}
            {hasHelper && <p className="text-field-helper">
                {NLS.get(helperText)}
            </p>}
        </label>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings     : PropTypes.object.isRequired,
        isHidden     : PropTypes.bool,
        passedRef    : PropTypes.any,
        className    : PropTypes.string,
        type         : PropTypes.string,
        name         : PropTypes.string.isRequired,
        label        : PropTypes.string,
        placeholder  : PropTypes.string,
        value        : PropTypes.any,
        isChecked    : PropTypes.bool,
        autoComplete : PropTypes.string,
        isRequired   : PropTypes.bool,
        isDisabled   : PropTypes.bool,
        onChange     : PropTypes.func.isRequired,
        onKeyDown    : PropTypes.func,
        onSubmit     : PropTypes.func,
        onFocus      : PropTypes.func,
        onBlur       : PropTypes.func,
        error        : PropTypes.string,
        helperText   : PropTypes.string,
        options      : PropTypes.array,
        shrink       : PropTypes.bool,
        fullWidth    : PropTypes.bool,
        isLarge      : PropTypes.bool,
        autoGrow     : PropTypes.bool,
        noResize     : PropTypes.bool,
        noCapitalize : PropTypes.bool,
        withLabel    : PropTypes.bool,
        withMargin   : PropTypes.bool,
        smallMargin  : PropTypes.bool,
        withNone     : PropTypes.bool,
        autoFocus    : PropTypes.bool,
        children     : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        isHidden     : false,
        className    : "",
        type         : "text",
        autoComplete : "off",
        isRequired   : false,
        isDisabled   : false,
        options      : [],
        shrink       : false,
        isLarge      : false,
        autoGrow     : false,
        noResize     : false,
        withLabel    : true,
        withMargin   : false,
        smallMargin  : false,
        withNone     : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(TextField.mapStateToProps)(TextField);
