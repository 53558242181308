import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "Utils/Core/Href";
import Responsive           from "Utils/App/Responsive";

// Components
import TerTitle             from "Components/Utils/Title/TerTitle";
import Slider               from "Components/Utils/Common/Slider";
import ImageLink            from "Components/Utils/Media/ImageLink";

// Styles
import "Styles/Components/Content/Sections/ImageList.css";



/**
 * The Image List
 */
class ImageList extends React.Component {
    /**
     * Does the Content Render
     * @returns {Object}
     */
    renderContent() {
        const { responsive, list, cols, url, useSlug } = this.props;
        const result = [];

        for (const elem of list) {
            const href = elem.link || Href.url(url, useSlug ? elem.slug : elem.id);
            let  image = elem.desktopImageUrl;
            if (elem.mobileImageUrl && (responsive.isMicro || cols > 1)) {
                image = elem.mobileImageUrl;
            }
            result.push(<ImageLink
                key={elem.id}
                variant="opacity"
                href={href}
                source={image}
                name={elem.name}
            />);
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { responsive, className, message, list, cols, minCols, asSlider } = this.props;

        if (!list.length) {
            return <React.Fragment />;
        }
        const count = Responsive.calcColumns(responsive, cols, minCols, list.length);
        const style = { "--image-cols" : count };

        return <section className={className + " section image-list"}>
            <TerTitle message={message} />
            {asSlider ? <Slider
                className="image-list-slider"
                total={list.length}
                count={count}
                outsideNav
            >
                {this.renderContent()}
            </Slider> : <div className="image-list-grid" style={style}>
                {this.renderContent()}
            </div>}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        responsive : PropTypes.object.isRequired,
        className  : PropTypes.string,
        message    : PropTypes.string,
        list       : PropTypes.array.isRequired,
        cols       : PropTypes.number,
        minCols    : PropTypes.number,
        url        : PropTypes.string,
        useSlug    : PropTypes.bool,
        asSlider   : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
        minCols   : 1,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            responsive : state.core.responsive,
        };
    }
}

export default connect(ImageList.mapStateToProps)(ImageList);
