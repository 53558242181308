import Auth                 from "Utils/Core/Auth";
import Utils                from "Utils/Common/Utils";

// Actions
import {
    setLoading, showResult,
} from "Actions/Core/CoreActions";

// Module variables
let store      = null;
let forPreview = false;
let longTerm   = false;
let controller = null;
let endTimeout = null;



/**
 * Initialize the API
 * @param {Object}  theStore
 * @param {Boolean} isPreview
 * @param {Boolean} isApp
 * @returns {Void}
 */
function init(theStore, isPreview, isApp) {
    store      = theStore;
    forPreview = isPreview;
    longTerm   = isApp;
    store.dispatch(setLoading(false));
}

/**
 * Shows the Loader
 * @param {Boolean=} hasLoader
 * @returns {Void}
 */
function showLoader(hasLoader = true) {
    if (hasLoader) {
        if (endTimeout) {
            window.clearTimeout(endTimeout);
            endTimeout = null;
        } else {
            store.dispatch(setLoading(true));
        }
    }
}

/**
 * Hides the Loader
 * @param {Boolean=} hasLoader
 * @returns {Void}
 */
function hideLoader(hasLoader = true) {
    if (hasLoader) {
        endTimeout = window.setTimeout(() => {
            store.dispatch(setLoading(false));
            endTimeout = null;
        }, 200);
    }
}



/**
 * Fetch wrapper
 * @param {(String|URL)} url
 * @param {Object=}      options
 * @param {Boolean=}     hasLoader
 * @returns {Object}
 */
async function ajax(url, options = {}, hasLoader = true) {
    const defError = { form : "GENERAL_ERROR" };
    let response   = null;
    let result     = null;

    // To be able to Aboert
    if (window.AbortController) {
        controller     = new window.AbortController();
        options.signal = controller.signal;
    }

    // Show the Loader
    showLoader(hasLoader);

    // Do the Request
    try {
        response = await fetch(url, options);
        hideLoader(hasLoader);
    } catch (error) {
        hideLoader(hasLoader);
        throw defError;
    }

    // Bad Response
    if (!response.ok) {
        throw defError;
    }

    // Get the JSON Result
    try {
        result = await response.text();
        // Hack to fix php warnnings
        if (result.indexOf("<br />") > -1) {
            result = result.split("<br />")[0];
        }
        result = JSON.parse(result);
    } catch (error) {
        throw defError;
    }

    // The session ended
    if (result.userLoggedOut) {
        Auth.unsetToken();
        return null;
    }

    // Update the Token
    if (result.jwt) {
        Auth.setToken(result.jwt);
    }

    // There was an error
    if (result.errors && !Utils.isEmpty(result.errors)) {
        if (result.data) {
            throw result;
        }
        throw result.errors;
    }

    // Show the Result
    if (!result.data) {
        result.data = {};
    }
    if (result.success) {
        store.dispatch(showResult("success", result.success));
        result.data.success = result.success;
    }
    if (result.warning) {
        store.dispatch(showResult("warning", result.warning));
        result.data.warning = result.warning;
    }
    if (result.error) {
        store.dispatch(showResult("error", result.error));
        result.data.error = result.error;
    }

    // Return just the data
    return result.data;
}

/**
 * Aborts a Fetch
 * @returns {Void}
 */
function abort() {
    if (controller) {
        controller.abort();
    }
    if (endTimeout) {
        window.clearTimeout(endTimeout);
        store.dispatch(setLoading(false));
    }
}



/**
 * Creates a new Url
 * @param {String}   route
 * @param {Object=}  params
 * @param {Boolean=} forAdmin
 * @returns {URL}
 */
function createUrl(route, params = {}, forAdmin = false) {
    const url   = new URL(process.env.REACT_APP_API + route);
    const token = Auth.getToken();

    for (const [ key, value ] of Object.entries(params)) {
        url.searchParams.append(key, value);
    }
    if (forPreview) {
        url.searchParams.append("preview", 1);
    }
    if (longTerm) {
        url.searchParams.append("app", 1);
    }
    if (token) {
        url.searchParams.append("jwt", token);
    }
    if (forAdmin) {
        const adminToken = Auth.getAdminToken();
        if (adminToken) {
            url.searchParams.append("adminJWT", adminToken);
        }
    }
    return url;
}

/**
 * Does a Get Request
 * @param {String}   route
 * @param {Object=}  params
 * @param {Boolean=} hasLoader
 * @param {Boolean=} forAdmin
 * @returns {Object}
 */
async function get(route, params = {}, hasLoader = true, forAdmin = false) {
    const url = createUrl(route, params, forAdmin);
    let result;

    try {
        result = await ajax(url, {}, hasLoader);
    } catch(e) {
        result = { error : true };
    }
    return result;
}

/**
 * Does a Post Request
 * @param {String}   route
 * @param {Object=}  params
 * @param {Boolean=} hasLoader
 * @returns {Object}
 */
function post(route, params = {}, hasLoader = true) {
    const url  = createUrl(route);
    const body = new FormData();
    for (const [ key, value ] of Object.entries(params)) {
        body.append(key, value);
    }
    return ajax(url, { method : "post", body }, hasLoader);
}

/**
 * Returns the url
 * @param {String}  route
 * @param {Object=} params
 * @returns {String}
 */
function url(route, params = {}) {
    return createUrl(route, params).href;
}



/**
 * The API functions
 */
export default {
    init,
    abort,


    // Auth actions
    Auth : {
        "getData"             : (data) => get("/session/getData",            data, false),
        "enter"               : (data) => post("/session/enter",             data),
        "login"               : (data) => post("/session/login",             data),
        "recover"             : (data) => post("/session/recover",           data),
        "reset"               : (data) => post("/session/reset",             data),
        "register"            : (data) => post("/session/register",          data),
        "logout"              : (data) => post("/session/logout",            data),
    },
    Metrics : {
        "startSession"        : (data) => post("/metrics/startSession",      data, false),
        "updateSession"       : (data) => post("/metrics/updateSession",     data, false),
        "pageView"            : (data) => post("/metrics/pageView",          data, false),
        "productView"         : (data) => post("/metrics/productView",       data, false),
    },
    Device : {
        "add"                 : (data) => post("/device/add",                data, false),
        "edit"                : (data) => post("/device/edit",               data, false),
        "remove"              : (data) => post("/device/remove",             data, false),
    },

    // Store actions
    Store : {
        "getSettings"         : (data) => get("/store/getSettings",          data, false, true),
        "getData"             : (data) => get("/store/getData",              data),
        "getHome"             : (data) => get("/store/getHome",              data),
        "getPage"             : (data) => get("/store/getPage",              data),
        "getEntry"            : (data) => get("/store/getEntry",             data),
        "getArtist"           : (data) => get("/store/getArtist",            data),
        "getUpdates"          : (data) => get("/store/getUpdates",           data, false),
        "setPreference"       : (data) => post("/store/setPreference",       data, false),
        "discardNotification" : (data) => post("/store/discardNotification", data, false),
        "toggleFavorite"      : (data) => post("/store/toggleFavorite",      data, false),
        "addToHistory"        : (data) => post("/store/addToHistory",        data, false),
    },
    Product : {
        "getProduct"          : (data) => get("/product/getProduct",         data),
        "getRoomImage"        : (data) => url("/product/getRoomImage",       data),
        "getProducts"         : (data) => get("/product/getProducts",        data),
        "getOffer"            : (data) => get("/product/getOffer",           data),
        "getBrand"            : (data) => get("/product/getBrand",           data),
        "getArtist"           : (data) => get("/product/getArtist",          data),
        "getSearch"           : (data) => get("/product/getSearch",          data),
        "getNewests"          : (data) => get("/product/getNewests",         data),
        "getImportants"       : (data) => get("/product/getImportants",      data),
        "getFavorites"        : (data) => get("/product/getFavorites",       data),
        "getPurchases"        : (data) => get("/product/getPurchases",       data),
        "getHistory"          : (data) => get("/product/getHistory",         data),
    },
    Cart : {
        "getCart"             : (data) => get("/cart/getCart",               data),
        "addProduct"          : (data) => post("/cart/addProduct",           data, false),
        "editProduct"         : (data) => post("/cart/editProduct",          data, false),
        "removeProduct"       : (data) => post("/cart/removeProduct",        data, false),
        "priceShipment"       : (data) => post("/cart/priceShipment",        data),
        "tryShipment"         : (data) => post("/cart/tryShipment",          data),
        "tryPayment"          : (data) => post("/cart/tryPayment",           data),
        "confirmProducts"     : (data) => post("/cart/confirmProducts",      data),
        "confirmShipment"     : (data) => post("/cart/confirmShipment",      data),
        "confirmPayment"      : (data) => post("/cart/confirmPayment",       data),
        "unconfirmToProducts" : (data) => post("/cart/unconfirmToProducts",  data),
        "unconfirmToShipment" : (data) => post("/cart/unconfirmToShipment",  data),
    },

    // Client actions
    Chat : {
        "getMessages"         : (data, loader) => get("/chat/getMessages",   data, loader),
        "sendMessage"         : (data) => get("/chat/sendMessage",           data, false),
        "markAsRead"          : (data) => get("/chat/markAsRead",            data, false),
    },
    Client : {
        "getAccount"          : (data) => get("/client/getAccount",          data),
        "editAccount"         : (data) => post("/client/editAccount",        data),
    },
    Address : {
        "getAddresses"        : (data) => get("/address/getAddresses",       data),
        "getAddress"          : (data) => get("/address/getAddress",         data),
        "editAddress"         : (data) => post("/address/editAddress",       data),
        "deleteAddress"       : (data) => post("/address/deleteAddress",     data),
    },
    Voucher : {
        "getInvoices"         : (data) => get("/voucher/getInvoices",        data),
        "getReceipts"         : (data) => get("/voucher/getReceipts",        data),
        "getCreditNotes"      : (data) => get("/voucher/getCreditNotes",     data),
        "getDebitNotes"       : (data) => get("/voucher/getDebitNotes",      data),
    },
    Query : {
        "getQueries"          : (data) => get("/query/getQueries",           data),
        "getQuery"            : (data) => get("/query/getQuery",             data),
        "getQueryCreate"      : (data) => get("/query/getQueryCreate",       data),
        "createQuery"         : (data) => post("/query/createQuery",         data),
        "replyQuery"          : (data) => post("/query/replyQuery",          data),
        "sendContact"         : (data) => post("/query/sendContact",         data),
    },
    Order : {
        "getOrders"           : (data) => get("/order/getOrders",            data),
        "getOrder"            : (data) => get("/order/getOrder",             data),
        "getTicket"           : (data) => url("/order/getTicket",            data),
        "sendVoucher"         : (data) => post("/order/sendVoucher",         data),
    },
};
